import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import NewHome from './components/pages/NewHome';

const PrivateRoute = ({component: Component, authed, ...rest}) => (
    <Route
        {...rest}
        render={(props) =>
            authed === true ? (
                <Component {...props} />
            ) : (
                <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
            )
        }
    />
);

const AppRouter = (props) => (
    <Switch history={props.history}>
        <Route exact path='/newhome' component={NewHome}/>
            <Route exact path='/newhome' component={NewHome}/>
    </Switch>
);

export default AppRouter;
