import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    Grid,
    Form,
    Button,
    Header,
    Dimmer,
    Loader,
    Card,
    Divider, Table,
} from 'semantic-ui-react';

import {Media} from '../../AppMedia';
import {actionCreators} from '../../store/Users';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import {getConfiguration} from '../../store/api/apiClient';
import {toFormatCurrencyWithCent} from '../../utils/NumberHelper';
import {Logger} from '../../utils/Logger';

class AddTip extends Component {
    state = {
        data: {
            tip: 0,
        },
        isCustom: false,
        loading: true,
        error: null,
    };

    componentWillReceiveProps(nextProps) {
        try {
            const data = {tip: nextProps.ticket.ticket_order.tip ? nextProps.ticket.ticket_order.tip.toFixed(2) : ''};
            const subTotalAmount = nextProps.ticket.totals.sub_total.replace(/[^0-9.]/g, '');

            const tenPercent = (0.1 * subTotalAmount).toFixed(2);
            const fifteenPercent = (0.15 * subTotalAmount).toFixed(2);
            const twentyPercent = (0.2 * subTotalAmount).toFixed(2);
            const isCustom = data.tip > 0 && tenPercent !== data.tip && fifteenPercent !== data.tip && twentyPercent !== data.tip;
            this.setState({
                data,
                isCustom,
                tenPercent,
                fifteenPercent,
                twentyPercent,
                settings: nextProps.ticket.ticket_settings,
                loading: false
            });
        } catch (err) {
            this.setState({loading: false});
            Logger.error('AddTip', 'componentWillReceiveProps', JSON.stringify(err));
        }
    }

    componentWillMount() {
        this.props.getTicketOrder()
    }

    async updateTicketOrder(e) {
        e.preventDefault();
        try {
            this.setState({loading: true})
            const error = await this.props.updateTicketOrderTip(parseFloat(this.state.data.tip || 0))
            this.setState({loading: false})
            if (!error) this.props.nextStep('payment');
        } catch (err) {
            Logger.error('AddTip', 'updateTicketOrder', JSON.stringify(err));
        }
    }

    handleGoBack() {
        try {
            const isPromoEnable = this.props.ticket.ticket_settings ? this.props.locations.location.settings.promo_codes.enable : false;
            this.props.handleGoBack(isPromoEnable ? 'promo' : 'information');
        } catch (err) {
            Logger.error('AddTip', 'handleGoBack', JSON.stringify(err));
        }
    }

    renderDesktop() {
        const {data, isCustom, tenPercent, fifteenPercent, twentyPercent, loading} = this.state;

        return (
            <Grid textAlign={'center'}>
                <Grid.Column width={14}>
                    <Table basic={'very'}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={3}>
                                    <span style={{fontWeight: 600}}>
                                        Would you like to add a tip?
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan={3}>
                                    <Form.Group widths={'equal'}>
                                        <Button
                                            className={'tip_button'}
                                            size='large'
                                            style={
                                                isCustom || tenPercent !== data.tip
                                                    ? {width: "50%"}
                                                    : {
                                                        width: "50%",
                                                        ...getConfiguration().button_color,
                                                    }
                                            }
                                            onClick={() => this.setState({
                                                ...this.state,
                                                data: {tip: tenPercent},
                                                isCustom: false
                                            })}
                                        >
                                            10% ({toFormatCurrencyWithCent(tenPercent)})
                                        </Button>
                                        <Button
                                            className={'tip_button'}
                                            size='large'
                                            style={
                                                isCustom || fifteenPercent !== data.tip
                                                    ? {width: "50%"}
                                                    : {
                                                        width: "50%",
                                                        ...getConfiguration().button_color,
                                                    }
                                            }
                                            onClick={() => this.setState({
                                                ...this.state,
                                                data: {tip: fifteenPercent},
                                                isCustom: false
                                            })}
                                        >
                                            15% ({toFormatCurrencyWithCent(fifteenPercent)})
                                        </Button>
                                    </Form.Group>
                                    <Form.Group>
                                        <Button
                                            className={'tip_button'}
                                            size='large'
                                            style={
                                                isCustom || twentyPercent !== data.tip
                                                    ? {width: "50%"}
                                                    : {
                                                        width: "50%",
                                                        ...getConfiguration().button_color,
                                                    }
                                            }
                                            onClick={() => this.setState({
                                                ...this.state,
                                                data: {tip: twentyPercent},
                                                isCustom: false
                                            })}
                                        >
                                            20% ({toFormatCurrencyWithCent(twentyPercent)})
                                        </Button>
                                        <Button
                                            className={'tip_button'}
                                            size='large'
                                            onClick={() => this.setState({
                                                ...this.state,
                                                data: {tip: 0},
                                                isCustom: true
                                            })}
                                            style={
                                                isCustom
                                                    ? {
                                                        width: "23%",
                                                        ...getConfiguration().button_color,
                                                        padding: 0,
                                                    }
                                                    : {
                                                        width: "50%",
                                                        padding: 0,
                                                    }
                                            }
                                        >
                                            CUSTOM TIP
                                        </Button>
                                        {isCustom ? (
                                            <Form.Input
                                                type='text'
                                                pattern='\d+(\.\d*)?'
                                                style={{minWidth: "100%"}}
                                                onInput={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        data: {
                                                            tip: e.target.validity.valid
                                                                ? e.target.value
                                                                : data.tip,
                                                        },
                                                    })
                                                }
                                                value={data.tip || ''}
                                                placeholder='Enter Amount'
                                                maxLength='5'
                                            />
                                        ) : null}
                                    </Form.Group>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                        <Grid doubling columns={2} centered>
                            <Grid.Column width={5}>
                                <Button
                                    fluid
                                    size='large'
                                    circular
                                    onClick={this.handleGoBack.bind(this)}
                                >
                                    BACK
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Button
                                    fluid
                                    size='large'
                                    circular
                                    disabled={loading}
                                    onClick={this.updateTicketOrder.bind(this)}
                                    style={getConfiguration().button_color}
                                >
                                    CONTINUE
                                </Button>
                            </Grid.Column>
                        </Grid>
                    ) : null}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile() {
        const {data, isCustom, tenPercent, fifteenPercent, twentyPercent, loading} = this.state;

        return (
            <>
                <Form.Field>
                    <Header as='h3' textAlign='center'>
                        Would you like to add a tip?
                    </Header>
                </Form.Field>
                <Form.Button
                    fluid
                    style={
                        isCustom || tenPercent !== data.tip
                            ? {}
                            : {...getConfiguration().button_color}
                    }
                    onClick={() => this.setState({...this.state, data: {tip: tenPercent}, isCustom: false})}
                >
                    10% ({toFormatCurrencyWithCent(tenPercent)})
                </Form.Button>
                <Form.Button
                    fluid
                    style={
                        isCustom || fifteenPercent !== data.tip
                            ? {}
                            : {...getConfiguration().button_color}
                    }
                    onClick={() => this.setState({...this.state, data: {tip: fifteenPercent}, isCustom: false})}
                >
                    15% ({toFormatCurrencyWithCent(fifteenPercent)})
                </Form.Button>
                <Form.Button
                    fluid
                    style={
                        isCustom || twentyPercent !== data.tip
                            ? {}
                            : {...getConfiguration().button_color}
                    }
                    onClick={() => this.setState({...this.state, data: {tip: twentyPercent}, isCustom: false})}
                >
                    20% ({toFormatCurrencyWithCent(twentyPercent)})
                </Form.Button>
                <Form.Button
                    fluid
                    onClick={() => this.setState({...this.state, data: {tip: 0}, isCustom: true})}
                    style={isCustom ? {...getConfiguration().button_color} : {}}
                >
                    CUSTOM TIP
                </Form.Button>
                {isCustom ? (
                    <Form.Field>
                        <input
                            type='number'
                            inputMode='decimal'
                            step='0.01'
                            onChange={(e) =>
                                this.setState({
                                    data: {
                                        tip: e.target.validity.valid
                                            ? e.target.value
                                            : data.tip,
                                    },
                                })
                            }
                            value={data.tip || ''}
                            placeholder='Enter Amount'
                            maxLength='7'
                        />
                    </Form.Field>
                ) : null}
                {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                    <>
                        <Form.Button
                            fluid
                            circular
                            disabled={loading}
                            onClick={this.updateTicketOrder.bind(this)}
                            style={getConfiguration().button_color}
                        >
                            CONTINUE
                        </Form.Button>
                        <Form.Button
                            fluid
                            circular
                            onClick={this.handleGoBack.bind(this)}
                        >
                            BACK
                        </Form.Button>
                    </>
                ) : null}
            </>
        );
    }

    render() {
        const {loading} = this.state;

        return (
            <>
                <Dimmer active={loading} inverted>
                    <Loader size='small' inline='centered'>
                        Loading
                    </Loader>
                </Dimmer>
                <Media at='mobile'>
                    {this.renderMobile()}
                </Media>
                <Media greaterThanOrEqual='tablet'>
                    {this.renderDesktop()}
                </Media>
            </>
        );
    }
}

export default connect(
    (state) => {
        const {users, locations, ticket} = state;
        return {users, locations, ticket};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
            },
            dispatch
        )
)(AddTip);
