import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {range} from 'lodash';
import {Card, Dimmer, Dropdown, Grid, Header, Image, Label, Loader, Menu, Placeholder,} from 'semantic-ui-react';
import Carousel from 'semantic-ui-carousel-react';
import 'semantic-ui-carousel-react/dist/index.css';

import {Media} from '../../AppMedia';
import GlutenVeganView from '../common/ui/components/GlutenVeganView';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Menus';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import {getConfiguration} from '../../store/api/apiClient';

class MenuCategoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuId: props.match.params.menuId,
            categoryId: props.match.params.categoryId,
            loading: true,
        };
    }

    componentDidMount() {
        this.handleInit();
        this.scrollStep()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.scrollStep()
    }

    scrollStep() {
        if(window.document.getElementById('orderItem')){
            window.document.getElementById('orderItem').scrollTo({top:0, behavior: "smooth"})
            window.document.getElementById('orderItem').scroll({top:0, behavior: "smooth"})
        }
    }

    handleInit = async () => {
        this.setState({ loading: true });
        await this.props.clearCustomized();
        await this.props.getMenus(true);
        await this.props.getMenuCategories(true, this.props.match.params.menuId);
        await this.props.getMenuCategoryItems(
            true,
            this.props.match.params.menuId,
            this.props.match.params.categoryId
        );
        await this.props.getLocation();
        await this.props.getTicketOrder();
        this.setState({ loading: false });
    }

    goToCategory(menu) {
        this.setState({
            menuId: menu.external_id,
        });
        this.props.getMenuCategories(true, menu.external_id);
    }

    goToCategoryItems(menu) {
        this.scrollStep()
        this.setState({
            categoryId: menu.external_id,
        });
        this.props.getMenuCategoryItems(true, menu.external_menu_id, menu.external_id);
    }

    addToCart(menu) {
        this.props.pushMenuTitle(menu.name.toUpperCase());
        this.props.history.push(
            '/menus/' +
            menu.external_menu_id +
            '/category/' +
            menu.external_menu_category_id +
            '/item/' +
            menu.external_id
        );
    }

    renderDesktop(params) {
        const {backgroundColor, rest, currentCategory} = params;

        return (
            <Grid textAlign='center' verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 1280, marginTop: 20}}>
                    {currentCategory.description &&
                        <Card fluid raised>
                            <Card.Content textAlign='left'>
                                {currentCategory.description}
                            </Card.Content>
                        </Card>
                    }
                    <Card.Group stackable doubling itemsPerRow={3}>
                        {this.props.menus.items ? (
                            this.props.menus.items.items.length > 0 ? (
                                this.props.menus.items.items.map((menu, index) => {
                                    let rowHeight = 86
                                    let lineToShow = 4
                                    return (
                                        <Card
                                            raised
                                            key={menu.id}
                                            onClick={this.addToCart.bind(this, menu)}
                                            style={{
                                                maxHeight: 300,
                                                backgroundImage:
                                                'url("' +
                                                (menu.images.length > 0
                                                    ? menu.images[0].imageSignedUrl
                                                    : 'none') +
                                                '")',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center top',
                                            }}
                                        >
                                            {menu.images.length === 1 ? null : menu.images.length >
                                            1 ? (
                                                <Carousel
                                                    style={{
                                                        height: 300,
                                                        width: 'auto',
                                                    }}
                                                    elements={menu.images.map((image) => {
                                                        return {
                                                            render: () => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            border: 'none',
                                                                            height: 300,
                                                                            backgroundImage:
                                                                            'url("' + image.imageSignedUrl + '")',
                                                                            backgroundSize: 'cover',
                                                                            backgroundPosition: 'center top',
                                                                        }}
                                                                    />
                                                                );
                                                            },
                                                        };
                                                    })}
                                                    duration={3000}
                                                    animation='flip'
                                                    showNextPrev={false}
                                                    showIndicators={false}
                                                />
                                            ) : null}
                                            <Card.Content
                                                className='item-content'
                                                style={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    bottom: 0,
                                                    textAlign: 'center',
                                                    background: 'linear-gradient(to left bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 1))',
                                                    background: '-webkit-linear-gradient(left bottom, rgb(253, 253, 253, 0.9) 65%, rgba(0, 0, 0, 0.1) 100%)',
                                                    borderTop: 'none',
                                                }}
                                            >
                                                <Grid textAlign='left'>
                                                    <Grid.Row style={{paddingBottom: 6, paddingTop: 15}}>
                                                        <Grid.Column width={16}
                                                                     style={{paddingRight: 15, paddingLeft: 20}}>
                                                            <Header
                                                                style={{
                                                                    lineHeight: '22px',
                                                                    fontSize: 18,
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'hidden',
                                                                    textShadow: '1px 1px 5px white',
                                                                }}
                                                                as={'h4'}
                                                            >
                                                                {menu.name}
                                                            </Header>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: [
                                                                ' .ellipsis-' + index + ' {' +
                                                                '  display: block; /* Fallback for non-webkit */\n' +
                                                                '  display: -webkit-box;\n' +
                                                                '  -webkit-line-clamp: ' + lineToShow + ';\n' +
                                                                '  -webkit-box-orient: vertical;\n' +
                                                                '  overflow: hidden;\n' +
                                                                '  text-overflow: ellipsis;\n' +
                                                                '  margin-bottom: 10px !important; \n' +
                                                                '}',
                                                            ].join('\n')
                                                        }}
                                                    >
                                                    </style>
                                                    <Grid.Row
                                                        style={{
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            minHeight: rowHeight,
                                                            display: 'inline-block',
                                                            verticalAlign: 'top',
                                                        }}
                                                    >
                                                        {menu.description.length > 0 ? (
                                                            <Grid.Column

                                                                style={{paddingRight: 15, paddingLeft: 20}}>

                                                                <p className={'ellipsis-' + index}
                                                                   style={{
                                                                       color: 'black', fontSize: 14
                                                                   }}>
                                                                    {menu.description}
                                                                </p>
                                                            </Grid.Column>) : null}
                                                    </Grid.Row>
                                                </Grid>
                                            </Card.Content>
                                            <Card.Content
                                                extra
                                                style={{
                                                    position: 'absolute',
                                                    width: 70,
                                                    top: 0,
                                                    left: 10,
                                                    textAlign: 'center',
                                                    color: 'white',
                                                    backgroundColor,
                                                }}
                                            >
                                                <span style={rest}>
                                                $
                                                    {menu.omnivore
                                                        ? menu.omnivore.price_per_unit.toFixed(2)
                                                        : 0.0}
                                                </span>
                                            </Card.Content>
                                            {(menu.vegan || menu.gluten_free) && (
                                                <GlutenVeganView
                                                    vegan={menu.vegan}
                                                    gluten_free={menu.gluten_free}
                                                    style={{
                                                        display: 'flex',
                                                        position: 'absolute',
                                                        bottom: 130,
                                                        right: 10,
                                                    }}
                                                />
                                            )}
                                        </Card>
                                    );
                                })
                            ) : (
                                <span
                                    style={{fontSize: 18, margin: '30px auto 10px auto'}}
                                    color='black'
                                >
                                    {' '}
                                    No items on this category
                                </span>
                            )
                        ) : (
                            range(9).map((menu) => (
                                <Card
                                    raised
                                    key={menu}
                                    style={{
                                        maxHeight: 300,
                                    }}
                                >
                                    <Placeholder>
                                        <Placeholder.Image rectangular/>
                                    </Placeholder>
                                    <Card.Content
                                        className='item-content'
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            bottom: 0,
                                            textAlign: 'center',
                                            background: 'linear-gradient(to left bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 1))',
                                            background: '-webkit-linear-gradient(left bottom, rgb(253, 253, 253, 0.9) 65%, rgba(0, 0, 0, 0.1) 100%)',
                                            borderTop: 'none',
                                        }}
                                    >
                                        <Grid textAlign='left'>
                                            <Grid.Row>
                                                <Grid.Column width={16}
                                                             style={{paddingRight: 0}}>
                                                    <Placeholder>
                                                        <Placeholder.Header>
                                                            <Placeholder.Line/>
                                                        </Placeholder.Header>
                                                    </Placeholder>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row
                                                style={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    maxHeight: 85,
                                                    minHeight: 85,
                                                }}
                                            >
                                                <Grid.Column>
                                                    <Placeholder>
                                                        <Placeholder.Paragraph>
                                                            <Placeholder.Line/>
                                                            <Placeholder.Line/>
                                                            <Placeholder.Line/>
                                                        </Placeholder.Paragraph>
                                                    </Placeholder>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Content>
                                </Card>
                            ))
                        )}
                    </Card.Group>
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(currentCategory) {
        return (
            <Grid verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 1080, marginTop: 15}}>
                    {currentCategory.description &&
                        <Card fluid raised>
                            <Card.Content textAlign='left'>
                                {currentCategory.description}
                            </Card.Content>
                        </Card>
                    }
                    <Card.Group centered>
                        {this.props.menus.items ? (
                            this.props.menus.items.items.length > 0 ? (
                                this.props.menus.items.items.map((menu) => {
                                    return (
                                        <Card
                                            fluid
                                            key={menu.id}
                                            onClick={this.addToCart.bind(this, menu)}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Card.Content>
                                                <Image
                                                    floated='left'
                                                    size='tiny'
                                                    style={{
                                                        height: 64,
                                                        marginLeft: -14,
                                                        marginBottom: -14,
                                                        marginTop: -14,
                                                        borderRadius: 5,
                                                    }}
                                                    src={
                                                        menu.images.length > 0
                                                            ? menu.images[0].imageSignedUrl
                                                            : 'none'
                                                    }
                                                />
                                                <Card.Header
                                                    style={{
                                                        fontSize: '14px',
                                                        overflow: 'hidden',
                                                        textDecoration: 'none',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {menu.name}
                                                </Card.Header>
                                                <Card.Meta>
                                                    {`$${
                                                        menu.omnivore
                                                            ? menu.omnivore.price_per_unit.toFixed(2)
                                                            : 0.0
                                                        }`}
                                                    {(menu.vegan || menu.gluten_free) && (
                                                        <GlutenVeganView
                                                            vegan={menu.vegan}
                                                            gluten_free={menu.gluten_free}
                                                            style={{
                                                                display: 'flex',
                                                                float: 'right',
                                                            }}
                                                        />
                                                    )}
                                                </Card.Meta>
                                            </Card.Content>
                                        </Card>
                                    );
                                })
                            ) : (
                                <Label size='large'>No items on this category</Label>
                            )
                        ) : (
                            range(9).map((menu) => (
                                <Card fluid key={menu} style={{ margin: '.5em .5em' }}>
                                    <Card.Content>
                                        <Placeholder>
                                            <Placeholder.Header image>
                                                <Placeholder.Line/>
                                                <Placeholder.Line/>
                                            </Placeholder.Header>
                                        </Placeholder>
                                    </Card.Content>
                                </Card>
                            ))
                        )}
                    </Card.Group>
                </Grid.Column>
            </Grid>
        );
    }

    render() {
        const {title} = this.props.menus;
        const {backgroundColor, ...rest} = getConfiguration().button_color;

        let cutIndex = 9
        let cutMenuIndex = 9
        let found = false
        let foundMenu = false
        const concatCategories = this.props.menus.categories ? this.props.menus.categories.categories.reduce((accumulator, currentValue, index) => {
            let currentLength = accumulator.concat(currentValue.name).join(" ").length
            if (!found && currentLength > 115) {
                found = true
                cutIndex = index
            }
            return accumulator.concat(currentValue.name)
        }, []).join(" ").length : null

        const concatMenus = this.props.menus.data.menus ? this.props.menus.data.menus.reduce((accumulator, currentValue, index) => {
            let currentLength = accumulator.concat(currentValue.name).join(" ").length
            if (!foundMenu && currentLength > 115) {
                foundMenu = true
                cutMenuIndex = index
            }
            return accumulator.concat(currentValue.name)
        }, []).join(" ").length : null

        const currentCategory = this.props.menus.categories ? this.props.menus.categories.categories.find(category => category.external_id === this.state.categoryId) : {}

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: [
                                        ' .ui.stackable.cards>.card {\n' +
                                        '       height: 350px !important;\n' +
                                        '}',
                                    ].join('\n'),
                                }}
                            />
                            <Dimmer active={this.state.loading} inverted>
                                <Loader size='medium' inline='centered'>
                                    Loading
                                </Loader>
                            </Dimmer>
                            <Media
                                lessThan='computer'
                                style={{
                                    background: 'white',
                                    margin: -14,
                                    padding: '0px 5px',
                                    borderTopLeftRadius: '5px',
                                    borderTopRightRadius: '5px',
                                }}
                                onUpdate={this.handleOnUpdate}
                            >
                                <Menu secondary>
                                    <Menu.Item
                                        style={{
                                            marginTop: 10,
                                            padding: 10,
                                        }}
                                    >
                                        <Header
                                            style={{
                                                textDecoration: 'none',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                width: 'calc(100vw * 4 / 5)',
                                                color: backgroundColor
                                            }}
                                        >
                                            {title[title.length - 1]}
                                        </Header>
                                    </Menu.Item>
                                </Menu>
                            </Media>
                            <Media
                                greaterThanOrEqual='computer'
                                style={{
                                    background: 'white',
                                    margin: -14,
                                    padding: '0px 5px',
                                    borderTopLeftRadius: '5px',
                                    borderTopRightRadius: '5px',
                                }}
                            >
                                <Menu
                                    pointing
                                    secondary
                                    style={{borderBottom: '2px solid rgba(34, 36, 38, 0.01)'}}
                                >
                                    {this.props.menus.data.menus
                                        ? this.props.menus.data.menus.map((menu, index) => {
                                            let isActive = this.state.menuId === menu.external_id;
                                            return index <= cutMenuIndex ? (
                                                <Menu.Item
                                                    key={menu.external_id}
                                                    active={isActive}
                                                    style={{
                                                        marginTop: 10,
                                                        padding: 10,
                                                        color: isActive ? backgroundColor : 'inherit',
                                                        borderColor: isActive ? backgroundColor : '#ffffff00',
                                                    }}
                                                    as={Link}
                                                    to={'/menus/' + menu.external_id}
                                                    name={menu.name}
                                                    content={menu.name.toUpperCase()}
                                                />
                                            ): null
                                        })
                                        : null}
                                    {this.props.menus.data.menus ? (
                                        this.props.menus.data.menus.length > cutMenuIndex + 1 ? (
                                            <Dropdown
                                                item
                                                text='MORE'
                                                style={{
                                                    padding: 10,
                                                }}
                                            >
                                                <Dropdown.Menu>
                                                    {this.props.menus.data.menus.map(
                                                        (menu, index) => {
                                                            let isActive = this.state.menuId === menu.external_id;
                                                            return index > cutMenuIndex ? (
                                                                <Dropdown.Item
                                                                    key={menu.external_id}
                                                                    active={isActive}
                                                                    style={{
                                                                        marginTop: 10,
                                                                        padding: 10,
                                                                        color: isActive ? backgroundColor : 'inherit',
                                                                        borderColor: isActive
                                                                            ? backgroundColor
                                                                            : '#ffffff00',
                                                                    }}
                                                                    as={Link}
                                                                    to={'/menus/' + menu.external_id}
                                                                    text={menu.name.toUpperCase()}
                                                                />
                                                            ) : null;
                                                        }
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ) : null
                                    ) : null}
                                </Menu>

                                <Menu
                                    stackable
                                    pointing
                                    secondary
                                    style={{borderBottom: '2px solid rgba(34, 36, 38, 0.01)'}}
                                >
                                    {this.props.menus.categories
                                        ? this.props.menus.categories.categories.length > 0
                                            ? this.props.menus.categories.categories.map((menu, index) => {
                                                let isActive = this.state.categoryId === menu.external_id;
                                                return index <= cutIndex ? (
                                                    <Menu.Item
                                                        key={menu.external_id}
                                                        active={isActive}
                                                        style={{
                                                            padding: 10,
                                                            color: isActive ? backgroundColor : 'inherit',
                                                            borderColor: isActive ? backgroundColor : '#ffffff00',
                                                        }}
                                                        onClick={this.goToCategoryItems.bind(this, menu)}
                                                        name={menu.name}
                                                        content={menu.name.toUpperCase()}
                                                    />
                                                ) : null;
                                            })
                                            : null
                                        : null}
                                    {this.props.menus.categories ? (
                                        this.props.menus.categories.categories.length > cutIndex + 1 ? (
                                            <Dropdown
                                                item
                                                text='MORE'
                                                style={{
                                                    padding: 10,
                                                }}
                                            >
                                                <Dropdown.Menu>
                                                    {this.props.menus.categories.categories.map(
                                                        (menu, index) => {
                                                            let isActive =
                                                                this.state.categoryId === menu.external_id;
                                                            return index > cutIndex ? (
                                                                <Dropdown.Item
                                                                    key={menu.external_id}
                                                                    active={isActive}
                                                                    style={{
                                                                        marginTop: 10,
                                                                        padding: 10,
                                                                        color: isActive ? backgroundColor : 'inherit',
                                                                        borderColor: isActive
                                                                            ? backgroundColor
                                                                            : '#ffffff00',
                                                                    }}
                                                                    onClick={this.goToCategoryItems.bind(this, menu)}
                                                                    text={menu.name.toUpperCase()}
                                                                />
                                                            ) : null;
                                                        }
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ) : null
                                    ) : null}
                                </Menu>
                            </Media>
                            <Media at='mobile'>
                                {this.renderMobile(currentCategory)}
                            </Media>
                            <Media greaterThanOrEqual='tablet'>
                                {this.renderDesktop({backgroundColor, rest, currentCategory})}
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {menus} = state;
        return {menus};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators
            },
            dispatch
        )
)(MenuCategoryItem);
