import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Accordion, Button, Card, Divider, Feed, Grid, Header, Icon, Segment,} from 'semantic-ui-react';
import {Link, withRouter} from 'react-router-dom';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';
import {getConfiguration} from '../../../store/api/apiClient';

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1,
        };
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    };

    renderContent = () => {
        const { about_page } = this.props.locations.configurations;
        const { activeIndex } = this.state;

        return (
            <Segment>
                <Grid>
                    <Grid.Column width={16}>
                        <Header textAlign='center' as='h3'>
                            FAQ's
                        </Header>
                        <Accordion fluid>
                            {about_page.faqs.map((item, index) => {
                                return (
                                    <div key={`item_${index}`}>
                                        <Accordion.Title
                                            active={activeIndex === index}
                                            index={index}
                                            onClick={this.handleClick}
                                        >
                                            <span>{item.header}</span>
                                            <Icon
                                                name={
                                                    activeIndex === index
                                                        ? 'angle up'
                                                        : 'angle down'
                                                }
                                                style={getConfiguration().label_color}
                                            />
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === index}>
                                            <span dangerouslySetInnerHTML={{ __html: item.details, }} />
                                        </Accordion.Content>
                                        {index < about_page.faqs.length - 1 && <Divider style={{ borderTop: `1px solid ${getConfiguration().label_color.color}` }} />}
                                    </div>
                                );
                            })}
                        </Accordion>
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    };

    renderLinks = () => {
        const { about_page } = this.props.locations.configurations;
        return (
            <Card.Group>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign='center' as='h4'>
                            {about_page.legal_information.header}
                        </Header>
                        <Card.Description
                            dangerouslySetInnerHTML={{
                                __html: about_page.legal_information.details,
                            }}
                        />
                        <Feed>
                            <Feed.Event>
                                <Feed.Content>
                                    <Button
                                        as={Link}
                                        to='/terms'
                                        name='/terms'
                                        fluid
                                        circular
                                        style={getConfiguration().button_color}
                                    >
                                        TERMS AND CONDITIONS
                                    </Button>
                                </Feed.Content>
                            </Feed.Event>
                            <Feed.Event>
                                <Feed.Content>
                                    <Button
                                        as={Link}
                                        to='/privacy'
                                        name='/privacy'
                                        fluid
                                        circular
                                        style={getConfiguration().button_color}
                                    >
                                        PRIVACY POLICY
                                    </Button>
                                </Feed.Content>
                            </Feed.Event>
                        </Feed>
                    </Card.Content>
                </Card>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign='center' as='h4'>
                            {about_page.about.header}
                        </Header>
                        <Card.Description
                            dangerouslySetInnerHTML={{
                                __html: about_page.about.details,
                            }}
                        />
                    </Card.Content>
                </Card>
            </Card.Group>
        );
    };

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.card .description img {' +
                                    '   width: 100%; \n' +
                                    '   height: auto !important; \n' +
                                    '}'
                                ].join('\n'),
                            }}
                            />
                        <Grid padded>
                            <Grid.Column mobile={16} tablet={10} computer={11}>{this.renderContent()}</Grid.Column>
                            <Grid.Column mobile={16} tablet={6} computer={5}>{this.renderLinks()}</Grid.Column>
                        </Grid>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, locations } = state;
        const { user } = users;
        return { user, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
            },
            dispatch
        )
)(withRouter(About));
