import React, { useState } from 'react';
import { Button, Form, Grid, Item } from 'semantic-ui-react';

import { Media } from '../../../AppMedia';
import { getConfiguration } from '../../../store/api/apiClient';

export default (props) => {
    const { cards: { order }, handlePromoSelect, handleNextStep, handleGoBack } = props;
    const [promo, setPromo] = useState(order.promo_code);

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        Have a promo code?
                    </Item.Header>
                </Item.Content>
            </Item>
            <Media at='mobile'>
                <Form.Input
                    autoComplete='new'
                    type={'text'}
                    placeholder='Enter Code'
                    value={promo || ''}
                    onChange={(e) => setPromo(e.target.value)}
                />
                <Form.Button
                    fluid
                    disabled={!promo}
                    style={getConfiguration().button_color}
                    onClick={() => handlePromoSelect(promo)}
                >
                    APPLY
                </Form.Button>
                <Form.Button
                    fluid
                    circular
                    style={getConfiguration().button_color}
                    onClick={() => handleNextStep()}
                >
                    CONTINUE
                </Form.Button>
                <Form.Button
                    fluid
                    circular
                    onClick={() => handleGoBack()}
                >
                    BACK
                </Form.Button>
            </Media>
            <Media greaterThanOrEqual='tablet'>
                <Item>
                    <Item.Content>
                        <Grid centered>
                            <Grid.Column width={8}>
                                <Form.Input
                                    fluid
                                    autoComplete='new'
                                    type={'text'}
                                    placeholder='Enter Code'
                                    value={promo || ''}
                                    onChange={(e) => setPromo(e.target.value)}
                                />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Button
                                    fluid
                                    disabled={!promo}
                                    style={getConfiguration().button_color}
                                    onClick={() => handlePromoSelect(promo)}
                                >
                                    APPLY
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Item.Content>
                </Item>
                <Grid centered>
                    <Grid.Column width={4}>
                        <Button
                            fluid
                            circular
                            onClick={() => handleGoBack()}
                        >
                            BACK
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button
                            fluid
                            circular
                            style={getConfiguration().button_color}
                            onClick={() => handleNextStep()}
                        >
                            CONTINUE
                        </Button>
                    </Grid.Column>
                </Grid>
            </Media>
        </Item.Group>
    );
};
