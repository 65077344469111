import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {range} from 'lodash';
import {
    Button,
    Card,
    Dimmer,
    Divider,
    Dropdown,
    Form,
    Grid,
    Header,
    Icon,
    Image,
    Item,
    Loader,
    Menu,
    Message,
    Placeholder,
    Segment,
    Select,
    Table
} from 'semantic-ui-react';
import Carousel from 'semantic-ui-carousel-react';
import 'semantic-ui-carousel-react/src/carousel.css';

import {Media} from '../../AppMedia';
import GlutenVeganView from '../common/ui/components/GlutenVeganView';
import Sections from './ui/Sections';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Menus';
import * as appStore from '../../store/Apps';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import {getConfiguration} from '../../store/api/apiClient';
import {toFormatCurrencyWithCent} from '../../utils/NumberHelper';
import {Logger} from '../../utils/Logger';

class OrderItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            retrieving: true,
            customize: false,
            customized: [],
            json: {},
            activeIndex: null,
            quantity: 1,
            showNewItems: false,
            isOpen: false,
            saving: -1,
            error: {},
            menuId: props.match.params.menuId,
            categoryId: props.match.params.categoryId,
            width: window.innerWidth,
            intervalId: 0,
        };
    }

    componentWillMount() {
        this.props.locationAnnouncement();
        this.props.getLocation();
        this.props.getTicketOrder();
        this.props.getMenus(false);
        this.props.getMenuCategories(false, this.props.match.params.menuId);
        this.props.getMenuCategoryItems(
            false,
            this.props.match.params.menuId,
            this.props.match.params.categoryId
        );
        this.props.getMenuCategoryItem(
            this.props.match.params.menuId,
            this.props.match.params.categoryId,
            this.props.match.params.itemId
        );
    }

    componentDidMount() {
        this.props.updateLoading(false);
        this.props.updateDimmed(false);
        // this.scrollEnd()
    }

    componentWillReceiveProps(nextProps) {
        this.scrollStep()
        try {
            if (nextProps.menus.item) {
                let { custom_options, additional_items, ...rest } = nextProps.menus.item;

                let customized = nextProps.menus.item
                    ? nextProps.menus.item.custom_options.map((section) => {
                          if (section.choices) {
                              if (section.choices[0].required) {
                                  let found = section.choices[0].values.find(
                                      (choice) => choice.default
                                  );
                                  section.chosen = {
                                      chosen: found,
                                  };
                              }
                              if (nextProps.ticket.customized) {
                                  let found = section.choices[0].values.find((choice) => {
                                      let foundModifier = nextProps.ticket.customized.modifiers.find(
                                          (modifiers) => {
                                              let foundOmnivore = null;
                                              if (choice.omnivore_id.length > 0) {
                                                  foundOmnivore = choice.omnivore_id.find(
                                                      (omnivore) => {
                                                          return (
                                                              omnivore.omnivore_id ===
                                                              modifiers.modifier
                                                          );
                                                      }
                                                  );
                                              }
                                              return foundOmnivore ? true : false;
                                          }
                                      );
                                      return foundModifier ? true : false;
                                  });
                                  section.chosen = {
                                      chosen: found,
                                  };
                              }
                          }

                          if (section.checklist && nextProps.ticket.customized) {
                              section.checked = [];
                              section.checklist.forEach((check) => {
                                  let found = null;
                                  if (check.selected) {
                                      found = nextProps.ticket.customized.modifiers.find(
                                          (modifiers) => {
                                              let foundOmnivore = null;
                                              if (check.unselected_omnivore_id.length > 0) {
                                                  foundOmnivore = check.unselected_omnivore_id.find(
                                                      (omnivore) => {
                                                          return (
                                                              omnivore.omnivore_id ===
                                                              modifiers.modifier
                                                          );
                                                      }
                                                  );
                                              }
                                              return foundOmnivore ? true : false;
                                          }
                                      );
                                  } else {
                                      found = nextProps.ticket.customized.modifiers.find(
                                          (modifiers) => {
                                              let foundOmnivore = null;
                                              if (check.selected_omnivore_id.length > 0) {
                                                  foundOmnivore = check.selected_omnivore_id.find(
                                                      (omnivore) => {
                                                          return (
                                                              omnivore.omnivore_id ===
                                                              modifiers.modifier
                                                          );
                                                      }
                                                  );
                                              }
                                              return foundOmnivore ? true : false;
                                          }
                                      );
                                  }
                                  if (found) {
                                      section.checked.push(check);
                                  }
                              });
                          }
                          return section;
                      })
                    : null;

                let quantity = nextProps.ticket.customized
                    ? nextProps.ticket.customized.quantity
                    : this.state.quantity;
                this.setState({
                    json: {
                        sections: nextProps.menus.item.custom_options,
                        additional_item_section: nextProps.menus.item.additional_items,
                        ...rest,
                    },
                    customized,
                    quantity,
                    retrieving: false,
                });
            }
        } catch (err) {
            Logger.error('OrderItem', 'componentWillReceiveProps', JSON.stringify(err));
        }
    }

    submit(section) {
        try {
            let customized = this.state.customized.filter((customization) => {
                return customization.name !== section.name && customization.id !== section.id;
            });
            customized.push(section);
            this.setState({
                customized,
            });
        } catch (err) {
            Logger.error('OrderItem', 'submit', JSON.stringify(err));
        }
    }

    goToCategoryItems(menu) {
        this.scrollStep()
        this.setState({
            categoryId: menu.external_id,
        });
        this.props.getMenuCategoryItems(false, menu.external_menu_id, menu.external_id);
    }

    customizeItem() {
        let customize = !this.state.customize;
        this.setState({
            customize,
            activeIndex: null,
        });
    }

    getToggled(toggle) {
        return <span>{toggle.name}</span>;
    }

    getChecked(checked) {
        return checked.map((check, index) => {
            return (
                <div style={{ paddingLeft: '20px' }} key={index}>
                    <span>
                        {check.toggled ? this.getToggled(check.toggled) : null} {check.name}
                    </span>
                </div>
            );
        });
    }

    getChosen(chosen) {
        return (
            <div style={{ paddingLeft: '20px' }}>
                <span>
                    {chosen.toggled ? this.getToggled(chosen.toggled) : null} {chosen.name}{' '}
                </span>
                {chosen.customized ? <div>{this.getSections(chosen.customized)}</div> : null}
                {chosen.checked ? <div>{this.getChecked(chosen.checked)} </div> : null}
            </div>
        );
    }

    getSections(sections) {
        return sections.map((customize, index) => {
            return (
                <div style={{ paddingLeft: '20px' }} key={index}>
                    <span>{customize.name}</span>
                    {customize.chosen ? <div>{this.getChosen(customize.chosen)}</div> : null}
                    {customize.checked ? <div>{this.getChecked(customize.checked)}</div> : null}
                </div>
            );
        });
    }

    quantityChange(e, option) {
        this.setState({ quantity: option.value });
    }

    getTotalPrice() {
        let customized = this.state.customized;
        let json = this.state.json;
        let total = json.omnivore ? json.omnivore.price_per_unit : 0;
        customized.forEach((section) => {
            if (section.chosen && section.chosen.chosen) {
                total += section.chosen.chosen.price;
            }
            if (section.checked) {
                section.checked.forEach((check) => (total += check.price));
            }
        });
        return toFormatCurrencyWithCent(total * this.state.quantity);
    }

    scrollStep() {
        setTimeout(()=>{
            if(window.document.getElementById('orderItem')){
                window.document.getElementById('orderItem').scrollTo({top:0, behavior: "smooth"})
                window.document.getElementById('orderItem').scroll({top:0, behavior: "smooth"})
            }
        },1000)
    }

    scrollEnd() {
        setTimeout(()=>{
            if(window.document.getElementById('orderItem')){
                // window.document.getElementById('orderItem').scrollTo({top:0, behavior: "smooth"})
                // window.document.getElementById('orderItem').scroll({top:0, behavior: "smooth"})
                window.document.getElementById('orderItem').scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"})
            }
        },1000)
    }

    async createTicketOrder() {
        this.props.updateLoading(true);
        const response = await this.props.createTicketOrder();
        if (response && !response.error) {
            this.scrollStep()
            await this.addToOrder();
        } else {
            this.scrollStep()
            this.setState({
                loading: false,
                saving: 2,
                errors: response ? response.error : {
                    code: 'bad request',
                    message: 'The kitchen is currently closed and not accepting new orders at this time.'
                },
                data: { card_number: '' },
            });
            this.scrollStep()
        }
        this.props.updateLoading(false);
    }

    async addToOrder() {
        this.props.updateLoading(true);
        try {
            this.setState({ errors: null });
            let customized = this.state.customized;
            let json = this.state.json;
            let modifiers = [];
            customized.forEach((section) => {
                if (
                    section.chosen &&
                    section.chosen.chosen &&
                    section.chosen.chosen.omnivore_id.length > 0
                ) {
                    section.chosen.chosen.omnivore_id.forEach((omnivore) => {
                        modifiers.push({
                            modifier: omnivore.omnivore_id,
                        });
                    });
                }
                if (section.checked) {
                    section.checked.forEach((check) => {
                        if (check.type === 'modifier') {
                            if (check.selected && check.unselected_omnivore_id.length > 0) {
                                check.unselected_omnivore_id.forEach((omnivore) => {
                                    modifiers.push({
                                        modifier: omnivore.omnivore_id,
                                    });
                                });
                            }
                            if (!check.selected && check.selected_omnivore_id.length > 0) {
                                check.selected_omnivore_id.forEach((omnivore) => {
                                    modifiers.push({
                                        modifier: omnivore.omnivore_id,
                                    });
                                });
                            }
                        }
                    });
                }
            });
            let item = {
                menu_category_external_id: json.external_menu_category_id,
                menu_external_id: json.external_menu_id,
                menu_item: json.omnivore_id,
                menu_item_external_id: json.external_id,
                modifiers,
                quantity: this.state.quantity,
            };
            let index = this.props.ticket.customized ? this.props.ticket.customized.index : null;
            let response = await this.props.addToOrder(item, index);
            this.props.updateLoading(false);
            if (response && !response.error) {
                this.props.updateDimmed(true);
                this.setState({
                    loading: false,
                    saving: 0,
                    showNewItems: true,
                    data: { card_number: '' },
                });
            } else {
                this.setState({
                    loading: false,
                    saving: 2,
                    errors: response ? response.error : {
                        code: 'bad request',
                        message: 'The kitchen is currently closed and not accepting new orders at this time.'
                    },
                    data: { card_number: '' },
                });
            }
            this.scrollStep()
        } catch (err) {
            console.log('addToOrder error: ', err);
            Logger.error('OrderItem', 'addToOrder', JSON.stringify(err));
        }
    }

    toggleMessage() {
        this.props.updateDimmed(false);
        this.setState({ showNewItems: false, saving: -1 });
    }

    notAllowAddToOrder(customized) {
        let found = customized.find((section) => {
            if (section.chosen) {
                if (section.chosen.chosen) {
                    if (section.choices[0].required) {
                        return section.chosen.chosen.omnivore_id.length === 0;
                    }
                } else {
                    return section.chosen.required;
                }
            }
            if (section.checked) {
                section.checked.forEach((check) => {
                    if (check.type === 'modifier') {
                        if (check.selected) {
                            let omnivore = check.unselected_omnivore_id.length === 0;
                            if (omnivore) {
                                return true;
                            }
                        }
                        if (!check.selected) {
                            let omnivore = check.selected_omnivore_id.length === 0;
                            if (omnivore) {
                                return true;
                            }
                        }
                    }
                });
            }
            return false;
        });
        return !!found;
    }

    removeCustomized(custom) {
        let customized = this.state.customized;
        let newCustomized = [];
        try {
            customized.forEach((section) => {
                if (section.checked) {
                    section.checked = section.checked.filter((check) => check.id !== custom.id);
                }
                if (section.chosen && section.chosen.chosen && section.chosen.chosen.id === custom.id) {
                    if (section.choices[0].required) {
                        let found = section.choices[0].values.find((choice) => choice.default);
                        section.chosen = {
                            chosen: found,
                        };
                    } else {
                        let found = section.choices[0].values.find((choice) => choice.default);
                        section.chosen = {
                            chosen: found,
                        };
                    }
                }
                newCustomized.push(section);
            });
            this.setState({ customized: newCustomized });
        } catch (err) {
            Logger.error('OrderItem', 'removeCustomized', JSON.stringify(err));
        }
    }

    renderDesktop(params) {
        const { json, backgroundColor, customized, rest } = params;

        return (
            <Grid textAlign='center' padded stackable style={{ marginTop: 20 }}>
                <Grid.Column width={10} style={{ paddingLeft: 10 }}>
                    <Segment>
                        {this.state.errors && this.state.errors.message && (
                            <Message
                                icon
                                error
                                visible={true}
                                header='Error'
                                content={this.state.errors.message}
                            />
                        )}
                        <Grid stackable>
                            <Grid.Column width={8}>
                                {json.images && json.images.length === 1 ? (
                                    <Image
                                        rounded
                                        style={{
                                            borderRadius: '10px',
                                            width: 'auto',
                                        }}
                                        src={json.images[0].imageSignedUrl}
                                        wrapped
                                        ui={true}
                                    />
                                ) : json.images ? (
                                    <Carousel
                                        style={{
                                            height: 350,
                                            width: 'auto',
                                            borderRadius: '10px',
                                        }}
                                        elements={json.images.map((image) => {
                                            return {
                                                render: () => {
                                                    return (
                                                        <Image
                                                            rounded
                                                            style={{
                                                                border: 'none',
                                                            }}
                                                            src={image.imageSignedUrl}
                                                        />
                                                    );
                                                },
                                            };
                                        })}
                                        duration={3000}
                                        animation='flip'
                                        showNextPrev={false}
                                        showIndicators={false}
                                    />
                                ) : (
                                    <Placeholder>
                                        <Placeholder.Image square />
                                    </Placeholder>
                                )}
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: 70,
                                        top: 0,
                                        left: 30,
                                        textAlign: 'center',
                                        color: 'white',
                                        borderBottomRightRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        padding: 10,
                                        backgroundColor,
                                    }}
                                >
                                    <span style={rest}>
                                        $
                                        {json.omnivore
                                            ? json.omnivore.price_per_unit.toFixed(2)
                                            : 0.0}
                                    </span>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                {json.name ? (
                                    <Grid textAlign='left' stackable>
                                        <Grid.Column width={16}>
                                            <Header as={'h4'} style={{ marginBottom: 8 }}>{json.name}</Header>
                                            {(json.vegan || json.gluten_free) && (
                                                <GlutenVeganView
                                                    vegan={json.vegan}
                                                    gluten_free={json.gluten_free}
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                />
                                            )}
                                        </Grid.Column>
                                        {json.description &&
                                            <Grid.Column width={16} style={{ paddingTop: 0 }}>
                                                <p>{json.description}</p>
                                            </Grid.Column>
                                        }
                                        <Grid.Column
                                            width={16}
                                            style={this.state.isOpen ? { height: 185, verticalAlign: 'top', paddingTop: 0 } : { paddingTop: 0 }}
                                        >
                                            <strong
                                                style={
                                                    getConfiguration().label_color
                                                }
                                            >
                                                QUANTITY
                                            </strong>
                                            <Select
                                                placeholder='Quantity'
                                                options={range(1, 11).map((quantity, index) => {
                                                    return {
                                                        key: index,
                                                        value: quantity,
                                                        text: quantity,
                                                    };
                                                })}
                                                onOpen={() => this.setState({ isOpen: true })}
                                                onClose={() => this.setState({ isOpen: false })}
                                                value={this.state.quantity}
                                                onChange={this.quantityChange.bind(
                                                    this
                                                )}
                                                style={{
                                                    width: '100%',
                                                    height: '30px',
                                                }}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                ) : (
                                    <Placeholder fluid>
                                        <Placeholder.Header>
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                        </Placeholder.Header>
                                        <Placeholder.Paragraph>
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                        </Placeholder.Paragraph>
                                    </Placeholder>
                                )}
                                {json.sections && json.sections.length > 0 ? (
                                    <Sections
                                        submit={this.submit.bind(this)}
                                        sections={json.sections}
                                        customize={false}
                                        // defaultCustomized={this.props.ticket.customized}
                                        customized={customized}
                                        activeIndex={this.state.activeIndex}
                                    />
                                ) : this.state.retrieving ? (
                                    <Placeholder fluid>
                                        <Placeholder.Paragraph>
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                        </Placeholder.Paragraph>
                                    </Placeholder>
                                ) : null}
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={6} style={{ paddingLeft: 0, paddingRight: 20 }}>
                    <Segment>
                        <Form>
                            <Grid verticalAlign='middle' textAlign={'center'}>
                                <Grid.Row>
                                    <Grid.Column
                                        width={16}
                                        style={{
                                            textAlign: 'left',
                                            paddingRight: 0,
                                            paddingLeft: 0,
                                            marginTop: -14,
                                            marginLeft: -15,
                                        }}
                                    >
                                        <Table unstackable compact basic='very'>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell
                                                        style={{
                                                            border: 'none',
                                                            padding: '0px 10px',
                                                            background: '#d3d3d35e',
                                                        }}
                                                    >
                                                        <Table unstackable compact basic='very'>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell
                                                                        style={{
                                                                            border: 'none',
                                                                            fontSize: 16,
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        Total{' '}
                                                                    </Table.Cell>
                                                                    <Table.Cell
                                                                        style={{
                                                                            border: 'none',
                                                                            fontSize: 16,
                                                                            fontWeight: 600,
                                                                        }}
                                                                        textAlign='right'
                                                                    >
                                                                        {this.getTotalPrice()}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                    <Grid.Column
                                        width={16}
                                        style={{
                                            paddingLeft: 10,
                                            paddingRight: 20,
                                            marginTop: 20,
                                        }}
                                    >
                                        <Button
                                            circular
                                            fluid
                                            style={{
                                                ...getConfiguration().button_color,
                                            }}
                                            disabled={this.notAllowAddToOrder(this.state.customized) || this.state.retrieving}
                                            onClick={
                                                this.props.ticket.ticket_order &&
                                                this.props.ticket.ticket_order.status &&
                                                this.props.ticket.ticket_order.status !==
                                                    'Submitted' &&
                                                this.props.ticket.ticket_order.status !==
                                                    'Queued'
                                                    ? this.addToOrder.bind(this)
                                                    : this.createTicketOrder.bind(this)
                                            }
                                        >
                                            {this.props.ticket.customized ? 'SAVE' : 'ADD'} TO
                                            ORDER
                                        </Button>
                                        {customized.length > 0 ? (
                                            <Segment
                                                style={{
                                                    border:
                                                        '1px solid ' +
                                                        getConfiguration().label_color.color,
                                                    borderRadius: 5,
                                                }}
                                            >
                                                <Grid
                                                    textAlign={'left'}
                                                    verticalAlign={'middle'}
                                                >
                                                    {customized.map((custom, index) => {
                                                        let name =
                                                            custom.selected === undefined
                                                                ? custom.name
                                                                : custom.selected
                                                                ? custom.unselected_omnivore_id
                                                                        .map((id) => id.name)
                                                                        .join(',')
                                                                : custom.selected_omnivore_id
                                                                        .map((id) => id.name)
                                                                        .join(',');
                                                        name =
                                                            name.length > 0
                                                                ? name
                                                                : custom.name;
                                                        return (
                                                            <Grid.Row
                                                                key={index}
                                                                style={{
                                                                    padding: 10,
                                                                }}
                                                            >
                                                                <Grid.Column
                                                                    width={12}
                                                                    style={{
                                                                        paddingLeft: 5,
                                                                        paddingRight: 0,
                                                                        ...getConfiguration()
                                                                            .label_color,
                                                                    }}
                                                                >
                                                                    {name}
                                                                </Grid.Column>
                                                                <Grid.Column width={2}>
                                                                    {custom.price ? (
                                                                        <span
                                                                            style={{
                                                                                float: 'right',
                                                                            }}
                                                                        >
                                                                            +
                                                                            {toFormatCurrencyWithCent(
                                                                                custom.price
                                                                            )}
                                                                        </span>
                                                                    ) : null}
                                                                </Grid.Column>
                                                                <Grid.Column width={1}>
                                                                    <Button
                                                                        icon
                                                                        circular
                                                                        size={'mini'}
                                                                        onClick={this.removeCustomized.bind(
                                                                            this,
                                                                            custom
                                                                        )}
                                                                        style={{
                                                                            ...getConfiguration()
                                                                                .button_color,
                                                                            width: 25,
                                                                            height: 25,
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name='minus'
                                                                            style={{
                                                                                fontSize: 9,
                                                                                lineHeight:
                                                                                    '3px',
                                                                                verticalAlign:
                                                                                    'middle',
                                                                            }}
                                                                            onClick={this.removeCustomized.bind(
                                                                                this,
                                                                                custom
                                                                            )}
                                                                        />
                                                                    </Button>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        );
                                                    })}
                                                </Grid>
                                            </Segment>
                                        ) : null}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>

                    {json.sections && json.sections.length > 0 ? (
                        <Segment>
                            <Form>
                                <Sections
                                    submit={this.submit.bind(this)}
                                    sections={json.sections}
                                    customize={true}
                                    customized={customized}
                                    activeIndex={this.state.activeIndex}
                                />
                                {json.additional_item_section &&
                                json.additional_item_section.length > 0 ? (
                                    <Divider />
                                ) : null}
                                {json.additional_item_section ? (
                                    <Sections
                                        submit={this.submit.bind(this)}
                                        sections={json.additional_item_section}
                                        // defaultCustomized={this.props.ticket.customized}
                                        customize={this.state.customize}
                                    />
                                ) : null}
                            </Form>
                        </Segment>
                    ) : this.state.retrieving ? (
                        <Segment>
                            <Placeholder fluid>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Segment>
                    ) : null}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(params) {
        const { json, customized } = params;

        return (
            <Card id="orderItem" fluid style={{ boxShadow: 'none' }}>
                {this.state.errors && this.state.errors.message && (
                    <Message
                        icon
                        error
                        visible={true}
                        header='Error'
                        content={this.state.errors.message}
                    />
                )}
                {json.images && json.images.length === 1 ? (
                    <Image
                        style={{
                            width: 'auto',
                        }}
                        src={json.images[0].imageSignedUrl}
                        wrapped
                        ui={false}
                    />
                ) : json.images ? (
                    <Carousel
                        style={{
                            width: 'auto',
                        }}
                        elements={json.images.map((image) => {
                            return {
                                render: () => {
                                    return (
                                        <Image
                                            rounded
                                            style={{ border: 'none' }}
                                            src={image.imageSignedUrl}
                                        />
                                    );
                                },
                            };
                        })}
                        duration={3000}
                        animation='flip'
                        showNextPrev={false}
                        showIndicators={false}
                    />
                ) : (
                    <Placeholder>
                        <Placeholder.Image square />
                    </Placeholder>
                )}
                <Card.Content>
                    <Header floated='right' style={getConfiguration().label_color}>
                        ${json.omnivore ? json.omnivore.price_per_unit.toFixed(2) : 0.0}
                    </Header>
                    <Card.Header as='h5'>{json.name}</Card.Header>
                    {(json.vegan || json.gluten_free) && (
                        <GlutenVeganView
                            vegan={json.vegan}
                            gluten_free={json.gluten_free}
                            style={{
                                display: 'flex',
                                marginBottom: 10,
                                marginTop: -10,
                            }}
                        />
                    )}
                    {json.description &&
                        <Card.Description>{json.description}</Card.Description>
                    }
                </Card.Content>
                <Card.Content>
                    <Form>
                        {json.name ? (
                            <Form.Field style={this.state.isOpen ? { height: 185, verticalAlign: 'top' } : {}}>
                                <strong style={getConfiguration().label_color}>QUANTITY:</strong>
                                <Select
                                    placeholder='Quantity'
                                    options={range(1, 11).map((quantity, index) => {
                                        return {
                                            key: index,
                                            value: quantity,
                                            text: quantity,
                                        };
                                    })}
                                    onOpen={() => this.setState({ isOpen: true })}
                                    onClose={() => this.setState({ isOpen: false })}
                                    value={this.state.quantity}
                                    onChange={this.quantityChange.bind(this)}
                                />
                            </Form.Field>
                        ) : (
                            <Placeholder fluid>
                                <Placeholder.Header>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        )}
                        {json.sections && json.sections.length > 0 ? (
                            <Form.Field>
                                <Sections
                                    submit={this.submit.bind(this)}
                                    sections={json.sections}
                                    customize={false}
                                    // defaultCustomized={this.props.ticket.customized}
                                    customized={customized}
                                    activeIndex={this.state.activeIndex}
                                />
                            </Form.Field>
                        ) : this.state.retrieving ? (
                            <Placeholder fluid>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        ) : null}
                        {json.sections && json.sections.length > 0 ? (
                            <>
                                <Form.Field>
                                    <Sections
                                        submit={this.submit.bind(this)}
                                        sections={json.sections}
                                        customize={true}
                                        customized={customized}
                                        // defaultCustomized={this.props.ticket.customized}
                                        activeIndex={this.state.activeIndex}
                                    />
                                </Form.Field>
                                {json.additional_item_section &&
                                json.additional_item_section.length > 0 ? (
                                    <Divider />
                                ) : null}
                                {json.additional_item_section ? (
                                    <Form.Field>
                                        <Sections
                                            submit={this.submit.bind(this)}
                                            sections={json.additional_item_section}
                                            // defaultCustomized={this.props.ticket.customized}
                                            customize={this.state.customize}
                                        />
                                    </Form.Field>
                                ) : null}
                            </>
                        ) : this.state.retrieving ? (
                            <Placeholder fluid>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        ) : null}
                        {customized.length > 0 ? (
                            <Form.Field>
                                <Segment
                                    raised
                                    style={{
                                        borderColor: getConfiguration().label_color.color,
                                        background: '#d3d3d35e',
                                    }}
                                >
                                    <Grid>
                                        <Grid.Row style={{ padding: 10 }}>
                                            <Grid.Column
                                                style={{
                                                    paddingLeft: 5,
                                                    paddingRight: 0,
                                                }}
                                            >
                                                CUSTOMIZATIONS
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Grid
                                        textAlign={'left'}
                                        verticalAlign={'middle'}
                                        style={{
                                            background: 'white',
                                        }}
                                    >
                                        {customized.map((custom, index) => {
                                            let name =
                                                custom.selected === undefined
                                                    ? custom.name
                                                    : custom.selected
                                                    ? custom.unselected_omnivore_id
                                                          .map((id) => id.name)
                                                          .join(',')
                                                    : custom.selected_omnivore_id
                                                          .map((id) => id.name)
                                                          .join(',');
                                            name = name.length > 0 ? name : custom.name;
                                            return (
                                                <Grid.Row key={index} style={{ padding: 10 }}>
                                                    <Grid.Column
                                                        width={12}
                                                        style={{
                                                            paddingLeft: 5,
                                                            paddingRight: 0,
                                                            ...getConfiguration().label_color,
                                                        }}
                                                    >
                                                        {name}
                                                    </Grid.Column>
                                                    <Grid.Column width={2}>
                                                        {custom.price ? (
                                                            <span
                                                                style={{
                                                                    float: 'right',
                                                                }}
                                                            >
                                                                +{toFormatCurrencyWithCent(custom.price)}
                                                            </span>
                                                        ) : null}
                                                    </Grid.Column>
                                                    <Grid.Column width={1} style={{ padding: 0 }}>
                                                        <Button
                                                            icon
                                                            circular
                                                            size={'mini'}
                                                            onClick={this.removeCustomized.bind(
                                                                this,
                                                                custom
                                                            )}
                                                            style={{
                                                                ...getConfiguration().button_color,
                                                                width: 25,
                                                                height: 25,
                                                            }}
                                                        >
                                                            <Icon
                                                                name='minus'
                                                                style={{
                                                                    fontSize: 9,
                                                                    lineHeight: '3px',
                                                                    verticalAlign: 'middle',
                                                                }}
                                                                onClick={this.removeCustomized.bind(
                                                                    this,
                                                                    custom
                                                                )}
                                                            />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            );
                                        })}
                                    </Grid>
                                </Segment>
                            </Form.Field>
                        ) : null}
                        <Form.Field>
                            <Button
                                circular
                                floated='right'
                                style={{ ...getConfiguration().button_color }}
                                disabled={this.notAllowAddToOrder(this.state.customized) || this.state.retrieving}
                                onClick={
                                    this.props.ticket.ticket_order &&
                                    this.props.ticket.ticket_order.status &&
                                    this.props.ticket.ticket_order.status !== 'Submitted' &&
                                    this.props.ticket.ticket_order.status !== 'Queued'
                                        ? this.addToOrder.bind(this)
                                        : this.createTicketOrder.bind(this)
                                }
                            >
                                {this.props.ticket.customized ? 'SAVE' : 'ADD'} TO ORDER
                            </Button>
                            <Header style={{ padding: 5 }}>Total: {this.getTotalPrice()}</Header>
                        </Form.Field>
                        <Form.Field></Form.Field>
                    </Form>
                </Card.Content>
            </Card>
        );
    }

    render() {
        const { menus: { title } } = this.props;
        const { json, width } = this.state;
        let { backgroundColor, ...rest } = getConfiguration().button_color;

        let omnivoreItems = null;
        let ticketOrderItems = null;
        if (this.props.ticket.ticket_order && this.props.ticket.ticket_order.items && this.props.ticket.ticket_order.items.length > 0) {
            let index = this.props.ticket.customized
                ? this.props.ticket.customized.index
                : this.props.ticket.ticket_order.items.length - 1;
            ticketOrderItems = this.props.ticket.ticket_order.items[index];
            omnivoreItems = this.props.ticket.omnivore_ids
                ? this.props.ticket.omnivore_ids.items.find(
                      (omnivoreItem) =>
                          omnivoreItem.menu_item_external_id ===
                          ticketOrderItems.menu_item_external_id
                  )
                : null;
        }
        let customized = [];

        this.state.customized.forEach((section) => {
            if (section.chosen && section.chosen.chosen) {
                if (
                    section.chosen.chosen.omnivore_id &&
                    section.chosen.chosen.omnivore_id.length > 0
                )
                customized.push(section.chosen.chosen);
            }
            if (section.checked) {
                section.checked.forEach((check) => customized.push(check));
            }
        });

        let cutIndex = 9
        let cutMenuIndex = 9
        let found = false
        let foundMenu = false
        const concatCategories = this.props.menus.categories ? this.props.menus.categories.categories.reduce((accumulator, currentValue, index) => {
            let currentLength = accumulator.concat(currentValue.name).join(" ").length
            if (!found && currentLength > 115) {
                found = true
                cutIndex = index
            }
            return accumulator.concat(currentValue.name)
        }, []).join(" ").length : null

        const concatMenus = this.props.menus.data.menus ? this.props.menus.data.menus.reduce((accumulator, currentValue, index) => {
            let currentLength = accumulator.concat(currentValue.name).join(" ").length
            if (!foundMenu && currentLength > 115) {
                foundMenu = true
                cutMenuIndex = index
            }
            return accumulator.concat(currentValue.name)
        }, []).join(" ").length : null

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            {this.props.isDimmed && omnivoreItems ?
                                <style dangerouslySetInnerHTML={{
                                    __html: [
                                        '.ui.dimmer > .content {' +
                                        '  top: 10%;\n' +
                                        '  transform: translateY(10%);\n' +
                                        '    position: inherit;\n' +
                                        '    overflow-y: auto;\n' +
                                        '    border: 1px solid lightgray;\n' +
                                        '    max-height: calc(100% - 200px)\n' +
                                        '}\n'
                                    ].join('\n'),
                                }}
                                />: null
                            }

                            <div id="orderItem" >
                                <Media
                                    lessThan='computer'
                                    style={{
                                        background: 'white',
                                        margin: -14,
                                        padding: '0px 5px',
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                    }}
                                    onUpdate={this.handleOnUpdate}
                                >
                                    <Menu secondary>
                                        <Menu.Item
                                            style={{
                                                marginTop: 10,
                                                padding: 10,
                                            }}
                                        >
                                            <Header
                                                style={{
                                                    textDecoration: 'none',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    width: 'calc(100vw * 4 / 5)',
                                                    color: backgroundColor
                                                }}
                                            >
                                                {title[title.length - 1]}
                                            </Header>
                                        </Menu.Item>
                                    </Menu>
                                </Media>
                                <Media
                                    greaterThanOrEqual='computer'
                                    style={{
                                        background: 'white',
                                        margin: -14,
                                        padding: '0px 5px',
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                    }}
                                >
                                    <Menu
                                        pointing
                                        secondary
                                        style={{borderBottom: '2px solid rgba(34, 36, 38, 0.01)'}}
                                    >
                                        {this.props.menus.data.menus
                                            ? this.props.menus.data.menus.map((menu, index) => {
                                                let isActive = this.state.menuId === menu.external_id;
                                                return index <= cutMenuIndex ? (
                                                    <Menu.Item
                                                        key={menu.external_id}
                                                        active={isActive}
                                                        style={{
                                                            marginTop: 10,
                                                            padding: 10,
                                                            color: isActive ? backgroundColor : 'inherit',
                                                            borderColor: isActive ? backgroundColor : '#ffffff00',
                                                        }}
                                                        as={Link}
                                                        to={'/order/' + menu.external_id}
                                                        name={menu.name}
                                                        content={menu.name.toUpperCase()}
                                                    />
                                                ): null
                                            })
                                            : null}
                                        {this.props.menus.data.menus ? (
                                            this.props.menus.data.menus.length > cutMenuIndex + 1 ? (
                                                <Dropdown
                                                    item
                                                    text='MORE'
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        {this.props.menus.data.menus.map(
                                                            (menu, index) => {
                                                                let isActive = this.state.menuId === menu.external_id;
                                                                return index > cutMenuIndex ? (
                                                                    <Dropdown.Item
                                                                        key={menu.external_id}
                                                                        active={isActive}
                                                                        style={{
                                                                            marginTop: 10,
                                                                            padding: 10,
                                                                            color: isActive ? backgroundColor : 'inherit',
                                                                            borderColor: isActive
                                                                                ? backgroundColor
                                                                                : '#ffffff00',
                                                                        }}
                                                                        as={Link}
                                                                        to={'/order/' + menu.external_id}
                                                                        text={menu.name.toUpperCase()}
                                                                    />
                                                                ) : null;
                                                            }
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) : null
                                        ) : null}
                                    </Menu>
                                    <Menu
                                        stackable
                                        pointing
                                        secondary
                                        style={{
                                            borderBottom: '2px solid rgba(34, 36, 38, 0.01)',
                                        }}
                                    >
                                        {this.props.menus.categories
                                            ? this.props.menus.categories.categories.length > 0
                                                ? this.props.menus.categories.categories.map((menu, index) => {
                                                    let isActive = this.state.categoryId === menu.external_id;
                                                    return index <= cutIndex ? (
                                                        <Menu.Item
                                                            key={menu.external_id}
                                                            active={isActive}
                                                            style={{
                                                                padding: 10,
                                                                color: isActive ? backgroundColor : 'inherit',
                                                                borderColor: isActive
                                                                    ? backgroundColor
                                                                    : '#ffffff00',
                                                            }}
                                                            as={Link}
                                                            to={
                                                                '/order/' +
                                                                menu.external_menu_id +
                                                                '/category/' +
                                                                menu.external_id
                                                            }
                                                            name={menu.name}
                                                            content={menu.name.toUpperCase()}
                                                        />
                                                    ) : null;
                                                })
                                                : null
                                            : null}
                                        {this.props.menus.categories ? (
                                            this.props.menus.categories.categories.length > cutIndex + 1 ? (
                                                <Dropdown
                                                    item
                                                    text='MORE'
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        {this.props.menus.categories.categories.map(
                                                            (menu, index) => {
                                                                let isActive =
                                                                    this.state.categoryId === menu.external_id;
                                                                return index > cutIndex ? (
                                                                    <Dropdown.Item
                                                                        as={Link}
                                                                        to={
                                                                            '/order/' +
                                                                            menu.external_menu_id +
                                                                            '/category/' +
                                                                            menu.external_id
                                                                        }
                                                                        key={menu.external_id}
                                                                        active={isActive}
                                                                        style={{
                                                                            marginTop: 10,
                                                                            padding: 10,
                                                                            color: isActive
                                                                                ? backgroundColor
                                                                                : 'inherit',
                                                                            borderColor: isActive
                                                                                ? backgroundColor
                                                                                : '#ffffff00',
                                                                        }}
                                                                        onClick={this.goToCategoryItems.bind(
                                                                            this,
                                                                            menu
                                                                        )}
                                                                        text={menu.name.toUpperCase()}
                                                                    />
                                                                ) : null;
                                                            }
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) : null
                                        ) : null}
                                    </Menu>
                                </Media>
                                <Media at='mobile' style={{ margin: '14px -14px -14px' }}>
                                    {this.renderMobile({
                                        json,
                                        backgroundColor,
                                        customized,
                                        rest,
                                    })}
                                </Media>
                                <Media greaterThanOrEqual='tablet'>
                                    {this.renderDesktop({
                                        json,
                                        backgroundColor,
                                        customized,
                                        rest,
                                    })}
                                </Media>
                            </div>
                            <Dimmer active={this.props.isDimmed || this.props.isLoading} inverted
                                    verticalAlign='center'>
                                {this.props.isLoading ? (
                                    <Loader size='small' inline='centered'>
                                        Loading
                                    </Loader>
                                ) : null}
                                {this.props.isDimmed && omnivoreItems ? (
                                    <Card
                                        style={{
                                            width: width >= 768 ? 500 : 300,
                                        }}
                                    >
                                        <Card.Content extra>
                                            <Button
                                                icon
                                                circular
                                                floated='right'
                                                size={'mini'}
                                                onClick={this.toggleMessage.bind(this)}
                                                style={{
                                                    ...getConfiguration().button_color,
                                                }}
                                            >
                                                <Icon name='x' />
                                            </Button>
                                            <Card.Header
                                                style={{
                                                    ...getConfiguration().label_color,
                                                    marginTop: 3,
                                                }}
                                            >
                                                {this.props.ticket.customized ? 'SAVED' : 'ADDED'} TO ORDER
                                            </Card.Header>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Item.Group>
                                                <Item>
                                                    <Item.Image size='tiny' src={omnivoreItems.imageUrl} />
                                                    <Item.Content>
                                                        <Item.Description>
                                                            <span style={{ float: 'right' }}>
                                                                {omnivoreItems.price
                                                                    ? omnivoreItems.pretty_price
                                                                    : null}
                                                            </span>
                                                            <span>
                                                                x {ticketOrderItems.quantity}{' '}
                                                                <strong>{omnivoreItems.name}</strong>
                                                            </span>
                                                        </Item.Description>
                                                        {ticketOrderItems.modifiers
                                                            ? ticketOrderItems.modifiers.map(
                                                                (modifier, index) => {
                                                                    let findModifier = this.props.ticket.omnivore_ids.modifiers.find(
                                                                        (omnivoreModifier) =>
                                                                            omnivoreModifier.modifier ===
                                                                            modifier.modifier
                                                                    );
                                                                    return findModifier ? (
                                                                        <Item.Description key={`item_${index}`}>
                                                                            <span
                                                                                style={{
                                                                                    float: 'right',
                                                                                }}
                                                                            >
                                                                                {toFormatCurrencyWithCent(
                                                                                    findModifier.price
                                                                                )}
                                                                            </span>
                                                                            <span>{findModifier.name}</span>
                                                                        </Item.Description>
                                                                    ) : null;
                                                                }
                                                            )
                                                            : null}
                                                    </Item.Content>
                                                </Item>
                                            </Item.Group>

                                            <Card.Description
                                                style={{
                                                    background: '#d3d3d3cc',
                                                    padding: 10,
                                                }}
                                            >
                                                <span style={{ float: 'right' }}>
                                                    <strong>{this.props.ticket.totals.total}</strong>
                                                </span>
                                                <span>
                                                    <strong>Order Total </strong>
                                                </span>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Button
                                                fluid
                                                as={Link}
                                                to={'/ordercart'}
                                                onClick={() => {
                                                    this.props.updateDimmed(false);
                                                    this.props.clearMenuTitle();
                                                }}
                                                circular
                                                style={{
                                                    ...getConfiguration().button_color,
                                                }}
                                            >
                                                REVIEW ORDER
                                            </Button>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Button
                                                fluid
                                                as={Link}
                                                to={'/order'}
                                                circular
                                                style={{
                                                    ...getConfiguration().button_color,
                                                }}
                                            >
                                                ADD MORE ITEMS
                                            </Button>
                                        </Card.Content>
                                    </Card>
                                ) : null}
                            </Dimmer>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { menus, ticket, locations, apps } = state;
        return {
            menus,
            ticket,
            locations,
            isLoading: apps.loading,
            isDimmed: apps.dimmed,
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...appStore.actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
            },
            dispatch
        )
)(OrderItem);
