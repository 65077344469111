import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {routerReducer, routerMiddleware} from 'react-router-redux';
import * as Apps from './Apps';
import * as Card from './Card';
import * as SideMenu from "./SideMenu";
import * as UserManagement from "./UserManagement";
import * as Login from "./Login";
import * as SearchStore from "./SearchStore";
import * as Users from "./Users";
import * as Menus from "./Menus";
import * as Cart from "./Cart";
import * as Location from "./Location";
import * as Ticket from "./Ticket";
import * as ServiceWorker from "./service-worker-redux";
import {autoRehydrate} from "redux-persist";

export default function configureStore(history, initialState) {
    const reducers = {
        apps: Apps.reducer,
        cards: Card.reducer,
        sideMenu: SideMenu.reducer,
        userManagement: UserManagement.reducer,
        searchStore: SearchStore.reducer,
        login: Login.reducer,
        users: Users.reducer,
        menus: Menus.reducer,
        cart: Cart.reducer,
        ticket: Ticket.reducer,
        locations: Location.reducer,

        serviceWorker: ServiceWorker.reducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    enhancers.push(autoRehydrate())
    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });

    return createStore(
        rootReducer,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
