import React from "react";
import {Card, Grid, Header, Image} from "semantic-ui-react";

export default class ComponentToPrint extends React.Component {
    render() {
        let footer = this.props.footer
        let app_icon = this.props.app_icon
        return (
            <div style={{margin: 20}}>
                <Card.Group>
                    <Card fluid className={'james'}>
                        <Card.Content>
                            <Card.Description>
                                <Grid stackable columns={2}>
                                    <Grid.Row>
                                        <Grid.Column width={11}
                                                     style={{
                                                         paddingBottom: 0,
                                                         color: 'white',
                                                         verticalAlign: "middle"
                                                     }}>
                                            {footer.show_footer_logo ?
                                                <Image src={app_icon} size='small'
                                                       className={"footer-image-mobile"}
                                                       style={{
                                                           height: 80,
                                                           width: "auto"
                                                       }}
                                                       floated='left'
                                                /> : null}

                                        </Grid.Column>
                                        <Grid.Column width={5}
                                                     style={{
                                                         paddingBottom: 0,
                                                         color: 'white',
                                                         verticalAlign: "middle"
                                                     }}>
                                            {footer.show_contact_info ?
                                                <div style={{textAlign: "left", margin: "auto", padding: 10}}
                                                     className={"footer-contact-mobile"}
                                                     dangerouslySetInnerHTML={{__html: footer.contact_info_text}}>
                                                </div> : null}
                                        </Grid.Column>

                                    </Grid.Row>
                                </Grid>
                            </Card.Description>
                        </Card.Content>
                    </Card>

                </Card.Group>
                <Card.Group itemsPerRow={2} doubling>
                    {
                        this.props.menus.items ? (
                            this.props.menus.items.items.length > 0 ? (
                                this.props.menus.items.items.map((menu, index) => {
                                    return <Card key={index} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        boxShadow: "none",
                                        background: "transparent"
                                    }}>

                                        <div
                                            style={{
                                                height: "auto",
                                                minWidth: 150,
                                                minHeight: 110,
                                                backgroundImage: menu.images[0] && `url("${ encodeURI(menu.images[0].imageSignedUrl)}")`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center top'
                                            }}>
                                            <img src={menu.images[0] && menu.images[0].imageSignedUrl}
                                                 style={{
                                                     aspectRatio: 'auto',
                                                     height: "auto",
                                                     maxHeight: 150,
                                                     width: 150,
                                                 }}/>
                                        </div>

                                        <Card.Content style={{border: "none", width: 300}}
                                                      className={"mobile-menu-item"}
                                        >
                                            <Card.Header>{menu.name}</Card.Header>
                                            <Card.Description>
                                                {menu.description}
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra
                                                      style={{
                                                          fontWeight: "bold",
                                                          border: "none",
                                                          padding: 5
                                                      }}>
                                            <p style={{
                                                fontSize: '1.1em',
                                                color: 'black'
                                            }}>${menu.omnivore
                                                ? menu.omnivore.price_per_unit.toFixed(2)
                                                : 0.0}</p>
                                        </Card.Content>
                                    </Card>
                                })
                            ) : null) : null
                    }
                </Card.Group>
            </div>
        );
    }
}
