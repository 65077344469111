import axios from 'axios'
import Config from '../../config'

export const client = axios.create({
    baseURL: Config.baseURL,
    responseType: 'json'
});

export const getHeaders = () => {
    return sessionStorage.getItem('token') ? {
        authorization: "Bearer " + sessionStorage.getItem('token')
    } : {}
}

export const getHeadersRefreshToken = () => {
    return sessionStorage.getItem('refresh_token') ? {
        authorization: "Bearer " + sessionStorage.getItem('refresh_token')
    } : {}
}

export const getConfiguration = () => {
    let host = window.location.host.split('.')
    let locationConfig = Config.locations.filter(location => {
        return location.hostname.indexOf(host[0]) !== -1
    })
    return locationConfig[0]
}