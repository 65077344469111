import React, {Component} from 'react'
import {Button, Form, Grid, Header, Segment, Message, Image} from 'semantic-ui-react'
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Login";
import {connect} from "react-redux";
import queryString from 'query-string'
import {getConfiguration} from '../../store/api/apiClient'

class UnlockAccount extends Component {
    constructor(props) {
        super(props)
        let query = queryString.parse(props.location.search)
        this.state = {
            email: "",
            password: "",
            errorMessage: null,
            successMessage: null,
            token: query.token
        }
    }

    componentWillMount() {
        if (this.state.token) {
            this.handleSubmit()
        }
    }

    async handleSubmit() {
        let response = await this.props.unlockAccount(this.state)
        this.handleResponse(response);
    }

    handleResponse(response) {
        let errors = response.data.errors
        if (!errors) {
            let successMessage = <p>{response.data.message}</p>
            this.setState({successMessage, errorMessage: null, loading: false})
        } else {
            let errorMessage = Array.isArray(errors) ? errors.map((error, index) => {
                return <p key={index}>{error.message}</p>
            }) : <p>{response.data.message}</p>
            this.setState({successMessage: null, errorMessage, loading: false})

        }
    }

    handleChange(field, e) {
        let state = this.state
        state[field] = e.target.value
        this.setState(state)
    }

    async handleResend() {
        let response = await this.props.resendUnlock(this.state)
        this.handleResponse(response);
    }

    render() {
        let appIcon = this.props.locations.configurations ? this.props.locations.configurations.app_icon : null
        let locationBackgroundUrl = this.props.locations.configurations.location_background_url
        return (

            <>
            <div className="bg-image"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html: [
                            '\n',
                            `body:after {` +
                            `  background-image: url(${ locationBackgroundUrl ? locationBackgroundUrl : getConfiguration().backgroundImage});` +
                            `}`,
                        ].join('\n'),
                    }}
                ></style>
                <div class="bg-text">

                    <Grid textAlign='center' style={{ margin:0}} verticalAlign='middle'>
                        <Grid.Column style={{maxWidth: 450}}>
                            <Image src={appIcon} style={{maxHeight: 300, margin: 'auto'}} onClick={() => this.props.history.push('/')}/>
                            <Segment stacked style={{
                                padding: 40,
                                background: '#ffffffe6',
                                borderRadius: 5,
                                border: '1px solid darkgray',
                            }}>
                                <Header as='h3'
                                        style={getConfiguration().label_color}
                                        textAlign='center'> UNLOCK ACCOUNT
                                </Header>
                                <br/>
                                <Form>
                                    {this.state.errorMessage ? <Message negative>
                                        <Message.Header>There were some errors with your submission</Message.Header>
                                        {this.state.errorMessage}
                                    </Message> : this.state.successMessage ? <Message positive key={1}>
                                        <Message.Header>Success!</Message.Header>
                                        {this.state.successMessage}
                                        <a href='/'>Click here </a> to login
                                    </Message> : null}
                                    <Form.Input key={1} fluid icon='user' iconPosition='left'
                                                placeholder='E-mail address'
                                                onChange={this.handleChange.bind(this, 'email')}/>,
                                    <Button key={2} style={getConfiguration().button_color} fluid size='large'
                                            onClick={this.handleResend.bind(this)}>
                                        RESEND UNLOCK CODE
                                    </Button>
                                </Form>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </div>
            </div>
            </>
        )
    }
}

export default connect(
    state => {
        const {locations} = state;
        return {locations};
    },
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UnlockAccount);
