import React from 'react';
import {Card, Image} from 'semantic-ui-react';
import {getConfiguration} from '../../../../store/api/apiClient';
import bwipjs from "bwip-js";
import {toFormatCurrencyWithCent} from '../../../../utils/NumberHelper';

const Style = {
    content: {
        minHeight: 200,
        maxHeight: 200,
    },
    logo: {
        height: 70,
        margin: 5,
        width: "auto"
    },
    description: {
        color: 'white',
    },
    cardNumber: {
        color: 'white',
        fontSize: 15,
    },
};

class ProgramCard extends React.Component {
    componentDidMount() {
        try {
            // The return value is the canvas element
            let canvas = bwipjs.toCanvas(this.props.canvasName, {
                bcid: 'code128',       // Barcode type
                text: (this.props.card && this.props.card.number) || (this.props.program.cardNumber || "0000000000001234"),    // Text to encode
                scaleY: 2,               // 3x scaling factor
                scaleX: 1,
                height: 10,
                width: 90, // Bar height, in millimeters
                includetext: true,            // Show human-readable text
                textxalign: 'center',        // Always good to set this
                backgroundcolor: 'ffffff',
                paddingwidth: 5,
                paddingheight: 3
            });
        } catch (e) {
            // `e` may be a string or Error object
        }
    }

    render() {
        const {program, handleClick, style, card} = this.props;

        return (
            <Card
                style={{...getConfiguration().button_color, margin: "auto"}}
                onClick={() => handleClick && handleClick(program.id)}
            >
                <Card.Content textAlign='center' style={style ? style : Style.content}>
                    <Image
                        className={'program'}
                        src={program.image ? program.image.imageSignedUrl || '' : ''}
                        ui={false}
                        style={Style.logo}
                    />
                    {!card && program.description ? (
                        <Card.Header style={Style.description}>{program.description}</Card.Header>
                    ) : <br/>}
                    <canvas style={{width: 180}} id={this.props.canvasName}></canvas>
                    <Card.Header as='h3' style={Style.cardNumber}>
                        {!card && program.amount ? toFormatCurrencyWithCent(program.amount) : card && card.balance ? toFormatCurrencyWithCent(card.balance) : null}
                    </Card.Header>
                </Card.Content>
            </Card>
        );
    }
}

export default ProgramCard;

