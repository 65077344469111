import appsApi from './api/apps'
import {handleError} from './api/errorHandler'

const GET_DEVICE_APPS = "GET_DEVICE_APPS";
const GET_APP = "GET_APP";
const GET_APP_ACTIVITIES = "GET_APP_ACTIVITIES";
const GET_MASTER_APPS = "GET_MASTER_APPS"
const GET_MASTER_APP = "GET_MASTER_APP"
const UPDATE_LOADING = 'UPDATE_LOADING';
const UPDATE_DIMMED = 'UPDATE_DIMMED';

const initialState = {
    data: {},
    master: {},
    app: {},
    masterapp: {},
    loading: false,
    dimmed: false,
};
export const actionCreators = {
    getMasterApps: (filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await appsApi.adminMasterApps(filter)
            let data = response.data
            dispatch({
                type: GET_MASTER_APPS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMasterApp: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await appsApi.adminMasterApp(id)
            let data = response.data
            dispatch({
                type: GET_MASTER_APP,
                payload: data.app
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    createMasterApp: (data) => async (dispatch, getState) => {
        let callback = async function () {
            await appsApi.adminCreateMasterApp(data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateMasterApp: (id, data) => async (dispatch, getState) => {
        let callback = async function () {
            await appsApi.adminUpdateMasterApp(id, data)
        };
        try {
            await callback();
        } catch (e) {
            return await handleError(e.response, callback)
        }
    },
    updateLoading: (isLoading) => async (dispatch, getState) => {
      let callback = async function () {
        dispatch({
          type: UPDATE_LOADING,
          payload: isLoading,
        });
      };
      try {
        await callback();
      } catch (e) {
        return handleError(e.response, callback);
      }
    },
    updateDimmed: (isDimmed) => async (dispatch, getState) => {
      let callback = async function () {
        dispatch({
          type: UPDATE_DIMMED,
          payload: isDimmed,
        });
      };
      try {
        await callback();
      } catch (e) {
        return handleError(e.response, callback);
      }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
      case GET_DEVICE_APPS:
        return { ...state, data: action.payload };
      case GET_MASTER_APPS:
        return { ...state, master: action.payload };
      case GET_APP:
        return { ...state, app: action.payload, activities: null };
      case GET_APP_ACTIVITIES:
        return { ...state, activities: action.payload };
      case GET_MASTER_APP:
        return { ...state, masterapp: action.payload };
      case UPDATE_LOADING:
        return { ...state, loading: action.payload };
      case UPDATE_DIMMED:
        return { ...state, dimmed: action.payload };
      default:
        return state;
    }
};
