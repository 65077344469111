import React, { useState } from 'react';
import { Button, Form, Grid, Item, Responsive } from 'semantic-ui-react';

import { Media } from '../../../AppMedia';
import { getConfiguration } from '../../../store/api/apiClient';

export default (props) => {
    const { cards: { order }, handlePersonalMessage, handleGoBack } = props;
    const [message, setMessage] = useState(order.personal_message);

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        Would you like to include a personal message?
                    </Item.Header>
                </Item.Content>
            </Item>
            <Item>
                <Item.Content>
                    <Form.Field>
                        <label style={getConfiguration().label_color}>MESSAGE</label>
                        <textarea
                            placeholder='Personal message...'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </Form.Field>
                </Item.Content>
            </Item>
            <Media at='mobile'>
                <Form.Button
                    fluid
                    circular
                    style={getConfiguration().button_color}
                    onClick={() => handlePersonalMessage(message)}
                >
                    CONTINUE
                </Form.Button>
                <Form.Button
                    fluid
                    circular
                    onClick={() => handleGoBack()}
                >
                    BACK
                </Form.Button>
            </Media>
            <Media greaterThanOrEqual='tablet'>
                <Item>
                    <Item.Content>
                        <Grid centered>
                            <Grid.Column width={5}>
                                <Button
                                    fluid
                                    circular
                                    onClick={() => handleGoBack()}
                                >
                                    BACK
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Button
                                    fluid
                                    circular
                                    style={getConfiguration().button_color}
                                    onClick={() => handlePersonalMessage(message)}
                                >
                                    CONTINUE
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Item.Content>
                </Item>
            </Media>
        </Item.Group>
    );
};
