import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { isEqual } from 'lodash';
import { Button, Card, Form, Grid, Icon, Item, Responsive, Table, Input } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { Media } from '../../../AppMedia';
import { getConfiguration } from '../../../store/api/apiClient';
import { Logger } from '../../../utils/Logger';

function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
  
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
  
    return debouncedValue;
}

export default (props) => {
    const {
        cards: { order },
        updatePhysicalCard,
        addPhysicalCard,
        handlePhysicalPreview,
        handleGoBack
    } = props;
    const [items, setItems] = useState([...order.items]);
    const [error, setError] = useState({});
    const debouncedItems = useDebounce(items, 500);

    useEffect(
        () => {
          if (debouncedItems) {
            if (!isSame() && isEmpty(error)) {
                updatePhysicalCard(items);
            }
          }
        },
        [debouncedItems] // Only call effect if debounced search term changes
    );

    const handleChangeQuantity = (field, index, value) => {
        let newError = { ...error };
        try {
            if (!value || value == 0) {
                if (!newError[index]) {
                    newError[index] = {};
                }
                newError[index][field] = {
                    content: 'Please enter a valid ' + field,
                    pointing: 'below',
                };
            } else {
                if (!isEmpty(newError[index])) {
                    delete newError[index][field];
                    if (isEmpty(newError[index])) {
                        delete newError[index];
                    }
                }
            }
    
            setItems(items.map((item, idx) => {
                if (index === idx) return {...item, [field]: value };
                return item;
            }));
            setError(newError);
        } catch (err) {
            Logger.error('PhysicalPreview', 'handleChangeQuantity', JSON.stringify(err));
        }
    };

    const handleRemove = (index) => {
        let newError = { ...error };
        try {
            delete newError[index];
    
            const newItems = items.filter((item, idx) => {
                return idx !== index;
            });
            updatePhysicalCard(newItems);
    
            setItems(newItems);
            setError(newError);
        } catch (err) {
            Logger.error('PhysicalPreview', 'handleRemove', JSON.stringify(err));
        }
    };

    const isSame = () => {
        return isEqual(order.items, items);
    };

    const renderDesktop = () => {
        return (
            <Table compact>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>Product</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                        <Table.HeaderCell>Subtotal</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map((item, index) => {
                        return (
                            <Table.Row key={`row_${index}`}>
                                <Table.Cell>
                                    <Button basic icon onClick={() => handleRemove(index)}>
                                        <Icon name='times' />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.item}</Table.Cell>
                                <Table.Cell>
                                    <Form.Field
                                        error={
                                            error[index]
                                                ? error[index].price
                                                    ? true
                                                    : false
                                                : null
                                        }
                                    >
                                        <NumberFormat
                                            value={item.price || ''}
                                            placeholder='Price'
                                            inputMode='decimal'
                                            isAllowed={(values) => {
                                                const { formattedValue, floatValue } = values;
                                                return formattedValue === '' || floatValue <= 10000;
                                            }}
                                            pattern='[0-9],*'
                                            customInput={Input}
                                            style={{ width: '100%' }}
                                            onInvalid={(e) => e.preventDefault()}
                                            onValueChange={(values) =>
                                                handleChangeQuantity('price', index, values.floatValue)
                                            }
                                        />
                                    </Form.Field>
                                </Table.Cell>
                                <Table.Cell>
                                    <Form.Field
                                        error={
                                            error[index]
                                                ? error[index].quantity
                                                    ? true
                                                    : false
                                                : null
                                        }
                                    >
                                        <NumberFormat
                                            value={item.quantity || ''}
                                            placeholder='Quantity'
                                            inputMode='decimal'
                                            isAllowed={(values) => {
                                                const { formattedValue, floatValue } = values;
                                                return formattedValue === '' || floatValue <= 10000;
                                            }}
                                            pattern='[0-9]*'
                                            customInput={Input}
                                            style={{ width: '100%' }}
                                            onInvalid={(e) => e.preventDefault()}
                                            onValueChange={(values) =>
                                                handleChangeQuantity('quantity', index, values.floatValue)
                                            }
                                        />
                                    </Form.Field>
                                </Table.Cell>
                                <Table.Cell>
                                    {'$' + Number(item.price * item.quantity).toFixed(2)}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>

                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan='5' textAlign={'right'}>
                            <Button
                                icon
                                style={getConfiguration().button_color}
                                onClick={() => addPhysicalCard()}
                            >
                                <Icon name='plus' />
                                &nbsp; Add Another
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    };

    const renderMobile = () => {
        return (
            <>
            <Card.Group>
                {items.map((item, index) => {
                    return (
                        <Card fluid key={`item_${index}`}>
                            <Card.Content>
                                <Button
                                    floated='right'
                                    basic
                                    icon
                                    size='small'
                                    onClick={() => handleRemove(index)}
                                >
                                    <Icon name='times' />
                                </Button>
                                <div><strong>{item.item}</strong></div>
                            </Card.Content>
                            <Card.Content>
                                <Form>
                                    <Form.Field
                                        error={
                                            error[index]
                                                ? error[index].price
                                                    ? true
                                                    : false
                                                : null
                                        }
                                    >
                                        <label>Price</label>
                                        <NumberFormat
                                            value={item.price || ''}
                                            placeholder='Price'
                                            inputMode='decimal'
                                            isAllowed={(values) => {
                                                const { formattedValue, floatValue } = values;
                                                return formattedValue === '' || floatValue <= 10000;
                                            }}
                                            pattern='[0-9],*'
                                            customInput={Input}
                                            style={{ width: '100%' }}
                                            onInvalid={(e) => e.preventDefault()}
                                            onValueChange={(values) =>
                                                handleChangeQuantity('price', index, values.floatValue)
                                            }
                                        />
                                    </Form.Field>
                                    <Form.Field
                                        error={
                                            error[index]
                                                ? error[index].quantity
                                                    ? true
                                                    : false
                                                : null
                                        }
                                    >
                                        <label>Quantity</label>
                                        <NumberFormat
                                            value={item.quantity || ''}
                                            placeholder='Quantity'
                                            inputMode='decimal'
                                            isAllowed={(values) => {
                                                const { formattedValue, floatValue } = values;
                                                return formattedValue === '' || floatValue <= 10000;
                                            }}
                                            pattern='[0-9]*'
                                            customInput={Input}
                                            style={{ width: '100%' }}
                                            onInvalid={(e) => e.preventDefault()}
                                            onValueChange={(values) =>
                                                handleChangeQuantity('quantity', index, values.floatValue)
                                            }
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <div style={{ float: 'right' }}>{`$${Number(item.price * item.quantity).toFixed(2)}`}</div>
                                        <div><strong>Subtotal</strong></div>
                                    </Form.Field>
                                </Form>
                            </Card.Content>
                        </Card>
                    );
                })}
            </Card.Group>
            <Grid>
                <Grid.Column width={16}>
                    <Button
                        fluid
                        icon
                        style={getConfiguration().button_color}
                        onClick={() => addPhysicalCard()}
                    >
                        <Icon name='plus' />
                        &nbsp; Add Another
                    </Button>
                </Grid.Column>
            </Grid>
            </>
        );
    };

    return (
        <Item.Group divided>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        Review Order
                    </Item.Header>
                    <Media at='mobile'>
                        <Item.Description>
                            {renderMobile()}
                        </Item.Description>
                        <Item.Extra>
                            <Form.Button
                                fluid
                                circular
                                style={getConfiguration().button_color}
                                disabled={
                                    !isEmpty(error) || items.length === 0
                                }
                                onClick={() => handlePhysicalPreview()}
                            >
                                CONTINUE
                            </Form.Button>
                            <Form.Button
                                fluid
                                circular
                                onClick={() => handleGoBack()}
                            >
                                BACK
                            </Form.Button>
                        </Item.Extra>
                    </Media>
                    <Media greaterThanOrEqual='tablet'>
                        <Item.Description>
                            {renderDesktop()}
                        </Item.Description>
                        <Item.Extra>
                            <Grid centered>
                                <Grid.Column width={6}>
                                    <Button
                                        fluid
                                        circular
                                        onClick={() => handleGoBack()}
                                    >
                                        BACK
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Button
                                        fluid
                                        circular
                                        style={getConfiguration().button_color}
                                        disabled={
                                            !isEmpty(error) || items.length === 0
                                        }
                                        onClick={() => handlePhysicalPreview()}
                                    >
                                        CONTINUE
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </Item.Extra>
                    </Media>
                </Item.Content>
            </Item>
        </Item.Group>
    );
};
