import {client, getHeaders, getConfiguration} from './apiClient'
import moment from 'moment'

const locationMenus = async (readOnly, filter) => {
    let time = moment().format("h:mma")
    let query = 'sort_field=order&sort_order=asc'
    if (readOnly) {
        query += '&read_only=true'
    } else {
        query += '&online_order=true&time=' + time
    }
    return await client.request({
        url: 'location/' + getConfiguration().locationId + '/menus?' + query,
        method: "GET",
        headers: getHeaders(),
        params: filter
    })
}

const locationMenuCategories = async (readOnly, menuId, filter) => {
    let query = 'sort_field=order&sort_order=asc'
    if (readOnly) {
        query += '&read_only=true'
    }
    return await client.request({
        url: 'location/' + getConfiguration().locationId + '/menu/' + menuId + '/categories?' + query,
        method: "GET",
        headers: getHeaders(),
        params: filter
    })
}

const locationMenuCategoryItems = async (readOnly, menuId, categoryId, filter) => {
    let query = 'sort_field=order&sort_order=asc&minimize=true'
    if (readOnly) {
        query += '&read_only=true'
    }
    return await client.request({
        url: 'location/' + getConfiguration().locationId + '/menu/' + menuId + '/category/' + categoryId + '/items?' + query,
        method: "GET",
        headers: getHeaders(),
        params: filter
    })
}

const locationMenuCategoryItem = async (menuId, categoryId, itemId) => {
    return await client.request({
        url: 'location/' + getConfiguration().locationId + '/menu/' + menuId + '/category/' + categoryId + '/item/' + itemId,
        method: "GET",
        headers: getHeaders(),
    })
}

export default {
    locationMenus,
    locationMenuCategories,
    locationMenuCategoryItems,
    locationMenuCategoryItem
}