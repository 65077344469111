import React from 'react';
import {
    Icon,
    Message,
    Segment,
    TransitionablePortal,
} from "semantic-ui-react";

const AppMessage = props => (
    <TransitionablePortal open={props.saving !== -1}>
      <Segment
        style={{
          padding: 0,
          position: 'fixed',
          zIndex: 1000,
          top: 60,
          border: 'none',
          maxWidth: '400px',
          margin: '0 auto',
          left: 0,
          right: 0,
        }}
      >
        {props.saving === 1 ? (
          <Message icon info>
            <Icon name='circle notched' loading />
            <Message.Content>
              <Message.Header>Just one second</Message.Header>
              We are doing it.
            </Message.Content>
          </Message>
        ) : props.saving === 2 ? (
          Array.isArray(props.errors.errors) ? (
            <Message
              error
              header='Validation Failed'
              list={props.errors.errors.map((error) => error.message)}
            />
          ) : (
            <Message
              error
              header='Validation Failed'
              list={[props.errors.message]}
            />
          )
        ) : (
          <Message icon positive>
            <Icon name='info circle' />
            <Message.Content>
              <Message.Header>Done</Message.Header>
              {props.message || 'Successfully saved'}
            </Message.Content>
          </Message>
        )}
      </Segment>
    </TransitionablePortal>
);

export default AppMessage;