import React from 'react';
import { Form, Header, TextArea, Card } from 'semantic-ui-react';

import { Media } from '../../AppMedia';
import { getConfiguration } from '../../store/api/apiClient';

class PersonalMessageSection extends React.Component {
  state = {}

  handleChange(field, e) {
    this.props.update(field, e.target.value)
  }

  renderDesktop() {
    return (
      <>
        <br/>
        <Header as='h3' style={getConfiguration().label_color}>
          Personal Message
        </Header>
        <Form>
          <Form.Field>
            <TextArea
              onChange={this.handleChange.bind(this, 'personal_message')}
              value={this.props.data.personal_message}
              placeholder='Would you like to have us write a personal message?'
              style={{minHeight: 100}}/>
          </Form.Field>
        </Form>
      </>
    );
  }

  renderMobile() {
    return (
      <Card fluid style={{ marginBottom: 15 }}>
        <Card.Content>
          <Card.Header as='h3' style={getConfiguration().label_color}>
            Personal Message
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Form>
            <Form.Field>
              <TextArea
                onChange={this.handleChange.bind(this, 'personal_message')}
                value={this.props.data.personal_message}
                placeholder='Would you like to have us write a personal message?'
                style={{ minHeight: 100 }}
              />
            </Form.Field>
          </Form>
        </Card.Content>
      </Card>
    );
  }

  render() {
    return (
      <>
        <Media at='mobile'>
          {this.renderMobile()}
        </Media>
        <Media greaterThanOrEqual='tablet'>
          {this.renderDesktop()}
        </Media>
      </>
    );
  }
}

export default PersonalMessageSection;