import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {
    Button,
    Container,
    Dimmer,
    Dropdown,
    Grid,
    Header,
    Icon,
    Image,
    Label,
    Menu,
    Modal,
    Progress,
    Segment,
    Sidebar,
    Visibility
} from 'semantic-ui-react';
import {Link, withRouter} from 'react-router-dom';
import {PullToRefresh, RefreshContent} from 'react-js-pull-to-refresh';
import {differenceInMinutes} from 'date-fns';

import {Media} from '../../AppMedia';
import ErrorBoundary from '../common/ErrorBoundary';
import {actionCreators} from '../../store/Menus';
import * as loginStore from '../../store/Login';
import * as userStore from '../../store/Users';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import {getConfiguration} from '../../store/api/apiClient';
import Footer from './Footer'
import OpenTable from "react-opentable";

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixed: false, open: false, scrollTop: 0, scaleHeight: {
                desktop_banner_one_eighth: 0, desktop_banner_one_third: 0
            }, scaleWidth: {
                desktop_banner_one_eighth: 0, desktop_banner_one_third: 0
            }, offsetHeight: {
                desktop_banner_one_eighth: 0, desktop_banner_one_third: 0
            },
        };
        this.wWidth = window.screen.width;
    }

    componentDidMount() {
        window.addEventListener("resize", (event) => {
            window.location.reload(false)
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let dom = document.getElementById('maindiv')
        if (dom) dom.scrollTo(0, 0);
    }

    hideFixedMenu = (e) => {
        this.setState({fixed: false});
    }
    showFixedMenu = (e) => {
        this.setState({fixed: true});
    }

    getStyling = (isActive, backgroundColor, fixed, isDropdown) => {
        const bColor = getConfiguration().button_color.menucolor || 'white';
        if (fixed) {
            let style = {
                color: isActive ? backgroundColor : bColor, padding: this.wWidth > 1024 ? '30px' : '30px 16px',
            };
            if (isActive) {
                style.borderBottomWidth = '2px';
                style.borderBottomStyle = 'solid';
                style.borderColor = backgroundColor;
            }
            if (!isDropdown && !getConfiguration().button_color.menucolor) {
                style.textShadow = '#000000d6 1px 1px 10px';
            }
            return style;
        } else {
            let style = {
                color: isActive ? backgroundColor : bColor,
                borderColor: isActive ? backgroundColor : '#ffffff00',
                padding: this.wWidth > 1024 ? '30px' : '30px 16px',
            };
            if (!isDropdown && !getConfiguration().button_color.menucolor) {
                style.textShadow = '#000000d6 1px 1px 10px';
            }
            return style;
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.open) {
            this.setState({open: false})
        }
    }

    handleScroll(event) {
        this.setState({scrollTop: event.currentTarget.scrollTop})

    };

    setOpen(open) {
        this.setState({open})
    }

    onImgLoad(key, {target: img}) {
        let state = this.state
        state.scaleHeight[key] = img.offsetHeight / img.naturalHeight;
        state.scaleWidth[key] = img.offsetWidth / img.naturalWidth;
        state.offsetHeight[key] = img.offsetHeight;
        this.setState(state);
    }

    render() {
        const {
            children,
            appIcon,
            isMyCard,
            isCard,
            isSendGiftCards,
            checkCardBalance,
            isOrder,
            isCheck,
            isMyReward,
            isTicketHistory,
            isMyGift,
            isMyAccount,
            isAboutCraveIt,
            isFeedback,
            isLoggedIn,
            isReadOnlyMenu,
            isCatering,
            aboutLocationName,
            isReadOnlyNonPosMenu,
            isGallery
        } = this.props;
        const {pathname: activeItem} = this.props.location;
        const {location} = this.props.locations;
        const menuNames = location && location.settings ? location.settings.consumer_application_settings : null;
        const showHomeButton = location && location.settings ? location.settings.home.show_home_button : true;
        const showFooter = location && location.settings ? location.settings.consumer_application_settings.consumer_portal_footer.show_footer : false;
        const {fixed} = this.state;
        const showRewardsHome = this.props.locations.location ? this.props.locations.location.settings.home.show_rewards : false;

        const {
            button_color: {backgroundColor},
        } = getConfiguration();

        let getQuantity = (items) => {
            return items.length > 0 ? items.reduce((a, b) => ({quantity: a.quantity + b.quantity})).quantity : 0
        }
        const openttable = this.props.locations.announcements.find(announcement => announcement.data.opentable_id != null)
        const advBanner = this.props.locations.announcements.find(announcement => announcement.type == "advertisement_banner")

        const activeMenus = location && location.settings ? location.settings.consumer_application_settings.home_page.navigation_menu.active : null;
        const innerHeight = window.innerHeight / 8

        let banner = advBanner && advBanner.data.desktop_banner_one_eighth
        let bannerKey = "desktop_banner_one_eighth"
        if (this.state.scrollTop < innerHeight) {
            bannerKey = "desktop_banner_one_third"
            banner = advBanner && advBanner.data.desktop_banner_one_third
        }
        if (activeItem !== '/home' && activeItem !== "/") {
            bannerKey = "desktop_banner_one_eighth"
            banner = advBanner && advBanner.data.desktop_banner_one_eighth
        }

        const scaleHeight = this.state.scaleHeight[bannerKey]
        const scaleWidth = this.state.scaleWidth[bannerKey]

        let right = "calc(50vw - " + (getConfiguration().container_width / 2 - 30) + "px)"
        if (window.innerWidth / 2 < getConfiguration().container_width / 2 - 30) {
            right = "30px"
        }
        const bannerDimensions = advBanner ? parseInt(this.state.scrollTop > innerHeight ? this.state.offsetHeight[bannerKey] : bannerKey == "desktop_banner_one_eighth" ? this.state.offsetHeight[bannerKey] : this.state.offsetHeight[bannerKey] - this.state.scrollTop) || 400 : 0
        return (<Visibility
            className='visibility-container'
            onBottomPassed={this.showFixedMenu.bind(this)}
            onBottomPassedReverse={this.hideFixedMenu.bind(this)}
        >

            {banner && <Menu
                fixed={"top"}
                pointing
                secondary
                size='large'
                className="ui-banner"
                style={{
                    backgroundColor: "black",
                    marginBottom: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: advBanner ? (this.state.scrollTop > innerHeight ? 0 : bannerKey == "desktop_banner_one_eighth" ? 0 : -this.state.scrollTop) : 0,
                    // transition: bannerKey === "desktop_banner_one_eighth" ? "" : "margin 500ms ease"
                }}
            >
                {banner && banner.buttons.map((button, index) => {
                    return <div key={index}
                                style={{
                                    width: button.width * scaleWidth,
                                    height: button.height * scaleHeight,
                                    position: "absolute",
                                    top: button.top * scaleHeight,
                                    left: button.left * scaleWidth,
                                    zIndex: 2,
                                }}
                                onClick={() => {
                                    if (button.action_is_external) {
                                        window.location = (button.protocol || "https:") + "//" + button.hostname
                                    } else {
                                        this.props.history.push(button.action)
                                    }
                                }}></div>
                })}

                {advBanner &&
                    <div className="mainRunner" style={{width: "100%", height: "100%", backgroundColor: "black"}}>
                        <img
                            id={"banner_img"}
                            onLoad={this.onImgLoad.bind(this, "desktop_banner_one_eighth")}
                            style={{

                                position: "absolute",
                                width: "100%", // marginTop: (this.state.scrollTop > 100 ? 0 : -this.state.scrollTop)
                                zIndex: -1,
                                // display: bannerKey === "desktop_banner_one_eighth" || this.state.offsetHeight["desktop_banner_one_eighth"] === 0 ? "inline-block" : "none",
                                height: this.state.offsetHeight["desktop_banner_one_eighth"] === 0 ? "auto" : bannerKey === "desktop_banner_one_eighth" ? this.state.offsetHeight["desktop_banner_one_eighth"] : 0,
                                opacity: bannerKey === "desktop_banner_one_eighth" ? 1 : 0,
                                transition: bannerKey === "desktop_banner_one_eighth" ? "opacity 1000ms ease" : "opacity 1000ms ease",
                            }}
                            src={advBanner.data.desktop_banner_one_eighth.image_src}>
                        </img>
                        <img
                            id={"banner_img"}
                            onLoad={this.onImgLoad.bind(this, "desktop_banner_one_third")}
                            style={{
                                position: "absolute",
                                width: "100%", // marginTop: (this.state.scrollTop > 100 ? 0 : -this.state.scrollTop)
                                zIndex: -1,
                                // display: bannerKey === "desktop_banner_one_third" || this.state.offsetHeight["desktop_banner_one_third"] === 0 ? "block" : "none",
                                opacity: bannerKey === "desktop_banner_one_third" ? 1 : 0,
                                height: this.state.offsetHeight["desktop_banner_one_third"] === 0 ? "auto" : bannerKey === "desktop_banner_one_third" ? this.state.offsetHeight["desktop_banner_one_third"] : 0,
                                transition: bannerKey === "desktop_banner_one_third" ? "opacity 1000ms ease" : "opacity 1000ms ease",
                            }}
                            src={advBanner.data.desktop_banner_one_third.image_src}></img>

                    </div>
                }

                {/*{banner && <img*/}
                {/*    id={"banner_img"}*/}
                {/*    onLoad={this.onImgLoad.bind(this, bannerKey)}*/}
                {/*    style={{*/}
                {/*        position:'relative',*/}
                {/*        top: -1000,*/}
                {/*        width: "100%", // marginTop: (this.state.scrollTop > 100 ? 0 : -this.state.scrollTop)*/}
                {/*        zIndex: -1,*/}
                {/*        // transition: bannerKey === "desktop_banner_one_eighth" ? "" : "margin 500ms ease",*/}
                {/*    }}*/}
                {/*    src={banner.image_src}></img>}*/}
            </Menu>}
            <Menu
                fixed={"top"}
                pointing
                secondary
                size='large'
                className="ui-banner"
                style={{
                    ...getConfiguration().top_bar_style,
                    marginLeft: -5,
                    marginTop: (advBanner ? (this.state.scrollTop > innerHeight ? this.state.offsetHeight[bannerKey] : bannerKey == "desktop_banner_one_eighth" ? this.state.offsetHeight[bannerKey] : this.state.offsetHeight[bannerKey] - this.state.scrollTop) : 0),
                    // transition: bannerKey === "desktop_banner_one_eighth" ? "" : "margin 500ms ease"
                }}
            >
                <Container
                    textAlign='justified' style={{
                    width: getConfiguration().container_width,
                    zIndex: 99,
                    background: getConfiguration().button_color.menuBackground
                }}>
                    <Image
                        src={appIcon}
                        style={{height: 80, marginBottom: 5, marginTop: 5, cursor: 'pointer'}}
                        onClick={() => {
                            this.props.onRefreshHome()
                            this.props.history.push('/')
                        }}
                    />
                    {activeMenus.map((menu, index) => {
                        return menu.link ? <Menu.Item
                            key={index}
                            style={this.getStyling(activeItem.indexOf(menu.link) >= 0, backgroundColor, fixed, false)}
                            as={Link}
                            to={menu.link}
                            name={menu.link}
                            active={activeItem.indexOf(menu.link) >= 0}
                        >
                            {menu.name}
                        </Menu.Item> : <Dropdown
                            item
                            text={menu.name}
                            style={this.getStyling(false, backgroundColor, fixed, false)}
                        >
                            {menu.sub_links && <Dropdown.Menu>
                                {menu.sub_links.map((submenu, index) => {
                                    return submenu.link ? <Dropdown.Item
                                        key={index}
                                        text={submenu.name}
                                        as={Link}
                                        to={submenu.link}
                                        name={submenu.link}
                                        active={activeItem === submenu.link}
                                        style={this.getStyling(activeItem.indexOf(submenu.link) >= 0, backgroundColor, fixed, true)}
                                    /> : null
                                })}
                            </Dropdown.Menu>}
                        </Dropdown>
                    })}

                    <Menu.Item
                        style={{color: fixed ? null : 'white', padding: '30px 0px'}}
                        position='right'
                    >
                        &nbsp;
                        {isOrder && this.props.ticket.ticket_order && this.props.ticket.ticket_order.items && this.props.ticket.ticket_order.status !== 'Submitted' && this.props.ticket.ticket_order.status !== 'Queued' ? (
                            <Button
                                circular
                                icon
                                as={Link}
                                to={'/ordercart'}
                                name={'/ordercart'}
                                style={{
                                    border: `2px solid ${getConfiguration().button_color.menucolor || 'white'}`,
                                    background: 'transparent',
                                    color: getConfiguration().button_color.menucolor || 'white',
                                }}
                            >
                                <Label color='red' floating circular size={'tiny'}>
                                    {getQuantity(this.props.ticket.ticket_order.items.filter((item) => !!item.menu_item_external_id))}
                                </Label>{' '}
                                <Icon name='cart'/>
                            </Button>) : null}
                        &nbsp;
                        {this.props.cart.order ? (<Button
                            circular
                            icon
                            as={Link}
                            to={'/cart'}
                            name={'/cart'}
                            style={{
                                width: 42,
                                height: 42,
                                border: `2px solid ${getConfiguration().button_color.menucolor || 'white'}`,
                                background: 'transparent',
                                color: getConfiguration().button_color.menucolor || 'white',
                            }}
                        >
                            <Label color='red' floating circular size={'tiny'}>
                                {this.props.cart.order.items.length}
                            </Label>{' '}
                            <Image
                                style={{
                                    width: 26, position: 'absolute', top: 10, left: 7,
                                }}
                                size={'mini'}
                                className={'brightness'}
                                src={process.env.PUBLIC_URL + '/img/giftit.svg'}
                            />
                        </Button>) : null}
                        {openttable && <Modal
                            trigger={<Button
                                inverted
                                style={{
                                    border: `2px solid ${getConfiguration().button_color.menucolor || 'white'}`,
                                    color: getConfiguration().button_color.menucolor || 'white',
                                }}
                            >
                                RESERVATION
                            </Button>}
                            centered
                            basic
                            closeIcon
                            size={'mini'}
                            onClose={() => this.setOpen(false)}
                            onOpen={() => this.setOpen(true)}
                            open={this.state.open}
                        >
                            <Modal.Content>
                                <OpenTable
                                    rid={openttable.data.opentable_id}
                                    customClassName={'open_table'}
                                />
                            </Modal.Content>
                        </Modal>}
                        &nbsp; &nbsp;
                        {isLoggedIn ? null : (<Button as={Link} to={'/signup'} name={'/signup'}
                                                      inverted
                                                      style={{
                                                          border: `2px solid ${getConfiguration().button_color.menucolor || 'white'}`,
                                                          color: getConfiguration().button_color.menucolor || 'white',
                                                      }}
                        >
                            SIGN UP!
                        </Button>)}
                        &nbsp; &nbsp;
                        <Button as={Link} to={'/login'} name={'/login'}
                                inverted
                                style={{
                                    border: `2px solid ${getConfiguration().button_color.menucolor || 'white'}`,
                                    color: getConfiguration().button_color.menucolor || 'white',
                                }}
                        >
                            {isLoggedIn ? 'LOGOUT' : 'LOGIN'}
                        </Button>
                        &nbsp; &nbsp;
                    </Menu.Item>
                </Container>
            </Menu>

            {(activeItem === "/" || activeItem === "/home") && this.props.users.user.rewards && this.props.users.user.rewards.summary && this.props.users.user.rewards.is_rewards_on && showRewardsHome ?
                <div
                    style={{
                        visibility: bannerKey === "desktop_banner_one_third" ? "visible" : "hidden",
                        opacity: bannerKey === "desktop_banner_one_third" ? "1" : "0.5",
                        top: advBanner ? 90 + (this.state.scrollTop > innerHeight ? this.state.offsetHeight[bannerKey] : bannerKey == "desktop_banner_one_eighth" ? this.state.offsetHeight[bannerKey] : this.state.offsetHeight[bannerKey] - this.state.scrollTop) : 0,
                        position: "absolute",
                        float: "right",
                        zIndex: 2,
                        width: 400,
                        margin: 'auto',
                        right: right,
                        background: 'rgba(217,214,214,0.9)',
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                        padding: 25,
                        paddingTop: 15,
                        paddingBottom: 15,
                        transition: "visibility 500ms ease, opacity 500ms ease"
                    }}>
                    <div>
                        <p style={{fontSize: 15, fontWeight: "bold", margin: 0, textAlign: "center"}}>
                            My Rewards
                        </p>
                        <Header as='h4' textAlign='left' style={{margin: 0}}>
                                            <span
                                                style={{
                                                    fontWeight: 'bolder', fontSize: 20,
                                                }}
                                            >
                                            {this.props.users.user.rewards.summary.current_points}{' '}
                                                /
                                            </span>
                            <span>
                                                {' '}
                                &nbsp;
                                {this.props.users.user.rewards.summary.points_to_next_reward}
                                            </span>
                        </Header>
                        <Progress
                            percent={(this.props.users.user.rewards.summary.current_points / this.props.users.user.rewards.summary.points_to_next_reward) * 100}
                            style={{margin: '5px 0px 5px 0px'}}
                            className='custom-color-progress'
                        />
                        <p style={{fontSize: 12, fontWeight: "bold", marginBottom: 5}}>
                            {this.props.users.user.rewards.summary.reward_message !== undefined ? this.props.users.user.rewards.summary.reward_message : ""}
                        </p>
                        <Link to={'/rewards'} name={'/rewards'}>
                            <p style={{fontSize: 13, fontWeight: "bolder", color: "black"}}>
                                Manage My Rewards <Icon name='share'/>
                            </p>
                        </Link>
                    </div>
                </div>
                : null}

            <Container id={"maindiv"} fluid className='ui-content' style={{
                marginTop: advBanner ? (this.state.scrollTop > innerHeight ? this.state.offsetHeight[bannerKey] : bannerKey == "desktop_banner_one_eighth" ? this.state.offsetHeight[bannerKey] : this.state.offsetHeight[bannerKey] - this.state.scrollTop) : 0, // minHeight: "calc(100% + 150px)",
                // transition: bannerKey === "desktop_banner_one_eighth" ? "" : "margin 500ms ease"
            }}
                       onScroll={this.handleScroll.bind(this)}>
                <style dangerouslySetInnerHTML={{
                    __html: ['.open_table {' + '   top:50px;\n' + '   z-index:99;\n' + '   left:calc(50% - 100px);\n' + '}\n' + '.ui.menu>.container, .ui.menu>.grid {' + '   justify-content: center\n' + '}\n' + '.ui-content {\n' + '    max-height: calc(100vh - ' + (showFooter ? 0 : 0) + 'px);\n' + '    overflow: auto;\n' + '}'].join('\n'),
                }}
                />

                <div style={{minHeight: 'calc(100vh - 200px)',
                    marginTop: 90,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <div>{children}</div>
                    {showFooter ? <Footer/> : null}
                </div>
            </Container>
        </Visibility>);
    }
}

class MobileContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false, fixed: false, scrollTop: 0, scaleHeight: 1, scaleWidth: 1, offsetHeight: {
                mobile_banner_one_eighth: 0, mobile_banner_one_third: 0
            },
        };
    }

    componentDidMount() {
        const {pathname: activeItem} = this.props.location;
        if (activeItem.indexOf('/order') < 0 && activeItem.indexOf('/menus') < 0) {
            this.props.clearMenuTitle();
        }
        window.addEventListener('popstate', (event) => {
            if (this.props.menuTitle.length > 1) {
                this.props.popMenuTitle();
            }
        });
        window.addEventListener("resize", (event) => {
            this.setState({scrollTop: 0})
            let dom = document.getElementById('maindiv-mobile')
            if (dom) dom.scrollTo(0, 0);
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let dom = document.getElementById('maindiv-mobile')
        if (dom) dom.scrollTo(0, 0);
    }

    handleItemClick = (e, {name}) => {
        this.props.clearMenuTitle();
        if (name === '/order') {
            this.props.pushMenuTitle('ORDER');
        } else if (name === '/menus') {
            this.props.pushMenuTitle('MENUS');
        } else if (name === '/home') {
            this.props.onRefreshHome();
        }
        this.handlePusher();
    };

    hideFixedMenu = () => this.setState({fixed: false});
    showFixedMenu = () => this.setState({fixed: true});

    handlePusher = () => {
        const {visible} = this.state;

        if (visible) this.setState({visible: false});
    };

    handleToggle = () => {
        if (this.props.menuTitle.length > 1) {
            this.props.history.goBack();
        } else {
            this.setState({visible: !this.state.visible});
        }
    };

    handleCloseOrder = () => {
        this.props.clearMenuTitle();
        const {pathname: activeItem} = this.props.location;
        if (activeItem.indexOf('/order') >= 0) {
            this.props.pushMenuTitle('ORDER');
        } else if (activeItem.indexOf('/menus') >= 0) {
            this.props.pushMenuTitle('MENUS');
        }
    };

    handleScroll(event) {
        const innerHeight = window.innerHeight / 8
        this.setState({scrollTop: event.currentTarget.scrollTop})
    };


    onImgLoad(key, {target: img}) {
        let state = this.state
        state.scaleHeight = img.offsetHeight / img.naturalHeight;
        state.scaleWidth = img.offsetWidth / img.naturalWidth;
        state.offsetHeight[key] = img.offsetHeight;
        this.setState(state);
    }

    render() {
        const {
            children,
            isMyCard,
            isCard,
            isSendGiftCards,
            checkCardBalance,
            isOrder,
            isCheck,
            isMyReward,
            isTicketHistory,
            isMyGift,
            isMyAccount,
            isAboutCraveIt,
            isFeedback,
            menuTitle,
            isLoggedIn,
            isReadOnlyMenu,
            isCatering,
            aboutLocationName,
            isReadOnlyNonPosMenu,
            isGallery
        } = this.props;
        const {pathname: activeItem} = this.props.location;
        const {location} = this.props.locations;
        const menuNames = location && location.settings ? location.settings.consumer_application_settings : null;
        const showFooter = location && location.settings ? location.settings.consumer_application_settings.consumer_portal_footer.show_footer : false;
        const {visible, fixed} = this.state;
        let activeMenus = location && location.settings ? location.settings.consumer_application_settings.home_page.navigation_menu.active : null;

        let foundHome = activeMenus.find(menu => {
            if (menu.link === "/home") return true
            if (menu.sub_links) {
                let foundSub = menu.sub_links.find(sub => {
                    return sub.link === "/home"
                })
                return !!foundSub
            }
        })
        if (!foundHome) {
            activeMenus.unshift({
                id: "home",
                link: "/home",
                name: "HOME",
                user_required: false
            })
        }
        let {
            button_color: {backgroundColor},
        } = getConfiguration();
        const advBanner = this.props.locations.announcements.find(announcement => announcement.type == "advertisement_banner")
        const innerHeight = window.innerHeight / 8

        let banner = advBanner && advBanner.data.mobile_banner_one_eighth
        let bannerKey = "mobile_banner_one_eighth"
        if (this.state.scrollTop < innerHeight) {
            bannerKey = "mobile_banner_one_third"
            banner = advBanner && advBanner.data.mobile_banner_one_third
        }
        if (activeItem !== '/home' && activeItem !== "/") {
            bannerKey = "mobile_banner_one_eighth"
            banner = advBanner && advBanner.data.mobile_banner_one_eighth
        }
        const showRewardsHome = this.props.locations.location ? this.props.locations.location.settings.home.show_rewards : false;

        const scaleHeight = this.state.scaleHeight
        const scaleWidth = this.state.scaleWidth
        const bannerDimensions = advBanner?parseInt(this.state.scrollTop > innerHeight ? this.state.offsetHeight[bannerKey] : bannerKey == "mobile_banner_one_eighth" ? this.state.offsetHeight[bannerKey] : this.state.offsetHeight[bannerKey] - this.state.scrollTop) || 400 : 0
        const rewardsComponent = (activeItem === "/" || activeItem === "/home") && this.props.users.user.rewards && this.props.users.user.rewards.summary && this.props.users.user.rewards.is_rewards_on && showRewardsHome ?
            <Menu
                fixed={"top"}
                pointing
                secondary
                size='large'
                className="ui-banner rewards"
                style={{
                    ...getConfiguration().top_bar_style, marginLeft: -5,
                    marginTop: bannerDimensions + 55,
                    visibility: bannerKey === "mobile_banner_one_third" ? "visible" : "hidden",
                    opacity: bannerKey === "mobile_banner_one_third" ? 1 : 0,
                    // transition: bannerKey === "mobile_banner_one_third" ? "margin 500ms ease, visibility 500ms ease, opacity 500ms ease" : "margin 500ms ease, visibility 500ms ease, opacity 500ms ease"
                }}
            >
                <Container
                    style={{
                        justifyContent: "right", width: getConfiguration().container_width, zIndex: 99,
                    }}>
                    <div
                        style={{
                            position: "relative",
                            float: "right",
                            zIndex: 2,
                            width: 400,
                            margin: 0,
                            background: 'rgba(217,214,214,0.9)',
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15,
                            padding: 25,
                            paddingTop: 15,
                            paddingBottom: 15,
                        }}>
                        <div>
                            <p style={{
                                fontSize: 15, fontWeight: "bold", margin: 0, textAlign: "center"
                            }}>
                                My Rewards
                            </p>
                            <Header as='h4' textAlign='left' style={{margin: 0}}>
                                            <span
                                                style={{
                                                    fontWeight: 'bolder', fontSize: 20,
                                                }}
                                            >
                                            {this.props.users.user.rewards.summary.current_points}{' '}
                                                /
                                            </span>
                                <span>
                                                {' '}
                                    &nbsp;
                                    {this.props.users.user.rewards.summary.points_to_next_reward}
                                            </span>
                            </Header>
                            <Progress
                                percent={(this.props.users.user.rewards.summary.current_points / this.props.users.user.rewards.summary.points_to_next_reward) * 100}
                                style={{margin: '5px 0px 5px 0px'}}
                                className='custom-color-progress'
                            />
                            <p style={{fontSize: 12, fontWeight: "bold", marginBottom: 5}}>
                                {this.props.users.user.rewards.summary.reward_message !== undefined ? this.props.users.user.rewards.summary.reward_message : ""}
                            </p>
                            <Link to={'/rewards'} name={'/rewards'}>
                                <p style={{fontSize: 13, fontWeight: "bolder", color: "black"}}>
                                    Manage My Rewards <Icon name='share'/>
                                </p>
                            </Link>
                        </div>
                    </div>
                </Container>
            </Menu> : null

        return (<>
            <Visibility
                className='visibility-container'
                once={false}
                onBottomPassed={this.showFixedMenu}
                onBottomPassedReverse={this.hideFixedMenu}
            >

                <Menu
                    fixed={"top"}
                    pointing
                    secondary
                    size='large'
                    className="ui-banner"
                    style={{
                        marginBottom: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "100%",
                        marginTop: advBanner ? (this.state.scrollTop > innerHeight ? 0 : bannerKey == "mobile_banner_one_eighth" ? 0 : -this.state.scrollTop) : 0,
                        // transition: "margin 500ms ease"
                    }}
                >
                    {banner && banner.buttons.map((button, index) => {
                        return <div key={index}
                                    style={{
                                        width: button.width * scaleWidth,
                                        height: button.height * scaleHeight,
                                        position: "absolute",
                                        top: button.top * scaleHeight,
                                        left: button.left * scaleWidth,
                                        zIndex: 2,
                                    }}
                                    onClick={() => {
                                        if (button.action_is_external) {
                                            window.location = (button.protocol || "https:") + "//" + button.hostname
                                        } else {
                                            this.props.history.push(button.action)
                                        }
                                    }}></div>
                    })}

                    {advBanner &&
                        <div className="mainRunner" style={{width: "100%", height: "100%", backgroundColor: "black"}}>
                            <img
                                id={"banner_img"}
                                onLoad={this.onImgLoad.bind(this, "mobile_banner_one_eighth")}
                                style={{

                                    position: "absolute",
                                    width: "100%", // marginTop: (this.state.scrollTop > 100 ? 0 : -this.state.scrollTop)
                                    zIndex: -1,
                                    height: this.state.offsetHeight["mobile_banner_one_eighth"] === 0 ? "auto" : bannerKey === "mobile_banner_one_eighth" ? this.state.offsetHeight["mobile_banner_one_eighth"] : 0,
                                    opacity: bannerKey === "mobile_banner_one_eighth" ? 1 : 0,
                                    transition: "opacity 1000ms ease",
                                }}
                                src={advBanner.data.mobile_banner_one_eighth.image_src}>
                            </img>
                            <img
                                id={"banner_img"}
                                onLoad={this.onImgLoad.bind(this, "mobile_banner_one_third")}
                                style={{
                                    position: "absolute",
                                    width: "100%", // marginTop: (this.state.scrollTop > 100 ? 0 : -this.state.scrollTop)
                                    zIndex: -1,
                                    opacity: bannerKey === "mobile_banner_one_third" ? 1 : 0,
                                    height: this.state.offsetHeight["mobile_banner_one_third"] === 0 ? "auto" : bannerKey === "mobile_banner_one_third" ? this.state.offsetHeight["mobile_banner_one_third"] : 0,
                                    transition: "opacity 1000ms ease",
                                }}
                                src={advBanner.data.mobile_banner_one_third.image_src}></img>
                        </div>
                    }
                    {/*{banner && <img*/}
                    {/*    onLoad={this.onImgLoad.bind(this, bannerKey)}*/}
                    {/*    style={{*/}
                    {/*        zIndex: 1, width: "100%",*/}
                    {/*    }}*/}
                    {/*    src={banner.image_src}></img>}*/}

                </Menu>
                <Menu
                    fixed={'top'}
                    secondary
                    pointing
                    className="ui-banner"
                    style={{
                        ...getConfiguration().top_bar_style,
                        backgroundColor: "lightgrey",
                        marginTop: advBanner ? (this.state.scrollTop > innerHeight ? this.state.offsetHeight[bannerKey] : bannerKey == "mobile_banner_one_eighth" ? this.state.offsetHeight[bannerKey] : this.state.offsetHeight[bannerKey] - this.state.scrollTop) : 0,
                        // transition: "margin 500ms ease"
                    }}
                >
                    <Menu.Item onClick={this.handleToggle}>
                        {menuTitle.length > 1 ? (<Icon name='arrow left' size='large' fitted
                                                       inverted={!getConfiguration().button_color.menucolor}/>) : visible ? (
                            <Icon name='close' size='large' fitted
                                  inverted={!getConfiguration().button_color.menucolor}/>) : (
                            <Icon name='sidebar' size='large' fitted
                                  inverted={!getConfiguration().button_color.menucolor}/>)}
                    </Menu.Item>
                    <Menu.Item
                        style={{color: fixed ? null : 'white', padding: '5px 0px'}}
                        position='right'
                    >
                        {isOrder && this.props.ticket.ticket_order && this.props.ticket.ticket_order.items && this.props.ticket.ticket_order.status !== 'Submitted' && this.props.ticket.ticket_order.status !== 'Queued' ? (
                            <Menu.Item
                                as={Link}
                                to={'/ordercart'}
                                name={'/ordercart'}
                                onClick={this.handleItemClick}
                            >
                                <Label
                                    color='red'
                                    floating
                                    circular
                                    size={'tiny'}
                                    style={{top: '0px', left: '90%'}}
                                >
                                    {this.props.ticket.ticket_order.items.filter((item) => !!item.menu_item_external_id).length}
                                </Label>
                                <Icon name='cart' size='large' fitted
                                      inverted={!getConfiguration().button_color.menucolor}/>
                            </Menu.Item>) : null}
                        {this.props.cart.order ? (<Menu.Item
                            as={Link}
                            to={'/cart'}
                            name={'/cart'}
                            onClick={this.handleItemClick}
                        >
                            <Label
                                color='red'
                                floating
                                circular
                                size={'tiny'}
                                style={{top: '0px', left: '90%'}}
                            >
                                {this.props.cart.order.items.length}
                            </Label>
                            <Icon name='gift' size='large' fitted
                                  inverted={!getConfiguration().button_color.menucolor}/>
                        </Menu.Item>) : null}
                        {isLoggedIn || menuTitle.length > 1 ? null : (
                            <Menu.Item as={Link} to={'/signup'} name={'/signup'}>
                                <Icon name='signup' size='large' fitted
                                      inverted={!getConfiguration().button_color.menucolor}/>
                            </Menu.Item>)}
                        {menuTitle.length > 1 ? (<Menu.Item
                            as={Link}
                            to={activeItem.indexOf('/order') >= 0 ? '/order' : '/menus'}
                            name={activeItem.indexOf('/order') >= 0 ? '/order' : '/menus'}
                            onClick={this.handleCloseOrder}
                        >
                            <Icon name='close' size='large' fitted
                                  inverted={!getConfiguration().button_color.menucolor}/>
                        </Menu.Item>) : (<Menu.Item as={Link} to={'/login'} name={'/login'}>
                            <Icon
                                name={isLoggedIn ? 'log out' : 'sign in'}
                                size='large'
                                fitted
                                inverted={!getConfiguration().button_color.menucolor}
                            />
                        </Menu.Item>)}
                    </Menu.Item>
                </Menu>

                {rewardsComponent}

            </Visibility>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    inverted
                    vertical
                    fixed='left'
                    visible={visible}
                >
                    {activeMenus.map((menu, index) => {
                        return menu.link ? <Menu.Item
                            key={index}
                            as={Link}
                            to={menu.link}
                            name={menu.link}
                            className='sidebar-menu-item'
                            active={activeItem.indexOf(menu.link) >= 0}
                            onClick={this.handleItemClick}
                        >
                            {menu.name}
                        </Menu.Item> : <Menu.Item className='sidebar-menu-item'>
                            {menu.name}
                            {menu.sub_links && <Menu.Menu>
                                {menu.sub_links.map((submenu, index) => {
                                    return <Menu.Item
                                        key={index}
                                        as={Link}
                                        to={submenu.link}
                                        name={submenu.link}
                                        className='sidebar-menu-item'
                                        active={activeItem === submenu.link}
                                        onClick={this.handleItemClick}
                                    >
                                        {submenu.name}
                                    </Menu.Item>
                                })}
                            </Menu.Menu>}
                        </Menu.Item>
                    })}
                </Sidebar>
                <Sidebar.Pusher
                    dimmed={visible}
                    onClick={this.handlePusher}
                >

                    <style
                        type="text/css"
                        dangerouslySetInnerHTML={{
                            __html: ['\n', `#maindiv-mobile.ui.container.fluid {` + `  width: 100% !important;
                                       transition: margin 500ms ease;
                                       overflow-x: hidden;
                                       padding-left: 0 !important;
                                       padding-right: 0 !important;
                                       margin-top: ` + (this.state.scrollTop > innerHeight ? this.state.offsetHeight[bannerKey] : bannerKey == "mobile_banner_one_eighth" ? this.state.offsetHeight[bannerKey] : this.state.offsetHeight[bannerKey] - this.state.scrollTop) + `px !important;
                                       margin-left: 0px !important;
                                       margin-right:0px !important;` + `}
                                       .ui.visible.bottom.overlay.sidebar, .ui.visible.left.overlay.sidebar, .ui.visible.right.overlay.sidebar, .ui.visible.top.overlay.sidebar {
                                            margin-top: ` + (this.state.scrollTop > innerHeight ? this.state.offsetHeight[bannerKey] + 55 : bannerKey == "mobile_banner_one_eighth" ? this.state.offsetHeight[bannerKey] + 55 : this.state.offsetHeight[bannerKey] + 55 - this.state.scrollTop) + `px !important;
                                            z-index: 103 !important;
                                            position: fixed !important;
                                        }` +
                            '.open_table {' + '   top:50px;\n' + '   z-index:99;\n' + '   left:calc(50% - 100px);\n' + '}\n' + '.ui.menu>.container, .ui.menu>.grid {' + '   justify-content: center\n' + '}\n' + '' +
                            '.ui-content {\n' + '    max-height: calc(100vh - ' + (showFooter ? 90 : 0) + 'px);\n' + '    overflow: auto;\n' + '}'].join('\n'),
                        }}
                    ></style>
                    <Container id={"maindiv-mobile"}
                               onScroll={this.handleScroll.bind(this)}
                               className='ui-content'
                               fluid
                               style={{height: '100%'}}
                    >
                        <div style={{minHeight: 'calc(100vh - 208px)',
                            marginTop: 60,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}>
                            <div>{children}</div>
                            {showFooter ? <Footer isMobile={true}/> : null}
                        </div>
                    </Container>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>);
    }
}

MobileContainer.propTypes = {
    children: PropTypes.node,
};

class HomepageLayout extends React.Component {
    state = {
        loading: true,
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let pathname = window.location.pathname
        if (nextProps.locations.seo) {
            let page = nextProps.locations.seo.find((item) => {
                return pathname.indexOf(item.path) != -1
            });
            if (!page) {
                page = nextProps.locations.seo.find((item) => item.path === "/home");
            }
            document.title = page.title
            document.description = page.description
            var meta = document.getElementById("meta_description");
            meta.setAttribute("content", page.description);
        }
        return true
    }

    componentDidMount() {
        this.handleInit();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleInit = async () => {
        const {auth, locations, ticket} = this.props;
        await this.onCheckToken();
        this.interval = setInterval(async () => {
            await this.onCheckToken();
        }, 60000)
        if (auth.user.email && auth.user.authenticated) {
            await this.props.getUser();
        }
        if (!locations.configurations) {
            await this.props.locationConfiguration();
        }
        await this.props.locationAnnouncement();
        if (!ticket.ticket_order) {
            await this.props.getTicketOrder();
        }
        this.setState({loading: false})
    }

    handleRefreshHome = async () => {
        await this.props.locationAnnouncement();
    }

    onCheckToken = async () => {
        const {auth} = this.props;
        if (auth.user && auth.user.authenticated) {
            if (!sessionStorage.getItem('token')) {
                sessionStorage.setItem('token', auth.user.auth.token);
                sessionStorage.setItem('expires', auth.user.auth.expires);
                sessionStorage.setItem('refresh_token', auth.user.auth.refresh_token);
                sessionStorage.setItem('email', auth.user.email);
            }
            const expire = auth.user.auth.expires;
            if (expire) {
                const minutes = differenceInMinutes(new Date(expire), new Date());
                if (minutes < 1) {
                    await this.props.refreshToken()
                }
            }
        }
    }

    onRefresh = () => {
        return new Promise((resolve) => {
            setTimeout(resolve(window.location.reload()), 2000);
        });
    }

    renderPullDownContent = () => {
        return (<div style={{height: 50, padding: 15, textAlign: 'center', backgroundColor: 'white'}}>
            <Icon name='refresh'/>
        </div>)
    }

    renderReleaseContent = () => {
        return (<div style={{height: 50, padding: 15, textAlign: 'center', backgroundColor: 'white'}}>
            <Icon name='refresh' color='blue' loading/>
        </div>)
    }

    renderCloseAnnouncement = (announcement, activeItem) => {
        const {location: {settings}} = this.props.locations;
        const isShow = activeItem && (activeItem.indexOf('/menus') >= 0 || activeItem.indexOf('/order') >= 0 || activeItem.indexOf('/ordercart') >= 0 || activeItem.indexOf('/ordercheckout') >= 0);
        const {enable_for_online_order} = settings.location_hours
        return announcement && announcement.type === 'closed' && isShow && enable_for_online_order ? (<Segment
            raised
            textAlign='center'
            style={{
                background: '#ffffffe6',
                borderRadius: 5,
                border: '1px solid darkgray',
                maxWidth: 400,
                margin: '16px auto',
            }}
        >
            <Header
                as='h3'
                style={getConfiguration().label_color}
            >
                {announcement.title.toUpperCase()}
            </Header>
            <Grid>
                <Grid.Column className='announcement'>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: announcement.data.body,
                        }}
                    />
                </Grid.Column>
            </Grid>
        </Segment>) : null
    }

    renderMobile() {
        const {locations: {configurations, announcements, location}, auth, children, isLoading, isDimmed} = this.props;
        const {pathname: activeItem} = this.props.location;
        const announcement = announcements.find(ann => ann.title.toUpperCase() === 'CLOSED');

        const params = {
            appIcon: configurations ? configurations.app_icon : null,
            isMyCard: location ? location.settings.cards.digital.enable : false,
            isCard: location ? location.settings.cards.digital.enable || location.settings.cards.physical.enable : false,
            isSendGiftCards: location ? location.settings.cards.digital.send_card_by_text || location.settings.cards.digital.send_card_by_email || location.settings.cards.physical.send_card_by_mail : false,
            checkCardBalance: location ? location.settings.cards.physical.check_card_balance : false,
            isOrder: location ? location.settings.meals.online_orders.enable : false,
            isReadOnlyMenu: location ? location.settings.meals.read_only_menu.enable : false,
            isReadOnlyNonPosMenu: location ? location.settings.read_only_menu_non_pos.enable : false,
            isCheck: location ? location.settings.meals.inhouse_orders.enable : false,
            isMyReward: location ? location.settings.rewards.enable : false,
            isTicketHistory: location ? location.settings.meals.ticket_history.enable : false,
            isMyGift: location ? location.settings.my_gifts.enable : false,
            isMyAccount: location ? location.settings.my_account.enable : false,
            isAboutCraveIt: location ? location.settings.about.enable : false,
            isCatering: location ? location.settings.catering.enable : false,
            isGallery: location ? location.settings.gallery.enable : false,
            isFeedback: location ? location.settings.feedback.enable : false,
            isLoggedIn: auth.user && auth.user.email ? true : false,
            aboutLocationName: location ? 'ABOUT ' + location.name.toUpperCase() : 'ABOUT CRAVE IT'
        };
        return (<PullToRefresh
            pullDownContent={this.renderPullDownContent()}
            releaseContent={this.renderReleaseContent()}
            refreshContent={<RefreshContent/>}
            pullDownThreshold={200}
            onRefresh={this.onRefresh}
            triggerHeight={180}
            startInvisible={true}
        >
            <Dimmer.Dimmable
                as='div'
                dimmed={isLoading || isDimmed}
            >
                <MobileContainer {...this.props} {...params} onRefreshHome={this.handleRefreshHome}>
                    {!activeItem || activeItem === '/' || activeItem === '/home' ? (<div
                        style={{
                            background: getConfiguration().button_color.menuBackground,
                            borderRadius: 5,
                            border: 'none',
                            textAlign: 'left',
                            maxWidth: getConfiguration().container_width,
                            margin: '0 auto',
                        }}
                    >
                        {children}
                    </div>) : (<>
                        {this.renderCloseAnnouncement(announcement, activeItem)}
                        {<div
                            style={{
                                background: '#ffffffe6',
                                borderRadius: 5,
                                border: 'none',
                                textAlign: 'left',
                                maxWidth: getConfiguration().container_width,
                                margin: '0 auto',
                            }}
                        >
                            {children}
                        </div>}
                    </>)}
                </MobileContainer>
            </Dimmer.Dimmable>
        </PullToRefresh>);
    }

    renderDesktop() {
        const {locations: {configurations, announcements, location}, auth, children, isLoading, isDimmed} = this.props;
        const {pathname: activeItem} = this.props.location;
        const announcement = announcements.find(ann => ann.title.toUpperCase() === 'CLOSED');

        const params = {
            appIcon: configurations ? configurations.app_icon : null,
            isMyCard: location ? location.settings.cards.digital.enable : false,
            isCard: location ? location.settings.cards.digital.enable || location.settings.cards.physical.enable : false,
            isSendGiftCards: location ? location.settings.cards.digital.send_card_by_text || location.settings.cards.digital.send_card_by_email || location.settings.cards.physical.send_card_by_mail : false,
            checkCardBalance: location ? location.settings.cards.physical.check_card_balance : false,
            isOrder: location ? location.settings.meals.online_orders.enable : false,
            isReadOnlyMenu: location ? location.settings.meals.read_only_menu.enable : false,
            isReadOnlyNonPosMenu: location ? location.settings.read_only_menu_non_pos.enable : false,
            isCheck: location ? location.settings.meals.inhouse_orders.enable : false,
            isMyReward: location ? location.settings.rewards.enable : false,
            isTicketHistory: location ? location.settings.meals.ticket_history.enable : false,
            isMyGift: location ? location.settings.my_gifts.enable : false,
            isMyAccount: location ? location.settings.my_account.enable : false,
            isAboutCraveIt: location ? location.settings.about.enable : false,
            isCatering: location ? location.settings.catering.enable : false,
            isGallery: location ? location.settings.gallery.enable : false,
            isFeedback: location ? location.settings.feedback.enable : false,
            isLoggedIn: auth.user && auth.user.email ? true : false,
            aboutLocationName: location ? 'ABOUT ' + location.name.toUpperCase() : 'ABOUT CRAVE IT'
        };
        return (<Dimmer.Dimmable
            as='div'
            dimmed={isLoading || isDimmed}
        >
            <DesktopContainer {...this.props} {...params} onRefreshHome={this.handleRefreshHome}>
                {!activeItem || activeItem === '/' || activeItem === '/home' ? (<div
                    style={{
                        background: getConfiguration().button_color.menuBackground,
                        borderRadius: 5,
                        border: 'none',
                        textAlign: 'left',
                        maxWidth: getConfiguration().container_width,
                        margin: '0 auto',
                    }}
                >
                    {children}
                </div>) : (<>
                    {this.renderCloseAnnouncement(announcement, activeItem)}
                    {<div
                        style={{
                            background: '#ffffffe6',
                            borderRadius: 0,
                            border: 'none',
                            textAlign: 'left',
                            maxWidth: getConfiguration().container_width,
                            margin: '0 auto',
                            padding: -5
                        }}
                    >
                        {children}
                    </div>}
                </>)}
            </DesktopContainer>
        </Dimmer.Dimmable>);
    }

    render() {
        if (this.state.loading) {
            return <></>;
        }
        const {pathname: activeItem} = this.props.location;

        const {location} = this.props.locations;
        const showFooter = location && location.settings ? location.settings.consumer_application_settings.consumer_portal_footer.show_footer : false;

        let locationBackgroundUrl = this.props.locations.configurations.preview_location_background_url || this.props.locations.configurations.location_background_url

        return (<ErrorBoundary>
            <style
                type="text/css"
                dangerouslySetInnerHTML={{
                    __html: ['\n', `.fresnel-lessThan-computer .ui-content {` + `  max-height: calc(100vh - 55px)` + `}`,].join('\n'),
                }}
            ></style>
            {/*{activeItem === '/newhome' ?*/}
            <style
                type="text/css"
                dangerouslySetInnerHTML={{
                    __html: ['\n', `body:after {` + `  background-color:` + getConfiguration().button_color.bodyBackground + `;}`,].join('\n'),
                }}
            ></style>
            {/*:*/}
            {/*<style*/}
            {/*    type="text/css"*/}
            {/*    dangerouslySetInnerHTML={{*/}
            {/*        __html: [*/}
            {/*            '\n',*/}
            {/*            `body:after {` +*/}
            {/*            `  background-image: url(${locationBackgroundUrl ? locationBackgroundUrl : getConfiguration().backgroundImage});` +*/}
            {/*            `}`,*/}
            {/*        ].join('\n'),*/}
            {/*    }}*/}
            {/*></style>}*/}
            <Media lessThan='computer'>
                {this.renderMobile()}
            </Media>
            <Media greaterThanOrEqual='computer'>
                {this.renderDesktop()}
            </Media>
        </ErrorBoundary>);
    }
}

export default connect((state) => {
    return {
        isLoading: state.apps.loading,
        isDimmed: state.apps.dimmed,
        auth: state.login,
        locations: state.locations,
        users: state.users,
        cart: state.cart,
        serviceWorker: state.serviceWorker,
        ticket: state.ticket,
        menuTitle: state.menus.title,
    };
}, (dispatch) => bindActionCreators({
    ...actionCreators, ...loginStore.actionCreators, ...locationStore.actionCreators, ...userStore.actionCreators, ...ticketStore.actionCreators,
}, dispatch))(withRouter(HomepageLayout));
