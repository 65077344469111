import React, {Component, createRef} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {range} from 'lodash';
import {
    Button,
    Card,
    Dimmer,
    Grid,
    Header,
    Item,
    Loader,
    Message,
    Placeholder,
    Ref,
    Segment,
    Sticky,
} from 'semantic-ui-react';

import {Media} from '../../../AppMedia';
import GiftCard from '../../common/ui/components/GiftCard';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';
import * as loginStore from '../../../store/Login';
import * as locationStore from '../../../store/Location';
import * as cardStore from '../../../store/Card';
import {getConfiguration} from '../../../store/api/apiClient';

class MyCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showMessage: true,
            isStick: false,
            cardId: props.match.params.cardId,
            card: null,
            error: null,
        };
        this.contextRef = createRef();
    }

    componentDidMount() {
        this.handleInit();
    }

    handleInit = async () => {
        await this.props.fetchMyCard(this.state.cardId);
    }

    handleStick = (event, data) => {
        if (this.state.isStick) return;
        this.setState({ isStick: true })
    }

    handleUnstick = (event, data) => {
        if (!this.state.isStick) return;
        this.setState({ isStick: false })
    }

    renderContent = () => {
        const { my_card: card } = this.props.cards;
        const { showMessage } = this.state;

        return (
            <Segment>
                {card && card.success_message && showMessage ? (
                    <Message
                        icon
                        success
                        visible={showMessage}
                        header={card.success_message.header.toUpperCase()}
                        content={card.success_message.message}
                    />
                ) : card && card.error && showMessage ? (
                    <Message
                        icon
                        error
                        visible={showMessage}
                        header={'Error'}
                        content={card.error.message}
                    />
                ) : null}
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='small' inline='centered'>
                        Loading
                    </Loader>
                </Dimmer>
                <Header textAlign='center' style={{ margin: '5px 0 16px 0' }}>
                    Gift Card Transactions
                </Header>
                {card && card.transactions ? (
                    <Item.Group divided>
                        {card.transactions.map((transaction, index) => {
                            return (
                                <Item key={`transaction_${index}`}>
                                    <Item.Content>
                                        <Item.Header style={{ width: '100%' }}>
                                            <div style={{ float: 'right' }}>
                                                {transaction.pretty_amount}
                                            </div>
                                            {transaction.description}
                                        </Item.Header>
                                        <Item.Meta>
                                            {transaction.online_order && (
                                                <Link
                                                    to={`/ticket/${transaction.ticket_order_id}`}
                                                    style={{
                                                        float: 'right',
                                                        ...getConfiguration().label_color,
                                                    }}
                                                >
                                                    VIEW ORDER
                                                </Link>
                                            )}
                                            {transaction.pretty_date}
                                        </Item.Meta>
                                    </Item.Content>
                                </Item>
                            );
                        })}
                    </Item.Group>
                ) : (
                    range(5).map((i) => {
                        return (
                            <Placeholder key={`transaction_${i}`}>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                        );
                    })
                )}
            </Segment>
        );
    };

    renderLinks = () => {
        const { settings } = this.props.locations.location;
        const { my_card: card } = this.props.cards;
        return (
            <Card fluid centered>
                <Card.Content>
                    <Grid centered>
                        <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'center' }}>
                            {card && card.card ? (
                                <GiftCard card={card.card} fluid={true} showBarcode={true} canvasName={"myCard"}/>
                            ) : (
                                <Placeholder>
                                    <Placeholder.Image rectangular />
                                </Placeholder>
                            )}
                        </Grid.Column>
                        {card && card.card && card.card.card_is_expired && (
                            <Grid.Column width={16}>
                                <Header textAlign='center'>
                                    This gift card has expired.
                                </Header>
                            </Grid.Column>
                        )}
                        {card && card.card && card.card.has_expiration_date && (
                            <Grid.Column width={16}>
                                <Header textAlign='center'>
                                    {`Expires: ${card.card.pretty_expiration_date}`}
                                </Header>
                            </Grid.Column>
                        )}
                        {card && card.card && settings.cards.digital.card_management.reload_card && !card.card.card_is_expired && (
                            <Grid.Column width={16}>
                                <Button
                                    as={Link}
                                    to={`/mycards/Reload/${card.card.id}`}
                                    name={`/mycards/Reload/${card.card.id}`}
                                    fluid
                                    circular
                                    style={getConfiguration().button_color}
                                >
                                    RELOAD BALANCE
                                </Button>
                            </Grid.Column>
                        )}
                        <Grid.Column width={16}>
                            <Button
                                as={Link}
                                to={`/mycards`}
                                name={`/mycards`}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                RETURN TO MY GIFT CARDS
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Card.Content>
            </Card>
        );
    };

    render() {
        const { my_card: card } = this.props.cards;
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <Media at='mobile'>
                                <Grid>
                                    <Grid.Column width={16}>
                                        {this.renderLinks()}
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        {this.renderContent()}
                                    </Grid.Column>
                                </Grid>
                            </Media>
                            <Media at='tablet'>
                                <Ref innerRef={this.contextRef}>
                                    <Grid>
                                        <Grid.Column width={10} style={card && card.card && card.card.has_expiration_date ? { minHeight: 500 } : { minHeight: 450 }}>
                                            {this.renderContent()}
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <Sticky
                                                context={this.contextRef}
                                                styleElement={{ zIndex: 0 , paddingBottom:15}}
                                                onStick={this.handleStick}
                                                onUnstick={this.handleUnstick}
                                                offset={65}
                                            >
                                                {this.renderLinks()}
                                            </Sticky>
                                        </Grid.Column>
                                    </Grid>
                                </Ref>
                            </Media>
                            <Media greaterThanOrEqual='computer'>
                                <Ref innerRef={this.contextRef}>
                                    <Grid padded>
                                        <Grid.Column width={10} style={card && card.card && card.card.has_expiration_date ? { minHeight: 430 } : { minHeight: 390 }}>
                                            {this.renderContent()}
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <Sticky
                                                context={this.contextRef}
                                                styleElement={{ zIndex: 0, paddingBottom:15 }}
                                                onStick={this.handleStick}
                                                onUnstick={this.handleUnstick}
                                                offset={120}
                                            >
                                                {this.renderLinks()}
                                            </Sticky>
                                        </Grid.Column>
                                    </Grid>
                                </Ref>
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, cards, locations } = state;
        return { users, cards, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...loginStore.actionCreators,
                ...cardStore.actionCreators,
                ...locationStore.actionCreators,
            },
            dispatch
        )
)(withRouter(MyCard));
