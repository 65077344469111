import React from 'react'
import {withRouter} from 'react-router-dom';

import Layout from '.././layout/NewHomepageLayout'
import Router from '../../appRouter'
import CacheBuster from '../../CacheBuster';

const App = (props) => (
    <CacheBuster>
        {({loading, isLatestVersion, refreshCacheAndReload}) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
                // You can decide how and when you want to force reload
                console.log("BROWSER IS BEING REFRESHED!");
                refreshCacheAndReload();
            }
            return (
                <Layout>
                    <Router />
                </Layout>
            )
        }}
    </CacheBuster>
);

export default withRouter(App);
