import usersApi from './api/users'
import {handleError} from './api/errorHandler'

const GET_USER = "GET_USER";
const UPDATE_USER = "UPDATE_USER";
const USER_INFO = "USER_INFO";
const CLEAR_USER = "CLEAR_USER";

const initialState = {
    user: {},
};
export const actionCreators = {
    getUser: () => async (dispatch, getState) => {
        let callback = async function () {
            let response = await usersApi.user()
            let data = response.data
            dispatch({
                type: GET_USER,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getUserTransactions: () => async (dispatch, getState) => {
        let callback = async function () {
            let response = await usersApi.userTransactions()
            let data = response.data
            dispatch({
                type: USER_INFO,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    updateUserInfo: (params) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await usersApi.userInfoUpdate(params)
            let data = response.data
            dispatch({
                type: UPDATE_USER,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    resetUserPassword: (params) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await usersApi.userPasswordReset(params)
            let data = response.data
            dispatch({
                type: USER_INFO,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    clearUser:() => async (dispatch, getState) => {
        dispatch({
            type: CLEAR_USER,
        });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_USER:
            return {...state, user: action.payload};
        case UPDATE_USER:
            return {...state, user: action.payload, error: action.payload.error, success_message: action.payload.success_message, hasError: action.payload.hasError};
        case USER_INFO:
            return {...state, ...action.payload};
        case CLEAR_USER:
            return {user: {}};
    }

    return state;
};
