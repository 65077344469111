export default {
    "environment": "development",
    "baseURL": "https://devapi.crave.it/",
    "googleMapURL": "https://maps.googleapis.com/maps/api/js?key=AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U&v=3.exp&libraries=geometry,drawing,places",
    "googleApiKey": "AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U",
    "client_id": "ca_FcQY5hwqZcKVrFhsMGuQzcoVpbYR6VDm",
    "api_key": "pk_test_9b9iWNIq2o1Z95XDe0gvJhbZ00I9erDF6g",
    "secret_key": "sk_test_fIGLW6bQoOoUyI4q1PVPpLwP00JQDYXBBA",
    "logentriesToken": "3251b465-6048-490f-90ed-b6af3d37c7ae",
    "recaptcha_siteKey":"6Lchds4aAAAAACPpaPZxfiLPyDFJ68ZMgFt4ZTy_",
    "recaptcha_secretKey":"6Lchds4aAAAAADdjwHt1At8QYnd3OEF9VM7qNYVt",
    "locations": [
        {
            "hostname": [
                "devjonathans",
                "devjonathanswebapp",
            ],
            "googleApiKey": "AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U",
            "organizationId": "0a98c0a8-dcf5-4fef-abf3-d896ac540e77",
            "locationId": "e62f2d74-cd15-4b8c-8859-8879a843319b",
            "adminURL": "https://devjonathanswebapp.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": '#0db77d',
                "color": 'white',
                "selected": '#208e68',
                "menuBackground": "rgba(0,0,0,0.7)",
                "menucolor": 'white',
            },
            "label_color": {
                "color": '#0db77d'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, rgb(144, 10, 10), rgb(232,15,38))"
            },
            "backgroundImage": 'https://assets.crave.it/config_assets/organization/0a98c0a8-dcf5-4fef-abf3-d896ac540e77/jonathans_background_03_25_2021.jpeg'
        },
        {
            "hostname": [
                "devlamesa",
                "localhost:8080",
            ],
            "googleApiKey": "AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U",
            "organizationId": "7567fa60-2f79-4fe6-baf2-360f32ef0c4d",
            "locationId": "9eddb678-524b-4d12-a6a3-5b9ef773df3f",
            "adminURL": "https://devlamesa.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": 'rgb(255, 51, 0)',
                "color": 'white',
                "selected": 'rgb(222, 44, 0)',
                "menuBackground": "rgba(0,0,0,0.7)",
                "bodyBackground": "#0000009e",
                "menucolor": 'white',
            },
            "button2_color": {
                "backgroundColor": 'rgb(222, 44, 0)',
                "color": 'white',
            },
            "label_color": {
                "color": 'rgb(255, 51, 0)'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, rgb(144, 10, 10), rgb(232,15,38))"
            },
            "backgroundImage": 'background-image.jpg'
        },
        {
            "hostname": [
                "devmaxburger",
                "d1s3xdoor72zt0",
                "d1s3xdoor72zt0.cloudfront.net",
            ],
            "googleApiKey": "AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U",
            "organizationId": "7567fa60-2f79-4fe6-baf2-360f32ef0c4d",
            "locationId": "5c50abcc-4c06-422e-aba5-22d11efa0cb9",
            "adminURL": "https://devmaxburger.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": '#0db77d',
                "color": 'white',
                "selected": '#208e68',
                "menuBackground": "rgba(0,0,0,0.7)",
                "menucolor": 'white',
            },
            "label_color": {
                "color": '#0db77d'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, #0b7e57, #0db77d)"
            },
            "backgroundImage": 'background-image.jpg'
        },
        {
            "hostname": [
                "devgiftcardtemplate"
            ],
            "googleApiKey": "AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U",
            "organizationId": "bcfdb2ef-c548-4840-975d-93d9ecbdad71",
            "locationId": "71ed6270-958e-4be0-9034-f8f371556a69",
            "adminURL": "https://devgiftcardtemplate.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "#0db67c00",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": '#0db77d',
                "color": 'white',
                "selected": '#208e68'
            },
            "label_color": {
                "color": '#0db77d'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, #0b7e57, #0db77d)"
            },
            "backgroundImage": 'https://assets.crave.it/config_assets/organization/bcfdb2ef-c548-4840-975d-93d9ecbdad71/shutterstock_1277595532.jpg'
        },
        {
            "hostname": [
                "devginamaries",
                "localhost:1337",
            ],
            "googleApiKey": "AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U",
            "organizationId": "8355b8b6-fdff-48c0-8d9f-73fa0777c628",
            "locationId": "4eb300ff-0462-43d7-a3cc-cc8ffd434d06",
            "adminURL": "https://devginamaries.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": 'rgb(255, 51, 0)',
                "color": 'white',
                "menuBackground": "rgba(0,0,0,0.7)",
                "selected": 'rgb(222, 44, 0)'
            },
            "button2_color": {
                "backgroundColor": 'rgb(222, 44, 0)',
                "color": 'white',
            },
            "label_color": {
                "color": 'rgb(255, 51, 0)'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, rgb(255, 51, 0), rgb(255,136,38))"
            },
            "backgroundImage": 'https://assets.crave.it/config_assets/organization/8355b8b6-fdff-48c0-8d9f-73fa0777c628/gina_maries.jpg'
        },
        {
            "hostname": [
                "devcassariano",
            ],
            "googleApiKey": "AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U",
            "organizationId": "90bb4fbc-5d06-4717-94cb-bb8db2957545",
            "locationId": "803e426d-c479-4afb-8b19-0c4f0ae490c1",
            "adminURL": "https://devcassariano.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": 'rgb(255, 51, 0)',
                "color": 'white',
                "selected": 'rgb(222, 44, 0)',
                "menuBackground": "rgba(0,0,0,0.7)",
            },
            "button2_color": {
                "backgroundColor": 'rgb(222, 44, 0)',
                "color": 'white',
            },
            "label_color": {
                "color": 'rgb(255, 51, 0)'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, rgb(255, 51, 0), rgb(255,136,38))"
            },
            "backgroundImage": 'https://assets.crave.it/config_assets/organization/90bb4fbc-5d06-4717-94cb-bb8db2957545/Cassariano_background.jpg'
        },
        {
            "hostname": [
                "devarnolds",
            ],
            "googleApiKey": "AIzaSyAYDOkHRalY2QVsBuuNm5Eqt3qPVWfWs6U",
            "organizationId": "813dac0f-7d41-4cbf-af6d-ddbd3925c63c",
            "locationId": "8a3276bd-c68f-4304-b7da-4ea7f6716f1a",
            "adminURL": "https://devarnolds.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": 'rgb(255, 51, 0)',
                "color": 'white',
                "menuBackground": "rgba(0,0,0,0.7)",
                "selected": 'rgb(222, 44, 0)',
                "menucolor": 'white',
            },
            "button2_color": {
                "backgroundColor": 'rgb(222, 44, 0)',
                "color": 'white',
            },
            "label_color": {
                "color": 'rgb(255, 51, 0)'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, rgb(255, 51, 0), rgb(255,136,38))"
            },
            "backgroundImage": 'https://assets.crave.it/config_assets/organization/90bb4fbc-5d06-4717-94cb-bb8db2957545/Cassariano_background.jpg'
        },
    ]
};
