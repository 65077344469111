import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Grid, Segment, Header} from 'semantic-ui-react'

import CacheBuster from '../../CacheBuster';
import {actionCreators} from "../../store/Users";
import * as loginStore from "../../store/Login";
import * as locationStore from "../../store/Location";
import * as cartStore from "../../store/Cart";

class ThankYou extends Component {

    state = {
        data: {
            price: 0,
            quantity: 0,
            item: "Jonathan's Gift Card"
        },
    }

    handleChange(field, e) {
        let data = this.state.data
        data[field] = e.target.value
        this.setState({data})
    }

    handleSubmit() {
        this.props.addGiftItCardToCart(this.state.data)
    }

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <Grid textAlign='center' verticalAlign='middle'>
                            <Grid.Column style={{maxWidth: 850}} textAlign='left'>
                                <Segment raised style={{
                                    padding: 40,
                                    background: '#ffffffed',
                                    borderRadius: 5,
                                    border: '1px solid darkgray'
                                }}>
                                    <Grid>
                                        <Grid.Column width={16} textAlign='center'>
                                            <Header as='h3' color='teal'>
                                                Success!
                                            </Header>
                                            <Header as='h4'>
                                                Thank you for placing your order.
                                                <br/>
                                                <br/>
                                                Please allow 2 to 3 business days for delivery of your gift card(s).
                                            </Header>
                                            <br/>
                                            <br/>
                                            <Link to={'/'}>Back to Home </Link>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                    );
                }}
            </CacheBuster>
        )
    }
}

export default connect(
    state => {
        const {users, locations, cart} = state;
        return {users, cart, locations}
    },
    dispatch => bindActionCreators({...actionCreators, ...loginStore.actionCreators, ...cartStore.actionCreators, ...locationStore.actionCreators}, dispatch)
)(ThankYou);
