import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Dimmer, Grid, Item, Loader, Message, Segment} from 'semantic-ui-react';

import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as locationStore from '../../store/Location';
import * as cardStore from '../../store/Card';
import {getConfiguration} from '../../store/api/apiClient';
import {Logger} from '../../utils/Logger';

class SendCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            type: '',
            showMessage: false,
            successMessage: null,
            errors: null,
        };
    }

    handleSendCard = async () => {
        const { type } = this.state;
        const data = {
            param: {
                type,
            },
            step: 0,
        };
        try {
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.startCardOrder(data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else {
                this.setState({ ...this.state, loading: false, step: 0 });
                if (type === 'SendText' || type === 'SendEmail') {
                    await this.props.fetchMyCardPrograms(type);
                }
                this.props.history.push(`/mycards/send/${type}/0`);
            }
        } catch (err) {
            Logger.error('SendCard', 'handleSendCard', JSON.stringify(err));
        }
    };

    render() {
        const { location } = this.props.locations;
        const { type, showMessage, errors, successMessage } = this.state;

        const menuNames = location && location.settings ? location.settings.consumer_application_settings : null;
        const sendCardByText = location ? location.settings.cards.digital.send_card_by_text : false;
        const sendCardByEmail = location
            ? location.settings.cards.digital.send_card_by_email
            : false;
        const sendCardByMail = location
            ? location.settings.cards.physical.send_card_by_mail
            : false;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            {successMessage && showMessage ? (
                                <Message
                                    icon
                                    success
                                    visible={showMessage}
                                    header={successMessage.header.toUpperCase()}
                                    content={successMessage.message}
                                />
                            ) : errors && showMessage ? (
                                <Message
                                    icon
                                    error
                                    visible={showMessage}
                                    header={'Error'}
                                    content={errors.message}
                                />
                            ) : null}
                            <Dimmer active={loading} inverted>
                                <Loader size='small' inline='centered'>
                                    Loading
                                </Loader>
                            </Dimmer>
                            <Grid padded centered>
                                <Grid.Column mobile={16} computer={10}>
                                    <Segment>
                                        <Item.Group divided>
                                            <Item>
                                                <Item.Content>
                                                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                                                        Send A Gift Card
                                                    </Item.Header>
                                                    <Item.Description>{this.hint}</Item.Description>
                                                </Item.Content>
                                            </Item>
                                            <Item>
                                                <Item.Content>
                                                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                                                        Please select a delivery method.
                                                    </Item.Header>
                                                    <Item.Description>
                                                        <Grid centered>
                                                            {sendCardByText && (
                                                                <Grid.Column mobile={16} computer={10}>
                                                                    <Button
                                                                        fluid
                                                                        style={
                                                                            type === 'SendText'
                                                                                ? {
                                                                                    ...getConfiguration()
                                                                                        .button_color,
                                                                                }
                                                                                : {}
                                                                        }
                                                                        onClick={() =>
                                                                            this.setState({ type: 'SendText' })
                                                                        }
                                                                    >
                                                                        {menuNames ? menuNames.send_card_by_text_page_name : 'SEND CARD VIA TEXT'}
                                                                    </Button>
                                                                </Grid.Column>
                                                            )}
                                                            {sendCardByEmail && (
                                                                <Grid.Column mobile={16} computer={10}>
                                                                    <Button
                                                                        fluid
                                                                        style={
                                                                            type === 'SendEmail'
                                                                                ? {
                                                                                    ...getConfiguration()
                                                                                        .button_color,
                                                                                }
                                                                                : {}
                                                                        }
                                                                        onClick={() =>
                                                                            this.setState({ type: 'SendEmail' })
                                                                        }
                                                                    >
                                                                        {menuNames ? menuNames.send_card_by_email_page_name : 'SEND CARD VIA EMAIL'}
                                                                    </Button>
                                                                </Grid.Column>
                                                            )}
                                                            {sendCardByMail && (
                                                                <Grid.Column mobile={16} computer={10}>
                                                                    <Button
                                                                        fluid
                                                                        style={
                                                                            type === 'SendPhysical'
                                                                                ? {
                                                                                    ...getConfiguration()
                                                                                        .button_color,
                                                                                }
                                                                                : {}
                                                                        }
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                type: 'SendPhysical',
                                                                            })
                                                                        }
                                                                    >
                                                                        {menuNames ? menuNames.send_card_by_post_page_name : 'SEND CARD VIA POST'}
                                                                    </Button>
                                                                </Grid.Column>
                                                            )}
                                                        </Grid>
                                                    </Item.Description>
                                                    <Item.Extra>
                                                        <Grid centered>
                                                            <Grid.Column mobile={16} computer={10}>
                                                                <Button
                                                                    fluid
                                                                    circular
                                                                    style={getConfiguration().button_color}
                                                                    disabled={!type}
                                                                    onClick={() => this.handleSendCard()}
                                                                >
                                                                    CONTINUE
                                                                </Button>
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Item.Extra>
                                                </Item.Content>
                                            </Item>
                                            <Item>
                                                <Item.Content style={{width:"100%"}}>
                                                    <Item.Description>
                                                        <div dangerouslySetInnerHTML={{__html: menuNames.send_card_main_page_promotional_text}}></div>
                                                    </Item.Description>
                                                </Item.Content>
                                            </Item>
                                        </Item.Group>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, cards, locations } = state;
        return { users, cards, locations };
    },
    (dispatch) =>
        bindActionCreators(
            { ...actionCreators, ...locationStore.actionCreators, ...cardStore.actionCreators },
            dispatch
        )
)(withRouter(SendCard));
