import React, {Component} from 'react';
import {Grid} from 'semantic-ui-react'
import Values from './Values'
import {getConfiguration} from '../../../store/api/apiClient'

export default class newChoices extends Component {

    // componentWillMount() {
    //     this.props.choices.map(choice => {
    //         let values = choice.values.filter(value => value.default)
    //         choice.chosen = values
    //         this.props.submit(choice)
    //     })
    // }

    submitChoice(choice) {
        return (values) => {
            choice.chosen = values
            this.props.submit(choice)
        }
    }

    render() {
        return (
            <Grid textAlign='left'>
                {
                    this.props.choices.map((choice, index) => {
                        if (!choice.customization || this.props.customize) {
                            return <Grid.Row key={index}>
                                <Grid.Column>
                                    {
                                        choice.values ?
                                            <Values values={choice.values}
                                                    default={0}
                                                    choice={choice}
                                                    customized={this.props.customized}
                                                    customize={this.props.customize}
                                                    defaultCustomized={this.props.defaultCustomized}
                                                    submit={this.submitChoice(choice)}/>
                                            : null
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        } else {
                            return null
                        }

                    })
                }
            </Grid>
        );
    }
}

