import React from 'react';

import { Grid, Item, Placeholder } from 'semantic-ui-react';
import ProgramCard from '../../common/ui/components/ProgramCard';

export default (props) => {
    const { title, description, programs, handleProgramSelect } = props;

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        {title}
                    </Item.Header>
                    <Item.Description style={{ width: '100%', textAlign: 'center' }}>
                        {description}
                    </Item.Description>
                </Item.Content>
            </Item>
            <Item>
                <Item.Content>
                    <Grid>
                        {programs && programs.length > 1
                            ? programs.map((program, index) => {
                                    return (
                                        <Grid.Column
                                            mobile={16}
                                            computer={8}
                                            key={`program_${index}`}
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <ProgramCard
                                                canvasName={`program_${index}`}
                                                program={program}
                                                handleClick={(id) => handleProgramSelect(program)}
                                            />
                                        </Grid.Column>
                                    );
                                })
                            : [0, 1].map((i) => {
                                    return (
                                        <Grid.Column mobile={16} computer={8} key={`card_${i}`}>
                                            <Placeholder>
                                                <Placeholder.Image rectangular />
                                            </Placeholder>
                                        </Grid.Column>
                                    );
                                })}
                    </Grid>
                </Item.Content>
            </Item>
        </Item.Group>
    );
};
