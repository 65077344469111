import {history} from '../index'

const toggleMenu = 'TOGGLE_MENU';
const initialState = {smallMenu: false};

export const actionCreators = {
    toggleSideMenu: () => ({type: toggleMenu}),
    logout: () => history.push('/')
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === toggleMenu) {
        return {...state, smallMenu: !state.smallMenu};
    }

    return state;
};
