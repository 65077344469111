import React, {Component, createRef} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    Button,
    Checkbox,
    Dimmer,
    Grid,
    Header,
    Item,
    Loader,
    Message,
    Placeholder,
    Ref,
    Segment,
    Sticky,
} from 'semantic-ui-react';

import {Media} from '../../../AppMedia';
import GiftCard from '../../common/ui/components/GiftCard';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';
import * as loginStore from '../../../store/Login';
import * as locationStore from '../../../store/Location';
import * as cardStore from '../../../store/Card';
import {getConfiguration} from '../../../store/api/apiClient';
import {toFormatCurrencyWithCent} from '../../../utils/NumberHelper';
import {Logger} from '../../../utils/Logger';

class MyCards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showMessage: true,
            isStick: false,
            data: {
                showall: false,
            },
            intervalId: setInterval(this.scrollStep.bind(this), 16),
            error: {},
        };

        this.contextRef = createRef();
    }

    componentDidMount() {
        this.props.fetchMyCards(this.state.data);
    }

    scrollStep() {
        if (window.pageYOffset <= 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    handleStick = (event, data) => {
        if (this.state.isStick) return;
        this.setState({ isStick: true })
    }

    handleUnstick = (event, data) => {
        if (!this.state.isStick) return;
        this.setState({ isStick: false })
    }

    onChangeShowAll = async () => {
        const data = { ...this.state.data };
        try {
            data.showall = !this.state.data.showall;
            this.setState({ loading: true });
            await this.props.fetchMyCards(data);
            this.setState({ loading: false, data });
        } catch (err) {
            Logger.error('MyCards', 'onChangeShowAll', JSON.stringify(err));
        }
    };

    renderContent = () => {
        const { my_cards: cards } = this.props.cards;
        const { showMessage } = this.state;
        const balance = cards && !cards.has_error
            ? cards.reduce((a, b) => {
                  return a + b.balance;
              }, 0)
            : 0;
        const { color } = getConfiguration().label_color;

        return (
            <Segment>
                <style
                    dangerouslySetInnerHTML={{
                        __html: [
                            `.ui.toggle.checkbox input:checked~label:before {
                        background-color: ${color} !important;
                      }`,
                        ].join('\n'),
                    }}
                />
                {cards && cards.success_message && showMessage ? (
                    <Message
                        icon
                        success
                        visible={showMessage}
                        header={cards.success_message.header.toUpperCase()}
                        content={cards.success_message.message}
                    />
                ) : cards && cards.error && showMessage ? (
                    <Message
                        icon
                        error
                        visible={showMessage}
                        header={'Error'}
                        content={cards.error.message}
                    />
                ) : null}
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='small' inline='centered'>
                        Loading
                    </Loader>
                </Dimmer>
                <Item>
                    <Item.Content style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ margin: 10 }}>Include Zero Balance Cards</div>
                        <Checkbox toggle onClick={this.onChangeShowAll} />
                    </Item.Content>
                </Item>
                <Header
                    textAlign='center'
                    style={{ margin: '5px 0 16px 0' }}
                >{`My Gift Cards Balance: ${toFormatCurrencyWithCent(balance)}`}</Header>
                <Grid>
                    {cards && !cards.has_error
                        ? cards.map((card, index) => {
                              return (
                                  <Grid.Column
                                      mobile={16}
                                      computer={8}
                                      key={`card_${index}`}
                                      style={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                      <GiftCard
                                          canvasName={`card_${index}`}
                                          showBarcode={true}
                                          card={card}
                                          handleClick={(id) =>
                                              this.props.history.push(`/mycards/${id}/view`)
                                          }
                                      />
                                  </Grid.Column>
                              );
                          })
                        : [0, 1].map((i) => {
                              return (
                                  <Grid.Column mobile={16} computer={8} key={`card_${i}`}>
                                      <Placeholder>
                                          <Placeholder.Image rectangular />
                                      </Placeholder>
                                  </Grid.Column>
                              );
                          })}
                </Grid>
            </Segment>
        );
    };

    renderLinks = () => {
        const { settings } = this.props.locations.location;
        return (
            <Segment>
                <Item.Group>
                    <Item className='align-center'>
                        <Item.Header>Manage or Purchase a Gift Card.</Item.Header>
                    </Item>
                    {settings.cards.digital.card_management.buy_card && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/Purchase/0'}
                                name={'/mycards/Purchase/0'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                PURCHASE A NEW CARD
                            </Button>
                        </Item>
                    )}
                    {settings.cards.digital.card_management.import_card && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/import'}
                                name={'/mycards/import'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                ADD AN EXISTING CARD
                            </Button>
                        </Item>
                    )}
                    {settings.cards.physical.check_card_balance && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/check'}
                                name={'/mycards/check'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                CHECK A CARD BALANCE
                            </Button>
                        </Item>
                    )}
                </Item.Group>
            </Segment>
        );
    };

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <Media at='mobile'>
                                <Grid>
                                    <Grid.Column width={16}>
                                        {this.renderLinks()}
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        {this.renderContent()}
                                    </Grid.Column>
                                </Grid>
                            </Media>
                            <Media greaterThanOrEqual='tablet'>
                                <Ref innerRef={this.contextRef}>
                                    <Grid padded>
                                        <Grid.Column width={11}>
                                            {this.renderContent()}
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <Sticky
                                                context={this.contextRef}
                                                styleElement={{ zIndex: 0 }}
                                                onStick={this.handleStick}
                                                onUnstick={this.handleUnstick}
                                                offset={120}
                                            >
                                                {this.renderLinks()}
                                            </Sticky>
                                        </Grid.Column>
                                    </Grid>
                                </Ref>
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, cards, locations } = state;
        return { users, cards, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...loginStore.actionCreators,
                ...cardStore.actionCreators,
                ...locationStore.actionCreators,
            },
            dispatch
        )
)(withRouter(MyCards));
