import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import NumberFormat from 'react-number-format';
import {
    Grid,
    Segment,
    Table,
    Button,
    Form,
    Icon,
    Header,
    Card,
    Divider,
    Message,
    Dropdown,
    List,
    Dimmer,
    Loader, Item,
} from 'semantic-ui-react';
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import { isEmpty, range } from 'lodash';

import { Media } from '../../AppMedia';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as appStore from '../../store/Apps';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import * as cartStore from '../../store/Cart';
import {getConfiguration} from '../../store/api/apiClient';
import { toFormatCurrencyWithCent } from '../../utils/NumberHelper';
import { Logger } from '../../utils/Logger';

import states from '../../assets/states.json';
import vehicleMakes from '../../assets/vehicle_makes.json';

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            items: [],
            saving: -1,
            error: {},
            error_address: {
                recipient_address: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_city: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_state: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_zip: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_phone: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
            },
        };
    }

    componentWillMount() {
        this.props.locationConfiguration();
        // this.props.getTicketOrder()
        let ticketOrderId = localStorage.getItem('ticket');

        if (!ticketOrderId || ticketOrderId === 'null') {
            this.props.history.push('/order');
        }
    }

    componentDidMount() {
        this.props.updateLoading(false);
        this.props.updateDimmed(false);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let successMessage = nextProps.ticket.success_message;
        let showError = false;
        let items = nextProps.ticket.ticket_order
            ? nextProps.ticket.ticket_order.items
            : [];
        if (
            !successMessage &&
            nextProps.ticket.ticket_order &&
            (nextProps.ticket.ticket_order.status === 'Submitted' ||
                nextProps.ticket.ticket_order.status === 'Queued')
        ) {
            showError = true;
        }
        let data = nextProps.ticket.ticket_order;
        if (data) {
            let isCurbSide = data ? data.use_curbside : false;
            let error_address = this.state.error_address;

            if (data.recipient_phone) {
                delete error_address['recipient_phone'];
            }
            if (!isCurbSide) {
                if (data.recipient_address) {
                    delete error_address['recipient_address'];
                }
                if (data.recipient_city) {
                    delete error_address['recipient_city'];
                }
                if (data.recipient_state) {
                    delete error_address['recipient_state'];
                }
                if (data.recipient_zip) {
                    delete error_address['recipient_zip'];
                }
            }

            this.setState({
                data,
                error_address,
                isCurbSide,
            });
        }

        this.setState({
            data,
            items: JSON.parse(JSON.stringify(items)),
            successMessage,
            showError,
        });
    }

    isSame() {
        let originalItems = this.props.ticket.ticket_order
            ? this.props.ticket.ticket_order.items
            : [];
        return JSON.stringify(originalItems) === JSON.stringify(this.state.items);
    }

    handleStateChange(field, e, target) {
        let data = this.state.data;
        let error_address = this.state.error_address;
        try {
            if (field === 'recipient_state') {
                if (target.value) {
                    delete error_address[field];
                } else {
                    error_address[field] = {
                        content: 'Please enter a valid info',
                        pointing: 'below',
                    };
                }
            }

            data[field] = target.value;
            this.setState({data, error_address});
        } catch (err) {
            Logger.error('TicketSubmit', 'handleStateChange', JSON.stringify(err));
        }
    }

    handleChange(field, value) {
        let data = { ...this.state.data };
        let error_address = { ...this.state.error_address };
        try {
            if (value) {
                if (field !== 'recipient_phone' || (field === 'recipient_phone' && value.length === 10)) {
                    delete error_address[field];
                } else {
                    error_address[field] = {
                        content: 'Please enter a valid phone number',
                        pointing: 'below',
                    };
                }
            } else {
                error_address[field] = {
                    content: 'Please enter a valid info',
                    pointing: 'below',
                };
            }
            data[field] = value;
            this.setState({data, error_address});
        } catch (err) {
            Logger.error('TicketSubmit', 'handleChange', JSON.stringify(err));
        }
    }

    handlePlaceSelect = (data) => {
        geocodeByAddress(data.description)
            .then((results) => {
                this.handleGeocode(results[0], data);
            })
            .catch(err => Logger.error('TicketSubmit', 'handlePlaceSelect', JSON.stringify(err)));
    };

    handleGeocode(addressResult, addr) {
        let data = { ...this.state.data };
        let error_address = { ...this.state.error_address };

        if (addr.types[0] === 'street_address') {
            data.recipient_address = this.getAddressType(addressResult, ['street_number', 'route']);
            data.recipient_city = this.getAddressType(addressResult, ['locality']) || this.getAddressType(addressResult, ['neighborhood']);
        } else {
            data.recipient_address = `${addr.terms[0].value} ${this.getAddressType(addressResult, ['route'])}`;
            data.recipient_city = addr.terms[2].value;
        }
        data.recipient_state = this.getAddressType(addressResult, ['administrative_area_level_1']);
        data.recipient_zip = this.getAddressType(addressResult, ['postal_code']);

        if (data.recipient_address) delete error_address.recipient_address;
        if (data.recipient_city) delete error_address.recipient_city;
        if (data.recipient_state) delete error_address.recipient_state;
        if (data.recipient_zip) delete error_address.recipient_zip;

        this.setState({ data, error_address });
    }

    getAddressType(addressResult, types) {
        let geocodeAddress = [];
        types.forEach((type) => {
            addressResult.address_components.forEach((geocode) => {
                if (geocode.types.indexOf(type) !== -1) {
                    return geocodeAddress.push(geocode.short_name);
                }
            });
        });
        return geocodeAddress.join(' ');
    }

    async updateTicketOrderDelivery(e) {
        e.preventDefault();
        try {
            this.props.updateLoading(true);
            const data = {
                phone_number: this.state.data.recipient_phone,
                recipient_phone: this.state.data.recipient_phone,
                recipient_address: this.state.data.recipient_address,
                recipient_address2: this.state.data.recipient_address2,
                recipient_city: this.state.data.recipient_city,
                recipient_state: this.state.data.recipient_state,
                recipient_zip: this.state.data.recipient_zip,
            };
            await this.props.updateTicketOrderDelivery(data);
            this.props.updateLoading(false);
            this.setState({editMode: false});
            this.scrollStep()
        } catch (err) {
            Logger.error('TicketSubmit', 'updateTicketOrderDelivery', JSON.stringify(err));
        }
    }

    async updateTicketOrderCurbside(e) {
        e.preventDefault();
        try {
            this.props.updateLoading(true);
            const data = {
                phone_number: this.state.data.recipient_phone,
                recipient_phone: this.state.data.recipient_phone,
                vehicle_make: this.state.data.vehicle_make,
                vehicle_model: this.state.data.vehicle_model,
                vehicle_color: this.state.data.vehicle_color,
                vehicle_license: this.state.data.vehicle_license,
            };
            await this.props.updateTicketOrderDelivery(data);
            this.props.updateLoading(false);
            this.setState({editMode: false});
            this.scrollStep()
        } catch (err) {
            Logger.error('TicketSubmit', 'updateTicketOrderCurbside', JSON.stringify(err));
        }
    }

    editInformation() {
        this.setState({editMode: !this.state.editMode, data: this.props.ticket.ticket_order, error_address: {}});
    }

    getTicketItemComponent(index,
                           ticketOrderItems,
                           omnivoreItems,
                           backgroundColor,
                           rest) {
        return (
            <Table.Row key={index}>
                <Table.Cell style={{width: 50, verticalAlign: 'top', paddingTop: 23}}>
                    <strong style={getConfiguration().label_color}>QUANTITY</strong>
                    <br/>
                    {
                        <Dropdown
                            placeholder='Quantity'
                            options={range(1, 11).map(
                                (quantity, index) => {
                                    return {
                                        disabled: ticketOrderItems.menu_external_id ? false : true,
                                        key: index,
                                        value: quantity,
                                        text: quantity,
                                    };
                                }
                            )}
                            button
                            fluid
                            search
                            selection
                            disabled
                            value={ticketOrderItems.quantity}
                        />
                    }
                </Table.Cell>
                <Table.Cell style={{textAlign: 'center', verticalAlign: 'top'}}>
                    {omnivoreItems.imageUrl ? (
                        <div
                            style={{
                                width: 180,
                                height: 150,
                                backgroundImage: 'url("' + omnivoreItems.imageUrl + '")',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center top',
                                borderRadius: 5,
                                margin: '15px auto 10px auto',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    width: 60,
                                    height: 32,
                                    top: 0,
                                    left: 10,
                                    textAlign: 'center',
                                    color: 'white',
                                    borderBottomRightRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    padding: 8,
                                    backgroundColor,
                                    fontSize: 12,
                                }}
                            >
                                <span style={rest}>{omnivoreItems.pretty_price}</span>
                            </div>
                        </div>
                    ) : null}
                </Table.Cell>
                <Table.Cell style={{verticalAlign: 'top'}}>
                    <Table compact basic='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Card.Header
                                        style={{
                                            lineHeight: '30px',
                                            fontWeight: 600,
                                            fontSize: 14,
                                            textShadow: '1px 1px 5px white',
                                        }}
                                    >
                                        {omnivoreItems.name}{' '}
                                        {ticketOrderItems.quantity > 1 && omnivoreItems.price
                                            ? '(' + omnivoreItems.pretty_price + ')'
                                            : null}{' '}
                                    </Card.Header>
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='right'
                                    style={{
                                        padding: 1,
                                        border: 'none',
                                        fontWeight: 600,
                                    }}
                                >
                                    {omnivoreItems.price
                                        ? toFormatCurrencyWithCent(
                                            omnivoreItems.price * ticketOrderItems.quantity
                                        )
                                        : null}
                                </Table.Cell>
                            </Table.Row>
                            {ticketOrderItems.modifiers
                                ? ticketOrderItems.modifiers.map((modifier, index) => {
                                    let findModifier = this.props.ticket.omnivore_ids.modifiers.find(
                                        (omnivoreModifier) =>
                                            omnivoreModifier.modifier === modifier.modifier
                                    );
                                    return findModifier ? (
                                        <Table.Row key={index} style={{padding: 0}}>
                                            <Table.Cell
                                                style={{
                                                    padding: 1,
                                                    border: 'none',
                                                }}
                                                width={14}
                                            >
                                                <Card.Meta style={{color: 'darkgrey'}}>
                                                    {findModifier.name}{' '}
                                                    {findModifier.price
                                                        ? '(' + findModifier.pretty_price + ')'
                                                        : null}{' '}
                                                </Card.Meta>
                                            </Table.Cell>
                                            <Table.Cell
                                                style={{
                                                    padding: 1,
                                                    border: 'none',
                                                }}
                                                width={2}
                                                textAlign='right'
                                            >
                                                {findModifier.price
                                                    ? '$' +
                                                    (
                                                        findModifier.price * ticketOrderItems.quantity
                                                    ).toFixed(2)
                                                    : null}
                                            </Table.Cell>
                                        </Table.Row>
                                    ) : null;
                                })
                                : null}
                        </Table.Body>
                    </Table>
                </Table.Cell>
            </Table.Row>
        );
    }

    renderDesktop(params) {
        const {
            items,
            isCurbSide,
            data,
            error_address,
            backgroundColor,
            rest,
        } = params;

        return (
            <Grid stackable>
                <Grid.Column width={10} style={{paddingLeft: 10}}>
                    <Segment style={{marginTop: 0}}>
                        {this.state.successMessage ? (
                            <Message icon positive>
                                <Message.Content>
                                    <Message.Header>
                                        {this.state.successMessage.header.toUpperCase()}
                                    </Message.Header>
                                    <br/>
                                    {this.state.successMessage.message}
                                </Message.Content>
                            </Message>
                        ) : null}
                        <Table basic='very'>
                            <Table.Body>
                                {this.props.ticket.ticket_order &&
                                this.props.ticket.ticket_order.items.length > 0
                                    ? this.props.ticket.ticket_order.items.map(
                                        (ticketOrderItems, index) => {
                                            let omnivoreItems = this.props.ticket.omnivore_ids.items.find(
                                                (omnivoreItem) =>
                                                    omnivoreItem.menu_item_external_id ===
                                                    ticketOrderItems.menu_item_external_id ||
                                                    omnivoreItem.menu_item ===
                                                    ticketOrderItems.menu_item
                                            );
                                            if (omnivoreItems) {
                                                return this.getTicketItemComponent(
                                                    index,
                                                    ticketOrderItems,
                                                    omnivoreItems,
                                                    backgroundColor,
                                                    rest
                                                );
                                            }
                                        }
                                    )
                                    : null}
                            </Table.Body>
                        </Table>
                        {this.props.ticket.ticket_order &&
                        this.props.ticket.ticket_settings ? (
                            <Grid>
                                <Grid.Column floated='right' width={16}>
                                    <Button
                                        as={Link}
                                        to={'/'}
                                        name={'/'}
                                        fluid
                                        circular
                                        size='large'
                                        disabled={
                                            !isEmpty(this.state.error) ||
                                            items.length === 0 ||
                                            !this.isSame()
                                        }
                                        style={getConfiguration().button_color}
                                    >
                                        &nbsp; &nbsp; RETURN HOME
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        ) : null}
                    </Segment>
                </Grid.Column>
                <Grid.Column width={6} style={{paddingLeft: 0, paddingRight: 20}}>
                    {this.props.ticket.ticket_order && (
                        <Segment>
                            <List>
                                <List.Item>
                                    <List.Content
                                        style={{
                                            padding: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Header
                                            as='h4'
                                            style={getConfiguration().label_color}
                                        >
                                            {`Online Order # ${this.props.ticket.ticket_order.name}`}
                                        </Header>
                                    </List.Content>
                                </List.Item>
                                {this.props.ticket.totals.sub_total && this.props.ticket.totals.sub_total !== '$0.00' ? (
                                    <List.Item style={{ paddingBottom: 10 }}>
                                        <List.Content style={{float: 'right'}}>
                                            {this.props.ticket.totals.sub_total}
                                        </List.Content>
                                        <List.Content>Subtotal</List.Content>
                                    </List.Item>
                                ) : null}
                                {this.props.ticket.totals.tax && this.props.ticket.totals.tax !== '$0.00' ? (
                                    <List.Item style={{ paddingBottom: 10 }}>
                                        <List.Content style={{float: 'right'}}>
                                            {this.props.ticket.totals.tax}
                                        </List.Content>
                                        <List.Content>Tax</List.Content>
                                    </List.Item>
                                ) : null}
                                {this.props.ticket.totals.discounts && this.props.ticket.totals.discounts !== '$0.00' ? (
                                    <List.Item style={{ paddingBottom: 10 }}>
                                        <List.Content style={{float: 'right'}}>
                                            -{this.props.ticket.totals.discounts}
                                        </List.Content>
                                        <List.Content>Promo</List.Content>
                                    </List.Item>
                                ) : null}
                                <List.Item style={{ paddingBottom: 10 }}>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.tips}
                                    </List.Content>
                                    <List.Content>Tip</List.Content>
                                </List.Item>
                                <List.Item
                                    style={{
                                        background: '#d3d3d3cc',
                                        padding: '8px 14px',
                                        margin: '0 -14px 10px',
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.total}
                                    </List.Content>
                                    <List.Content>Total Amount</List.Content>
                                </List.Item>
                                {this.props.ticket.ticket_order.payments.map((payment, index) => {
                                    return (
                                        <List.Item key={`torder-${index}`} style={{ paddingBottom: 10 }}>
                                            <List.Content style={{float: 'right'}}>
                                                {payment.display_amount}
                                            </List.Content>
                                            <List.Content style={getConfiguration().label_color}>
                                                {`${payment.display_name} ${payment.tip !== '$0.00' ? '(tip included)' : ''}`}
                                            </List.Content>
                                        </List.Item>
                                    )
                                })}
                                <List.Item
                                    style={{
                                        background: '#d3d3d3cc',
                                        padding: '8px 14px',
                                        margin: '0 -14px -14px',
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.due}
                                    </List.Content>
                                    <List.Content>Amount Due</List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                    )}
                    {this.props.ticket.ticket_order && (
                        <Segment>
                            <List>
                                <List.Item>
                                    <List.Content
                                        style={{
                                            padding: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Header as='h4'>
                                            Order Information
                                        </Header>
                                    </List.Content>
                                </List.Item>
                                <List.Item style={{ paddingBottom: 10 }}>
                                    <List.Content style={{float: 'right'}}>
                                        {`${this.props.ticket.ticket_order.recipient_first_name} ${this.props.ticket.ticket_order.recipient_last_name}`}
                                    </List.Content>
                                    <List.Content>Name</List.Content>
                                </List.Item>
                                <List.Item style={{ paddingBottom: 10 }}>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.ticket_order.name}
                                    </List.Content>
                                    <List.Content>Order Number</List.Content>
                                </List.Item>
                                <List.Item style={{ paddingBottom: 10 }}>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.ticket_order.use_delivery ? 'Delivery' : 'Curbside'}
                                    </List.Content>
                                    <List.Content>Order Type</List.Content>
                                </List.Item>
                                <List.Item style={{ paddingBottom: 10 }}>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.ticket_order.ready_date_display}
                                    </List.Content>
                                    <List.Content>Order Date</List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.ticket_order.ready_time_display}
                                    </List.Content>
                                    <List.Content>{`${this.props.ticket.ticket_order.use_curbside ? 'Ready' : 'Delivery'} Time`}</List.Content>
                                </List.Item>

                            </List>
                            <Divider />
                            <List>
                                <List.Item>
                                    <List.Content
                                        style={{
                                            padding: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Header as='h4'>
                                            Additional Information
                                        </Header>
                                    </List.Content>
                                </List.Item>
                                <List.Item style={{ paddingBottom: 10 }}>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.ticket_order.recipient_comment}
                                    </List.Content>
                                    <List.Content>Comments</List.Content>
                                </List.Item>
                            </List>
                            <Divider />
                            {isCurbSide ? (
                                <List>
                                    <List.Item
                                        style={{
                                            paddingBottom: 10,
                                            fontWeight: 600,
                                        }}
                                    >
                                        <List.Content style={{float: 'right'}}>
                                            <a
                                                onClick={this.editInformation.bind(this)}
                                                style={{color: backgroundColor, cursor: 'pointer' }}
                                            >
                                                {this.state.editMode ? 'CANCEL ' : 'EDIT '}
                                                <Icon name={this.state.editMode ? 'times' : 'pencil'} />
                                            </a>
                                        </List.Content>
                                        <List.Content>Vehicle Information</List.Content>
                                    </List.Item>
                                    {this.state.editMode ? (
                                        <Form>
                                            <Form.Field>
                                                <label>Make</label>
                                                <Dropdown
                                                    autoComplete='new'
                                                    placeholder='Make'
                                                    onChange={this.handleStateChange.bind(
                                                        this,
                                                        'vehicle_make'
                                                    )}
                                                    value={data.vehicle_make}
                                                    search
                                                    selection
                                                    options={vehicleMakes.map(
                                                        (make, index) => {
                                                            return {
                                                                key: index,
                                                                value: make,
                                                                text: make,
                                                            };
                                                        }
                                                    )}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Model</label>
                                                <input
                                                    autoComplete='new'
                                                    type={'text'}
                                                    value={data.vehicle_model}
                                                    placeholder='Model'
                                                    onChange={(e) => this.handleChange(
                                                        'vehicle_model',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Color</label>
                                                <input
                                                    autoComplete='new'
                                                    type={'text'}
                                                    value={data.vehicle_color}
                                                    placeholder='Color'
                                                    onChange={(e) => this.handleChange(
                                                        'vehicle_color',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>License</label>
                                                <input
                                                    autoComplete='new'
                                                    type={'text'}
                                                    value={data.vehicle_license}
                                                    placeholder='License'
                                                    onChange={(e) => this.handleChange(
                                                        'vehicle_license',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>
                                            <Form.Field
                                                error={
                                                    data.recipient_phone !== null
                                                        ? error_address.recipient_phone
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>*Phone</label>
                                                <NumberFormat
                                                    value={data.recipient_phone}
                                                    placeholder='Phone Number'
                                                    inputMode='decimal'
                                                    pattern='[0-9],*'
                                                    format='(###) ###-####'
                                                    mask='_'
                                                    onInvalid={(e) => e.preventDefault()}
                                                    onValueChange={(values) => this.handleChange(
                                                        'recipient_phone',
                                                        values.value
                                                    )}
                                                />
                                            </Form.Field>
                                            <Button
                                                fluid
                                                size='large'
                                                circular
                                                disabled={this.props.isLoading}
                                                onClick={this.updateTicketOrderCurbside.bind(
                                                    this
                                                )}
                                                style={getConfiguration().button_color}
                                            >
                                                &nbsp; &nbsp; SAVE INFORMATION
                                            </Button>
                                        </Form>
                                    ) : (
                                        <>
                                            <List.Item style={{ paddingBottom: 10 }}>
                                                <List.Content style={{float: 'right'}}>
                                                    {data.vehicle_make}
                                                </List.Content>
                                                <List.Content>Make</List.Content>
                                            </List.Item>
                                            <List.Item style={{ paddingBottom: 10 }}>
                                                <List.Content style={{float: 'right'}}>
                                                    {data.vehicle_model}
                                                </List.Content>
                                                <List.Content>Model</List.Content>
                                            </List.Item>
                                            <List.Item style={{ paddingBottom: 10 }}>
                                                <List.Content style={{float: 'right'}}>
                                                    {data.vehicle_color}
                                                </List.Content>
                                                <List.Content>Color</List.Content>
                                            </List.Item>
                                            <List.Item style={{ paddingBottom: 10 }}>
                                                <List.Content style={{float: 'right'}}>
                                                    {data.vehicle_license}
                                                </List.Content>
                                                <List.Content>License</List.Content>
                                            </List.Item>
                                            <List.Item>
                                                <List.Content style={{float: 'right'}}>
                                                    {data.recipient_phone}
                                                </List.Content>
                                                <List.Content>Phone</List.Content>
                                            </List.Item>
                                        </>
                                    )}
                                </List>
                            ) : (
                                <List>
                                    <List.Item
                                        style={{
                                            paddingBottom: 10,
                                            fontWeight: 600,
                                        }}
                                    >
                                        <List.Content style={{float: 'right'}}>
                                            <a
                                                onClick={this.editInformation.bind(this)}
                                                style={{color: backgroundColor, cursor: 'pointer'}}
                                            >
                                                {this.state.editMode ? 'CANCEL ' : 'EDIT '}
                                                <Icon name={this.state.editMode ? 'times' : 'pencil'} />
                                            </a>
                                        </List.Content>
                                        <List.Content>Delivery Information</List.Content>
                                    </List.Item>
                                    {this.state.editMode ? (
                                        <Form>
                                            <Form.Field
                                                error={
                                                    data.recipient_address !== null
                                                        ? error_address.recipient_address
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>*Address</label>
                                                <GooglePlacesAutocomplete
                                                    autoComplete='new'
                                                    debounce={600}
                                                    onSelect={this.handlePlaceSelect}
                                                    initialValue={data.recipient_address}
                                                />
                                            </Form.Field>
                                            <Form.Field >
                                                <label>Address 2</label>
                                                <input
                                                    autoComplete='new'
                                                    type={'text'}
                                                    value={data.recipient_address2}
                                                    placeholder='Address 2'
                                                    onChange={(e) => this.handleChange(
                                                        'recipient_address2',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>
                                            <Form.Field
                                                error={
                                                    data.recipient_city !== null
                                                        ? error_address.recipient_city
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>*City</label>
                                                <input
                                                    autoComplete='new'
                                                    type={'text'}
                                                    value={data.recipient_city}
                                                    placeholder='City'
                                                    onChange={(e) => this.handleChange(
                                                        'recipient_city',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>
                                            <Form.Group widths='equal'>
                                                <Form.Field
                                                    error={
                                                        data.recipient_state !== null
                                                            ? error_address.recipient_state
                                                            ? true
                                                            : false
                                                            : null
                                                    }
                                                >
                                                    <label>*State</label>
                                                    <Dropdown
                                                        autoComplete='new'
                                                        placeholder='State'
                                                        onChange={this.handleStateChange.bind(
                                                            this,
                                                            'recipient_state'
                                                        )}
                                                        value={data.recipient_state}
                                                        search
                                                        selection
                                                        options={states.map(
                                                            (state, index) => {
                                                                return {
                                                                    key: index,
                                                                    value: state.abbreviation,
                                                                    text: state.name,
                                                                };
                                                            }
                                                        )}
                                                    />
                                                </Form.Field>

                                                <Form.Field
                                                    error={
                                                        data.recipient_zip !== null
                                                            ? error_address.recipient_zip
                                                            ? true
                                                            : false
                                                            : null
                                                    }
                                                >
                                                    <label>*Zip Code</label>
                                                    <NumberFormat
                                                        value={data.recipient_zip || ''}
                                                        placeholder='Zip code'
                                                        inputMode='decimal'
                                                        pattern='[0-9],*'
                                                        format='#####'
                                                        onInvalid={(e) => e.preventDefault()}
                                                        onValueChange={(values) =>
                                                            this.handleChange('recipient_zip', values.value)
                                                        }
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Field
                                                error={
                                                    data.recipient_phone !== null
                                                        ? error_address.recipient_phone
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>*Phone</label>
                                                <NumberFormat
                                                    value={data.recipient_phone}
                                                    placeholder='Phone Number'
                                                    inputMode='decimal'
                                                    pattern='[0-9],*'
                                                    format='(###) ###-####'
                                                    mask='_'
                                                    onInvalid={(e) => e.preventDefault()}
                                                    onValueChange={(values) => this.handleChange(
                                                        'recipient_phone',
                                                        values.value
                                                    )}
                                                />
                                            </Form.Field>
                                            <Button
                                                fluid
                                                size='large'
                                                circular
                                                disabled={
                                                    !isEmpty(
                                                        this.state.error_address
                                                    ) || this.props.isLoading
                                                }
                                                onClick={this.updateTicketOrderDelivery.bind(
                                                    this
                                                )}
                                                style={getConfiguration().button_color}
                                            >
                                                &nbsp; &nbsp; SAVE INFORMATION
                                            </Button>
                                        </Form>
                                    ) : (
                                        <>
                                            <List.Item style={{ paddingBottom: 10 }}>
                                                <List.Content style={{float: 'right'}}>
                                                    <div style={{ textAlign: 'right' }}>{data.recipient_address},</div>
                                                    {data.recipient_address2 && <div style={{ textAlign: 'right' }}>{data.recipient_address2},</div>}
                                                    <div style={{ textAlign: 'right' }}>{`${data.recipient_city}, ${data.recipient_state}, ${data.recipient_zip}`}</div>
                                                </List.Content>
                                                <List.Content>Address</List.Content>
                                            </List.Item>
                                            <List.Item>
                                                <List.Content style={{float: 'right'}}>
                                                    {data.recipient_phone}
                                                </List.Content>
                                                <List.Content>Phone</List.Content>
                                            </List.Item>
                                        </>
                                    )}
                                </List>
                            )}
                        </Segment>
                    )}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(params) {
        const {items, isCurbSide, data, error_address} = params;
        return (
            <Card.Group>
                <Card fluid>
                    <Card.Content>
                        {this.state.successMessage ? (
                            <Message icon positive>
                                <Message.Content>
                                    <Message.Header>
                                        {this.state.successMessage.header.toUpperCase()}
                                    </Message.Header>
                                    <br/>
                                    {this.state.successMessage.message}
                                </Message.Content>
                            </Message>
                        ) : null}
                        <List divided>
                            {this.props.ticket.ticket_order &&
                            this.props.ticket.ticket_order.items.length > 0
                                ? this.props.ticket.ticket_order.items.map(
                                    (ticketOrderItems, index) => {
                                        let omnivoreItems = this.props.ticket.omnivore_ids.items.find(
                                            (omnivoreItem) =>
                                                omnivoreItem.menu_item_external_id ===
                                                ticketOrderItems.menu_item_external_id ||
                                                omnivoreItem.menu_item === ticketOrderItems.menu_item
                                        );
                                        if (omnivoreItems) {
                                            return (
                                                <List.Item style={{display: 'flex'}} key={`torder-${index}`}>
                                                    <div>
                                                        <div
                                                            style={{
                                                                width: 60,
                                                                height: 50,
                                                                backgroundImage: 'url("' + omnivoreItems.imageUrl + '")',
                                                                backgroundSize: 'cover',
                                                                backgroundPosition: 'center top',
                                                                borderRadius: 5,
                                                                marginRight: 5,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: 'relative',
                                                                    width: 45,
                                                                    height: 15,
                                                                    top: 0,
                                                                    left: 3,
                                                                    textAlign: 'center',
                                                                    color: 'white',
                                                                    fontSize: 10,
                                                                    borderBottomRightRadius: 5,
                                                                    borderBottomLeftRadius: 5,
                                                                    opacity: 0.7,
                                                                    ...getConfiguration().button_color,
                                                                }}
                                                            >
                                                                {omnivoreItems.pretty_price}
                                                            </div>
                                                        </div>
                                                        <Dropdown
                                                            placeholder='Quantity'
                                                            options={range(1, 11).map(
                                                                (quantity, index) => {
                                                                    return {
                                                                        disabled: ticketOrderItems.menu_external_id
                                                                            ? false
                                                                            : true,
                                                                        key: index,
                                                                        value: quantity,
                                                                        text: quantity,
                                                                    };
                                                                }
                                                            )}
                                                            button
                                                            disabled
                                                            value={ticketOrderItems.quantity}
                                                        />
                                                    </div>
                                                    <div style={{flex: 1, fontSize: 12}}>
                                                        <div>
                                                            <div
                                                                style={{float: 'right', fontWeight: 'boid'}}
                                                            >
                                                                {omnivoreItems.price
                                                                    ? toFormatCurrencyWithCent(
                                                                        omnivoreItems.price *
                                                                        ticketOrderItems.quantity
                                                                    )
                                                                    : ''}
                                                            </div>
                                                            <div style={{fontWeight: 'bold'}}>
                                                                {`${omnivoreItems.name} ${
                                                                    ticketOrderItems.quantity > 1 &&
                                                                    omnivoreItems.price
                                                                        ? '(' + omnivoreItems.pretty_price + ')'
                                                                        : ''
                                                                    }`}
                                                            </div>
                                                        </div>
                                                        {ticketOrderItems.modifiers
                                                            ? ticketOrderItems.modifiers.map(
                                                                (modifier, index) => {
                                                                    let findModifier = this.props.ticket.omnivore_ids.modifiers.find(
                                                                        (omnivoreModifier) =>
                                                                            omnivoreModifier.modifier ===
                                                                            modifier.modifier
                                                                    );
                                                                    return findModifier ? (
                                                                        <div key={index}>
                                                                            <div style={{float: 'right'}}>
                                                                                {findModifier.price
                                                                                    ? `$${(
                                                                                        findModifier.price *
                                                                                        ticketOrderItems.quantity
                                                                                    ).toFixed(2)}`
                                                                                    : ''}
                                                                            </div>
                                                                            <div>
                                                                                {`${findModifier.name} ${
                                                                                    findModifier.price
                                                                                        ? '(' +
                                                                                        findModifier.pretty_price +
                                                                                        ')'
                                                                                        : ''
                                                                                    }`}
                                                                            </div>
                                                                        </div>
                                                                    ) : null;
                                                                }
                                                            )
                                                            : null}
                                                    </div>
                                                </List.Item>
                                            );
                                        }
                                    }
                                )
                                : null}
                        </List>
                    </Card.Content>
                    {this.props.ticket.ticket_order &&
                    this.props.ticket.ticket_settings ? (
                        <Card.Content>
                            <Button
                                as={Link}
                                to={'/'}
                                name={'/'}
                                fluid
                                circular
                                disabled={
                                    !isEmpty(this.state.error) ||
                                    items.length === 0 ||
                                    !this.isSame()
                                }
                                onClick={() => this.props.clearMenuTitle()}
                                style={getConfiguration().button_color}
                            >
                                RETURN HOME
                            </Button>
                        </Card.Content>
                    ) : null}
                </Card>
                <Card fluid>
                    <Card.Content>
                        {this.props.ticket.ticket_order ? (
                            <Card.Meta
                                style={{
                                    ...getConfiguration().label_color,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {`Online Order # ${this.props.ticket.ticket_order.name}`}
                            </Card.Meta>
                        ) : null}
                    </Card.Content>
                    {this.props.ticket.ticket_order ? (
                        <Card.Content extra style={{color: 'black'}}>
                            {this.props.ticket.totals.sub_total &&
                            this.props.ticket.totals.sub_total !== '$0.00' ? (
                                <List.Item>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.sub_total}
                                    </List.Content>
                                    <List.Content>Subtotal</List.Content>
                                </List.Item>
                            ) : null}
                            {this.props.ticket.totals.tax &&
                            this.props.ticket.totals.tax !== '$0.00' ? (
                                <List.Item>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.tax}
                                    </List.Content>
                                    <List.Content>Tax</List.Content>
                                </List.Item>
                            ) : null}
                            {this.props.ticket.totals.discounts &&
                            this.props.ticket.totals.discounts !== '$0.00' ? (
                                <List.Item>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.discounts}
                                    </List.Content>
                                    <List.Content>Promo</List.Content>
                                </List.Item>
                            ) : null}
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.totals.tips}
                                </List.Content>
                                <List.Content>Tip</List.Content>
                            </List.Item>
                            <List.Item
                                style={{
                                    background: '#d3d3d3cc',
                                    padding: '5px 14px',
                                    margin: '0 -14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.totals.total}
                                </List.Content>
                                <List.Content>Total Amount</List.Content>
                            </List.Item>
                            <List divided>
                                {this.props.ticket.ticket_order.payments.map((payment, index) => {
                                    return (
                                        <List.Item key={`torder-${index}`}>
                                            <List.Content style={{float: 'right'}}>
                                                {payment.display_amount}
                                            </List.Content>
                                            <List.Content style={getConfiguration().label_color}>
                                                {`${payment.display_name} ${
                                                    payment.tip !== '$0.00' ? '(tip included)' : ''
                                                    }`}
                                            </List.Content>
                                        </List.Item>
                                    );
                                })}
                            </List>
                            <List.Item
                                style={{
                                    background: '#d3d3d3cc',
                                    padding: '5px 14px',
                                    margin: '0 -14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.totals.due}
                                </List.Content>
                                <List.Content>Amount Due</List.Content>
                            </List.Item>
                        </Card.Content>
                    ) : null}
                </Card>
                <Card fluid>
                    <Card.Content>
                        {this.props.ticket.ticket_order ? (
                            <Card.Meta
                                style={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                Order Information
                            </Card.Meta>
                        ) : null}
                    </Card.Content>
                    {this.props.ticket.ticket_order ? (
                        <Card.Content>
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {`${this.props.ticket.ticket_order.recipient_first_name} ${this.props.ticket.ticket_order.recipient_last_name}`}
                                </List.Content>
                                <List.Content>Name</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.ticket_order.name}
                                </List.Content>
                                <List.Content>Order Number</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.ticket_order.use_delivery
                                        ? 'Delivery'
                                        : 'Curbside'}
                                </List.Content>
                                <List.Content>Order Type</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.ticket_order.ready_date_display}
                                </List.Content>
                                <List.Content>Order Date</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.ticket_order.ready_time_display}
                                </List.Content>
                                <List.Content>Ready Time</List.Content>
                            </List.Item>
                            <Divider/>
                            <List.Item
                                style={{
                                    padding: '5px 14px',
                                    margin: '0 -14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <List.Content>Additional Information</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.ticket_order.recipient_comment}
                                </List.Content>
                                <List.Content>Additional Comments</List.Content>
                            </List.Item>
                            <Divider/>
                            {!isCurbSide ? (
                                <>
                                <List.Item
                                    style={{
                                        padding: '5px 14px',
                                        margin: '0 -14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <List.Content style={{float: 'right'}}>
                                        <Button
                                            icon
                                            circular
                                            compact
                                            size={'mini'}
                                            onClick={this.editInformation.bind(this)}
                                            style={{
                                                ...getConfiguration().button_color,
                                            }}
                                        >
                                            <Icon name={this.state.editMode ? 'times' : 'pencil'}/>
                                        </Button>
                                    </List.Content>
                                    <List.Content>Delivery Information</List.Content>
                                </List.Item>
                                {this.state.editMode ? (
                                    <Form>
                                        <Form.Field
                                            error={
                                                data.recipient_address !== null
                                                    ? error_address.recipient_address
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>Address: *</label>
                                            <GooglePlacesAutocomplete
                                                autoComplete='new'
                                                debounce={600}
                                                onSelect={this.handlePlaceSelect}
                                                initialValue={data.recipient_address}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Address 2: </label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.recipient_address2}
                                                placeholder='Address 2'
                                                onChange={(e) => this.handleChange(
                                                    'recipient_address2',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={
                                                data.recipient_city !== null
                                                    ? error_address.recipient_city
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>City: *</label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.recipient_city}
                                                placeholder='City'
                                                onChange={(e) => this.handleChange(
                                                    'recipient_city',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Group widths='equal'>
                                            <Form.Field
                                                error={
                                                    data.recipient_state !== null
                                                        ? error_address.recipient_state
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>State: *</label>
                                                <Dropdown
                                                    autoComplete='new'
                                                    placeholder='State'
                                                    onChange={this.handleStateChange.bind(
                                                        this,
                                                        'recipient_state'
                                                    )}
                                                    value={data.recipient_state}
                                                    selection
                                                    options={states.map((state, index) => {
                                                        return {
                                                            key: index,
                                                            value: state.abbreviation,
                                                            text: state.name,
                                                        };
                                                    })}
                                                />
                                            </Form.Field>

                                            <Form.Field
                                                error={
                                                    data.recipient_zip !== null
                                                        ? error_address.recipient_zip
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>Zip Code: *</label>
                                                <NumberFormat
                                                    value={data.recipient_zip || ''}
                                                    placeholder='Zip code'
                                                    inputMode='decimal'
                                                    pattern='[0-9],*'
                                                    format='#####'
                                                    onInvalid={(e) => e.preventDefault()}
                                                    onValueChange={(values) =>
                                                        this.handleChange('recipient_zip', values.value)
                                                    }
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Field
                                            error={
                                                data.recipient_phone !== null
                                                    ? error_address.recipient_phone
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>Phone: *</label>
                                            <NumberFormat
                                                value={data.recipient_phone}
                                                placeholder='Phone Number'
                                                inputMode='decimal'
                                                pattern='[0-9],*'
                                                format='(###) ###-####'
                                                mask='_'
                                                onInvalid={(e) => e.preventDefault()}
                                                onValueChange={(values) => this.handleChange(
                                                    'recipient_phone',
                                                    values.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Button
                                            fluid
                                            size='large'
                                            circular
                                            disabled={
                                                !isEmpty(this.state.error_address) ||
                                                this.props.isLoading
                                            }
                                            onClick={this.updateTicketOrderDelivery.bind(this)}
                                            style={getConfiguration().button_color}
                                        >
                                            &nbsp; &nbsp; SAVE INFORMATION
                                        </Button>
                                    </Form>
                                ) : (
                                    <>
                                        <List.Item>
                                            <List.Content style={{float: 'right'}}>
                                                {`${data.recipient_address}, ${data.recipient_address2}, `}
                                            </List.Content>
                                            <List.Content>Address</List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content style={{float: 'right'}}>
                                                {`${data.recipient_city}, ${data.recipient_state}, ${data.recipient_zip}`}
                                            </List.Content>
                                            <List.Content><br/></List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content style={{float: 'right'}}>
                                                {data.recipient_phone}
                                            </List.Content>
                                            <List.Content>Phone Number</List.Content>
                                        </List.Item>
                                        </>
                                )}
                                </>
                            ) : (
                                <>
                                <List.Item
                                    style={{
                                        padding: '5px 14px',
                                        margin: '0 -14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <List.Content style={{float: 'right'}}>
                                        <Button
                                            icon
                                            circular
                                            compact
                                            size={'mini'}
                                            onClick={this.editInformation.bind(this)}
                                            style={{
                                                ...getConfiguration().button_color,
                                            }}
                                        >
                                            <Icon name={this.state.editMode ? 'times' : 'pencil'}/>
                                        </Button>
                                    </List.Content>
                                    <List.Content>Vehicle Information</List.Content>
                                </List.Item>
                                {this.state.editMode ? (
                                    <Form>
                                        <Form.Field>
                                            <label>Make: </label>
                                            <Dropdown
                                                autoComplete='new'
                                                placeholder='Make'
                                                onChange={this.handleStateChange.bind(
                                                    this,
                                                    'vehicle_make'
                                                )}
                                                value={data.vehicle_make}
                                                selection
                                                options={vehicleMakes.map((make, index) => {
                                                    return {
                                                        key: index,
                                                        value: make,
                                                        text: make,
                                                    };
                                                })}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Model: </label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.vehicle_model}
                                                placeholder='Model'
                                                onChange={(e) => this.handleChange(
                                                    'vehicle_model',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Color: </label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.vehicle_color}
                                                placeholder='Color'
                                                onChange={(e) => this.handleChange(
                                                    'vehicle_color',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>License: </label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.vehicle_license}
                                                placeholder='License'
                                                onChange={(e) => this.handleChange(
                                                    'vehicle_license',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={
                                                data.recipient_phone !== null
                                                    ? error_address.recipient_phone
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>*Phone :</label>
                                            <NumberFormat
                                                value={data.recipient_phone}
                                                placeholder='Phone Number'
                                                inputMode='decimal'
                                                pattern='[0-9],*'
                                                format='(###) ###-####'
                                                mask='_'
                                                onInvalid={(e) => e.preventDefault()}
                                                onValueChange={(values) => this.handleChange(
                                                    'recipient_phone',
                                                    values.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Button
                                            fluid
                                            circular
                                            disabled={this.props.isLoading}
                                            onClick={this.updateTicketOrderCurbside.bind(this)}
                                            style={getConfiguration().button_color}
                                        >
                                            SAVE INFORMATION
                                        </Button>
                                    </Form>
                                ) : (
                                    <>
                                    <List.Item>
                                        <List.Content style={{float: 'right'}}>
                                            {data.vehicle_make}
                                        </List.Content>
                                        <List.Content>Make</List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content style={{float: 'right'}}>
                                            {data.vehicle_model}
                                        </List.Content>
                                        <List.Content>Model</List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content style={{float: 'right'}}>
                                            {data.vehicle_color}
                                        </List.Content>
                                        <List.Content>Color</List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content style={{float: 'right'}}>
                                            {data.vehicle_license}
                                        </List.Content>
                                        <List.Content>License</List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content style={{float: 'right'}}>
                                            {data.recipient_phone}
                                        </List.Content>
                                        <List.Content>Phone</List.Content>
                                    </List.Item>
                                    </>
                                )}
                                </>
                            )}
                        </Card.Content>
                    ) : null}
                </Card>
            </Card.Group>
        );
    }

    scrollStep() {
        if(window.document.getElementById('orderItem'))
            window.document.getElementById('orderItem').scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
    }
    render() {
        let items = this.state.items;
        let data = this.state.data;
        let error_address = this.state.error_address;
        let {color} = getConfiguration().label_color;
        let isCurbSide = this.state.isCurbSide;
        let {backgroundColor, ...rest} = getConfiguration().button_color;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: [
                                        '.ui.positive.message {\n' +
                                        '    text-align: center;\n' +
                                        '    background-color: #fcfff5 !important;\n' +
                                        '    color: inherit !important;\n' +
                                        '    box-shadow: 0 0 0 1px ' +
                                        color +
                                        ' inset, 0 0 0 0 transparent !important;\n' +
                                        '}\n' +
                                        '.ui.positive.message .header {\n' +
                                        '    color: ' +
                                        color +
                                        ' !important;\n' +
                                        '}\n' +
                                        '.checkout.ui.segment {\n' +
                                        '   padding:0;\n' +
                                        '}' +
                                        '.checkout i.icon.close:before {\n' +
                                        '   color: ' +
                                        color +
                                        '\n' +
                                        '}',
                                    ].join('\n'),
                                }}
                            />
                            <Dimmer active={this.props.isLoading} inverted>
                                <Loader size='small' inline='centered'>
                                    Loading
                                </Loader>
                            </Dimmer>
                            <Media at='mobile'>
                                {this.renderMobile({
                                    items,
                                    isCurbSide,
                                    data,
                                    error_address,
                                })}
                            </Media>
                            <Media greaterThanOrEqual='tablet'>
                                {this.renderDesktop({
                                    items,
                                    isCurbSide,
                                    data,
                                    error_address,
                                    backgroundColor,
                                    rest,
                                })}
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {users, apps, login, cart, ticket} = state;
        return {users, cart, auth: {...login}, ticket, isLoading: apps.loading};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...appStore.actionCreators,
                ...loginStore.actionCreators,
                ...cartStore.actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
            },
            dispatch
        )
)(Cart);
