import React, { useState } from 'react';
import { Button, Form, Grid, Item } from 'semantic-ui-react';

import { Media } from '../../../AppMedia';
import { getConfiguration } from '../../../store/api/apiClient';

export default (props) => {
    const { cards: { order }, handleEmailInfo, handleGoBack } = props;
    const [firstName, setFirstName] = useState(order.recipient_first_name);
    const [lastName, setLastName] = useState(order.recipient_last_name);
    const [email, setEmail] = useState(order.recipient_email);

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        Please provide the following information for the recipient.
                    </Item.Header>
                </Item.Content>
            </Item>
            <Item>
                <Item.Content>
                    <Grid centered>
                        <Grid.Column mobile={16} computer={7}>
                            <Form.Input required width={16}
                                autoComplete='new'
                                type={'text'}
                                label='Recipient First Name'
                                placeholder='First Name'
                                value={firstName || ''}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={7}>
                            <Form.Input required width={16}
                                autoComplete='new'
                                type={'text'}
                                label='Recipient Last Name'
                                placeholder='Last Name'
                                value={lastName || ''}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={7}>
                            <Form.Input required width={16}
                                autoComplete='new'
                                type={'email'}
                                label='Recipient Email'
                                placeholder='Email address'
                                value={email || ''}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={7}></Grid.Column>
                    </Grid>
                </Item.Content>
            </Item>
            <Media at='mobile'>
                <Form.Button
                    fluid
                    circular
                    disabled={!firstName || !lastName || !email}
                    style={getConfiguration().button_color}
                    onClick={() => handleEmailInfo({firstName, lastName, email})}
                >
                    CONTINUE
                </Form.Button>
                <Form.Button
                    fluid
                    circular
                    onClick={() => handleGoBack()}
                >
                    BACK
                </Form.Button>
            </Media>
            <Media greaterThanOrEqual='tablet'>
                <Item>
                    <Item.Content>
                        <Grid centered>
                            <Grid.Column width={5}>
                                <Button
                                    fluid
                                    circular
                                    onClick={() => handleGoBack()}
                                >
                                    BACK
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Button
                                    fluid
                                    circular
                                    disabled={!firstName || !lastName || !email}
                                    style={getConfiguration().button_color}
                                    onClick={() => handleEmailInfo({firstName, lastName, email})}
                                >
                                    CONTINUE
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Item.Content>
                </Item>
            </Media>
        </Item.Group>
    );
};
