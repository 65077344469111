import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Dimmer, Divider, Grid, Header, Item, Loader, Message, Placeholder, Segment,} from 'semantic-ui-react';
import copy from 'copy-to-clipboard';

import CacheBuster from '../../../CacheBuster';
import ProgramCard from '../../common/ui/components/ProgramCard';
import {actionCreators} from '../../../store/Users';
import * as cardStore from '../../../store/Card';
import {getConfiguration} from '../../../store/api/apiClient';
import {Logger} from '../../../utils/Logger';

class EGiftCardView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            card: null,
            result: null,
            showMessage: true,
            successMessage: null,
            isLoggedIn: props.login.user.authenticated,
        };
    }

    componentDidMount() {
        this.handleInit();
    }

    handleInit = async () => {
        const response = await this.props.fetchEGiftCard(this.props.match.params.cardId);
        this.setState({ loading: false, showMessage: true, card: response.card, successMessage: response.success_message, error: response.error, has_error: response.has_error });
    }

    handleApplyAccount = async () => {
        const { card } = this.state;
        try {
            if (!this.state.isLoggedIn) {
                this.props.history.push({ pathname: '/login', state: { from: this.props.location } });
                return;
            }
            const data = {
                claim_code: card.claim_code,
            };
            this.setState({ loading: true, showMessage: false, result: null });
            const response = await this.props.importCard(data);
            this.setState({ loading: false, showMessage: true, result: response.card, successMessage: response.success_message, error: response.error, has_error: response.has_error });
            this.props.history.push('/mycards/'+response.card.id+'/import')
        } catch (err) {
            this.setState({ loading: false })
            Logger.error('EGiftCardView', 'handleApplyAccount', JSON.stringify(err));
        }
    };

    handleCopyNumber = () => {
        const { card } = this.state;
        try {
            copy(card.number);
            this.setState({
                ...this.state,
                loading: false,
                showMessage: true,
                successMessage: { header: 'Success', message: 'Card number copied to clipboard!' },
            });
        } catch (err) {
            Logger.error('EGiftCardView', 'handleCopyNumber', JSON.stringify(err));
        }
    };

    renderContent = () => {
        const { loading, card, result, showMessage, successMessage, error, has_error } = this.state;

        return (
            <Segment>
                {showMessage && successMessage && result ? (
                    <Message
                        icon
                        success
                        visible={true}
                        header={successMessage.header.toUpperCase()}
                        content={successMessage.message}
                    />
                ) : showMessage && has_error ? (
                    <Message
                        icon
                        error
                        visible={true}
                        header={'Error'}
                        content={error.message || error}
                    />
                ) : null}
                <Dimmer active={loading} inverted>
                    <Loader size='small' inline='centered'>
                        Loading
                    </Loader>
                </Dimmer>
                <Grid centered>
                    <Grid.Column>
                        <Header style={{ width: '100%', textAlign: 'center' }}>
                            {card && successMessage && !result ? (
                                successMessage.message
                            ) : (
                                <Placeholder>
                                    <Placeholder.Line />
                                </Placeholder>
                            )}
                        </Header>
                    </Grid.Column>
                </Grid>
                <Grid centered>
                    <Grid.Column
                        mobile={16}
                        computer={7}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        {card && !has_error && !result ? (
                            <ProgramCard program={card.program || {}} card={card} canvasName={"egift"}/>
                        ) : (
                            <Placeholder>
                                <Placeholder.Image rectangular />
                            </Placeholder>
                        )}
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={8}>
                        {card && !has_error && !result ? (
                            <Item.Group>
                                <Item>
                                    <Item.Content>
                                        <Header as='h4' style={{ width: '100%', marginTop: 10 }}>
                                            <label style={getConfiguration().label_color}>
                                                CARD NUMBER
                                            </label>
                                            <div style={{ float: 'right' }}>
                                                {card ? card.number_display : ''}
                                            </div>
                                        </Header>
                                    </Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>
                                        <Header as='h4' style={{ width: '100%' }}>
                                            <label style={getConfiguration().label_color}>
                                                GIFT AMOUNT
                                            </label>
                                            <div style={{ float: 'right' }}>{card ? card.balance : ''}</div>
                                        </Header>
                                    </Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>
                                        <Header as='h4' style={{ width: '100%' }}>
                                            <label style={getConfiguration().label_color}>
                                                FROM
                                            </label>
                                            <div style={{ float: 'right' }}>{card ? card.from_name : ''}</div>
                                        </Header>
                                    </Item.Content>
                                </Item>
                                {card && card.has_expiration_date && (
                                    <Item>
                                        <Item.Content>
                                            <Header as='h4' style={{ width: '100%' }}>
                                                <label style={getConfiguration().label_color}>
                                                    EXPIRES
                                                </label>
                                                <div style={{ float: 'right' }}>
                                                    {card.pretty_expiration_date}
                                                </div>
                                            </Header>
                                        </Item.Content>
                                    </Item>
                                )}
                                <Item>
                                    <Item.Content>
                                        <Header as='h4' style={{ width: '100%' }}>
                                            <label style={getConfiguration().label_color}>
                                                MESSAGE
                                            </label>
                                            <div style={{ float: 'right' }}>
                                                {card ? card.personal_message : ''}
                                            </div>
                                        </Header>
                                    </Item.Content>
                                </Item>
                            </Item.Group>
                        ) : (
                            <Placeholder>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                        )}
                    </Grid.Column>
                </Grid>
                <Grid centered>
                    <Grid.Column mobile={16} computer={8}>
                        <Button
                            fluid
                            circular
                            style={getConfiguration().button_color}
                            disabled={has_error || (result && showMessage)}
                            onClick={() => this.handleApplyAccount()}
                        >
                            APPLY TO ACCOUNT
                        </Button>
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={8}>
                        <Button
                            fluid
                            circular
                            style={getConfiguration().button_color}
                            disabled={has_error || (result && showMessage)}
                            onClick={() => this.handleCopyNumber()}
                        >
                            COPY CARD NUMBER
                        </Button>
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    };

    renderLinks = () => {
        return (
            <Segment>
                <Item.Group>
                    <Item className='align-center'>
                        <Item.Header>Start Enjoying Your Gift</Item.Header>
                    </Item>
                    <Item>
                        <Item.Content>
                            <Item.Description>
                                {`Redeeming your eGift couldn’t be easier. Here’s how:`}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <Item.Description>
                                Select <strong>APPLY TO ACCOUNT</strong> to apply your gift card
                                directly to your account.
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    <Divider horizontal>Or</Divider>
                    <Item>
                        <Item.Content>
                            <Item.Description>
                                {`Select copy card number and enter the number when you make a purchase. If
                                you would like to use your card at another time bookmark this page to refer back to.`}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
        );
    };

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <Grid padded>
                            <Grid.Column mobile={16} computer={11}>
                                {this.renderContent()}
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={5}>
                                {this.renderLinks()}
                            </Grid.Column>
                        </Grid>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, login, cards } = state;
        return { users, login, cards };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...cardStore.actionCreators,
            },
            dispatch
        )
)(withRouter(EGiftCardView));
