import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    Grid,
    Segment,
    Table,
    Button,
    Form,
    Icon,
    Header,
    Card,
    List,
    Dropdown,
    Loader,
    Dimmer,
    Message,
} from 'semantic-ui-react';
import { isEmpty, range } from 'lodash';

import { Media } from '../../AppMedia';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import * as cartStore from '../../store/Cart';
import {getConfiguration} from '../../store/api/apiClient';
import { toFormatCurrencyWithCent } from '../../utils/NumberHelper';
import { Logger } from '../../utils/Logger';

class OrderCart extends Component {
    state = {
        data: {},
        items: [],
        saving: -1,
        error: {},
        loading: false
    };

    componentWillMount() {
        this.props.getTicketOrder('/order');
    }

    componentDidMount() {
        // this.scrollEnd()
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.scrollStep()
        try {
            if (!nextProps.ticket.ticket_order) {
                this.props.history.replace('/')
            } else {
                const items = nextProps.ticket.ticket_order
                    ? nextProps.ticket.ticket_order.items
                    : [];
                const error = nextProps.ticket.error ? nextProps.ticket.error : null;
                const successMessage = nextProps.ticket.success_message
                    ? nextProps.ticket.success_message
                    : null;
                this.setState({
                    error,
                    successMessage,
                    items: JSON.parse(JSON.stringify(items)),
                });
            }
        } catch (err) {
            Logger.error('OrderCart', 'componentWillReceiveProps', JSON.stringify(err));
        }

    }

    scrollStep() {
        if (window.document.getElementById('orderItem')){
            window.document.getElementById('orderItem').scrollTo({top:0, behavior: "smooth"})
            window.document.getElementById('orderItem').scroll({top:0, behavior: "smooth"})
        }
    }

    scrollEnd() {
        setTimeout(()=>{
            if(window.document.getElementById('orderItem')){
                window.document.getElementById('orderItem').scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"})
            }
        },1000)
    }

    addToCart(menu, index) {
        menu.index = index;
        this.props.setCustomized(menu, () =>
            this.props.history.push(
                '/order/' +
                menu.menu_external_id +
                '/category/' +
                menu.menu_category_external_id +
                '/item/' +
                menu.menu_item_external_id
            )
        );
    }

    handleChangeQuantity(field, index, e) {
        let error = this.state.error;
        try {
            if (e.target.value === '') {
                if (!error[index]) {
                    error[index] = {};
                }
                error[index][field] = {
                    content: 'Please enter a valid ' + field,
                    pointing: 'below',
                };
            } else {
                if (!isEmpty(error[index])) {
                    delete error[index][field];
                    if (isEmpty(error[index])) {
                        delete error[index];
                    }
                }
            }

            let items = this.state.items;
            items = items.map((item) => {
                if (item.index === index) {
                    if (e.target.value === '') {
                        item[field] = '';
                    } else if (!e.target.validity.badInput && Number(e.target.value) > 0) {
                        item[field] = Number(e.target.value);
                    }
                }
                return item;
            });
            this.setState({items, saving: -1, error});
        } catch (err) {
            Logger.error('OrderCart', 'handleChangeQuantity', JSON.stringify(err));
        }
    }

    handleChange(field, e) {
        let data = this.state.data;
        data[field] = e.target.value;
        this.setState({data, saving: -1});
    }

    isSame() {
        let originalItems = this.props.ticket.ticket_order
            ? this.props.ticket.ticket_order.items
            : [];
        return JSON.stringify(originalItems) === JSON.stringify(this.state.items);
    }

    async quantityChange(item, index, e, option) {
        try {
            item.quantity = option.value;
            this.setState({loading: true, showError: false});
            await this.props.updateOrder(item, index);
            this.setState({showError: true, loading: false});
        } catch (err) {
            Logger.error('OrderCart', 'quantityChange', JSON.stringify(err));
        }
    }

    async removeItem(item, index) {
        try {
            this.setState({loading: true, showError: false});
            await this.props.removeOrder(item, index);
            this.setState({showError: true, loading: false});
        } catch (err) {
            Logger.error('OrderCart', 'removeItem', JSON.stringify(err));
        }
    }

    renderDesktop(params) {
        const { ticket_order, omnivore_ids, totals, ticket_settings } = this.props.ticket;
        const { user } = this.props.auth;
        const { showError, successMessage, error } = this.state;
        const {backgroundColor, rest, items} = params;

        return (
            <Grid padded stackable id="orderItem" >
                <Grid.Column width={11} style={{paddingLeft: 10}}>
                    <Segment>
                        {successMessage && showError ? (
                            <Message
                                icon
                                success
                                visible={showError}
                                header={successMessage.header.toUpperCase()}
                                content={successMessage.message}
                            />
                        ) : error && showError ? (
                            <Message
                                icon
                                success
                                visible={showError}
                                header={'Error'}
                                content={error.message}
                            />
                        ) : null}
                        <Table basic='very'>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.Cell colSpan='2'>
                                        <Header as='h3' style={getConfiguration().label_color}>
                                            REVIEW ORDER
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell colSpan='1'>
                                        <Button
                                            floated='right'
                                            circular
                                            as={Link}
                                            to={'/order'}
                                            name={'/order'}
                                            icon
                                            style={getConfiguration().button_color}
                                        >
                                            &nbsp;&nbsp; ADD ITEM &nbsp;&nbsp;
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {ticket_order &&
                                ticket_order.items.length > 0
                                    ? ticket_order.items.map(
                                        (ticketOrderItems, index) => {
                                            let omnivoreItems = omnivore_ids.items.find(
                                                (omnivoreItem) =>
                                                    omnivoreItem.menu_item_external_id ===
                                                    ticketOrderItems.menu_item_external_id ||
                                                    omnivoreItem.menu_item ===
                                                    ticketOrderItems.menu_item
                                            );
                                            if (omnivoreItems) {
                                                return this.getTicketItemComponent(
                                                    index,
                                                    ticketOrderItems,
                                                    omnivoreItems,
                                                    backgroundColor,
                                                    rest
                                                );
                                            } else {
                                                return null;
                                            }
                                        }
                                    )
                                    : null}
                            </Table.Body>
                        </Table>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={5} style={{paddingLeft: 0, paddingRight: 20}}>
                    <Segment>
                        <Form>
                            <Grid
                                verticalAlign='middle'
                                textAlign={'center'}
                                style={{paddingTop: 10}}
                            >
                                <Grid.Row>
                                    <Grid.Column
                                        width={16}
                                        style={{
                                            textAlign: 'left',
                                            paddingRight: 0,
                                            paddingLeft: 0,
                                            marginTop: -14,
                                            marginLeft: -15,
                                        }}
                                    >
                                        <Table unstackable compact basic='very'>
                                            <Table.Header fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell
                                                        style={{
                                                            border: 'none',
                                                            padding: '20px 20px',
                                                            textAlign: 'center',
                                                        }}
                                                        colSpan='2'
                                                    >
                                                        {ticket_order ? (
                                                            <Header
                                                                as='h4'
                                                                style={getConfiguration().label_color}
                                                            >
                                                                Online Order #{' '}
                                                                {ticket_order.name}
                                                            </Header>
                                                        ) : null}
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Footer fullWidth>
                                                {ticket_order ? (
                                                    <>
                                                    {totals.sub_total &&
                                                    totals.sub_total !== '$0.00' ? (
                                                        <Table.Row>
                                                            <Table.Cell
                                                                style={{
                                                                    border: 'none',
                                                                    padding: '0px 20px',
                                                                }}
                                                            >
                                                                <Table compact basic='very'>
                                                                    <Table.Body>
                                                                        <Table.Row>
                                                                            <Table.Cell
                                                                                style={{border: 'none'}}
                                                                            >
                                                                                Subtotal
                                                                            </Table.Cell>
                                                                            <Table.Cell
                                                                                style={{border: 'none'}}
                                                                                textAlign='right'
                                                                            >
                                                                                {totals.sub_total}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    </Table.Body>
                                                                </Table>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ) : null}
                                                    {totals.tax &&
                                                    totals.tax !== '$0.00' ? (
                                                        <Table.Row>
                                                            <Table.Cell
                                                                style={{
                                                                    border: 'none',
                                                                    padding: '0px 20px',
                                                                }}
                                                            >
                                                                <Table compact basic='very'>
                                                                    <Table.Body>
                                                                        <Table.Row>
                                                                            <Table.Cell
                                                                                style={{border: 'none'}}
                                                                            >
                                                                                Tax
                                                                            </Table.Cell>
                                                                            <Table.Cell
                                                                                style={{border: 'none'}}
                                                                                textAlign='right'
                                                                            >
                                                                                {totals.tax}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    </Table.Body>
                                                                </Table>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ) : null}
                                                    <Table.Row>
                                                        <Table.Cell
                                                            style={{
                                                                border: 'none',
                                                                padding: '0px 20px',
                                                                background: '#d3d3d3cc',
                                                            }}
                                                        >
                                                            <Table compact basic='very'>
                                                                <Table.Body>
                                                                    <Table.Row>
                                                                        <Table.Cell
                                                                            style={{
                                                                                border: 'none',
                                                                                fontSize: 16,
                                                                                fontWeight: 500,
                                                                            }}
                                                                        >
                                                                            Amount Due
                                                                        </Table.Cell>
                                                                        <Table.Cell
                                                                            style={{
                                                                                border: 'none',
                                                                                fontSize: 16,
                                                                                fontWeight: 500,
                                                                            }}
                                                                            textAlign='right'
                                                                        >
                                                                            {totals.due}
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    </>
                                                ) : null}
                                            </Table.Footer>
                                        </Table>
                                    </Grid.Column>
                                    <Grid.Column
                                        width={16}
                                        style={{
                                            paddingLeft: 10,
                                            paddingRight: 20,
                                            marginTop: 20,
                                        }}
                                    >
                                        {ticket_order &&
                                        ticket_settings ? (
                                            <Grid doubling columns={2}>
                                                <Grid.Column floated='right' width={16}>
                                                    <Button
                                                        as={Link}
                                                        to={'/ordercheckout'}
                                                        name={'/ordercheckout'}
                                                        fluid
                                                        circular
                                                        disabled={
                                                            this.state.loading ||
                                                            !isEmpty(this.state.error) ||
                                                            items.length === 0 ||
                                                            !this.isSame()
                                                        }
                                                        style={getConfiguration().button_color}
                                                    >
                                                        CONTINUE
                                                    </Button>
                                                </Grid.Column>
                                            </Grid>
                                        ) : null}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                        <br/>
                    </Segment>
                    {user && !user.authenticated && (
                        <Card fluid>
                            <Card.Content extra>
                                <Card.Description>
                                    Become a member to earn points on this order!
                                </Card.Description>
                                <br />
                                <Form>
                                    <Form.Field>
                                        <Button
                                            as={Link}
                                            to={'/login'}
                                            name={'/login'}
                                            fluid
                                            circular
                                            style={getConfiguration().button_color}
                                            onClick={() =>
                                                sessionStorage.setItem('previouspath', this.props.history.location.pathname)}
                                        >
                                            SIGN IN
                                        </Button>
                                    </Form.Field>
                                    <Form.Field>
                                        <Button
                                            as={Link}
                                            to={'/signup'}
                                            name={'/signup'}
                                            fluid
                                            circular
                                            style={getConfiguration().button_color}
                                            onClick={() =>
                                                sessionStorage.setItem('previouspath', this.props.history.location.pathname)}
                                        >
                                            REGISTER
                                        </Button>
                                    </Form.Field>
                                </Form>
                            </Card.Content>
                        </Card>
                    )}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(params) {
        const { ticket_order, omnivore_ids, totals } = this.props.ticket;
        const { user } = this.props.auth;
        const { showError, successMessage, error } = this.state;
        const {backgroundColor, items} = params;

        return (
            <Card.Group id="orderItem" >
                <Card fluid>
                    <Card.Content>
                        {successMessage && showError ? (
                            <Message
                                icon
                                success
                                visible={showError}
                                header={successMessage.header.toUpperCase()}
                                content={successMessage.message}
                            />
                        ) : error && showError ? (
                            <Message
                                icon
                                success
                                visible={showError}
                                header={'Error'}
                                content={error.message}
                            />
                        ) : null}
                        <Button
                            floated='right'
                            circular
                            size='mini'
                            icon
                            as={Link}
                            to={'/order'}
                            name={'/order'}
                            style={getConfiguration().button_color}
                        >
                            <Icon name='plus'/>
                        </Button>
                        <Card.Header
                            style={{...getConfiguration().label_color, marginTop: 4}}
                        >
                            REVIEW ORDER
                        </Card.Header>
                    </Card.Content>
                    {ticket_order && ticket_order.items.length > 0
                        ? ticket_order.items.map(
                            (ticketOrderItems, index) => {
                                let omnivoreItems = omnivore_ids.items.find(
                                    (omnivoreItem) =>
                                        omnivoreItem.menu_item_external_id ===
                                        ticketOrderItems.menu_item_external_id ||
                                        omnivoreItem.menu_item === ticketOrderItems.menu_item
                                );
                                if (omnivoreItems) {
                                    return (
                                        <Card.Content key={`cart_${index}`}>
                                            <Card.Description style={{display: 'flex'}}>
                                                <div>
                                                    <div
                                                        style={{
                                                            width: 60,
                                                            height: 50,
                                                            backgroundImage: 'url("' + omnivoreItems.imageUrl + '")',
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center top',
                                                            borderRadius: 5,
                                                            marginRight: 5,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'relative',
                                                                width: 45,
                                                                height: 18,
                                                                top: 0,
                                                                left: 3,
                                                                fontSize: 10,
                                                                textAlign: 'center',
                                                                color: 'white',
                                                                borderBottomRightRadius: 5,
                                                                borderBottomLeftRadius: 5,
                                                                backgroundColor,
                                                                opacity: 0.7,
                                                            }}
                                                        >
                                                            {omnivoreItems.pretty_price}
                                                        </div>
                                                        <Button
                                                            size='mini'
                                                            icon='edit'
                                                            name={'/ordercart'}
                                                            onClick={this.addToCart.bind(
                                                                this,
                                                                ticketOrderItems,
                                                                index
                                                            )}
                                                            style={{
                                                                position: 'relative',
                                                                ...getConfiguration().label_color,
                                                                margin: 0,
                                                                background: 'none',
                                                                fontSize: 14,
                                                                padding: 5,
                                                                bottom: -7,
                                                                right: -35,
                                                            }}
                                                        />
                                                    </div>
                                                    <Dropdown
                                                        placeholder='Quantity'
                                                        options={range(1, 11).map(
                                                            (quantity, index) => {
                                                                return {
                                                                    disabled: ticketOrderItems.menu_external_id
                                                                        ? false
                                                                        : true,
                                                                    key: index,
                                                                    value: quantity,
                                                                    text: quantity,
                                                                };
                                                            }
                                                        )}
                                                        className='review-quantity'
                                                        button
                                                        disabled={
                                                            ticketOrderItems.menu_external_id ? false : true
                                                        }
                                                        selection
                                                        value={ticketOrderItems.quantity}
                                                        onChange={this.quantityChange.bind(
                                                            this,
                                                            ticketOrderItems,
                                                            index
                                                        )}
                                                    />
                                                </div>
                                                <div style={{flex: 1, fontSize: 12}}>
                                                    <div>
                                                        <div
                                                            style={{float: 'right', fontWeight: 'bold'}}
                                                        >
                                                            {omnivoreItems.price
                                                                ? toFormatCurrencyWithCent(
                                                                    omnivoreItems.price *
                                                                    ticketOrderItems.quantity
                                                                )
                                                                : ''}
                                                            {(!ticketOrderItems.no_delete || ticketOrderItems.no_delete === false) && (
                                                                <Icon
                                                                    name={'minus circle'}
                                                                    onClick={this.removeItem.bind(
                                                                        this,
                                                                        ticketOrderItems,
                                                                        index
                                                                    )}
                                                                    style={{
                                                                        position: 'relative',
                                                                        color: getConfiguration().button_color.backgroundColor,
                                                                        fontSize: 14,
                                                                        paddingLeft: 5
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div style={{fontWeight: 'bold'}}>
                                                            {`${omnivoreItems.name} ${
                                                                ticketOrderItems.quantity > 1 &&
                                                                omnivoreItems.price
                                                                    ? '(' + omnivoreItems.pretty_price + ')'
                                                                    : ''
                                                                }`}
                                                        </div>
                                                    </div>
                                                    {ticketOrderItems.modifiers
                                                        ? ticketOrderItems.modifiers.map(
                                                            (modifier, index) => {
                                                                let findModifier = omnivore_ids.modifiers.find(
                                                                    (omnivoreModifier) =>
                                                                        omnivoreModifier.modifier ===
                                                                        modifier.modifier
                                                                );
                                                                return findModifier ? (
                                                                    <div key={index}>
                                                                        <div style={{float: 'right'}}>
                                                                            {findModifier.price
                                                                                ? `$${(
                                                                                    findModifier.price *
                                                                                    ticketOrderItems.quantity
                                                                                ).toFixed(2)}`
                                                                                : ''}
                                                                        </div>
                                                                        <div>
                                                                            {`${findModifier.name} ${
                                                                                findModifier.price
                                                                                    ? '(' +
                                                                                    findModifier.pretty_price +
                                                                                    ')'
                                                                                    : ''
                                                                                }`}
                                                                        </div>
                                                                    </div>
                                                                ) : null;
                                                            }
                                                        )
                                                        : null}
                                                </div>
                                            </Card.Description>
                                        </Card.Content>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        )
                        : null}
                </Card>
                {ticket_order && (
                    <Card fluid>
                        <Card.Content extra>
                            <Card.Meta
                                style={{
                                    ...getConfiguration().label_color,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {`Online Order # ${ticket_order.name}`}
                            </Card.Meta>
                        </Card.Content>
                        <Card.Content extra style={{color: 'black'}}>
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {totals.sub_total}
                                </List.Content>
                                <List.Content>Subtotal</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content style={{float: 'right'}}>
                                    {totals.tax}
                                </List.Content>
                                <List.Content>Tax</List.Content>
                            </List.Item>
                            <List.Item
                                style={{
                                    background: '#d3d3d3cc',
                                    padding: '5px 14px',
                                    margin: '0 -14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <List.Content style={{float: 'right'}}>
                                    {totals.due}
                                </List.Content>
                                <List.Content>Amount Due</List.Content>
                            </List.Item>
                        </Card.Content>
                        <Card.Content>
                            <Button
                                as={Link}
                                to={'/ordercheckout'}
                                name={'/ordercheckout'}
                                fluid
                                circular
                                disabled={
                                    !isEmpty(this.state.error) ||
                                    items.length === 0 ||
                                    !this.isSame()
                                }
                                style={getConfiguration().button_color}
                            >
                                CONTINUE
                            </Button>
                        </Card.Content>
                    </Card>
                )}
                {user && !user.authenticated && (
                    <Card fluid>
                        <Card.Content extra>
                            <Card.Meta>
                                Become a member to earn points on this order!
                            </Card.Meta>
                        </Card.Content>
                        <Card.Content>
                            <Button
                                as={Link}
                                to={'/login'}
                                name={'/login'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                                onClick={() =>
                                    sessionStorage.setItem('previouspath', this.props.history.location.pathname)}
                            >
                                SIGN IN
                            </Button>
                        </Card.Content>
                        <Card.Content>
                            <Button
                                as={Link}
                                to={'/signup'}
                                name={'/signup'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                                onClick={() =>
                                    sessionStorage.setItem('previouspath', this.props.history.location.pathname)}
                            >
                                REGISTER
                            </Button>
                        </Card.Content>
                    </Card>
                )}
            </Card.Group>
        );
    }

    render() {
        const { items } = this.state;
        let {backgroundColor, ...rest} = getConfiguration().button_color;
        let {color} = getConfiguration().label_color;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: [
                                        '.ui.button .dropdown {\n' +
                                        '    background: lightgrey !important;\n' +
                                        '    right: .75em !important;\n' +
                                        '}' +
                                        '.ui.positive.message {\n' +
                                        '    background-color: #fcfff5 !important;\n' +
                                        '    color: inherit;\n' +
                                        '    box-shadow: 0 0 0 1px ' +
                                        color +
                                        ' inset, 0 0 0 0 transparent !important;\n' +
                                        '}\n' +
                                        '.ui.positive.message .header {\n' +
                                        '    color: ' +
                                        color +
                                        ' !important;\n' +
                                        '}\n' +
                                        '.checkout.ui.segment {\n' +
                                        '   padding:0;\n' +
                                        '}' +
                                        '.checkout i.icon.close:before {\n' +
                                        '   color: ' +
                                        color +
                                        '\n' +
                                        '}' +
                                        '.ui.selection .dropdown {\n' +
                                        '    background: lightgrey !important;\n' +
                                        '    right: .75em !important;\n' +
                                        '}',
                                    ].join('\n'),
                                }}
                            />
                            <Dimmer active={this.state.loading} inverted>
                                <Loader size='small' inline='centered'>
                                    Loading
                                </Loader>
                            </Dimmer>
                            <Media at='mobile'>
                                {this.renderMobile({backgroundColor, rest, items})}
                            </Media>
                            <Media greaterThanOrEqual='tablet'>
                                {this.renderDesktop({backgroundColor, rest, items})}
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }

    getTicketItemComponent(index,
                           ticketOrderItems,
                           omnivoreItems,
                           backgroundColor,
                           rest) {
        return (
            <Table.Row key={index}>
                <Table.Cell style={{width: 50, verticalAlign: 'top', paddingTop: 23}}>
                    <strong style={getConfiguration().label_color}>QUANTITY</strong>
                    <br/>
                    {
                        <Dropdown
                            placeholder='Quantity'
                            options={range(1, 11).map(
                                (quantity, index) => {
                                    return {
                                        disabled: ticketOrderItems.menu_external_id ? false : true,
                                        key: index,
                                        value: quantity,
                                        text: quantity,
                                    };
                                }
                            )}
                            button
                            disabled={ticketOrderItems.menu_external_id ? false : true}
                            fluid
                            search
                            selection
                            value={ticketOrderItems.quantity}
                            onChange={this.quantityChange.bind(this, ticketOrderItems, index)}
                        />
                    }
                </Table.Cell>
                <Table.Cell style={{textAlign: 'center', verticalAlign: 'top'}}>
                    {omnivoreItems.imageUrl ? (
                        <div
                            style={{
                                width: 180,
                                height: 150,
                                backgroundImage: 'url("' + omnivoreItems.imageUrl + '")',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center top',
                                borderRadius: 5,
                                margin: '15px auto 10px auto',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    width: 60,
                                    height: 32,
                                    top: 0,
                                    left: 10,
                                    textAlign: 'center',
                                    color: 'white',
                                    borderBottomRightRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    padding: 8,
                                    backgroundColor,
                                    fontSize: 12,
                                }}
                            >
                                <span style={rest}>{omnivoreItems.pretty_price}</span>
                            </div>
                        </div>
                    ) : null}
                    {omnivoreItems.imageUrl ? (
                        <Button
                            name={'/ordercart'}
                            fluid
                            circular
                            onClick={this.addToCart.bind(this, ticketOrderItems, index)}
                            style={getConfiguration().button_color}
                        >
                            CUSTOMIZE
                        </Button>
                    ) : null}
                </Table.Cell>
                <Table.Cell style={{verticalAlign: 'top'}}>
                    <Table compact basic='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Card.Header
                                        style={{
                                            lineHeight: '30px',
                                            fontWeight: 600,
                                            fontSize: 14,
                                            textShadow: '1px 1px 5px white',
                                        }}
                                    >
                                        {omnivoreItems.name}{' '}
                                        {ticketOrderItems.quantity > 1 && omnivoreItems.price
                                            ? '(' + omnivoreItems.pretty_price + ')'
                                            : null}{' '}
                                    </Card.Header>
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='right'
                                    style={{
                                        padding: 1,
                                        border: 'none',
                                        fontWeight: 600,
                                    }}
                                >
                                    {omnivoreItems.price
                                        ? toFormatCurrencyWithCent(
                                            omnivoreItems.price * ticketOrderItems.quantity
                                        )
                                        : null}
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    {(!ticketOrderItems.no_delete || ticketOrderItems.no_delete === false) && (
                                        <Button
                                            icon
                                            circular
                                            size={'mini'}
                                            inverted
                                            onClick={this.removeItem.bind(
                                                this,
                                                ticketOrderItems,
                                                index
                                            )}
                                            style={{
                                                color: getConfiguration().button_color.backgroundColor,
                                                background: 'white',
                                                border:
                                                '2px solid ' +
                                                getConfiguration().button_color.backgroundColor,
                                                height: 20,
                                                width: 20,
                                            }}
                                        >
                                            <Icon
                                                name={'minus'}
                                                style={{position: 'relative', top: -6, left: -5}}
                                            />
                                        </Button>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                            {ticketOrderItems.modifiers
                                ? ticketOrderItems.modifiers.map((modifier, index) => {
                                    let findModifier = this.props.ticket.omnivore_ids.modifiers.find(
                                        (omnivoreModifier) =>
                                            omnivoreModifier.modifier === modifier.modifier
                                    );
                                    return findModifier ? (
                                        <Table.Row key={index} style={{padding: 0}}>
                                            <Table.Cell
                                                style={{
                                                    padding: 1,
                                                    border: 'none',
                                                }}
                                            >
                                                <Card.Meta style={{color: 'darkgrey'}}>
                                                    {findModifier.name}{' '}
                                                    {findModifier.price
                                                        ? '(' + findModifier.pretty_price + ')'
                                                        : null}{' '}
                                                </Card.Meta>
                                            </Table.Cell>
                                            <Table.Cell
                                                style={{
                                                    padding: 1,
                                                    border: 'none',
                                                }}
                                                textAlign='right'
                                            >
                                                {findModifier.price
                                                    ? '$' +
                                                    (
                                                        findModifier.price * ticketOrderItems.quantity
                                                    ).toFixed(2)
                                                    : null}
                                            </Table.Cell>
                                            <Table.Cell
                                                style={{
                                                    padding: 1,
                                                    border: 'none',
                                                }}
                                                textAlign='right'
                                            >
                                                &nbsp;
                                            </Table.Cell>
                                        </Table.Row>
                                    ) : null;
                                })
                                : null}
                        </Table.Body>
                    </Table>
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default connect(
    (state) => {
        const {users, login, cart, ticket} = state;
        return {users, cart, auth: {...login}, ticket};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...loginStore.actionCreators,
                ...cartStore.actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
            },
            dispatch
        )
)(OrderCart);
