import React from 'react';
import {Card, Image} from 'semantic-ui-react';
import {getConfiguration} from '../../../../store/api/apiClient';
import {toFormatCurrencyWithCent} from '../../../../utils/NumberHelper';
import bwipjs from 'bwip-js';

const Style = {
    logo: {
        height: 70,
        margin: 5,
        width: "auto"
    },
    cardNumber: {
        color: 'white',
        fontSize: 15,
    },
    balance: {
        color: 'white',
    },
};

class GiftCard extends React.Component {
    componentDidMount() {
        try {
            // The return value is the canvas element
            let canvas = bwipjs.toCanvas(this.props.canvasName, {
                bcid: 'code128',       // Barcode type
                text: this.props.card.number,    // Text to encode
                scaleY: 2,               // 3x scaling factor
                scaleX: 1,
                height: 10,
                width: 90, // Bar height, in millimeters
                includetext: true,            // Show human-readable text
                textxalign: 'center',        // Always good to set this
                backgroundcolor: 'ffffff',
                paddingwidth: 5,
                paddingheight: 3
            });
        } catch (e) {
            // `e` may be a string or Error object
        }
    }

    render() {
        const {card, handleClick} = this.props;

        return (
            <Card
                style={getConfiguration().button_color}
                onClick={() => handleClick && handleClick(card.id)}
            >
                <Card.Content textAlign='center' style={{
                    minHeight: this.props.showBarcode ? 200: 163,
                    maxHeight: this.props.showBarcode ? 200: 163,
                }}>
                    {card.program.image? <Image
                        src={card.program.image.imageSignedUrl || ''}
                        ui={false}
                        style={Style.logo}
                    />: null}
                    <br/>
                    {this.props.showBarcode ? <canvas style={{width: 180}} id={this.props.canvasName}></canvas> : null}
                    <Card.Header as='h4' style={Style.cardNumber}>
                        {toFormatCurrencyWithCent(card.balance)}{card.card_is_expired && ' (Expired)'}
                    </Card.Header>
                </Card.Content>
            </Card>
        );
    }
}

export default GiftCard;

