import loginApi from './api/login'
import { Logger } from '../utils/Logger';

const LOGIN = 'LOGIN';
const CLEAR_LOGIN = 'CLEAR_LOGIN'
const REFRESH_TOKEN = 'REFRESH_TOKEN';

let interval = {}
const initialState = {
    user: {
        authenticated: sessionStorage.getItem('token') ? true : false,
        email: sessionStorage.getItem('email'),
        auth: {}
    },
};

export const actionCreators = {
    clearLogin: () => async (dispatch, getState) => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refresh_token');
        sessionStorage.removeItem('expires');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('custom_amount');
        sessionStorage.removeItem('custom_amount_value');
        dispatch({
            type: CLEAR_LOGIN,
        });
    },
    login: user => async (dispatch, getState) => {
        try {
            let response = await loginApi.auth(user.email, user.password)
            let auth = response.data
            sessionStorage.setItem('refresh_token', auth.refresh_token);
            sessionStorage.setItem('token', auth.token);
            sessionStorage.setItem('expires', auth.expires);
            sessionStorage.setItem('email', user.email);
            Logger.info('LoginStore', 'login', `User logged in with email: ${user.email}`)
            dispatch({
                type: LOGIN,
                payload: Object.assign({}, user, {auth, authenticated: auth.token ? true : false})
            });
        } catch (e) {
            Logger.error('LoginStore', 'login', JSON.stringify(e))
            return e.response
        }
    },
    loginFacebook: user => async (dispatch, getState) => {
        try {
            let response = await loginApi.authFacebook(user.accessToken)
            let auth = response.data
            sessionStorage.setItem('refresh_token', auth.refresh_token);
            sessionStorage.setItem('token', auth.token);
            sessionStorage.setItem('expires', auth.expires);
            sessionStorage.setItem('email', user.email);
            Logger.info('LoginStore', 'loginFacebook', `User logged in with facebook email: ${user.email}`)
            dispatch({
                type: LOGIN,
                payload: Object.assign({}, user, {auth, authenticated: auth.token ? true : false})
            });
        } catch (e) {
            Logger.error('LoginStore', 'loginFacebook', JSON.stringify(e))
            return e.response
        }
    },
    loginGoogle: user => async (dispatch, getState) => {
        try {
            let response = await loginApi.authGoogle(user.tokenId, user.accessToken)
            let auth = response.data
            sessionStorage.setItem('refresh_token', auth.refresh_token);
            sessionStorage.setItem('token', auth.token);
            sessionStorage.setItem('expires', auth.expires);
            sessionStorage.setItem('email', user.profileObj.email);
            sessionStorage.setItem('info', JSON.stringify(user.profileObj));
            Logger.info('LoginStore', 'loginGoogle', `User logged in with Google mail: ${user.profileObj.email}`)

            dispatch({
                type: LOGIN,
                payload: Object.assign({}, user.profileObj, {auth, authenticated: auth.token ? true : false})
            });
        } catch (e) {
            Logger.error('LoginStore', 'loginGoogle', JSON.stringify(e))
            return e.response
        }
    },
    sendPasswordReset: user => async (dispatch, getState) => {
        try {
            return await loginApi.sendPasswordReset(user.email)
        } catch (e) {
            Logger.error('LoginStore', 'sendPasswordReset', JSON.stringify(e))
            return e.response
        }
    },
    resetPassword: user => async (dispatch, getState) => {
        try {
            return await loginApi.resetPassword(user.newPassword, user.newPasswordConfirmation, user.token)
        } catch (e) {
            Logger.error('LoginStore', 'resetPassword', JSON.stringify(e))
            return e.response
        }
    },
    createAccount: user => async (dispatch, getState) => {
        try {
            return await loginApi.createAccount(user)
        } catch (e) {
            Logger.error('LoginStore', 'createAccount', JSON.stringify(e))
            return e.response
        }
    },

    updatePassword: user => async (dispatch, getState) => {
        try {
            return await loginApi.updatePassword(user.currentPassword, user.newPassword, user.newPasswordConfirmation)
        } catch (e) {
            return e.response
        }
    },
    inviteAdministrator: user => async (dispatch, getState) => {
        try {
            return await loginApi.inviteAdministrator(user.email, user.firstName, user.lastName, user.role)
        } catch (e) {
            Logger.error('LoginStore', 'inviteAdministrator', JSON.stringify(e))
            return e.response
        }
    },
    unlockAccount: user => async (dispatch, getState) => {
        try {
            return await loginApi.unlockAccount(user.token)
        } catch (e) {
            return e.response
        }
    },
    resendUnlock: user => async (dispatch, getState) => {
        try {
            return await loginApi.resendUnlock(user.email)
        } catch (e) {
            Logger.error('LoginStore', 'resendUnlock', JSON.stringify(e))
            return e.response
        }
    },
    refreshToken: () => async (dispatch, getState) => {
        try {
            const response = await loginApi.refresh();
            console.log('Refresh token: ', response);
            Logger.info('LoginStore', 'refreshToken', JSON.stringify(response));
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('expires', response.data.expires);
            sessionStorage.setItem('refresh_token', response.data.refresh_token);
            dispatch({
                type: REFRESH_TOKEN,
                payload: response.data
            });
        } catch (e) {
            console.log('Refresh token error: ', e);
            Logger.error('LoginStore', 'refreshToken', JSON.stringify(e))
            return e.response
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === LOGIN) {
        return {...state, user: action.payload};
    }

    if (action.type === CLEAR_LOGIN) {
        return {user: {}};
    }

    if (action.type === REFRESH_TOKEN) {
        return {...state, user: {...state.user, auth: action.payload}};
    }

    return state;
};
