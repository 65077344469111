import cartApi from './api/cart'
import {handleError} from './api/errorHandler'

const ADD_CARD_TO_CART = "ADD_CARD_TO_CART";
const UPDATE_CART = "UPDATE_CART";
const CLEAR_CART = "CLEAR_CART";
const GENERATE_EPHEMERAL_KEY = "GENERATE_EPHEMERAL_KEY"

const initialState = {};
export const actionCreators = {
    addGiftItCardToCart: data => async (dispatch, getState) => {
        let callback = async function () {
            let items = getState().cart.order ? getState().cart.order.items : []
            data.index = items.length
            if (getState().cart.order) {
                let order = JSON.parse(JSON.stringify(getState().cart.order))
                items = order.items
                items.push(JSON.parse(JSON.stringify(data)))
                let response = await cartApi.updateCart(order.id, order)
                dispatch({
                    type: UPDATE_CART,
                    payload: response.data
                });
            } else {
                items.push(JSON.parse(JSON.stringify(data)))
                let response = await cartApi.addToCartStart(items)
                sessionStorage.setItem('cart', response.data.order.id)
                dispatch({
                    type: UPDATE_CART,
                    payload: response.data
                });
            }
        };

        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    addToCartStart: () => async (dispatch, getState) => {
        let callback = async function () {
            let items = getState().cart.items
            if (items.length > 0) {
                let response = await cartApi.addToCartStart(items)
                sessionStorage.setItem('cart', response.data.order.id)
                dispatch({
                    type: UPDATE_CART,
                    payload: response.data
                });
            }
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getCartInformation: id => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cartApi.getCartInformation(id || sessionStorage.getItem('cart'))
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    updateCartAddress: data => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            for (const key in data) {
                order[key] = data[key]
            }
            let response = await cartApi.updateCart(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    updateCartPersonalMessage: data => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            order.personal_message = data.personal_message
            let response = await cartApi.updateCart(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    applyCoupon: data => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            order.promo_code = data.promo_code
            let response = await cartApi.applyCoupon(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    updateCartItems: items => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            order.items = items
            let response = await cartApi.updateCart(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    updateCartBuyer: (data) => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            if (sessionStorage.getItem('email')) {
                order.recipient_email = sessionStorage.getItem('email')
            } else {
                order.buyer_email = data.buyer_email
                order.buyer_first_name = data.buyer_first_name
                order.buyer_last_name = data.buyer_last_name
            }
            let response = await cartApi.updateCart(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    checkout: (data, token, source) => async (dispatch, getState) => {
        let callback = async function () {
            //IMPORTANT!!!!!
            /*
            Check to see if user is logged in.   If we have a user then we use payment source.   Otherwise we use payment token.   Otherwise you get errors
            with the Payment API server because payment source is assigned to a user in stripe.  All explained in the stripe documentation!
            If you don't do this then you get an error like this in production:
            "The reusable source you provided is consumed because it was previously charged without being attached to a customer or was detached
            from a customer. To charge a reusable source multiple time you must attach it to a customer first."
             */

            let isLoggedIn = !!sessionStorage.getItem('token');


            let order = getState().cart.order
            order.promo_code = data.promo_code
            order.recipient_first_name = data.recipient_first_name
            order.recipient_last_name = data.recipient_last_name
            order.recipient_email = data.recipient_email
            order.recipient_phone = data.recipient_phone
            order.recipient_address = data.recipient_address
            order.recipient_address2 = data.recipient_address2
            order.recipient_city = data.recipient_city
            order.recipient_state = data.recipient_state
            order.recipient_zip = data.recipient_zip
            order.status = 'Submit'
            if (isLoggedIn) {
                order.payment_source = source.id
            } else {
                order.payment_token = token.id
            }

            let response = await cartApi.checkout(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
            setTimeout(() => {
                dispatch({
                    type: CLEAR_CART,
                    payload: {}
                });
            }, 2000)
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    generateEphemeralKey: (email) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cartApi.generateEphemeralKey(email)
            dispatch({
                type: GENERATE_EPHEMERAL_KEY,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === ADD_CARD_TO_CART) {
        return {...state, items: action.payload};
    }
    if (action.type === UPDATE_CART) {
        return {...state, ...action.payload};
    }
    if (action.type === CLEAR_CART) {
        return {};
    }
    if (action.type === GENERATE_EPHEMERAL_KEY) {
        return {...state, ephemeral_key: action.payload};
    }
    return state;
};
