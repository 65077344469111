import menusApi from './api/menus'
import {handleError} from './api/errorHandler'

const GET_ALL_MENUS = "GET_ALL_MENUS";
const GET_ALL_MENU_CATEGORIES = "GET_ALL_MENU_CATEGORIES";
const CLEAR_ALL_MENU_CATEGORIES = "CLEAR_ALL_MENU_CATEGORIES";
const GET_ALL_MENU_CATEGORY_ITEMS = "GET_ALL_MENU_CATEGORY_ITEMS"
const CLEAR_ALL_MENU_CATEGORY_ITEMS = "CLEAR_ALL_MENU_CATEGORY_ITEMS"
const GET_ALL_MENU_CATEGORY_ITEM = "GET_ALL_MENU_CATEGORY_ITEM"
const CLEAR_ALL_MENU_CATEGORY_ITEM = "CLEAR_ALL_MENU_CATEGORY_ITEM"
const PUSH_MENU_TITLE = 'PUSH_MENU_TITLE';
const POP_MENU_TITLE = 'POP_MENU_TITLE';
const CLEAR_MENU_TITLE = 'CLEAR_MENU_TITLE';

const initialState = {
    data: {},
    categories: null,
    items: null,
    title: [],
};

export const actionCreators = {
    getAllMenusCategoriesItems: (read_only) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await menusApi.locationMenus(read_only)
            let data = response.data

            const menuId = data.menus[0].external_id
            let responseCategories = await menusApi.locationMenuCategories(read_only, menuId)
            let dataCategories = responseCategories.data

            const categoryId = dataCategories.categories[0].external_id
            let responseItems = await menusApi.locationMenuCategoryItems(read_only, menuId, categoryId)
            let dataItems = responseItems.data

            dispatch({
                type: GET_ALL_MENUS,
                payload: data
            });

            dispatch({
                type: GET_ALL_MENU_CATEGORIES,
                payload: dataCategories
            });


            dispatch({
                type: GET_ALL_MENU_CATEGORY_ITEMS,
                payload: dataItems
            });
        };
        try {
            await callback();
        } catch (e) {
            dispatch({
                type: GET_ALL_MENUS,
                payload: null
            });

            dispatch({
                type: GET_ALL_MENU_CATEGORIES,
                payload: null
            });


            dispatch({
                type: GET_ALL_MENU_CATEGORY_ITEMS,
                payload: null
            });
            return handleError(e.response, callback)
        }
    },
    getMenus: (read_only, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await menusApi.locationMenus(read_only, filter)
            let data = response.data

            dispatch({
                type: GET_ALL_MENUS,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMenuCategories: (read_only, menuId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await menusApi.locationMenuCategories(read_only, menuId, filter)
            let data = response.data

            if(data.categories.length < 1){
                 dispatch({
                    type: CLEAR_ALL_MENU_CATEGORY_ITEMS,
                    payload: null
                });
            }else{
                const categoryId = data.categories[0].external_id
                let responseItems = await menusApi.locationMenuCategoryItems(read_only, menuId, categoryId)
                let dataItems = responseItems.data
                dispatch({
                    type: GET_ALL_MENU_CATEGORY_ITEMS,
                    payload: dataItems
                });
            }
            dispatch({
                type: GET_ALL_MENU_CATEGORIES,
                payload: data
            });
            return data
        };
        try {
            return await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMenuCategoryItems: (read_only, menuId, categoryId, filter, page) => async (dispatch, getState) => {
        let callback = async function () {
            if (page) filter.page = page
            let response = await menusApi.locationMenuCategoryItems(read_only, menuId, categoryId, filter)
            let data = response.data

            dispatch({
                type: GET_ALL_MENU_CATEGORY_ITEMS,
                payload: data
            });
        };
        try {
            // dispatch({
            //     type: CLEAR_ALL_MENU_CATEGORY_ITEMS,
            //     payload: null
            // });
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    getMenuCategoryItem: (menuId, categoryId, itemId) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await menusApi.locationMenuCategoryItem(menuId, categoryId, itemId)
            let data = response.data

            dispatch({
                type: GET_ALL_MENU_CATEGORY_ITEM,
                payload: data.item
            });
        };
        try {
            dispatch({
                type: CLEAR_ALL_MENU_CATEGORY_ITEM,
                payload: null
            });
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    pushMenuTitle: (title) => async (dispatch, getState) => {
        let callback = async function () {
            dispatch({
                type: PUSH_MENU_TITLE,
                payload: title,
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback);
        }
    },
    popMenuTitle: (title) => async (dispatch, getState) => {
        let callback = async function () {
            dispatch({
                type: POP_MENU_TITLE,
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback);
        }
    },
    clearMenuTitle: (title) => async (dispatch, getState) => {
        let callback = async function () {
            dispatch({
                type: CLEAR_MENU_TITLE,
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback);
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_ALL_MENUS:
            return {...state, data: action.payload};
        case GET_ALL_MENU_CATEGORIES:
            return {...state, categories: action.payload};
        case CLEAR_ALL_MENU_CATEGORIES:
            return {...state, categories: null};
        case GET_ALL_MENU_CATEGORY_ITEMS:
            return {...state, items: action.payload};
        case CLEAR_ALL_MENU_CATEGORY_ITEMS:
            return {...state, items: null};
        case GET_ALL_MENU_CATEGORY_ITEM:
            return {...state, item: action.payload};
        case CLEAR_ALL_MENU_CATEGORY_ITEM:
            return {...state, item: null};
        case PUSH_MENU_TITLE: {
            const newTitle = state.title;
            newTitle.push(action.payload);
            return {...state, title: newTitle};
        }
        case POP_MENU_TITLE: {
            const newTitle = state.title;
            newTitle.pop();
            return {...state, title: newTitle};
        }
        case CLEAR_MENU_TITLE:
            return {...state, title: []};
        default:
            return state;
    }
};
