import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    Button,
    Card,
    Dimmer,
    Dropdown,
    Form,
    Grid,
    Header,
    Icon,
    Image,
    Label,
    Loader,
    Menu,
    Message,
    Modal,
    Placeholder,
    Segment
} from 'semantic-ui-react';

import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as menuStore from '../../store/Menus';
import * as ticketStore from '../../store/Ticket';
import * as locationStore from '../../store/Location';
import 'react-opentable/dist/index.css'
import {Carousel} from "react-responsive-carousel";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps"
import {getConfiguration} from "../../store/api/apiClient";
import {Media} from '../../AppMedia';
import axios from 'axios'
import Recaptcha from "react-recaptcha";
import Config from "../../config";
import {range} from "lodash";
import PrintMenuComponent from "./more/PrintMenuComponent";
import PrintNonPOSMenuComponent from "./more/PrintNonPOSMenuComponent";

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={15}
        defaultCenter={props.defaultCenter}
        center={props.defaultCenter}
        options={{gestureHandling: "cooperative"}}
    >
        <Marker position={props.defaultCenter}/>
    </GoogleMap>
))

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            width: window.innerWidth,
            loading: false,
            open: {},
            showAds: true,
            coordinates: {lat: 0, lng: 0}
        };
    }

    async componentWillMount() {
        await this.props.getAllMenusCategoriesItems(true);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.geocodingQuery(nextProps.locations.location.address1 + " " + nextProps.locations.location.zip + " " + nextProps.locations.location.state, nextProps.locations.location.city)
    }

    async geocodingQuery(address, city) {
        const geocoderQuery = encodeURIComponent(`${address} ${city}`.replace(/ /g, '+'))
        await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${geocoderQuery}&key=${Config.googleApiKey}`)
            .then(res => res.data)
            .then(json => {
                if (json.results.length === 0) {
                    return null
                }
                let lat = json.results['0'].geometry.location.lat
                let lng = json.results['0'].geometry.location.lng

                this.setState({coordinates: {lat, lng}})
            })
    }

    async componentDidMount() {
        this.handleInit();
    }


    handleInit = async () => {
        const {auth} = this.props;
        if (auth.user.email && auth.user.authenticated) {
            await this.props.getUser();
        }
        await this.props.getTicketOrder();
    }

    goToItems(item, index) {
        this.setState({openGallery: true, item, carouselIndex: index})

    }

    goToNonPosItems(menu) {
        this.setState({
            nonPos: menu,
        });
    }

    async goToCategoryItems(menu) {
        this.setState({
            categoryId: menu.external_id,
        });
        this.setState({loadingItems: true})
        await this.props.getMenuCategoryItems(true, menu.external_menu_id, menu.external_id);
        this.setState({loadingItems: false})

    }

    async goToCategory(menu) {
        this.setState({
            menuId: menu.external_id
        })
        this.setState({loadingItems: true})
        const categories = await this.props.getMenuCategories(true, menu.external_id)
        this.setState({
            loadingItems: false,
            categoryId: categories && categories.categories && categories.categories[0] ? categories.categories[0].external_id : null
        })
    }

    setOpenGallery(openGallery) {
        this.setState({openGallery})
    }

    setOpen(index, modal) {
        let open = {}
        open[index] = modal
        this.setState({open})
    }

    async contactUs() {
        this.setState({loading: true})
        const contactUsResponse = await this.props.contactUs(this.state.data)
        this.setState({contactUsResponse, loading: false})
    }

    handleChange(field, e) {
        let data = this.state.data
        data[field] = e.target.value
        this.setState({data})
    }

    render() {
        const open = this.state.open;
        const showRewardsHome = this.props.locations.location ? this.props.locations.location.settings.home.show_rewards : false;
        const showAnnoucementHome = this.props.locations.location ? this.props.locations.location.settings.home.show_announcements : false;
        // const isMyReward = this.props.locations.location
        //     ? this.props.locations.location.settings.rewards.enable
        //     : false;
        const {gallery_page, read_only_non_pos_page} = this.props.locations.configurations;
        const {width} = this.state;

        const location = this.props.locations.location
        const isTiles = this.props.locations.location.settings.consumer_application_settings.home_page_announcement_layout === 'tiles'
        const headerImages = this.props.locations.location.settings.consumer_application_settings.home_page.header_images
        const {backgroundImage} = getConfiguration().progress_bar_gradient;
        let cutIndex = 11
        let cutMenuIndex = 11
        let found = false
        let foundMenu = false
        const getLocationAddress = location.address1 + " " + location.city + " " + location.state + " " + location.zip
        const getLocationPhone = location.phone
        const concatCategories = this.props.menus && this.props.menus.categories && this.props.menus.categories.categories ? this.props.menus.categories.categories.reduce((accumulator, currentValue, index) => {
            let currentLength = accumulator.concat(currentValue.name).join("   ").length
            if (!found && currentLength > 120) {
                found = true
                cutIndex = index
            }
            return accumulator.concat(currentValue.name)
        }, []).join(" ").length : null

        const concatMenus = this.props.menus && this.props.menus.data && this.props.menus.data.menus ? this.props.menus.data.menus.reduce((accumulator, currentValue, index) => {
            let currentLength = accumulator.concat(currentValue.name).join("   ").length
            if (!foundMenu && currentLength > 120) {
                foundMenu = true
                cutMenuIndex = index
            }
            return accumulator.concat(currentValue.name)
        }, []).join(" ").length : null
        const otherNonPos = this.props.locations.location.settings.meals.inhouse_orders.enable || this.props.locations.location.settings.read_only_menu_non_pos.enable
        const nonPosMenu = this.state.nonPos || read_only_non_pos_page.all_menus[0]
        const isGallery = this.props.locations.location.settings.gallery.enable
        const indicatorStyles = {
            background: '#9f9d9d',
            width: 10,
            height: 10,
            display: 'inline-block',
            margin: '0px 8px',
            borderWidth: 1,
            borderRadius: 5,
            borderColor: 'white'
        };
        const arrowStyles = {
            position: 'absolute',
            zIndex: 2,
            top: 'calc(50% - 15px)',
            width: 30,
            height: 30,
            cursor: 'pointer',
        };
        const announcements = this.props.locations.announcements.filter(announcement => announcement.data.opentable_id == null && announcement.type !== "advertisement" && announcement.type !== "advertisement_banner" && announcement.type !== "closed")
        const getAnnouncements = (itemsPerRow) => {
            let count = announcements.length
            let batches = Array.from(Array(Math.ceil(count / itemsPerRow)).keys())
            return <Carousel
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <i className="fa fa-3x fa-angle-left"
                           onClick={onClickHandler} title={label} style={{
                            ...arrowStyles,
                            left: 15,
                        }}
                        ></i>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <i className="fa fa-3x fa-angle-right"
                           onClick={onClickHandler} title={label} style={{
                            ...arrowStyles,
                            right: 0
                        }}>
                        </i>
                    )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                        return (
                            <li
                                style={{...indicatorStyles, background: '#000'}}
                                aria-label={`Selected: ${label} ${index + 1}`}
                                title={`Selected: ${label} ${index + 1}`}
                            />
                        );
                    }
                    return (
                        <li
                            style={indicatorStyles}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            title={`${label} ${index + 1}`}
                            aria-label={`${label} ${index + 1}`}
                        />
                    );
                }}
                autoPlay={true}
                showArrows={true}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                selectedItem={this.state.index}
                onChange={(index) => this.setState({index})}
                dynamicHeight={false}
                interval={6000}
            >
                {batches.map((batch, ind) => {
                    return <Card.Group centered key={ind}>
                        {Array.from(Array(itemsPerRow).keys()).map((groupIndex) => {
                            const index = (batch * itemsPerRow) + groupIndex
                            const announcement = announcements[index]
                            if (announcement) {
                                return announcement.action_is_external ?
                                    (<Card key={`ann-${index}`}
                                           onClick={() => {
                                               window.open(announcement.action, '_blank');
                                           }}
                                           style={{
                                               width: (100 / itemsPerRow - 3.4) + "%",
                                               maxheight: "100%",
                                           }}>
                                        <Card.Content
                                            className={"mobile-menu-item"}
                                            style={{
                                                minHeight: 450,
                                            }}
                                        >
                                            <Grid textAlign='left'>
                                                <Grid.Column className='announcement'>
                                                    <div
                                                        style={{color: 'black'}}
                                                        className="announcement"
                                                        dangerouslySetInnerHTML={{
                                                            __html: announcement.data.body,
                                                        }}
                                                    >
                                                    </div>
                                                    <br/>
                                                    <br/>
                                                </Grid.Column>
                                            </Grid>
                                        </Card.Content>
                                    </Card>) : announcement.action ?
                                        (<Card key={`ann-${index}`}
                                               style={{
                                                   width: (100 / itemsPerRow - 3.4) + "%",
                                                   maxheight: "100%",
                                               }}
                                               onClick={() => {
                                                   this.props.history.push(announcement.action)
                                               }}
                                        >
                                            <Card.Content
                                                className={"mobile-menu-item"}
                                                style={{
                                                    minHeight: 450,
                                                }}
                                            >
                                                <Grid textAlign='left'>
                                                    <Grid.Column className='announcement'>

                                                        <div
                                                            style={{color: 'black'}}
                                                            className="announcement"
                                                            dangerouslySetInnerHTML={{
                                                                __html: announcement.data.body,
                                                            }}
                                                        >
                                                        </div>
                                                        <br/>
                                                        <br/>
                                                    </Grid.Column>
                                                </Grid>
                                            </Card.Content>
                                        </Card>)
                                        :
                                        (<Card key={`ann-${index}`}
                                               style={{
                                                   width: (100 / itemsPerRow - 3.4) + "%",
                                                   maxheight: "100%",
                                               }}>
                                            <Card.Content
                                                className={"mobile-menu-item"}
                                            >
                                                <Grid textAlign='left'>
                                                    <Grid.Column className='announcement'>
                                                        <div
                                                            onClick={() => this.setOpen(index, true)}
                                                            style={{color: 'black'}}
                                                            dangerouslySetInnerHTML={{
                                                                __html: announcement.data.body,
                                                            }}
                                                        ></div>
                                                        <br/>
                                                        <br/>
                                                    </Grid.Column>
                                                </Grid>
                                            </Card.Content>
                                        </Card>)
                            } else {
                                return null
                            }
                        })
                        }
                    </Card.Group>
                })
                }
            </Carousel>
        }
        const getGallery = () => {
            return <Card.Group stackable doubling itemsPerRow={3}>
                {gallery_page ? (gallery_page.active_images.length > 0 ? gallery_page.active_images.map((item, index) =>
                        <Card raised
                              padded
                              key={item.image.image_id}
                              onClick={this.goToItems.bind(this, item, index)}
                              style={{
                                  minHeight: width >= 768 ? 250 : 180,
                                  backgroundImage: 'url("' + item.image.url + '")',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center top'
                              }}>
                            <Card.Content style={{
                                position: 'absolute',
                                width: '100%',
                                bottom: 0,
                                textAlign: 'center',
                                border: 'none',
                                background: 'linear-gradient(to right top, rgb(0 0 0 / 29%), rgba(255, 255, 255, 0))',
                            }}>
                                <Card.Header style={{
                                    color: "white",
                                    textShadow: "0 0 5px rgb(0 0 0), 0 0 5px rgb(0 0 0), 0 0 5px rgb(0 0 0)"
                                }}>{item.title !== 'undefined' ? item.title : ""}</Card.Header>
                            </Card.Content>
                        </Card>) : null) :
                    [0, 1, 2].map((menu, index) =>
                        <Card key={index}
                              raised
                              style={{
                                  height: width >= 768 ? 250 : 180,
                              }}>
                            <Placeholder>
                                <Placeholder.Image rectangular/>
                            </Placeholder>
                            <Card.Content className="item-content" style={{
                                position: 'absolute',
                                width: '100%',
                                bottom: 0,
                                textAlign: 'center',
                                background: 'linear-gradient(to left bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 1))',
                                borderTop: 'none',
                            }}>
                                <Grid textAlign='left'>
                                    <Grid.Row>
                                        <Grid.Column width={16}
                                                     style={{paddingRight: 0}}>
                                            <Placeholder>
                                                <Placeholder.Header>
                                                    <Placeholder.Line/>
                                                </Placeholder.Header>
                                            </Placeholder>
                                        </Grid.Column>

                                    </Grid.Row>
                                    <Grid.Row style={{
                                        paddingTop: 0, paddingBottom: 0, maxHeight: 65, minHeight: 65
                                    }}>
                                        <Grid.Column>
                                            <Placeholder>
                                                <Placeholder.Paragraph>
                                                    <Placeholder.Line/>
                                                    <Placeholder.Line/>
                                                    <Placeholder.Line/>
                                                </Placeholder.Paragraph>
                                            </Placeholder>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                        </Card>)}
            </Card.Group>
        }

        const showAds = this.state.showAds
        const advertisement = this.props.locations.announcements.find(announcement => announcement.type === "advertisement")

        const getAds = () => {
            return advertisement ? <Modal
                basic
                size={'medium'}
                centered
                closeIcon
                open={showAds}
                onClose={() => this.setState({showAds: false})}
                onOpen={() => this.setState({showAds: true})}
            >
                <Modal.Content image>
                    <br/>
                    <div style={{position: "relative", margin: 30}}>
                        {advertisement.data.buttons.map((button, index) => {
                            return <div key={index}
                                        style={{
                                            width: button.width,
                                            height: button.height,
                                            position: "absolute",
                                            top: button.top,
                                            left: button.left,
                                            zIndex: 2,
                                        }}
                                        onClick={() => {
                                            if (button.action_is_external) {
                                                window.location = (button.protocol || "https:") + "//" + button.hostname
                                            } else {
                                                this.props.history.push(button.action)
                                            }
                                        }}></div>
                        })}
                        <img src={advertisement.data.image_src}/>
                    </div>

                </Modal.Content>
            </Modal> : null
        }
        const isNotValid = !this.state.data.recaptcha_confirmation || !this.state.data.email || !this.state.data.first_name || !this.state.data.last_name || !this.state.data.message
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }

                    return <div>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: [
                                    '.ui.progress.custom-color-progress{\n' +
                                    '    background: white' +
                                    '}' +
                                    '.ui.progress.custom-color-progress .bar {\n' +
                                    '    background-image: ' +
                                    backgroundImage +
                                    ';\n' +
                                    '    min-width: 0px' +
                                    '}' +
                                    '.ui.progress[data-percent="0"] .bar {\n' +
                                    '  background:transparent;\n' +
                                    '}\n' +
                                    '.ui.progress[data-percent="0"] .bar .progress {\n' +
                                    '  color: rgba(0, 0, 0, 0.87);\n' +
                                    '}\n' +
                                    '.ui.inverted.progress[data-percent="0"] .bar .progress {\n' +
                                    '  color: rgba(255, 255, 255, 0.9);\n' +
                                    '}\n' +
                                    '.ui.inverted.indicating.progress .label {\n' +
                                    '  color: rgba(255, 255, 255, 0.9);\n' +
                                    '}',
                                ].join('\n'),
                            }}
                        />
                        <div style={{position: "relative"}}>

                            <Carousel
                                autoPlay={true}
                                showArrows={false}
                                showThumbs={false}
                                showStatus={false}
                                infiniteLoop={true}
                                dynamicHeight={true}
                                interval={6000}
                            >
                                {headerImages && headerImages.length > 0 ? headerImages.map((item, index) => {
                                    return <div key={index}
                                                style={{
                                                    display: "flex", justifyContent: "center",
                                                }}>
                                        <Image src={item.url}
                                               style={{
                                                   height: "700px",
                                                   width: getConfiguration().container_width,
                                                   objectFit: 'cover'
                                               }}/>
                                        <div style={{
                                            opacity: 0.9,
                                            top: this.props.users.user.rewards &&
                                            this.props.users.user.rewards.summary &&
                                            this.props.users.user.rewards.is_rewards_on &&
                                            showRewardsHome ? 200 : 150,
                                            maxWidth: 500,
                                            position: "absolute",
                                            left: 0,
                                            right: 0,
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            textAlign: "center",
                                        }}>
                                            <Segment style={{
                                                opacity: 0.9,
                                                textAlign: "center",
                                                margin: 15
                                            }}>
                                                <Header
                                                    as='h2'>{this.props.locations.location.settings.consumer_application_settings.home_page.welcome_box.header}</Header>
                                                <Header as='h4'>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: this.props.locations.location.settings.consumer_application_settings.home_page.welcome_box.message}}/>
                                                </Header>
                                                {
                                                    this.props.locations.location.settings.consumer_application_settings.home_page.welcome_box.online_order.enable ?
                                                        <Link
                                                            to={this.props.locations.location.settings.consumer_application_settings.home_page.welcome_box.online_order.link}
                                                            name={this.props.locations.location.settings.consumer_application_settings.home_page.welcome_box.online_order.link}>
                                                            <Button
                                                                style={{
                                                                    border: '2px solid black',
                                                                    color: 'black',
                                                                    background: "transparent"
                                                                }}
                                                            >
                                                                ORDER ONLINE
                                                            </Button>
                                                        </Link> : null
                                                }
                                            </Segment>
                                        </div>
                                    </div>
                                }) : null}
                            </Carousel>
                        </div>
                        <br/>
                        <Header textAlign='center' size='large'
                                style={{color: getConfiguration().button_color.menucolor, textAlign: 'center'}}>Our
                            Latest Promotions</Header>

                        {showAds ? getAds() : null}
                        <div>
                            <Media at='mobile'>
                                {getAnnouncements(1)}
                            </Media>
                            <Media at='tablet'>
                                {getAnnouncements(2)}
                            </Media>
                            <Media greaterThanOrEqual='computer'>
                                {getAnnouncements(3)}
                            </Media>
                        </div>

                        {(this.props.menus && this.props.menus.categories) || otherNonPos
                            ? <Header textAlign='center' size='large'
                                      style={{color: getConfiguration().button_color.menucolor}}>Menus</Header> : null}
                        {this.props.menus && this.props.menus.categories ?
                            <Segment padded>
                                <Segment inverted>
                                    {this.props.menus &&
                                        <PrintMenuComponent
                                            color={{...getConfiguration().button_color}}
                                            menus={this.props.menus}
                                            footer={this.props.locations.location.settings.consumer_application_settings.consumer_portal_footer}
                                            app_icon={this.props.locations.configurations.app_icon}
                                        />
                                    }
                                    <Menu
                                        stackable
                                        pointing
                                        secondary
                                        style={{borderBottom: '2px solid rgba(34, 36, 38, 0.01)'}}
                                    >
                                        {this.props.menus.data.menus
                                            ? this.props.menus.data.menus.map((menu, index) => {
                                                let isActive = this.state.menuId === menu.external_id || (!this.state.menuId && index ==0) ;
                                                return index <= cutMenuIndex ? (
                                                    <Menu.Item key={menu.external_id}
                                                               active={isActive}
                                                               style={{
                                                                   marginTop: 10,
                                                                   padding: 10,
                                                                   color: isActive ? "inherit" : 'inherit',
                                                                   borderColor: isActive ? "red" : '#ffffff00',
                                                               }}
                                                               onClick={this.goToCategory.bind(this, menu)}
                                                               name={menu.name}
                                                               content={menu.name.toUpperCase()}
                                                    />
                                                ) : null
                                            })
                                            : null}
                                        {this.props.menus.data.menus ? (
                                            this.props.menus.data.menus.length > cutMenuIndex + 1 ? (
                                                <Dropdown
                                                    item
                                                    text='MORE'
                                                    style={{
                                                        padding: 10,
                                                        color:"white"
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        {this.props.menus.data.menus.map(
                                                            (menu, index) => {
                                                                let isActive = this.state.menuId === menu.external_id || (!this.state.menuId && index ==0) ;
                                                                return index > cutMenuIndex ? (
                                                                    <Dropdown.Item
                                                                        key={menu.external_id}
                                                                        active={isActive}
                                                                        style={{
                                                                            marginTop: 10,
                                                                            padding: 10,
                                                                            color: isActive ? "red" : 'inherit',
                                                                            borderColor: isActive
                                                                                ? "red"
                                                                                : '#ffffff00',
                                                                        }}
                                                                        onClick={this.goToCategory.bind(this, menu)}
                                                                        text={menu.name.toUpperCase()}
                                                                    />
                                                                ) : null;
                                                            }
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) : null
                                        ) : null}
                                    </Menu>
                                    <Menu
                                        stackable
                                        pointing
                                        secondary
                                        style={{borderBottom: '2px solid rgba(34, 36, 38, 0.01)'}}
                                    >
                                        {this.props.menus.categories.categories.length > 0
                                            ? this.props.menus.categories.categories.map((menu, index) => {
                                                let isActive = this.state.categoryId === menu.external_id || (!this.state.categoryId && index ==0) ;
                                                return index <= cutIndex ? (
                                                    <Menu.Item
                                                        key={menu.external_id}
                                                        active={isActive}
                                                        style={{
                                                            padding: 10,
                                                            color: isActive ? 'inherit' : 'inherit',
                                                            borderColor: isActive ? 'red' : '#ffffff00',
                                                        }}
                                                        onClick={this.goToCategoryItems.bind(this, menu)}
                                                        name={menu.name}
                                                        content={menu.name.toUpperCase()}
                                                    />
                                                ) : null;
                                            })
                                            :
                                            <div
                                                className={"item"}
                                                style={{
                                                    justifyContent:"center",
                                                    color: "inherit",
                                                    width: "100%"
                                                }}
                                            >
                                                <span>NO CATEGORIES</span>
                                            </div>}
                                        {this.props.menus && this.props.menus.categories ? (
                                            this.props.menus.categories.categories.length > cutIndex + 1 ? (
                                                <Dropdown
                                                    inverted
                                                    item
                                                    text='MORE'
                                                    style={{
                                                        padding: 10,
                                                        color: "white"
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        {this.props.menus.categories.categories.map(
                                                            (menu, index) => {
                                                                let isActive = this.state.categoryId === menu.external_id || (!this.state.categoryId && index ==0) ;
                                                                return index > cutIndex ? (
                                                                    <Dropdown.Item
                                                                        key={menu.external_id}
                                                                        active={isActive}
                                                                        style={{
                                                                            marginTop: 10,
                                                                            padding: 10,
                                                                            color: isActive ? 'inherit' : 'inherit',
                                                                            borderColor: isActive
                                                                                ? 'red'
                                                                                : '#ffffff00',
                                                                        }}
                                                                        onClick={this.goToCategoryItems.bind(this, menu)}
                                                                        text={menu.name.toUpperCase()}
                                                                    />
                                                                ) : null;
                                                            }
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) : null
                                        ) : null}
                                    </Menu>
                                </Segment>
                                <Media at='mobile'>
                                    <Card.Group centered>
                                        <Dimmer active={this.state.loadingItems} inverted>
                                            <Loader size='small' inline='centered'>
                                                Loading
                                            </Loader>
                                        </Dimmer>
                                        {this.props.menus.items ? (
                                            this.props.menus.items.items.length > 0 ? (
                                                this.props.menus.items.items.map((menu) => {
                                                    return (
                                                        <Card
                                                            fluid
                                                            key={menu.id}
                                                            style={{marginBottom: 0}}
                                                        >
                                                            <Card.Content
                                                                className={"mobile-menu-item"}
                                                            >
                                                                {/*<Image*/}
                                                                {/*    className={"mobile-menu-item"}*/}
                                                                {/*    floated='left'*/}
                                                                {/*    size='tiny'*/}
                                                                {/*    style={{*/}
                                                                {/*        height: 64,*/}
                                                                {/*        marginLeft: -14,*/}
                                                                {/*        marginBottom: -14,*/}
                                                                {/*        marginTop: -14,*/}
                                                                {/*        borderRadius: 5,*/}
                                                                {/*        width: "auto !important"*/}
                                                                {/*    }}*/}
                                                                {/*    src={*/}
                                                                {/*        menu.images.length > 0*/}
                                                                {/*            ? menu.images[0].imageSignedUrl*/}
                                                                {/*            : 'none'*/}
                                                                {/*    }*/}
                                                                {/*/>*/}
                                                                <Card.Header
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        overflow: 'hidden',
                                                                        textDecoration: 'none',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                    }}
                                                                >
                                                                    {menu.name}
                                                                    <p style={{
                                                                        fontSize: '1em',
                                                                        color: 'black',
                                                                        display: 'flex',
                                                                        float: 'right',
                                                                    }}>${menu.omnivore
                                                                        ? menu.omnivore.price_per_unit.toFixed(2)
                                                                        : 0.0}
                                                                    </p>
                                                                </Card.Header>
                                                                <Card.Description>
                                                                    {menu.description}
                                                                </Card.Description>
                                                            </Card.Content>
                                                        </Card>
                                                    );
                                                })
                                            ) : (
                                                <Label size='large'>No items on this category</Label>
                                            )
                                        ) : (
                                            range(9).map((menu) => (
                                                <Card fluid key={menu} style={{margin: '.5em .5em'}}>
                                                    <Card.Content>
                                                        <Placeholder>
                                                            <Placeholder.Header image>
                                                                <Placeholder.Line/>
                                                                <Placeholder.Line/>
                                                            </Placeholder.Header>
                                                        </Placeholder>
                                                    </Card.Content>
                                                </Card>
                                            ))
                                        )}
                                    </Card.Group>
                                </Media>
                                <Media greaterThanOrEqual='tablet'>
                                    <Card.Group itemsPerRow={2} doubling>
                                        <Dimmer active={this.state.loadingItems} inverted>
                                            <Loader size='medium' inline='centered'>
                                                Loading
                                            </Loader>
                                        </Dimmer>
                                        {
                                            this.props.menus.items ? (
                                                this.props.menus.items.items.length > 0 ? (
                                                    this.props.menus.items.items.map((menu, index) => {
                                                        return <Card key={index} style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            boxShadow: "none",
                                                            background: "transparent"
                                                        }}>
                                                            <div
                                                                style={{
                                                                    borderRadius: "20px 20px 20px 20px!important",
                                                                    height: "auto",
                                                                    minWidth: 150,
                                                                    margin: 10,
                                                                    minHeight: 110,
                                                                    backgroundImage: menu.images[0] && 'url("' + menu.images[0].imageSignedUrl + '")',
                                                                    backgroundSize: 'cover',
                                                                    backgroundPosition: 'center top'
                                                                }}/>
                                                            <Card.Content style={{border: "none"}}
                                                                          className={"mobile-menu-item"}
                                                            >
                                                                <Card.Header>{menu.name}</Card.Header>
                                                                <Card.Description>
                                                                    {menu.description}
                                                                </Card.Description>
                                                            </Card.Content>
                                                            <Card.Content extra
                                                                          style={{
                                                                              fontWeight: "bold",
                                                                              border: "none"
                                                                          }}>
                                                                <p style={{
                                                                    fontSize: '1.1em',
                                                                    color: 'black'
                                                                }}>${menu.omnivore
                                                                    ? menu.omnivore.price_per_unit.toFixed(2)
                                                                    : 0.0}</p>
                                                            </Card.Content>
                                                        </Card>
                                                    })
                                                ) : null) : null
                                        }
                                    </Card.Group>
                                </Media>
                            </Segment>
                            : otherNonPos ?
                                <Segment padded>
                                    <Segment inverted>
                                        {nonPosMenu &&
                                            <PrintNonPOSMenuComponent
                                                color={{...getConfiguration().button_color}}
                                                menus={nonPosMenu}
                                                footer={this.props.locations.location.settings.consumer_application_settings.consumer_portal_footer}
                                                app_icon={this.props.locations.configurations.app_icon}
                                            />
                                        }
                                        <Menu
                                            stackable
                                            pointing
                                            secondary
                                            style={{borderBottom: '2px solid rgba(34, 36, 38, 0.01)'}}
                                        >
                                            {read_only_non_pos_page.all_menus.map((menu, index) => {
                                                let isActive = nonPosMenu && nonPosMenu.header === menu.header;
                                                return index <= cutIndex ? (
                                                    <Menu.Item
                                                        key={menu.header}
                                                        active={isActive}
                                                        style={{
                                                            padding: 10,
                                                            color: isActive ? 'inherit' : 'inherit',
                                                            borderColor: isActive ? 'red' : '#ffffff00',
                                                        }}
                                                        onClick={this.goToNonPosItems.bind(this, menu)}
                                                        name={menu.header}
                                                        content={menu.header.toUpperCase()}
                                                    />
                                                ) : null;
                                            })}
                                        </Menu>
                                    </Segment>
                                    <Media at='mobile'>
                                        {nonPosMenu ?
                                            <Card fluid>
                                                <Card.Content>
                                                    <Header textAlign="center" as="h3">
                                                        {nonPosMenu.header}
                                                    </Header>
                                                    <Card.Description
                                                        dangerouslySetInnerHTML={{
                                                            __html: nonPosMenu.details,
                                                        }}
                                                    />
                                                </Card.Content>
                                            </Card> : null}
                                    </Media>
                                    <Media greaterThanOrEqual='tablet'>
                                        {nonPosMenu ?
                                            <Card fluid>
                                                <Card.Content>
                                                    <Header textAlign="center" as="h3">
                                                        {nonPosMenu.header}
                                                    </Header>
                                                    <Card.Description
                                                        dangerouslySetInnerHTML={{
                                                            __html: nonPosMenu.details,
                                                        }}
                                                    />
                                                </Card.Content>
                                            </Card> : null
                                        }
                                    </Media>
                                </Segment> : null}

                        {isGallery ?
                            <Header textAlign='center' size='large'
                                    style={{color: getConfiguration().button_color.menucolor}}>Gallery</Header> : null}
                        <Modal
                            dimmer={'blurring'}
                            basic
                            onClose={() => {
                                this.setOpenGallery(false)
                            }}
                            open={this.state.openGallery}
                            size='large'
                        >
                            <Header icon>
                                {gallery_page && gallery_page.active_images.length > 0 ?
                                    (gallery_page.active_images[this.state.carouselIndex || 0] && gallery_page.active_images[this.state.carouselIndex || 0].title !== 'undefined' ? gallery_page.active_images[this.state.carouselIndex || 0].title : null) : null}
                            </Header>
                            <Modal.Content>
                                <Carousel showArrows={true}
                                          showThumbs={false}
                                          showStatus={false}
                                          infiniteLoop={true}
                                          selectedItem={this.state.carouselIndex}
                                          onChange={(index) => {
                                              this.setState({carouselIndex: index})
                                          }}
                                          dynamicHeight={true}>
                                    {gallery_page && gallery_page.active_images.length > 0 ?
                                        gallery_page.active_images.map((item, index) => {
                                                return <div key={index}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}>
                                                    <Image src={item.image.url} key={index}
                                                           style={{
                                                               maxHeight: "calc( 100vh - 150px)",
                                                               width: "auto",
                                                               objectFit: 'cover'
                                                           }}/>
                                                </div>
                                            }
                                        )
                                        : null}
                                </Carousel>
                            </Modal.Content>
                            <Modal.Actions>
                                <Icon style={{
                                    position: 'absolute',
                                    top: 20,
                                    right: 15,
                                }} name='remove' size='large' onClick={() => this.setOpenGallery(false)}/>
                            </Modal.Actions>
                        </Modal>
                        {isGallery ?
                            <Grid padded textAlign='center' verticalAlign='middle'>
                                <Grid.Column style={{maxWidth: 1280, marginTop: 20, marginBottom: 20}}>
                                    <Media at='mobile'>
                                        {getGallery(1)}
                                    </Media>
                                    <Media greaterThanOrEqual='tablet'>
                                        {getGallery(3)}
                                    </Media>
                                </Grid.Column>
                            </Grid> : null}
                        <Segment inverted>
                            <Dimmer active={this.state.loading}>
                                <Loader active={this.state.loading}
                                        inline='centered'/>
                            </Dimmer>
                            <Header textAlign='center' size='large'>Contact Us</Header>
                            <Header textAlign='center' size='tiny'>Address: {getLocationAddress}</Header>
                            <Header textAlign='center' size='tiny'>Phone: {getLocationPhone}</Header>

                            {this.state.contactUsResponse ?
                                <Header textAlign='center' size='tiny'><Message compact positive
                                                                                floating>{this.state.contactUsResponse.message}</Message></Header>
                                :
                                <Form inverted>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid placeholder='First name'
                                                    onChange={this.handleChange.bind(this, 'first_name')}/>
                                        <Form.Input fluid placeholder='Last name'
                                                    onChange={this.handleChange.bind(this, 'last_name')}/>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid placeholder='Email'
                                                    onChange={this.handleChange.bind(this, 'email')}/>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.TextArea placeholder='Message' rows={4}
                                                       onChange={this.handleChange.bind(this, 'message')}
                                        />
                                    </Form.Group>
                                    <Form.Group widths='equal' centered>
                                        <Form.Field>
                                            <Recaptcha
                                                sitekey={Config.recaptcha_siteKey}
                                                render="explicit"
                                                onloadCallback={() => {
                                                    console.log('Done!!!!');
                                                }}
                                                verifyCallback={(response) => {
                                                    const data = this.state.data
                                                    data.recaptcha_confirmation = response
                                                    this.setState({
                                                        data
                                                    })
                                                }}
                                            />
                                        </Form.Field>

                                    </Form.Group>
                                    <Button floated={"right"} type='submit' onClick={this.contactUs.bind(this)}
                                            disabled={isNotValid}>SEND</Button>
                                    <br/>
                                    <br/>
                                </Form>}

                        </Segment>
                        <MyMapComponent
                            defaultCenter={this.state.coordinates}
                            isMarkerShown
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Config.googleApiKey}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `400px`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                        />
                    </div>
                }}
            </CacheBuster>
        );
    }
}

export default connect((state) => {
    const {login: auth, users, locations, menus} = state;
    return {auth, users, locations, menus};
}, (dispatch) => bindActionCreators({
    ...
        actionCreators,
    ...
        ticketStore.actionCreators,
    ...
        menuStore.actionCreators,
    ...
        locationStore.actionCreators
}, dispatch))(Home);
