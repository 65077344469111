import locationApi from './api/location'
import {handleError} from "./api/errorHandler";

const LOCATION_CONFIGURATION = 'LOCATION_CONFIGURATION';
const LOCATION_ANNOUCEMENT = 'LOCATION_ANNOUCEMENT';
const GET_LOCATION = 'GET_LOCATION';
const GET_SEO_LOCATION = 'GET_SEO_LOCATION';
const CONTACT_US = 'CONTACT_US';

const initialState = {};

export const actionCreators = {
    contactUs: (message) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await locationApi.contactUs(message)
            let data = {}
            dispatch({
                type: CONTACT_US,
                payload: response.data
            });
            return response.data
        };
        try {
            return await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    locationConfiguration: () => async (dispatch, getState) => {
        let callback = async function () {
            let response = await locationApi.locationConfiguration()
            let data = {}
            response.data.configurations.forEach(config => {
                data[config.name] = config.value
            })

            dispatch({
                type: LOCATION_CONFIGURATION,
                payload: data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback)
        }
    },
    locationAnnouncement: () => async (dispatch, getState) => {
        let callback = async function (authSkip = false) {
            let response = await locationApi.locationAnnouncement(authSkip)
            dispatch({
                type: LOCATION_ANNOUCEMENT,
                payload: response.data.announcements
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback, true)
        }
    },

    getLocation: () => async (dispatch, getState) => {
        let callback = async function (authSkip = false) {
            let response = await locationApi.location(authSkip)
            let data = response.data

            let pathname = window.location.pathname
            let consumerAppSettings = response.data.settings.consumer_application_settings
            let seoPath = []
            Object.keys(consumerAppSettings).forEach((key) => {
                if (key.endsWith("_path")) {
                    let mainKey = key.slice(0, key.indexOf('_path'))
                    let path = {
                        path: consumerAppSettings[key],
                        title: consumerAppSettings[mainKey + "_title"],
                        description: consumerAppSettings[mainKey + "_description"],
                    }
                    seoPath.push(path)
                }
            })
            let page = seoPath.find((item) => {
                return pathname.indexOf(item.path) != -1
            });
            if (!page) {
                page = seoPath.find((item) => item.path === "/home");
            }

            document.title = page.title
            document.description = page.description

            dispatch({
                type: GET_LOCATION,
                payload: data
            });

            console.log(seoPath)
            dispatch({
                type: GET_SEO_LOCATION,
                payload: seoPath
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback, true)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === LOCATION_CONFIGURATION) {
        return {...state, configurations: action.payload};
    }
    if (action.type === LOCATION_ANNOUCEMENT) {
        return {...state, announcements: action.payload};
    }

    if (action.type === GET_LOCATION) {
        return {...state, location: action.payload};
    }
    if (action.type === GET_SEO_LOCATION) {
        return {...state, seo: action.payload};
    }
    return state;
};
