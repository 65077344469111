import React, {Component} from 'react';
import {
    Container,
    Form,
    Grid,
    Header,
    Image,
    Message,
    Segment,
} from 'semantic-ui-react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import queryString from 'query-string';

import { Media } from '../../AppMedia';
import {actionCreators} from '../../store/Login';
import {getConfiguration} from '../../store/api/apiClient';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        let query = queryString.parse(props.location.search);
        this.state = {
            email: '',
            password: '',
            errorMessage: null,
            successMessage: null,
            token: query.token,
        };
    }

    async handleSubmit() {
        this.setState({errorMessage: null, loading: true});
        let response = await this.props.resetPassword(this.state);
        this.handleResponse(response);
    }

    handleResponse(response) {
        let errors = response.data.errors;
        if (!errors) {
            let successMessage = <p>{response.data.status}</p>;
            this.setState({successMessage, errorMessage: null, loading: false});
        } else {
            let errorMessage = Array.isArray(errors) ? (
                errors.map((error, index) => {
                    return <p key={index}>{error.message}</p>;
                })
            ) : (
                <p>{response.data.message}</p>
            );
            this.setState({successMessage: null, errorMessage, loading: false});
        }
    }

    handleChange(field, e) {
        let state = this.state;
        state[field] = e.target.value;
        state.errorMessage = null;
        this.setState(state);
    }

    render() {
        const appIcon = this.props.locations.configurations
            ? this.props.locations.configurations.app_icon
            : null;
        let locationBackgroundUrl = this.props.locations.configurations.location_background_url
        return (
            <Container
                fluid
                className='bg-image'
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html: [
                            '\n',
                            `body:after {` +
                            `  background-image: url(${ locationBackgroundUrl ? locationBackgroundUrl : getConfiguration().backgroundImage});` +
                            `}`,
                        ].join('\n'),
                    }}
                ></style>
                <Grid
                    class='bg-text'
                    textAlign='center'
                    style={{zIndex: 2, margin: 0}}
                    verticalAlign='middle'
                >
                    <Grid.Column style={{maxWidth: 450}}>
                        <Media at='mobile'>
                            <Image src={appIcon} style={{maxHeight: 150, margin: 'auto'}} onClick={() => this.props.history.push('/')}/>
                        </Media>
                        <Media greaterThanOrEqual='tablet'>
                            <Image src={appIcon} style={{maxHeight: 300, margin: 'auto'}} onClick={() => this.props.history.push('/')}/>
                        </Media>
                        <Segment
                            raised
                            style={{
                                background: '#ffffffe6',
                                borderRadius: 5,
                                border: '1px solid darkgray',
                            }}
                        >
                            <Header
                                as='h3'
                                style={getConfiguration().label_color}
                                textAlign='center'
                            >
                                RESET PASSWORD
                            </Header>
                            {this.state.errorMessage ? (
                                <Message negative>
                                    <Message.Header>
                                        There were some errors with your submission
                                    </Message.Header>
                                    {this.state.errorMessage}
                                </Message>
                            ) : this.state.successMessage ? (
                                <Message positive key={1}>
                                    <Message.Header>Success!</Message.Header>
                                    {this.state.successMessage}
                                    <a href='/login'>Click here </a> to login
                                </Message>
                            ) : null}
                            {this.state.successMessage ? null : (
                                <Form>
                                    <Form.Input
                                        label='NEW PASSWORD'
                                        fluid
                                        icon='lock'
                                        iconPosition='left'
                                        placeholder='New Password'
                                        type='password'
                                        onChange={this.handleChange.bind(this, 'newPassword')}
                                    />
                                    <Form.Input
                                        fluid
                                        label='CONFIRM PASSWORD'
                                        icon='lock'
                                        iconPosition='left'
                                        placeholder='Confirm Password'
                                        type='password'
                                        onChange={this.handleChange.bind(
                                            this,
                                            'newPasswordConfirmation'
                                        )}
                                    />
                                    <Form.Button
                                        circular
                                        style={getConfiguration().button_color}
                                        fluid
                                        size='large'
                                        onClick={this.handleSubmit.bind(this)}
                                    >
                                        SUBMIT
                                    </Form.Button>
                                </Form>
                            )}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

export default connect(
    (state) => {
        const {locations} = state;
        return {locations};
    },
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(ResetPassword);
