import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import { Form } from 'semantic-ui-react';
import AddTime from './AddTime';
import AddTip from './AddTip';
import AddPayment from './AddPayment';
import AddPromo from './AddPromo';
import AddInformation from './AddInformation';
import DeliveryOption from './DeliveryOption';
import { getConfiguration } from '../../store/api/apiClient';
import Config from '../../config'
import { Logger } from '../../utils/Logger';

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            active: props.screen,
            error_address: {
                recipient_first_name: {
                    content: "Please enter a valid data",
                    pointing: 'below'
                },
                recipient_last_name: {
                    content: "Please enter a valid data",
                    pointing: 'below'
                },
                recipient_address: {
                    content: "Please enter a valid data",
                    pointing: 'below'
                },
                recipient_city: {
                    content: "Please enter a valid data",
                    pointing: 'below'
                },
                recipient_state: {
                    content: "Please enter a valid data",
                    pointing: 'below'
                },
                recipient_zip: {
                    content: "Please enter a valid data",
                    pointing: 'below'
                }
            },
            error_card: {
                buyer_email: {
                    content: "Please enter a valid email address",
                    pointing: 'below',
                },
                buyer_first_name: {
                    content: "Please enter a valid first name",
                    pointing: 'below',
                },
                buyer_last_name: {
                    content: "Please enter a valid last name",
                    pointing: 'below',
                },
                card_number: {
                    content: "Please enter a valid credit number",
                    pointing: 'below',
                },
                card_expiry: {
                    content: "Please enter a valid credit expiry",
                    pointing: 'below',
                },
                card_cvc: {
                    content: "Please enter a valid credit cvc",
                    pointing: 'below',
                }
            },
            loading: false,
            error: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({active: nextProps.screen, loading: nextProps.loading})
    }

    handleSubmit = async (ev) => {
        ev.preventDefault();
        if (!this.props.elements) {
            return;
        }
        try {
            this.props.setLoading(true)
            const cardElement = this.props.elements.getElement('cardNumber');
            const name = this.state.data.first_name + " " + this.state.data.last_name
    
            const {paymentMethod, error: createPaymentMethodError} = await this.props.stripe
                .createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                    billing_details: {name},
                });
            console.log('Created PaymentMethod:', paymentMethod, createPaymentMethodError);
            if (createPaymentMethodError) {
                Logger.error('TicketCheckoutForm', 'handleSubmit', `Failed to create PaymentMethod: ${createPaymentMethodError.message}`);
                this.setState({errors: createPaymentMethodError});
                this.props.setLoading(false)
                return;
            } else {
                Logger.info('TicketCheckoutForm', 'handleSubmit', `Created PaymentMethod: ${paymentMethod.id}`);
            }

            /*
            The following two methods were commented out as these were NOT implemented correctly besides we are not using PaymentIntent API from Stripe at the moment.
            */
            // const {paymentIntent, error: confirmCardPaymentError} = await this.props.stripe.confirmCardPayment(Config.client_id + '_secret_' + Config.secret_key, {
            //     payment_method: {
            //         card: cardElement,
            //     },
            // });
            // console.log('confirmCardPayment:', paymentIntent, confirmCardPaymentError);
            // if (confirmCardPaymentError) {
            //     Logger.error('TicketCheckoutForm', 'handleSubmit', `Charge failed: ${confirmCardPaymentError.message}`);
            // } else {
            //     Logger.info('TicketCheckoutForm', 'handleSubmit', `Charge succeeded! PaymentIntent is in state: ${paymentIntent.status}`);
            // }
            //
            // const {setupIntent, error: confirmCardSetupError} = await this.props.stripe.confirmCardSetup(Config.client_id + '_secret_' + Config.secret_key, {
            //     payment_method: {
            //         card: cardElement,
            //     },
            // });
            // console.log('confirmCardSetup:', setupIntent, confirmCardSetupError);
            // if (confirmCardSetupError) {
            //     Logger.error('TicketCheckoutForm', 'handleSubmit', `Setup failed: ${confirmCardSetupError.message}`);
            // } else {
            //     Logger.info('TicketCheckoutForm', 'handleSubmit', `Setup succeeded! SetupIntent is in state: ${setupIntent.status}`);
            // }
    
            const {token} = await this.props.stripe.createToken({type: 'card', name: name});
            const {source} = await this.props.stripe.createSource({
                type: 'card',
                owner: {
                    name
                },
            });
    
            await this.props.generateEphemeralKey(this.state.data);
            const payment = await this.props.addPaymentTicketOrder(token, source);
            if (payment && payment.errors !== undefined) {
                this.setState({errors: {message: payment.message}});
            }
            this.props.setLoading(false)
        } catch (err) {
            console.log(err);
            Logger.error('TicketCheckoutForm', 'handleSubmit', JSON.stringify(err));
        }
    };

    update(field, value) {
        let data = this.state.data
        let error_address = this.state.error_address;
        let error_card = this.state.error_card;
        try {
            if (field === 'recipient_first_name' ||
                field === 'recipient_last_name' ||
                field === 'recipient_address' ||
                field === 'recipient_city' ||
                field === 'recipient_state' ||
                field === 'recipient_zip') {
                if (value) {
                    delete error_address[field]
                } else {
                    error_address[field] = {
                        content: "Please enter a valid info",
                        pointing: 'below',
                    }
                }
            }
            if (this.props.login.user.email) {
                delete error_card.buyer_email
                delete error_card.buyer_first_name
                delete error_card.buyer_last_name
            }
            if (field === 'buyer_email' && value) {
                if (this.validateEmail(value)) {
                    delete error_card.buyer_email
                } else {
                    error_card.buyer_email = {
                        content: "Please enter a valid email address",
                        pointing: 'below',
                    }
                }
            }
            if (field === 'buyer_first_name' ||
                field === 'buyer_last_name') {
                if (value) {
                    delete error_card[field]
                } else {
                    error_card[field] = {
                        content: "Please enter a valid info",
                        pointing: 'below',
                    }
                }
            }
            data[field] = value
            this.setState({data, error_address, error_card})
        } catch (err) {
            Logger.error('TicketCheckoutForm', 'update', JSON.stringify(err));
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    nextStep(active) {
        sessionStorage.setItem('screen', active)
        this.setState({active})
        this.props.screenChange(active)
    }

    handleGoBack(screen) {
        if (screen) {
            sessionStorage.setItem('screen', screen)
            this.setState({screen})
            this.props.screenChange(screen)
        } else {
            this.props.history.replace('/ordercart');
        }
    }

    handleGeocode(geocodeByAddress) {
        let data = this.state.data
        let error_address = this.state.error_address
        try {
            data.recipient_address = this.getAddressType(geocodeByAddress, ['street_number', 'route'])
            data.recipient_city = this.getAddressType(geocodeByAddress, ['locality'])
            data.recipient_state = this.getAddressType(geocodeByAddress, ['administrative_area_level_1'])
            data.recipient_zip = this.getAddressType(geocodeByAddress, ['postal_code'])
    
            if (data.recipient_address) delete error_address.recipient_address
            if (data.recipient_city) delete error_address.recipient_city
            if (data.recipient_state) delete error_address.recipient_state
            if (data.recipient_zip) delete error_address.recipient_zip
    
            this.setState({data})
        } catch (err) {
            Logger.error('TicketCheckoutForm', 'handleGeocode', JSON.stringify(err));
        }
    }

    getAddressType(geocodeByAddress, types) {
        let geocodeAddress = []
        types.forEach(type => {
            geocodeByAddress[0].address_components.forEach(geocode => {
                if (geocode.types.indexOf(type) !== -1) {
                    return geocodeAddress.push(geocode.short_name)
                }
            })
        })
        return geocodeAddress.join(" ")
    }

    render() {
        let {color} = getConfiguration().label_color

        return (
            <Form autoComplete="off" style={{width: '100%'}}>
                <style dangerouslySetInnerHTML={{
                    __html: [
                        '.ui.positive.message {\n' +
                        '    background-color: #fcfff5 !important;\n' +
                        '    color: inherit;\n' +
                        '    box-shadow: 0 0 0 1px ' + color + ' inset, 0 0 0 0 transparent !important;\n' +
                        '}\n' +
                        '.ui.positive.message .header {\n' +
                        '    color: ' + color + ' !important;\n' +
                        '}\n' +
                        '.checkout.ui.segment {\n' +
                        '   padding:0;\n' +
                        '}' +
                        '.checkout i.icon.close:before {\n' +
                        '   color: ' + color + '\n' +
                        '}' +
                        '.ui.selection .dropdown {\n' +
                        '    background: lightgrey !important;\n' +
                        '    right: .75em !important;\n' +
                        '}'
                    ].join('\n')
                }}/>
                {this.state.active === 'delivery' ?
                    <DeliveryOption
                        handleGoBack={this.handleGoBack.bind(this)}
                        nextStep={this.nextStep.bind(this)}/> : null}
                {this.state.active === 'time' ?
                    <AddTime
                        handleGoBack={this.handleGoBack.bind(this)}
                        nextStep={this.nextStep.bind(this)}/> : null}
                {this.state.active === 'information' ?
                    <AddInformation
                        handleGoBack={this.handleGoBack.bind(this)}
                        nextStep={this.nextStep.bind(this)}/> : null}
                {this.state.active === 'promo' ?
                    <AddPromo
                        handleGoBack={this.handleGoBack.bind(this)}
                        nextStep={this.nextStep.bind(this)}/> : null}
                {this.state.active === 'tip' ?
                    <AddTip
                        handleGoBack={this.handleGoBack.bind(this)}
                        nextStep={this.nextStep.bind(this)}/> : null}
                {this.state.active === 'payment' ?
                    <AddPayment
                        handleSubmit={this.handleSubmit.bind(this)}
                        history={this.props.history}
                        loading={this.state.loading}
                        setLoading={this.props.setLoading.bind(this)}
                        handleGoBack={this.handleGoBack.bind(this)}
                        nextStep={this.nextStep.bind(this)}/> : null}
            </Form>
        );
    }
}

export default injectStripe(CheckoutForm);
