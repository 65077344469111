import React, {Component} from 'react';
import NewChoices from './NewChoices'
import Checklist from './Checklist'
import {Grid, Header, Accordion, Menu} from 'semantic-ui-react'
import {getConfiguration} from '../../../store/api/apiClient'

class AccordionChecklist extends Component {

    state = {
        activeIndex: -1
    }

    submitCheck(section) {
        return (checks) => {
            section.checked = checks
            this.props.submit(section)
        }
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === null || activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    render() {
        const {activeIndex} = this.state

        return <Grid.Row key={this.props.index + "_" + 2}>
            <Grid.Column width={16}>
                <Accordion as={Menu} vertical
                           style={{width: '100%', background: 'none', border: 'none', boxShadow: 'none'}}>
                    <Menu.Item style={{padding: 0}}>
                        <Accordion.Title
                            style={{
                                ...getConfiguration().button_color,
                                borderRadius: 5,
                                height: 40,
                                padding: 13,
                            }}
                            className={'ui'}
                            active={activeIndex === null || activeIndex === this.props.index}
                            content={<strong
                                style={{color: 'white'}}>{this.props.section.name.toUpperCase()}</strong>}
                            index={this.props.index}
                            onClick={this.handleClick}
                        />
                        <Accordion.Content
                            style={{
                                border: '1px solid ' + getConfiguration().label_color.color,
                                borderRadius: 5,
                                marginTop: 10,
                                paddingTop: 0
                            }}
                            active={activeIndex === null || activeIndex === this.props.index}
                            content={
                                <Checklist checklist={this.props.section.checklist}
                                           default={0}
                                           customize={this.props.customize}
                                           customized={this.props.customized}
                                           defaultCustomized={this.props.defaultCustomized}
                                           submit={this.submitCheck(this.props.section)}/>}/>
                    </Menu.Item>
                </Accordion>
            </Grid.Column>
        </Grid.Row>
    }
}

class AccordionChoice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeIndex: props.customize ? 0 : -1
        }
    }

    submitChoice(section) {
        return (choice) => {
            section.chosen = choice
            this.props.submit(section)
        }
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === null || activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    render() {
        const {activeIndex} = this.state

        return <Grid.Row key={this.props.index + "_" + 2}>
            <Grid.Column width={16}>
                <Accordion as={Menu} vertical
                           style={{width: '100%', background: 'none', border: 'none', boxShadow: 'none'}}>
                    <Menu.Item style={{padding: 0}}>
                        {this.props.customize ? <Accordion.Title
                            className={'ui'}
                            style={{
                                ...getConfiguration().button_color,
                                borderRadius: 5,
                                height: 40,
                                padding: 13,
                            }}
                            active={activeIndex === null || activeIndex === this.props.index}
                            content={<strong
                                style={{color: 'white'}}>{this.props.section.name.toUpperCase()}</strong>}
                            index={this.props.index}
                            onClick={this.handleClick}
                        /> : <strong style={getConfiguration().label_color}>{this.props.section.name.toUpperCase()}</strong>
                        }
                        <Accordion.Content
                            style={{
                                borderRadius: 5,
                                marginTop: 10,
                                paddingTop: 0
                            }}
                            active={activeIndex === null || activeIndex === this.props.index || !this.props.customize}
                            content={
                                <NewChoices choices={this.props.section.choices}
                                            default={0}
                                            customize={this.props.customize}
                                            customized={this.props.customized}
                                            defaultCustomized={this.props.defaultCustomized}
                                            submit={this.submitChoice(this.props.section)}/>}/>
                    </Menu.Item>
                </Accordion>
            </Grid.Column>
        </Grid.Row>
    }
}

export default class Sections extends Component {
    render() {
        let {color} = getConfiguration().label_color
        let defaultColor = getConfiguration().button_color.color
        let selectedColor = getConfiguration().button_color.selected

        return (
            <Grid textAlign='left'>
                <style dangerouslySetInnerHTML={{
                    __html: [
                        '.order .ui.radio.checkbox input:checked~.box:before, .ui.radio.checkbox input:checked~label:before {\n' +
                        '    border-color: ' + color + ';\n' +
                        '}' +
                        '.order .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {\n' +
                        '    background-color: ' + color + ';\n' +
                        '}' +
                        '.order .ui.radio.checkbox .box:before, .ui.radio.checkbox label:before {' +
                        '    border-color: ' + color + ';\n' +
                        '}' +
                        '.order .ui.radio.checkbox.checked label {\n' +
                        '    color: ' + color + ';\n' +
                        '}' +
                        '.order .ui.accordion .accordion .title .dropdown.icon:before, ' +
                        '.ui.accordion .title .dropdown.icon:before{\n' +
                        '    color: ' + defaultColor + ';\n' +
                        '}' +
                        '.order .ui.selection .selection .title .dropdown.icon:before, ' +
                        '.ui.selection .title .dropdown.icon:before{\n' +
                        '    color: ' + color + ';\n' +
                        '}' +
                        '.order .ui.dropdown .menu .selected.item, .ui.dropdown.selected {\n' +
                        '    color: ' + color + ';\n' +
                        '}' +
                        '.order .ui.dropdown .menu .selected.item.not_valid, .ui.dropdown.selected.not_valid{\n' +
                        '    color: lightgrey;\n' +
                        '}' +
                        '.order .ui.dropdown .menu .item.not_valid, .ui.dropdown.not_valid{\n' +
                        '    color: lightgrey;\n' +
                        '}' +
                        '.order .ui.selection.dropdown {\n' +
                        '    color: ' + color + ';\n' +
                        '}' +
                        '.order .ui.vertical.accordion.menu .item .active.title {\n' +
                        '    background-color: ' + selectedColor + ' !important;\n' +
                        '}'

                    ].join('\n')
                }}>
                </style>
                {
                    this.props.sections.map((section, index) => {
                        if (section.customization === this.props.customize) {

                            return [section.choices ?
                                <AccordionChoice key={index + "_" + 1}
                                                 index={index}
                                                 customized={this.props.customized}
                                                 customize={this.props.customize}
                                                 defaultCustomized={this.props.defaultCustomized}
                                                 section={section}
                                                 submit={this.props.submit}/> : null,
                                section.checklist ?
                                    <AccordionChecklist key={index + "_" + 3}
                                                        index={index}
                                                        customized={this.props.customized}
                                                        customize={this.props.customize}
                                                        defaultCustomized={this.props.defaultCustomized}
                                                        section={section}
                                                        submit={this.props.submit}/> : null]
                        } else {
                            return null
                        }
                    })
                }
            </Grid>
        );
    }
}

