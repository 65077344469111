import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  Grid,
  Segment,
  Form,
  Image,
  Label,
  Button,
  Header,
  Icon,
  Card,
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import AppMessage from '../common/ui/AppMessage';
import { Media } from '../../AppMedia';
import CacheBuster from '../../CacheBuster';
import { actionCreators } from '../../store/Users';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as cartStore from '../../store/Cart';
import { getConfiguration } from '../../store/api/apiClient';
import { Logger } from '../../utils/Logger';
import giftitpng from '../../assets/giftit.png';

class Cards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            saving: -1,
            error: {
                price: {
                    content: "Please enter a valid price",
                    pointing: 'below',
                },
                quantity: {
                    content: "Please enter a valid quantity",
                    pointing: 'below',
                }
            },
        }
    }

    componentWillMount() {
        this.props.locationConfiguration()
    }

    handleChange(field, e, value) {
        let data = this.state.data
        let error = this.state.error;
        try {
          if (e.target.value === "") {
              data[field] = ""
              error[field] = {
                  content: "Please enter a valid " + field,
                  pointing: 'below',
              }
          } else if ((!e.target.validity.badInput && Number(e.target.value) > 0)) {
              if (field === 'price' && Number(e.target.value) < 1) {
                  data[field] = ""
                  error[field] = {
                      content: "Please enter a valid " + field,
                      pointing: 'below',
                  }
              } else if (field === 'quantity' && Number(e.target.value) > 10) {
                  data[field] = ""
                  error[field] = {
                      content: "Please enter a valid " + field,
                      pointing: 'below',
                  }
              } else {
                  data[field] = Number(e.target.value)
                  delete error[field]
              }
          }
          this.setState({data, saving: -1, error})
        } catch (err) {
            Logger.error('Cards', 'handleChange', JSON.stringify(err));
        }
    }

    async handleSubmit() {
        this.setState({saving: 1})
        let data = this.state.data
        try {
          data.item = this.props.locations.configurations.physical_gift_card.products[0].name
          data.program_id = this.props.locations.configurations.physical_gift_card.products[0].program_id
          let errors = await this.props.addGiftItCardToCart(data)
          if (errors !== undefined) {
              this.setState({saving: 2, errors})
          } else {
              delete data.quantity
              delete data.price
              this.setState({
                  saving: 0,
                  data,
                  error: {
                      price: {
                          content: "Please enter a valid price",
                          pointing: 'below',
                      },
                      quantity: {
                          content: "Please enter a valid quantity",
                          pointing: 'below',
                      }
                  }
              })
              this.toggleMessage()
          }
        } catch (err) {
            Logger.error('Cards', 'handleSubmit', JSON.stringify(err));
        }
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    renderDesktop(params) {
        const { physicalGiftCard, items } = params;

        return (
            <Grid textAlign='center' verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 850}} textAlign='left'>
                    <Segment raised style={{
                        padding: 40,
                        background: '#ffffffed',
                        borderRadius: 5,
                        border: '1px solid darkgray',
                    }}>
                        <Grid doubling columns={2}>
                            <Grid.Column width={7} textAlign='center'>
                                <Segment raised textAlign='center'>
                                    <Image src={physicalGiftCard.products[0].image} size='large'
                                            style={{margin: '60px 0px'}}/>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Header as='h3' style={getConfiguration().label_color}>
                                    {physicalGiftCard.products[0].name}
                                </Header>
                                <Header as='h4' className='greensea'>
                                    {physicalGiftCard.products[0].subheader}
                                </Header>
                                <Form>
                                    <Form.Field
                                        error={this.state.data.price !== undefined ? this.state.error.price ? true : false : null}
                                    >
                                        <label>GIFT AMOUNT</label>
                                        <NumberFormat
                                            value={this.state.data.price ? this.state.data.price : ""}
                                            placeholder={"Gift Amount"}
                                            decimalScale={2}
                                            allowNegative={false}
                                            onInvalid={(e) => e.preventDefault()}
                                            onChange={this.handleChange.bind(this, 'price')}
                                        />
                                    </Form.Field>
                                    <Form.Field
                                        error={this.state.data.quantity !== undefined ? this.state.error.quantity ? true : false : null}>
                                        <label>QUANTITY</label>
                                        <input type={'text'} step="1"
                                                placeholder='Quantity'
                                                min={1} max={10}
                                                inputMode="numeric" pattern="[0-9]*"
                                                value={this.state.data.quantity ? this.state.data.quantity : ""}
                                                onChange={this.handleChange.bind(this, 'quantity')}/>
                                    </Form.Field>
                                    <br/>
                                    <br/>
                                    <Button type='submit' style={getConfiguration().button_color}
                                            onClick={this.handleSubmit.bind(this)}
                                            disabled={!isEmpty(this.state.error)} icon>
                                        <Icon name='add to cart'/> Add to Cart</Button>
                                    {items.length > 0 ? <Button as={Link} to={'/cart'}
                                                                name={'/cart'}>
                                        View Cart&nbsp;&nbsp;<img
                                        className={'brightness'}
                                        style={{height: 20}}
                                        src={``}
                                        alt="giftcart"
                                    />
                                        <Label color='red' circular size={'mini'}
                                                style={{
                                                    position: 'relative',
                                                    top: -10,
                                                    right: 10
                                                }}>
                                            {items.length}
                                        </Label> </Button> : null}
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <div
                                    dangerouslySetInnerHTML={{__html: physicalGiftCard.products[0].description}}></div>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(params) {
      const { physicalGiftCard, items } = params;

      return (
        <Card fluid>
          <Image src={physicalGiftCard.products[0].image} />
          <Card.Content>
            <Card.Header as='h3' style={getConfiguration().label_color}>
              {physicalGiftCard.products[0].name}
            </Card.Header>
            <Header as='h4' className='greensea'>
              {physicalGiftCard.products[0].subheader}
            </Header>
            <Form>
              <Form.Field
                error={
                  this.state.data.price !== undefined
                    ? this.state.error.price
                      ? true
                      : false
                    : null
                }
              >
                <label>GIFT AMOUNT</label>
                <NumberFormat
                  value={this.state.data.price ? this.state.data.price : ''}
                  placeholder={'Gift Amount'}
                  decimalScale={2}
                  allowNegative={false}
                  onInvalid={(e) => e.preventDefault()}
                  onChange={this.handleChange.bind(this, 'price')}
                />
              </Form.Field>
              <Form.Field
                error={
                  this.state.data.quantity !== undefined
                    ? this.state.error.quantity
                      ? true
                      : false
                    : null
                }
              >
                <label>QUANTITY</label>
                <input
                  type={'text'}
                  step='1'
                  placeholder='Quantity'
                  min={1}
                  max={10}
                  inputMode='numeric'
                  pattern='[0-9]*'
                  value={this.state.data.quantity ? this.state.data.quantity : ''}
                  onChange={this.handleChange.bind(this, 'quantity')}
                />
              </Form.Field>
              <Form.Button
                fluid
                type='submit'
                style={getConfiguration().button_color}
                onClick={this.handleSubmit.bind(this)}
                disabled={!isEmpty(this.state.error)}
                icon
              >
                <Icon name='add to cart' /> Add to Cart
              </Form.Button>
              {items.length > 0 ? (
                <Form.Field>
                  <Button fluid as={Link} to={'/cart'} name={'/cart'}>
                    View Cart{' '}
                    <img
                      className={'brightness'}
                      style={{ height: 20 }}
                      src={giftitpng}
                      alt="Giftit"
                    />
                    <Label
                      color='red'
                      circular
                      size={'mini'}
                      style={{
                        position: 'relative',
                        top: -10,
                        right: 10,
                      }}
                    >
                      {items.length}
                    </Label>{' '}
                  </Button>
                </Form.Field>
              ) : null}
              <Form.Field
                dangerouslySetInnerHTML={{
                  __html: physicalGiftCard.products[0].description,
                }}
              ></Form.Field>
            </Form>
          </Card.Content>
        </Card>
      );
    }

    render() {
      let physicalGiftCard = this.props.locations.configurations
        ? this.props.locations.configurations.physical_gift_card
        : null;
      let items = this.props.cart.order ? this.props.cart.order.items : [];

      return physicalGiftCard ? (
        <CacheBuster>
            {({loading, isLatestVersion, refreshCacheAndReload}) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    console.log('BROWSER IS BEING REFRESHED!');
                    refreshCacheAndReload();
                }
                return (
                    <>
                      <AppMessage
                        saving={this.state.saving}
                        errors={this.state.errors}
                      />
                      <Media at='mobile'>
                        {this.renderMobile({ physicalGiftCard, items })}
                      </Media>
                      <Media greaterThanOrEqual='tablet'>
                        {this.renderDesktop({ physicalGiftCard, items })}
                      </Media>
                    </>
                );
            }}
        </CacheBuster>
      ) : null;
    }
}

export default connect(
  (state) => {
    const { users, login, cart, locations } = state;
    return { users, cart, auth: { ...login }, locations };
  },
  (dispatch) =>
    bindActionCreators(
      {
        ...actionCreators,
        ...loginStore.actionCreators,
        ...cartStore.actionCreators,
        ...locationStore.actionCreators,
      },
      dispatch
    )
)(Cards);
