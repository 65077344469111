import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import {
  Button,
  Grid,
  Header,
  Icon,
  Step,
  Card,
  Form,
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { Media } from '../../AppMedia';
import CardSection from './CardSection';
import AddressSection from './AddressSection';
import PersonalMessageSection from './PersonalMessageSection';
import { Link } from 'react-router-dom';
import { getConfiguration } from '../../store/api/apiClient';
import Config from '../../config';
import { Logger } from '../../utils/Logger';

class CheckoutForm extends React.Component {
    state = {
        data: {},
        active: 'billing',
        error_address: {
            recipient_first_name: {
                content: "Please enter a valid data",
                pointing: 'below'
            },
            recipient_last_name: {
                content: "Please enter a valid data",
                pointing: 'below'
            },
            recipient_address: {
                content: "Please enter a valid data",
                pointing: 'below'
            },
            recipient_city: {
                content: "Please enter a valid data",
                pointing: 'below'
            },
            recipient_state: {
                content: "Please enter a valid data",
                pointing: 'below'
            },
            recipient_zip: {
                content: "Please enter a valid data",
                pointing: 'below'
            }
        },
        error_card: {
            buyer_email: {
                content: "Please enter a valid email address",
                pointing: 'below',
            },
            buyer_first_name: {
                content: "Please enter a valid first name",
                pointing: 'below',
            },
            buyer_last_name: {
                content: "Please enter a valid last name",
                pointing: 'below',
            },
            card_number: {
                content: "Please enter a valid credit number",
                pointing: 'below',
            },
            card_expiry: {
                content: "Please enter a valid credit expiry",
                pointing: 'below',
            },
            card_cvc: {
                content: "Please enter a valid credit cvc",
                pointing: 'below',
            }
        },
        loading: false,
        saving: -1,
        error: {}
    }

    handleSubmit = async (ev) => {
        ev.preventDefault();
        this.setState({loading: true})
        try {
          const cardElement = this.props.elements.getElement('cardNumber');
          const name = this.state.data.first_name + " " + this.state.data.last_name
          const cardInfo = {
              type: 'card',
              card: cardElement,
              billing_details: {name},
          }
          const {paymentMethod, error: createPaymentMethodError} = await this.props.stripe.createPaymentMethod(cardInfo);
          console.log('Created PaymentMethod:', paymentMethod, createPaymentMethodError);
          if (createPaymentMethodError) {
              Logger.error('CheckoutForm', 'handleSubmit', `Failed to create PaymentMethod: ${createPaymentMethodError.message}`);
              this.setState({saving: 2, errors: createPaymentMethodError, loading: false})
              return;
          } else {
              Logger.info('CheckoutForm', 'handleSubmit', `Created PaymentMethod: ${paymentMethod.id}`);
          }

            /*
            The following two methods were commented out as these were NOT implemented correctly besides we are not using PaymentIntent API from Stripe at the moment.
             */
          // const {paymentIntent, error: confirmCardPaymentError} = this.props.stripe.confirmCardPayment(Config.client_id + '_secret_' + Config.secret_key, {
          //     payment_method: {
          //         card: cardElement,
          //     },
          // });
          // console.log('confirmCardPayment:', paymentIntent, confirmCardPaymentError);
          // if (confirmCardPaymentError) {
          //     Logger.error('CheckoutForm', 'handleSubmit', `Charge failed: ${confirmCardPaymentError.message}`);
          // } else {
          //     Logger.info('CheckoutForm', 'handleSubmit', `Charge succeeded! PaymentIntent is in state: ${paymentIntent.status}`);
          // }
          //
          // const {setupIntent, error: confirmCardSetupError} = this.props.stripe.confirmCardSetup(Config.client_id + '_secret_' + Config.secret_key, {
          //     payment_method: {
          //         card: cardElement,
          //     },
          // });
          // console.log('confirmCardSetup:', setupIntent, confirmCardSetupError);
          // if (confirmCardSetupError) {
          //     Logger.error('CheckoutForm', 'handleSubmit', `Setup failed: ${confirmCardSetupError.message}`);
          // } else {
          //     Logger.info('CheckoutForm', 'handleSubmit', `Setup succeeded! SetupIntent is in state: ${setupIntent.status}`);
          // }
  
          const token = await this.props.stripe.createToken({type: 'card', name: name});
          const source = await this.props.stripe.createSource({
              type: 'card',
              owner: {
                  name
              },
          });
          if (token.error !== undefined) {
              this.setState({saving: 2, errors: token.error, loading: false})
          } else {
              await this.props.generateEphemeralKey(this.state.data)
              await this.props.checkout(this.state.data, token.token, source.source)
              this.setState({saving: 0, loading: false})
              this.toggleMessage()
          }
        } catch (err) {
            Logger.error('CheckoutForm', 'handleSubmit', JSON.stringify(err));
        }
    };

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    async saveAddressAndContinue() {
        await this.props.updateCartAddress(this.state.data)
    }

    async savePersonalMessage() {
        await this.props.updateCartPersonalMessage(this.state.data)
    }

    onError(field, content, complete) {
        let data = this.state.data;
        let error_card = this.state.error_card;
        try {
          if (content) {
              error_card[field] = {
                  content,
                  pointing: "below"
              }
          } else {
              delete error_card[field]
          }
          data[field] = complete
          this.setState({data, error_card})
        } catch (err) {
            Logger.error('CheckoutForm', 'onError', JSON.stringify(err));
        }
    }

    update(field, value) {
        let data = this.state.data
        let error_address = this.state.error_address;
        let error_card = this.state.error_card;
        try {
          if (field === 'recipient_first_name' ||
              field === 'recipient_last_name' ||
              field === 'recipient_address' ||
              field === 'recipient_city' ||
              field === 'recipient_state' ||
              field === 'recipient_zip') {
              if (value) {
                  delete error_address[field]
              } else {
                  error_address[field] = {
                      content: "Please enter a valid info",
                      pointing: 'below',
                  }
              }
          }
          if (this.props.login.user.email) {
              delete error_card.buyer_email
              delete error_card.buyer_first_name
              delete error_card.buyer_last_name
          }
          if (field === 'buyer_email' && value) {
              if (this.validateEmail(value)) {
                  delete error_card.buyer_email
              } else {
                  error_card.buyer_email = {
                      content: "Please enter a valid email address",
                      pointing: 'below',
                  }
              }
          }
          if (field === 'buyer_first_name' ||
              field === 'buyer_last_name') {
              if (value) {
                  delete error_card[field]
              } else {
                  error_card[field] = {
                      content: "Please enter a valid info",
                      pointing: 'below',
                  }
              }
          }
          data[field] = value
          this.setState({data, error_address, error_card})
        } catch (err) {
            Logger.error('CheckoutForm', 'update', JSON.stringify(err));
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    nextStep(active, e, data) {
        this.setState({active})
        try {
          if (active === 'personal_message') {
            this.saveAddressAndContinue()
          } else if (active === 'payment') {
            this.savePersonalMessage()
          }
        } catch (err) {
            Logger.error('CheckoutForm', 'nextStep', JSON.stringify(err));
        }
    }

    backStep(active, e, data) {
        this.setState({active})
    }

    skip(active) {
        this.setState({active})
    }

    step(active) {
      try {
        if (active === 'billing' && this.state.data.recipient_address) {
          this.setState({active})
        } else if (active === 'personal_message' && this.state.data.personal_message) {
          this.setState({active})
        }
      } catch (err) {
          Logger.error('CheckoutForm', 'step', JSON.stringify(err));
      }
    }

    async generateEphemeralKey() {
      try {
        await this.props.generateEphemeralKey(this.state.data.buyer_email)
        this.setState({active: 'payment'})
      } catch (err) {
          Logger.error('CheckoutForm', 'generateEphemeralKey', JSON.stringify(err));
      }
    }

    handleGeocode(addressResult, addr) {
        let data = { ...this.state.data };
        let error_address = { ...this.state.error_address };
        try {
          if (addr.types[0] === 'street_address') {
              data.recipient_address = this.getAddressType(addressResult, ['street_number', 'route']);
              data.recipient_city = this.getAddressType(addressResult, ['locality']) || this.getAddressType(addressResult, ['neighborhood']);
          } else {
              data.recipient_address = `${addr.terms[0].value} ${this.getAddressType(addressResult, ['route'])}`;
              data.recipient_city = addr.terms[2].value;
          }
          data.recipient_state = this.getAddressType(addressResult, ['administrative_area_level_1'])
          data.recipient_zip = this.getAddressType(addressResult, ['postal_code'])
  
          if (data.recipient_address) delete error_address.recipient_address
          if (data.recipient_city) delete error_address.recipient_city
          if (data.recipient_state) delete error_address.recipient_state
          if (data.recipient_zip) delete error_address.recipient_zip
  
          this.setState({ data, error_address });
        } catch (err) {
            Logger.error('CheckoutForm', 'handleGeocode', JSON.stringify(err));
        }
    }

    getAddressType(addressResult, types) {
        let geocodeAddress = []
        types.forEach(type => {
            addressResult.address_components.forEach(geocode => {
                if (geocode.types.indexOf(type) !== -1) {
                    return geocodeAddress.push(geocode.short_name)
                }
            })
        })
        return geocodeAddress.join(" ")
    }

    renderDesktop() {
      return (
        <>
            <Step.Group size='tiny' widths={3} fluid stackable='tablet'>
                <Step active={this.state.active === 'billing'} onClick={this.step.bind(this, 'billing')}>
                    <Icon name='truck'/>
                    <Step.Content>
                        <Step.Title>Shipping</Step.Title>
                        <Step.Description>Enter Shipping Information</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.state.active === 'personal_message'}
                        onClick={this.step.bind(this, 'personal_message')}>
                    <Icon name='conversation'/>
                    <Step.Content>
                        <Step.Title>Personal Message</Step.Title>
                        <Step.Description>Enter your Personal Message</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.state.active === 'payment'} onClick={this.step.bind(this, 'payment')}>
                    <Icon name='payment'/>
                    <Step.Content>
                        <Step.Title>Payment</Step.Title>
                        <Step.Description>Enter Payment Information</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Grid.Column width={16}>
                {this.state.active === 'billing' ?
                    <AddressSection
                        update={this.update.bind(this)}
                        email={this.props.login.user.email}
                        data={this.state.data}
                        error={this.state.error_address}
                        handleGeocode={this.handleGeocode.bind(this)}/> : null}
                {this.state.active === 'personal_message' ?
                    <PersonalMessageSection
                        update={this.update.bind(this)}
                        email={this.props.login.user.email}
                        data={this.state.data}/> : null}
                {this.state.active === 'payment' ?
                    <CardSection
                        loading={this.state.loading}
                        update={this.update.bind(this)}
                        error={this.state.error_card}
                        data={this.state.data}
                        onError={this.onError.bind(this)}
                        email={this.props.login.user.email}/> : null}
                {this.state.active === 'done' ? <Grid>
                    <Grid.Column width={16} textAlign='center'>
                        <br/>
                        <Header as='h3' color='teal'>
                            Success!
                        </Header>
                        <Header as='h4'>
                            Thank you for placing your order.
                            <br/>
                            <br/>
                            Will start processing this immediately and get the cards mailed out to you.
                        </Header>
                        <br/>
                        <br/>
                    </Grid.Column>
                </Grid> : null}
            </Grid.Column>
            <Grid.Column width={16}>
                <br/>
                {this.state.active === 'billing' ?
                    <>
                    <Button as={Link}
                            to={'/cart'}
                            name={'/cart'}
                            labelPosition='left'
                            icon
                            style={getConfiguration().button_color}>
                        <Icon name='left arrow'/>
                        Back </Button>
                    <Button
                        icon
                        style={getConfiguration().button_color}
                        floated="right"
                        labelPosition='right'
                        onClick={this.nextStep.bind(this, 'personal_message')}
                        disabled={!isEmpty(this.state.error_address)}>
                        Save and Continue <Icon name='right arrow'/>
                    </Button>
                    </> : null}
                {this.state.active === 'personal_message' ? <>
                    <Button
                        icon
                        style={getConfiguration().button_color}
                        labelPosition='left'
                        onClick={this.backStep.bind(this, 'billing')}>
                        <Icon name='left arrow'/>
                        Back </Button>
                    <Button.Group
                        floated="right"
                    >
                        <Button
                            icon
                            style={getConfiguration().button_color}
                            labelPosition='right'
                            size={'tiny'}
                            onClick={this.nextStep.bind(this, 'payment')}>
                            Save and Continue <Icon name='right arrow'/>
                        </Button>
                        <Button
                            icon
                            color='blue'
                            labelPosition='right'
                            onClick={this.skip.bind(this, 'payment')}>
                            <Icon name='right arrow'/>
                            Skip
                        </Button>
                    </Button.Group>


                    </>
                    : null}
                {this.state.active === 'payment' ? <>
                    <Button
                        icon
                        style={getConfiguration().button_color}
                        labelPosition='left'
                        onClick={this.backStep.bind(this, 'personal_message')}>
                        <Icon name='left arrow'/>
                        Back </Button>
                    <Button
                        icon
                        type='submit'
                        style={getConfiguration().button_color}
                        floated="right"
                        labelPosition='right'
                        disabled={!isEmpty(this.state.error_card)}
                        onClick={this.handleSubmit.bind(this)}>
                        Confirm Order <Icon name='right arrow'/></Button></>
                    : null}
                {this.state.active === 'done' ?
                    <Button
                        icon
                        style={getConfiguration().button_color}
                        labelPosition='left'
                        onClick={this.nextStep.bind(this, 'payment')}>
                        <Icon name='left arrow'/>
                        Back </Button>
                    : null}
            </Grid.Column>
        </>
      );
    }

    renderMobile() {
      return (
        <>
          <Step.Group size='tiny' fluid unstackable>
            <Step
              active={this.state.active === 'billing'}
              onClick={this.step.bind(this, 'billing')}
            >
              <Icon name='truck' />
            </Step>
            <Step
              active={this.state.active === 'personal_message'}
              onClick={this.step.bind(this, 'personal_message')}
            >
              <Icon name='conversation' />
            </Step>
            <Step
              active={this.state.active === 'payment'}
              onClick={this.step.bind(this, 'payment')}
            >
              <Icon name='payment' />
            </Step>
          </Step.Group>
          {this.state.active === 'billing' ? (
            <AddressSection
              update={this.update.bind(this)}
              email={this.props.login.user.email}
              data={this.state.data}
              error={this.state.error_address}
              handleGeocode={this.handleGeocode.bind(this)}
            />
          ) : null}
          {this.state.active === 'personal_message' ? (
            <PersonalMessageSection
              update={this.update.bind(this)}
              email={this.props.login.user.email}
              data={this.state.data}
            />
          ) : null}
          {this.state.active === 'payment' ? (
            <CardSection
              loading={this.state.loading}
              update={this.update.bind(this)}
              error={this.state.error_card}
              data={this.state.data}
              onError={this.onError.bind(this)}
              email={this.props.login.user.email}
            />
          ) : null}
          {this.state.active === 'done' ? (
            <Card fluid style={{ marginBottom: 15 }}>
              <Card.Content>
                <Card.Header as='h3' color='teal'>
                  Success!
                </Card.Header>
                <Card.Description>
                  Thank you for placing your order.
                  <br />
                  Will start processing this immediately and get the cards mailed
                  out to you.
                </Card.Description>
              </Card.Content>
            </Card>
          ) : null}
          {this.state.active === 'billing' ? (
            <Form.Field>
              <Button
                as={Link}
                to={'/cart'}
                name={'/cart'}
                icon
                style={getConfiguration().button_color}
              >
                <Icon name='left arrow' />
              </Button>
              <Button
                icon
                style={getConfiguration().button_color}
                floated='right'
                labelPosition='right'
                onClick={this.nextStep.bind(this, 'personal_message')}
                disabled={!isEmpty(this.state.error_address)}
              >
                Save and Continue <Icon name='right arrow' />
              </Button>
            </Form.Field>
          ) : null}
          {this.state.active === 'personal_message' ? (
            <Form.Field>
              <Button
                icon
                style={getConfiguration().button_color}
                onClick={this.backStep.bind(this, 'billing')}
              >
                <Icon name='left arrow' />
              </Button>
              <Button.Group floated='right'>
                <Button
                  icon
                  style={getConfiguration().button_color}
                  onClick={this.nextStep.bind(this, 'payment')}
                >
                  Save and Continue
                </Button>
                <Button
                  icon
                  color='blue'
                  onClick={this.skip.bind(this, 'payment')}
                  style={{ marginLeft: 5 }}
                >
                  <Icon name='right arrow' />
                </Button>
              </Button.Group>
            </Form.Field>
          ) : null}
          {this.state.active === 'payment' ? (
            <Form.Field>
              <Button
                icon
                style={getConfiguration().button_color}
                onClick={this.backStep.bind(this, 'personal_message')}
              >
                <Icon name='left arrow' />
              </Button>
              <Button
                icon
                type='submit'
                style={getConfiguration().button_color}
                floated='right'
                disabled={!isEmpty(this.state.error_card)}
                onClick={this.handleSubmit.bind(this)}
              >
                Confirm Order <Icon name='right arrow' />
              </Button>
            </Form.Field>
          ) : null}
          {this.state.active === 'done' ? (
            <Button
              icon
              style={getConfiguration().button_color}
              onClick={this.nextStep.bind(this, 'payment')}
            >
              <Icon name='left arrow' />
            </Button>
          ) : null}
        </>
      );
    }
  
    render() {
      return (
        <form
          autoComplete='off'
          onSubmit={this.handleSubmit.bind(this)}
          style={{ width: '100%' }}
        >
          <Media at='mobile'>
            {this.renderMobile()}
          </Media>
          <Media greaterThanOrEqual='tablet'>
            {this.renderDesktop()}
          </Media>
        </form>
      );
    }
}
  
export default injectStripe(CheckoutForm);
