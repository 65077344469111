import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Elements, StripeProvider} from 'react-stripe-elements';

import AppMessage from '../common/ui/AppMessage';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as cartStore from '../../store/Cart';
import InjectedCheckoutForm from './CheckoutForm';
import Config from '../../config';
import {Logger} from '../../utils/Logger';

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      saving: -1,
      error: {},
    };
  }

  componentDidMount() {
    try {
      if (window.Stripe) {
        this.setState({ stripe: window.Stripe(Config.api_key) });
      } else if(document){
        const documentStripeJs = document.querySelector('#stripe-js')
        if(documentStripeJs)
          documentStripeJs.addEventListener('load', () => {
            // Create Stripe instance once Stripe.js loads
            this.setState({
              stripe: window.Stripe(Config.api_key),
            });
          });
      }
    } catch (err) {
        Logger.error('Checkout', 'componentDidMount', JSON.stringify(err));
    }
  }

  componentWillMount() {
      this.props.locationConfiguration();
  }

  handleChange(field, e) {
    try {
      let data = this.state.data;
      data[field] = e.target.value;
      this.setState({ data });
    } catch (err) {
        Logger.error('Checkout', 'handleChange', JSON.stringify(err));
    }
  }

  async checkout(data, token, source) {
    try {
      const errors = await this.props.checkout(data, token, source);
      if (errors !== undefined) {
        this.setState({ saving: 2, errors });
      } else {
        this.setState({ saving: 0 });
        this.toggleMessage();
        this.props.history.push('thankyou');
      }
    } catch (err) {
        Logger.error('Checkout', 'checkout', JSON.stringify(err));
    }
  }

  async generateEphemeralKey(data) {
    try {
      this.setState({ saving: -1, errors: {} });
      const errors = await this.props.generateEphemeralKey(data.buyer_email);
      if (errors !== undefined) {
        this.setState({ saving: 2, errors });
      }
      await this.props.updateCartBuyer(data);
    } catch (err) {
        Logger.error('Checkout', 'generateEphemeralKey', JSON.stringify(err));
    }
  }

  toggleMessage() {
    setTimeout(() => this.setState({ saving: -1 }), 3000);
  }

  render() {
    return (
      <CacheBuster>
          {({loading, isLatestVersion, refreshCacheAndReload}) => {
              if (loading) return null;
              if (!loading && !isLatestVersion) {
                  // You can decide how and when you want to force reload
                  console.log('BROWSER IS BEING REFRESHED!');
                  refreshCacheAndReload();
              }
              return (
                <>
                  <AppMessage saving={this.state.saving} errors={this.state.errors} />
                  <StripeProvider stripe={this.state.stripe}>
                    <Elements>
                      <InjectedCheckoutForm
                        updateCartAddress={this.props.updateCartAddress}
                        updateCartPersonalMessage={this.props.updateCartPersonalMessage}
                        order={this.props.cart.order}
                        checkout={this.checkout.bind(this)}
                        login={this.props.auth}
                        generateEphemeralKey={this.generateEphemeralKey.bind(this)}
                      />
                    </Elements>
                  </StripeProvider>
                </>
              );
          }}
      </CacheBuster>
    );
  }
}

export default connect(
  (state) => {
    const { users, login, cart, locations } = state;
    return { users, cart, auth: { ...login }, locations };
  },
  (dispatch) =>
    bindActionCreators(
      {
        ...actionCreators,
        ...loginStore.actionCreators,
        ...cartStore.actionCreators,
        ...locationStore.actionCreators,
      },
      dispatch
    )
)(Checkout);
