import React, { Component } from "react";
import Sections from "./Sections";
import Toggles from "./Toggles";
import { Select } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";

export default class Values extends Component {
  constructor(props) {
    super(props);
    let defaultChoice = props.values.find((value) => {
      return value.default;
    });

    // if (props.defaultCustomized) {
    //     defaultChoice = props.values.find(value => {
    //         let found = props.defaultCustomized.modifiers.find(modifiers => {
    //             let foundOmnivore = null
    //             if (value.omnivore_id.length > 0) {
    //                 foundOmnivore = value.omnivore_id.find(omnivore => {
    //                     return omnivore.omnivore_id === modifiers.modifier
    //                 })
    //             }
    //             return foundOmnivore ? true : false
    //         })
    //         return found ? true : false
    //     })
    // }
    this.state = {
      selectedChoice: defaultChoice,
      isOpen: false,
      customized: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    let defaultChoice = nextProps.values.find((value) => {
      let found =
        nextProps.customized &&
        nextProps.customized.find((customize) => {
          return customize.id === value.id;
        });
      return found ? true : false;
    });
    // if (nextProps.defaultCustomized) {
    //     defaultChoice = nextProps.values.find(value => {
    //         let found = nextProps.defaultCustomized.modifiers.find(modifiers => {
    //             let foundOmnivore = null
    //             if (value.omnivore_id.length > 0) {
    //                 foundOmnivore = value.omnivore_id.find(omnivore => {
    //                     return omnivore.omnivore_id === modifiers.modifier
    //                 })
    //             }
    //             return foundOmnivore ? true : false
    //         })
    //         return found ? true : false
    //     })
    // }
    this.setState({
      selectedChoice: defaultChoice,
    });
  }

  findChoice(option) {
    return this.props.values.find((value) => {
      let compare = value.omnivore_id
        ? value.omnivore_id.length > 0
          ? value.omnivore_id[0].omnivore_id
          : 0
        : 0;
      if (!compare) compare = value.name;
      return compare === option;
    });
  }

  getSelected(option, target) {
    let selectedChoice = this.findChoice(target.value);
    if (selectedChoice) delete selectedChoice.customized;
    this.setState({
      selectedChoice,
    });
    this.props.submit(selectedChoice);
  }

  isSelected() {
    let value = this.state.selectedChoice;
    let omnivore_id =
      value && value.omnivore_id
        ? value.omnivore_id.length > 0
          ? value.omnivore_id[0].omnivore_id
          : 0
        : 0;
    return omnivore_id;
  }

  findToggle() {
    return this.props.values.find((value) => {
      return value.toggle !== undefined;
    });
  }

  submit(section) {
    let customized = this.state.customized.filter((customization) => {
      return (
        customization.name !== section.name && customization.id !== section.id
      );
    });
    customized.push(section);
    let selectedChoice = this.state.selectedChoice;
    selectedChoice.customized = customized;
    this.setState({
      customized,
    });
    this.props.submit(selectedChoice);
  }

  submitToggle(selectedChoice) {
    return (toggle) => {
      selectedChoice.toggled = toggle;
      this.setState({
        selectedChoice,
      });
      this.props.submit(selectedChoice);
    };
  }

  formatMoney(number, decPlaces, decSep, thouSep) {
    let newDecSep = typeof decSep === "undefined" ? "." : decSep;
    let newDecPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
    let newThouSep = typeof thouSep === "undefined" ? "," : thouSep;
    let sign = number < 0 ? "-" : "";
    let i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(newDecPlaces)))
    );
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      "$" +
      sign +
      (j ? i.substr(0, j) + newThouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + newThouSep) +
      (newDecPlaces
        ? newDecSep +
          Math.abs(number - i)
            .toFixed(newDecPlaces)
            .slice(2)
        : "")
    );
  }

  render() {
    let options = this.props.values.map((value, index) => {
      let omnivoreId =
        value && value.omnivore_id
          ? value.omnivore_id.length > 0
            ? value.omnivore_id[0].omnivore_id
            : 0
          : 0;
      return {
        className:
          omnivoreId === 0
            ? this.props.choice.required
              ? "not_valid"
              : ""
            : "",
        key: index,
        value: omnivoreId,
        text:
          typeof value.price !== "undefined" && Number(value.price) > 0
            ? value.name + " (" + this.formatMoney(value.price) + ")"
            : value.name,
      };
    });

    return (
      <>
        {this.findToggle() ||
        (this.state.selectedChoice &&
          this.state.selectedChoice.omnivore_id !== 0 &&
          this.state.selectedChoice.toggle) ? (
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column width={10}>
              <Select
                options={options}
                value={this.isSelected()}
                style={{ width: "100%", height: "30px" }}
                onChange={this.getSelected.bind(this)}
              />
            </Grid.Column>
            <Grid.Column width={6} className="pull-right">
              {this.state.selectedChoice && this.state.selectedChoice.toggle ? (
                <Toggles
                  enabled={true}
                  toggles={this.state.selectedChoice.toggle}
                  customize={this.props.customize}
                  submit={this.submitToggle(this.state.selectedChoice)}
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column
              style={
                this.state.isOpen
                  ? {
                      height: 185,
                      verticalAlign: "top",
                      padding: "0px 1rem 0px 0px",
                    }
                  : { padding: "0px 1rem 0px 0px" }
              }
            >
              <Select
                options={options}
                value={this.isSelected()}
                onOpen={() => this.setState({ isOpen: true })}
                onClose={() => this.setState({ isOpen: false })}
                style={{ width: "100%", height: "30px" }}
                onChange={this.getSelected.bind(this)}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {this.state.selectedChoice &&
        this.state.selectedChoice.omnivore_id !== 0 &&
        this.state.selectedChoice.sections.length > 0 ? (
          <Sections
            submit={this.submit.bind(this)}
            sections={this.state.selectedChoice.sections}
            customize={this.props.customize}
          />
        ) : null}
      </>
    );
  }
}
