import ticketApi from './api/ticket'
import {handleTicketError} from './api/errorHandler'

const ADD_CARD_TO_CART = "ADD_CARD_TO_CART";
const UPDATE_CART = "UPDATE_CART";
const CLEAR_CART = "CLEAR_CART";
const GENERATE_EPHEMERAL_KEY = "GENERATE_EPHEMERAL_KEY"
const CREATE_TICKET_ORDER = "CREATE_TICKET_ORDER"
const UPDATE_TICKET_ORDER = "UPDATE_TICKET_ORDER"
const UPDATE_RECEIPT = "UPDATE_RECEIPT"
const PRE_UPDATE_TICKET_ORDER = "PRE_UPDATE_TICKET_ORDER"
const ADD_TICKET_ORDER = "ADD_TICKET_ORDER"
const UPDATE_TICKET_HISTORY = "UPDATE_TICKET_HISTORY"
const CLEAR_TICKET_ORDER = "CLEAR_TICKET_ORDER"
const FETCH_TICKET_HISTORY = "FETCH_TICKET_HISTORY"
const TICKET_CUSTOMIZED = "TICKET_CUSTOMIZED"
const CLEAR_CUSTOMIZED = "CLEAR_CUSTOMIZED"

const initialState = {
    history: {},
};
export const actionCreators = {
    updateOrder: (item, index) => async (dispatch, getState) => {
        let callback = async function () {
            let ticket_order = getState().ticket.ticket_order
            ticket_order.items[index] = item
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: getState().ticket
            });
            let response = await ticketApi.updateTicketOrderItems(ticket_order.id, {items: ticket_order.items})
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    removeOrder: (item, index) => async (dispatch, getState) => {
        let callback = async function () {
            let ticket_order = getState().ticket.ticket_order
            ticket_order.items.splice(index, 1)
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: getState().ticket
            });
            let response = await ticketApi.updateTicketOrderItems(ticket_order.id, {items: ticket_order.items})
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    addToOrder: (item, index) => async (dispatch, getState) => {
        let callback = async function () {
            let ticket_order = getState().ticket.ticket_order;
            let items = ticket_order.items || [];
            if (ticket_order) {
                delete ticket_order.error;
                sessionStorage.setItem('screen', 'delivery');

                if (index !== null) {
                    items.splice(index, 1, item);
                } else {
                    items.push(JSON.parse(JSON.stringify(item)));
                }
                let response = await ticketApi.updateTicketOrderItems(ticket_order.id, {items});
                dispatch({
                    type: ADD_TICKET_ORDER,
                    payload: response.data
                });

                return response.data;
            }
            return null;
        };

        try {
            return await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    createTicketOrder: () => async (dispatch, getState) => {
        let callback = async function () {
            let response = await ticketApi.createTicketOrder()
            localStorage.setItem('ticket', response.data.ticket_order.id)
            dispatch({
                type: CREATE_TICKET_ORDER,
                payload: response.data
            });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    getTicketOrder: (route = '/') => async (dispatch, getState) => {
        let callback = async function () {
            let ticketOrderId = localStorage.getItem('ticket');
            let type = UPDATE_TICKET_ORDER;
            let payload = null;
            if (ticketOrderId && ticketOrderId !== 'null' && ticketOrderId !== 'undefined') {
                let response = await ticketApi.getTicketOrder(ticketOrderId)
                if (response.data.ticket_order.status === 'Submitted' || response.data.ticket_order.status === 'Queued') {
                    sessionStorage.removeItem('screen')
                    type = CLEAR_TICKET_ORDER;
                } else {
                    payload = response.data;
                }
            } else {
                type = CLEAR_TICKET_ORDER;
            }
            dispatch({
                type,
                payload
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError({...e.response, route}, callback)
        }
    },
    getReceiptInformation: (type, objectId) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await ticketApi.getReceiptInformation(type, objectId)
            dispatch({
                type: UPDATE_RECEIPT,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError({...e.response}, callback)
        }
    },
    getTicketHistory: (ticketId) => async (dispatch, getState) => {
        dispatch({
            type: FETCH_TICKET_HISTORY,
            payload: {}
        });
        let callback = async function () {
            let response = await ticketApi.getTicketOrder(ticketId)
            dispatch({
                type: FETCH_TICKET_HISTORY,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError({...e.response, goBack: true}, callback)
        }
    },
    updateTicketOrderDelivery: (data) => async (dispatch, getState) => {
        let callback = async function () {
            let id = localStorage.getItem('ticket')
            let response = await ticketApi.updateTicketOrderDelivery(id, data)
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    updateTicketHistoryDelivery: (data, ticketId) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await ticketApi.updateTicketOrderDelivery(ticketId, data)
            dispatch({
                type: UPDATE_TICKET_HISTORY,
                payload: response.data
            });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    updateTicketOrderPromo: (data) => async (dispatch, getState) => {
        let callback = async function () {
            let id = localStorage.getItem('ticket')
            let response = await ticketApi.updateTicketOrderPromo(id, data)
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    updateTicketOrderTip: (tip) => async (dispatch, getState) => {
        let callback = async function () {
            let id = localStorage.getItem('ticket')
            let response = await ticketApi.updateTicketOrderTip(id, tip)
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
            return response.data.error
        };
        try {
            return await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    getCartInformation: id => async (dispatch, getState) => {
        let callback = async function () {
            let response = await ticketApi.getCartInformation(id || sessionStorage.getItem('cart'))
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    updateCartAddress: data => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            for (const key in data) {
                order[key] = data[key]
            }
            let response = await ticketApi.updateCart(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    updateCartPersonalMessage: data => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            order.personal_message = data.personal_message
            let response = await ticketApi.updateCart(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    applyCoupon: data => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            order.promo_code = data.promo_code
            let response = await ticketApi.applyCoupon(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    updateCartItems: items => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            order.items = items
            let response = await ticketApi.updateCart(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    updateCartBuyer: (data) => async (dispatch, getState) => {
        let callback = async function () {
            let order = JSON.parse(JSON.stringify(getState().cart.order))
            if (sessionStorage.getItem('email')) {
                order.recipient_email = sessionStorage.getItem('email')
            } else {
                order.buyer_email = data.buyer_email
                order.buyer_first_name = data.buyer_first_name
                order.buyer_last_name = data.buyer_last_name
            }
            let response = await ticketApi.updateCart(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    ordercheckout: (data, token, source) => async (dispatch, getState) => {
        let callback = async function () {
            //IMPORTANT!!!!!
            /*
            Check to see if user is logged in.   If we have a user then we use payment source.   Otherwise we use payment token.   Otherwise you get errors
            with the Payment API server because payment source is assigned to a user in stripe.  All explained in the stripe documentation!
            If you don't do this then you get an error like this in production:
            "The reusable source you provided is consumed because it was previously charged without being attached to a customer or was detached
            from a customer. To charge a reusable source multiple time you must attach it to a customer first."
             */

            let isLoggedIn = !!sessionStorage.getItem('token');


            let order = getState().cart.order
            order.promo_code = data.promo_code
            order.recipient_first_name = data.recipient_first_name
            order.recipient_last_name = data.recipient_last_name
            order.recipient_email = data.recipient_email
            order.recipient_phone = data.recipient_phone
            order.recipient_address = data.recipient_address
            order.recipient_address2 = data.recipient_address2
            order.recipient_city = data.recipient_city
            order.recipient_state = data.recipient_state
            order.recipient_zip = data.recipient_zip
            order.status = 'Submit'
            if (isLoggedIn) {
                order.payment_source = source.id
            } else {
                order.payment_token = token.id
            }

            let response = await ticketApi.checkout(order.id, order)
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            });
            setTimeout(() => {
                dispatch({
                    type: CLEAR_CART,
                    payload: {}
                });
            }, 2000)
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    generateEphemeralKey: (email) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await ticketApi.generateEphemeralKey(email)
            dispatch({
                type: GENERATE_EPHEMERAL_KEY,
                payload: response.data
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    addRewardPaymentTicketOrder: (reward) => async (dispatch, getState) => {
        let callback = async function () {
            let id = localStorage.getItem('ticket')
            let response = await ticketApi.addRewardPaymentTicketOrder(id, reward)
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
        };
        try {
            dispatch({
                type: PRE_UPDATE_TICKET_ORDER,
                payload: null
            });
            await callback();
        } catch (e) {
            if (e.response) {
                return handleTicketError(e.response, callback)
            } else {
                dispatch({
                    type: UPDATE_TICKET_ORDER,
                    payload: { error: {
                        message: 'The server has timeout. Please try again. If the problem persists please call the restaurant. Thank you.'
                    }}
                });
            }
        }
    },
    addGiftCardPaymentTicketOrder: (giftcard) => async (dispatch, getState) => {
        let callback = async function () {
            let id = localStorage.getItem('ticket')
            let response = await ticketApi.addGiftCardPaymentTicketOrder(id, giftcard)

            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
        };
        try {
            dispatch({
                type: PRE_UPDATE_TICKET_ORDER,
                payload: null
            });
            await callback();
        } catch (e) {
            if (e.response) {
                return handleTicketError(e.response, callback)
            } else {
                dispatch({
                    type: UPDATE_TICKET_ORDER,
                    payload: { error: {
                        message: 'The server has timeout. Please try again. If the problem persists please call the restaurant. Thank you.'
                    }}
                });
            }
        }
    },
    addPaymentTicketOrder: (token, source) => async (dispatch, getState) => {
        let callback = async function () {
            let ticketOrder = getState().ticket
            let id = localStorage.getItem('ticket')
            let tip = ticketOrder.ticket_order.tip
            let amount = (ticketOrder.totals.due.replace(/[^0-9.]/g, '') * 1)
            let ephemeralKey = getState().cart.ephemeral_key
            let response = await ticketApi.addPaymentTicketOrder(id, tip, amount, token, source.id, ephemeralKey.stripe_customer_id)
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
        };
        try {
            dispatch({
                type: PRE_UPDATE_TICKET_ORDER,
                payload: null
            });
            await callback();
        } catch (e) {
            if (e.response) {
                return handleTicketError(e.response, callback)
            } else {
                dispatch({
                    type: UPDATE_TICKET_ORDER,
                    payload: { error: {
                        message: 'The server has timeout. Please try again. If the problem persists please call the restaurant. Thank you.'
                    }}
                });
            }
        }
    },
    submitTicketOrder: (redirect) => async (dispatch, getState) => {
        let callback = async function () {
            let id = localStorage.getItem('ticket')
            let response = await ticketApi.submitTicketOrder(id)
            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
            if (response.data.ticket_order.status === 'Submitted' || response.data.ticket_order.status === 'Queued') {
                sessionStorage.removeItem('screen')
                redirect()
            }
        };
        try {
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    addGiftCardImportAndRedeemPaymentTicketOrder: (giftcard) => async (dispatch, getState) => {
        let callback = async function () {
            let id = localStorage.getItem('ticket')
            let response = await ticketApi.addGiftCardImportAndRedeemPaymentTicketOrder(id, giftcard)

            dispatch({
                type: UPDATE_TICKET_ORDER,
                payload: response.data
            });
        };
        try {
            dispatch({
                type: PRE_UPDATE_TICKET_ORDER,
                payload: null
            });
            await callback();
        } catch (e) {
            return handleTicketError(e.response, callback)
        }
    },
    setCustomized: (customized, callback) => async (dispatch, getState) => {
        dispatch({
            type: TICKET_CUSTOMIZED,
            payload: customized
        });
        callback()
    },
    clearCustomized: () => async (dispatch, getState) => {
        dispatch({
            type: CLEAR_CUSTOMIZED,
            payload: {}
        });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ADD_CARD_TO_CART:
            return {...state, items: action.payload};
        case UPDATE_CART:
        case CREATE_TICKET_ORDER:
            return {...state, ...action.payload};
        case ADD_TICKET_ORDER:
        case UPDATE_TICKET_ORDER: {
            let {error, ...rest} = state
            return {...rest, ...action.payload};
        }
        case PRE_UPDATE_TICKET_ORDER:
            return {...state, success_message: null, error: null};
        case TICKET_CUSTOMIZED:
            return {...state, customized: action.payload};
        case CLEAR_CUSTOMIZED: {
            delete state.customized
            return {...state};
        }
        case GENERATE_EPHEMERAL_KEY:
            return {...state, ephemeral_key: action.payload};
        case FETCH_TICKET_HISTORY:
            return {...state, history: action.payload};
        case UPDATE_TICKET_HISTORY: {
            let {error, ...rest} = state.history
            return {...state, history: {...rest, ...action.payload}};
        }
        case UPDATE_RECEIPT: {
            return {...state, receipt: action.payload};
        }
        case CLEAR_TICKET_ORDER:
        case CLEAR_CART:
            return { history: {} };
        default:
            return state;
    }
};
