import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Grid, Button, Form, Header, Table, Dimmer, Loader} from 'semantic-ui-react';

import {Media} from '../../AppMedia';
import {actionCreators} from '../../store/Users';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import {getConfiguration} from '../../store/api/apiClient';
import {Logger} from '../../utils/Logger';
import moment from 'moment'

class AddTime extends Component {
    constructor(props) {
        console.log(props.ticket.ticket_settings.available_order_ahead_time_slots_for_the_future)
        super(props);
        this.state = {
            stripe: null,
            saving: -1,
            error: {},
            data: {},
            isOpen: false,
            settings: props.ticket.ticket_settings,
            ready_date: props.ticket.ticket_order.ready_date || (props.ticket.ticket_settings.available_order_ahead_time_slots_for_the_future.length > 0 && props.ticket.ticket_settings.available_order_ahead_time_slots_for_the_future[0].date),
            ready_time: props.ticket.ticket_order.ready_time,
        };
    }

    componentWillReceiveProps(nextProps) {
        try {
            let error = nextProps.ticket.error;
            const {settings} = nextProps.ticket.ticket_settings;

            this.setState({
                ready_date: nextProps.ticket.ticket_order.ready_date || settings.available_order_ahead_time_slots_for_the_future[0].date,
                ready_time: nextProps.ticket.ticket_order.ready_time,
                settings: nextProps.ticket.ticket_settings,
                error,
            });
        } catch (err) {
            Logger.error('AddTime', 'componentWillReceiveProps', JSON.stringify(err));
        }
    }

    handleChange(field, e, target) {
        let state = this.state
        state[field] = target.value
        this.setState(state);
    }

    async updateTicketOrderTime(e) {
        e.preventDefault();
        try {
            this.setState({loading: true});
            const data = {
                ready_date_v2: this.state.ready_date,
                ready_time_v2: this.state.ready_time,
            };
            const result = await this.props.updateTicketOrderDelivery(data);
            this.setState({loading: false})
            if (!result.error) {
                this.props.nextStep('information');
            }
        } catch (err) {
            Logger.error('AddTime', 'updateTicketOrderTime', JSON.stringify(err));
        }
    }

    renderDesktop(params) {
        const {settings} = params;
        let dateSlot = settings.available_order_ahead_time_slots_for_the_future.find(date_slots => date_slots.date === this.state.ready_date)
            || {available_time_slots: []}

        let disabled = false
        if (settings.show_order_ahead_option) {
            disabled = settings.available_order_ahead_time_slots_for_the_future.length > 0 ? (!this.state.ready_date || !this.state.ready_time) : false
        }
        if (settings.available_order_ahead_time_slots_for_the_future.length === 0) {
            disabled = false
        }
        return (
            <Grid textAlign={'center'}>
                <Grid.Column width={11}>
                    <Table basic={'very'}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={3}>
                                    <span style={{fontWeight: 600}}>
                                        Please select a time you would like to receive your order
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan={3}
                                            style={this.state.isOpen ? {height: 185, verticalAlign: 'top'} : {}}>
                                    {settings.show_order_ahead_option ? <>
                                        <Form.Select
                                            fluid
                                            options={settings.available_order_ahead_time_slots_for_the_future.map(
                                                (slot, index) => {
                                                    return {
                                                        key: index,
                                                        text: slot.date_to_show,
                                                        value: slot.date,
                                                    };
                                                }
                                            )}
                                            onOpen={() => this.setState({isOpen: true})}
                                            onClose={() => this.setState({isOpen: false})}
                                            value={this.state.ready_date}
                                            onChange={this.handleChange.bind(this, 'ready_date')}
                                            placeholder='Select Date'
                                        />
                                        <Form.Select
                                            fluid
                                            options={dateSlot.available_time_slots.map(
                                                (slot, index) => {
                                                    if (slot.enable)
                                                        return {
                                                            key: index,
                                                            text: slot.display,
                                                            value: slot.value,
                                                        };
                                                }
                                            )}
                                            onOpen={() => this.setState({isOpen: true})}
                                            onClose={() => this.setState({isOpen: false})}
                                            value={this.state.ready_time}
                                            onChange={this.handleChange.bind(this, 'ready_time')}
                                            placeholder='Select Time'
                                        />
                                    </> : null}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                        <Grid doubling columns={2}>
                            <Grid.Column width={6}>
                                <Button
                                    fluid
                                    circular
                                    size='large'
                                    onClick={() => this.props.handleGoBack('delivery')}
                                >
                                    BACK
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Button
                                    fluid
                                    size='large'
                                    circular
                                    disabled={
                                        disabled
                                    }
                                    onClick={this.updateTicketOrderTime.bind(this)}
                                    style={getConfiguration().button_color}
                                >
                                    CONTINUE
                                </Button>
                            </Grid.Column>
                        </Grid>
                    ) : null}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(params) {
        const {settings} = params;
        let dateSlot = settings.available_order_ahead_time_slots_for_the_future.find(date_slots => date_slots.date === this.state.ready_date)
            || {available_time_slots: []}

        let disabled = false
        if (settings.show_order_ahead_option) {
            disabled = settings.available_order_ahead_time_slots_for_the_future.length > 0 ? (!this.state.ready_date || !this.state.ready_time) : false
        }
        if (settings.available_order_ahead_time_slots_for_the_future.length === 0) {
            disabled = false
        }
        return (
            <>
                <Form.Field>
                    <Header as='h3' textAlign='center'>
                        Please select a time you would like to receive your order
                    </Header>
                </Form.Field>
                {settings.show_order_ahead_option ? <>
                    <Form.Select
                        fluid
                        options={settings.available_order_ahead_time_slots_for_the_future.map((slot, index) => {
                            return {
                                key: index,
                                text: slot.date_to_show,
                                value: slot.date,
                            };
                        })}
                        onOpen={() => this.setState({isOpen: true})}
                        onClose={() => this.setState({isOpen: false})}
                        value={this.state.ready_date}
                        onChange={this.handleChange.bind(this, 'ready_date')}
                        placeholder='Select Date'
                    />
                    <Form.Select
                        fluid
                        options={dateSlot.available_time_slots.map(
                            (slot, index) => {
                                if (slot.enable)
                                    return {
                                        key: index,
                                        text: slot.display,
                                        value: slot.value,
                                    };
                            }
                        )}
                        onOpen={() => this.setState({isOpen: true})}
                        onClose={() => this.setState({isOpen: false})}
                        value={this.state.ready_time}
                        onChange={this.handleChange.bind(this, 'ready_time')}
                        placeholder='Select Time'
                    />
                </> : null}
                {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                    <>
                        <Form.Button
                            fluid
                            circular
                            disabled={
                                disabled
                            }
                            onClick={this.updateTicketOrderTime.bind(this)}
                            style={getConfiguration().button_color}
                        >
                            CONTINUE
                        </Form.Button>
                        <Form.Button
                            fluid
                            circular
                            onClick={() => this.props.handleGoBack('delivery')}
                        >
                            BACK
                        </Form.Button>
                    </>
                ) : null}
            </>
        );
    }

    render() {
        const {settings} = this.state;
        return this.props.ticket.ticket_order ? (
            <>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='small' inline='centered'>
                        Loading
                    </Loader>
                </Dimmer>
                <Media at='mobile'>
                    {this.renderMobile({settings})}
                </Media>
                <Media greaterThanOrEqual='tablet'>
                    {this.renderDesktop({settings})}
                </Media>
            </>
        ) : null;
    }
}

export default connect(
    (state) => {
        const {users, login, ticket, locations} = state;
        return {users, ticket, auth: {...login}, locations};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...loginStore.actionCreators,
                ...ticketStore.actionCreators,
                ...locationStore.actionCreators,
            },
            dispatch
        )
)(AddTime);
