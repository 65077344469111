import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import {
    Button,
    Card,
    Dimmer,
    Dropdown,
    Feed,
    Form,
    Grid,
    Header,
    Icon,
    Item,
    Loader,
    Message,
    Placeholder,
    Segment,
} from 'semantic-ui-react';
import {DateInput} from 'semantic-ui-calendar-react';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-mobile-datepicker';
import {format, parse} from 'date-fns';
import {isEmpty, range} from 'lodash';

import {Media} from '../../../AppMedia';
import states from '../../../assets/states.json';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';
import {getConfiguration} from '../../../store/api/apiClient';
import {Logger} from '../../../utils/Logger';

const monthMap = {
	'1': 'Jan',
	'2': 'Feb',
	'3': 'Mar',
	'4': 'Apr',
	'5': 'May',
	'6': 'Jun',
	'7': 'Jul',
	'8': 'Aug',
	'9': 'Sep',
	'10': 'Oct',
	'11': 'Nov',
	'12': 'Dec',
};

const dateConfig = {
	'month': {
		format: value => monthMap[value.getMonth() + 1],
		caption: 'Mon',
		step: 1,
	},
	'date': {
		format: 'DD',
		caption: 'Day',
		step: 1,
	},
	'year': {
		format: 'YYYY',
		caption: 'Year',
		step: 1,
	},
};

class MyAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1,
            loading: true,
            showMessage: false,
            hasError: false,
            editMode: false,
            passwordMode: false,
            showDatePicker: false,
            data: {},
            password: {},
            error_info: {},
            error_password: {
                current_password: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                new_password: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                new_password_confirmation: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
            },
            intervalId: setInterval(this.scrollStep.bind(this), 16),
        };
    }

    componentDidMount() {
        this.handleInit();
    }

    componentWillReceiveProps(nextProps) {
        try {
            let data = nextProps.users.user
            let error = nextProps.users.error
            let hasError = nextProps.users.hasError
            let successMessage = nextProps.users.success_message

            this.setState({
                data,
                error,
                successMessage,
                hasError
            })
        } catch (err) {
            Logger.error('MyAccount', 'componentWillReceiveProps', JSON.stringify(err));
        }
    }d

    handleInit = async () => {
        await this.props.getUser();
        await this.props.getUserTransactions();
        this.setState({ loading: false });
    }

    handleChange = (field, value) => {
        try {
            let data = {...this.state.data};
            let error_info = {...this.state.error_info};

            if (value || field === 'dob') {
                delete error_info[field];
            } else {
                error_info[field] = {
                    content: 'Please enter a valid info',
                    pointing: 'below',
                };
            }

            data[field] = value;
            this.setState({ data, error_info, showDatePicker: false });
        } catch (err) {
            Logger.error('MyAccount', 'handleChange', JSON.stringify(err));
        }
    }

    handlePasswordChange = (field, value) => {
        try {
            let password = {...this.state.password};
            let error_password = {...this.state.error_password};

            if (value) {
                delete error_password[field];
            } else {
                error_password[field] = {
                    content: 'Please enter a valid info',
                    pointing: 'below',
                };
            }

            password[field] = value;
            this.setState({ password, error_password });
        } catch (err) {
            Logger.error('MyAccount', 'handlePasswordChange', JSON.stringify(err));
        }
    }

    handlePlaceSelect = (data) => {
        geocodeByAddress(data.description)
            .then((results) => {
                this.handleGeocode(results[0], data);
            })
            .catch(err => Logger.error('MyAccount', 'handlePlaceSelect', JSON.stringify(err)));
    };

    handleGeocode(addressResult, addr) {
        let data = {...this.state.data};
        let error_info = {...this.state.error_info};

        if (addr.types[0] === 'street_address') {
            data.address = this.getAddressType(addressResult, ['street_number', 'route']);
            data.city = this.getAddressType(addressResult, ['locality']) || this.getAddressType(addressResult, ['neighborhood']);
        } else {
            data.address = `${addr.terms[0].value} ${this.getAddressType(addressResult, ['route'])}`;
            data.city = addr.terms[2].value;
        }
        data.state = this.getAddressType(addressResult, ['administrative_area_level_1']);
        data.zip = this.getAddressType(addressResult, ['postal_code']);

        if (data.address) delete error_info.address;
        if (data.city) delete error_info.city;
        if (data.state) delete error_info.state;
        if (data.zip) delete error_info.zip;

        this.setState({ data, error_info });
    }

    getAddressType(addressResult, types) {
        let geocodeAddress = [];
        types.forEach((type) => {
            addressResult.address_components.forEach((geocode) => {
                if (geocode.types.indexOf(type) !== -1) {
                    return geocodeAddress.push(geocode.short_name);
                }
            });
        });
        return geocodeAddress.join(' ');
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    handleUpdateUserInfo = async () => {
        try {
            const params = {
                first_name: this.state.data.first_name,
                last_name: this.state.data.last_name,
                dob: this.state.data.dob,
                phone: this.state.data.phone,
                address: this.state.data.address,
                address2: this.state.data.address2 || '',
                city: this.state.data.city,
                state: this.state.data.state,
                zip: this.state.data.zip,
            }
            clearInterval(this.state.intervalId);
            let intervalId = setInterval(this.scrollStep.bind(this), 16);
            this.setState({ ...this.state, intervalId, loading: true, showMessage: false });
            await this.props.updateUserInfo(params);
            this.setState({ loading: false, editMode: this.state.hasError, showMessage: true });
        } catch (err) {
            Logger.error('MyAccount', 'handleUpdateUserInfo', JSON.stringify(err));
        }
    }

    handleUpdatePassword = async () => {
        try {
            clearInterval(this.state.intervalId);
            let intervalId = setInterval(this.scrollStep.bind(this), 16);
            this.setState({ ...this.state, intervalId, loading: true, showMessage: false });
            await this.props.resetUserPassword(this.state.password);
            this.setState({ loading: false, passwordMode: this.state.hasError, showMessage: true });
        } catch (err) {
            Logger.error('MyAccount', 'handleUpdatePassword', JSON.stringify(err));
        }
    }

    renderContent = () => {
        const { transactions } = this.props.users;
        const { error, showMessage, successMessage } = this.state;

        return (
            <Segment>
                {successMessage && showMessage ? (
                    <Message
                        icon
                        success
                        visible={showMessage}
                        header={successMessage.header.toUpperCase()}
                        content={successMessage.message}
                    />
                ) : error && showMessage ? (
                    <Message
                        icon
                        error
                        visible={showMessage}
                        header={'Error'}
                        content={error.message}
                    />
                ) : null}
                <Header textAlign='center' style={{ margin: '5px 0 16px 0' }}>
                    My Transactions
                </Header>
                {transactions ? (
                    <Item.Group divided>
                        {transactions.map((transaction, index) => {
                            return (
                                <Item key={`transaction_${index}`}>
                                    <Item.Content>
                                        <Item.Header style={{ width: '100%' }}>
                                            <div style={{ float: 'right' }}>
                                                {transaction.pretty_amount}
                                            </div>
                                            {transaction.description}
                                        </Item.Header>
                                        <Item.Meta>
                                            <div style={{ float: 'right' }}>
                                                {transaction.card_id && (
                                                    <Link
                                                        to={`/mycards/${transaction.card_id}/view`}
                                                        style={{
                                                            ...getConfiguration().label_color,
                                                        }}
                                                    >
                                                        VIEW CARD
                                                    </Link>
                                                )}
                                                {transaction.card_id && transaction.ticket_order_id && ' | '}
                                                {transaction.ticket_order_id && (
                                                    <Link
                                                        to={`/ticket/${transaction.ticket_order_id}`}
                                                        style={{
                                                            marginLeft: 5,
                                                            ...getConfiguration().label_color,
                                                        }}
                                                    >
                                                        VIEW ORDER
                                                    </Link>
                                                )}
                                            </div>
                                            {transaction.pretty_date}
                                        </Item.Meta>
                                    </Item.Content>
                                </Item>
                            );
                        })}
                    </Item.Group>
                ) : (
                    range(5).map((i) => {
                        return (
                            <Placeholder key={`transaction_${i}`}>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder>
                        );
                    })
                )}
            </Segment>
        );
    };

    renderLinks = () => {
        const { data, password, error_info, error_password, editMode, passwordMode } = this.state;

        const labelStyle = {...getConfiguration().label_color};
        const textStyle = {float: 'right', color: 'black', fontSize: 13, textAlign: 'right'};

        return (
            <Card fluid>
                {data.email ? (
                    <Card.Content>
                        <Header
                            textAlign='center'
                            as='h4'
                            style={{
                                    ...getConfiguration().label_color,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                            {`Welcome, ${data.display_name}!`}
                        </Header>
                        {passwordMode ? (
                            <Form>
                                <Form.Field>
                                    <p>Enter the following information to update your password.</p>
                                </Form.Field>
                                <Form.Field
                                    error={
                                        password.current_password
                                            ? error_password.current_password
                                            ? true
                                            : false
                                            : null
                                    }
                                >
                                    <label style={labelStyle}>CURRENT PASSWORD</label>
                                    <input
                                        autoComplete='new'
                                        type='password'
                                        value={password.current_password || ''}
                                        placeholder='Current Password'
                                        onChange={(e) => this.handlePasswordChange(
                                            'current_password',
                                            e.target.value
                                        )}
                                    />
                                </Form.Field>
                                <Form.Field
                                    error={
                                        password.new_password
                                            ? error_password.new_password
                                            ? true
                                            : false
                                            : null
                                    }
                                >
                                    <label style={labelStyle}>NEW PASSWORD</label>
                                    <input
                                        autoComplete='new'
                                        type='password'
                                        value={password.new_password || ''}
                                        placeholder='New Password'
                                        onChange={(e) => this.handlePasswordChange(
                                            'new_password',
                                            e.target.value
                                        )}
                                    />
                                </Form.Field>
                                <Form.Field
                                    error={
                                        password.new_password_confirmation
                                            ? error_password.new_password_confirmation
                                            ? true
                                            : false
                                            : null
                                    }
                                >
                                    <label style={labelStyle}>CONFIRM PASSWORD</label>
                                    <input
                                        autoComplete='new'
                                        type='password'
                                        value={password.new_password_confirmation || ''}
                                        placeholder='Confirm Password'
                                        onChange={(e) => this.handlePasswordChange(
                                            'new_password_confirmation',
                                            e.target.value
                                        )}
                                    />
                                </Form.Field>
                                <Form.Button
                                    fluid
                                    circular
                                    disabled={!isEmpty(error_password)}
                                    style={getConfiguration().button_color}
                                    onClick={() => this.handleUpdatePassword()}
                                >
                                    UPDATE PASSWORD
                                </Form.Button>
                                <Form.Field
                                    style={{ textAlign: 'center' }}
                                    onClick={() => this.setState({ passwordMode: false })}
                                >
                                    <a>
                                        <label style={labelStyle}>CANCEL</label>
                                    </a>
                                </Form.Field>
                            </Form>
                        ) : (
                            <>
                                <Feed>
                                    <Feed.Event>
                                        <Feed.Content>
                                            <label style={{ float: 'right' }}>
                                                <Media at='mobile'>
                                                    <Button
                                                        icon
                                                        circular
                                                        size={'mini'}
                                                        onClick={() => this.setState({ editMode: !editMode })}
                                                        style={getConfiguration().button_color}
                                                    >
                                                        <Icon name={editMode ? 'times' : 'pencil'}/>
                                                    </Button>
                                                </Media>
                                                <Media greaterThanOrEqual='tablet'>
                                                    <a
                                                        onClick={() => this.setState({ editMode: !editMode })}
                                                        style={labelStyle}
                                                    >
                                                        {editMode ? 'CANCEL' : 'EDIT'}
                                                        &nbsp;&nbsp;
                                                        <Icon name={editMode ? 'times' : 'pencil'} />
                                                    </a>
                                                </Media>
                                            </label>
                                            Account Details
                                        </Feed.Content>
                                    </Feed.Event>
                                </Feed>
                                {editMode ? (
                                    <Form>
                                        <Form.Field
                                            error={
                                                data.first_name
                                                    ? error_info.first_name
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label style={labelStyle}>FIRST NAME</label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.first_name || ''}
                                                placeholder='First Name'
                                                onChange={(e) => this.handleChange(
                                                    'first_name',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={
                                                data.last_name
                                                    ? error_info.last_name
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label style={labelStyle}>LAST NAME</label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.last_name || ''}
                                                placeholder='Last Name'
                                                onChange={(e) => this.handleChange(
                                                    'last_name',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label style={labelStyle}>EMAIL</label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.email || ''}
                                                placeholder='Email address'
                                                disabled
                                                style={{ background: 'lightgray' }}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={
                                                data.phone
                                                    ? error_info.phone
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label style={labelStyle}>PHONE NUMBER</label>
                                            <NumberFormat
                                                value={data.phone || ''}
                                                placeholder='Phone Number'
                                                inputMode='decimal'
                                                pattern='[0-9],*'
                                                format='(###) ###-####'
                                                mask='_'
                                                onInvalid={(e) => e.preventDefault()}
                                                onValueChange={(values) =>
                                                    this.handleChange('phone', values.value)
                                                }
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={
                                                data.address !== null
                                                    ? error_info.address
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label style={labelStyle}>ADDRESS</label>
                                            <GooglePlacesAutocomplete
                                                autoComplete="new"
                                                debounce={600}
                                                onSelect={this.handlePlaceSelect}
                                                initialValue={data.address || ''}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label style={labelStyle}>ADDRESS 2</label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.address2 || ''}
                                                placeholder='Address 2'
                                                onChange={(e) => this.handleChange(
                                                    'address2',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={
                                                data.city !== null
                                                    ? error_info.city
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label style={labelStyle}>CITY</label>
                                            <input
                                                autoComplete='new'
                                                type={'text'}
                                                value={data.city || ''}
                                                placeholder='City'
                                                onChange={(e) => this.handleChange(
                                                    'city',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Group widths='equal'>
                                            <Form.Field
                                                error={
                                                    data.state !== null
                                                        ? error_info.state
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label style={labelStyle}>STATE</label>
                                                <Dropdown
                                                    autoComplete='new'
                                                    placeholder='State'
                                                    value={data.state}
                                                    selection
                                                    onChange={(e, data) => this.handleChange(
                                                        'state',
                                                        data.value
                                                    )}
                                                    options={states.map((state, index) => {
                                                        return {
                                                            key: index,
                                                            value: state.abbreviation,
                                                            text: state.name,
                                                        };
                                                    })}
                                                />
                                            </Form.Field>
                                            <Form.Field
                                                error={
                                                    data.zip !== null
                                                        ? error_info.zip
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label style={labelStyle}>ZIP CODE</label>
                                                <NumberFormat
                                                    value={data.zip || ''}
                                                    placeholder='Zip code'
                                                    inputMode='decimal'
                                                    pattern='[0-9],*'
                                                    format='#####'
                                                    onInvalid={(e) => e.preventDefault()}
                                                    onValueChange={(values) =>
                                                        this.handleChange('zip', values.value)
                                                    }
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Field
                                            error={
                                                data.dob !== null
                                                    ? error_info.dob
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label style={labelStyle}>BIRTHDAY</label>
                                            <Media lessThan='computer'>
                                                <div
                                                    className='form-field-readonly'
                                                    style={data.dob ? {color: 'rgba(0,0,0,.7)'} : {color: 'rgba(0,0,0,.25)'}}
                                                    onClick={() => this.setState({ showDatePicker: true })}
                                                >
                                                    {data.dob || 'Date of Birth'}
                                                </div>
                                                <DatePicker
                                                    value={parse(data.dob || '01/01/1970', 'MM/dd/yyyy', new Date())}
                                                    isOpen={this.state.showDatePicker}
                                                    dateConfig={dateConfig}
                                                    min={new Date(1900, 0, 1)}
                                                    headerFormat='MM/DD/YYYY'
                                                    confirmText='Done'
                                                    cancelText='Cancel'
                                                    onSelect={(date) => this.handleChange(
                                                        'dob',
                                                        format(date, 'MM/dd/yyyy')
                                                    )}
                                                    onCancel={() => this.setState({ showDatePicker: false })} />
                                            </Media>
                                            <Media greaterThanOrEqual='computer'>
                                                <DateInput
                                                    color='teal'
                                                    fluid
                                                    name='date'
                                                    hideMobileKeyboard={true}
                                                    icon={false}
                                                    placeholder='Date of Birth'
                                                    value={data.dob || ''}
                                                    dateFormat={'MM/DD/YYYY'}
                                                    closable={true}
                                                    animation='none'
                                                    pickerStyle={{ width: 200, height: 200 }}
                                                    onChange={(e, obj) => {
                                                        e.persist();
                                                        this.handleChange('dob', obj.value);
                                                    }}
                                                />
                                            </Media>
                                        </Form.Field>
                                        <Form.Button
                                            fluid
                                            circular
                                            disabled={!isEmpty(error_info)}
                                            style={getConfiguration().button2_color}
                                            onClick={() => this.handleUpdateUserInfo()}
                                        >
                                            SAVE INFORMATION
                                        </Form.Button>
                                    </Form>
                                ) : (
                                    <Feed>
                                        <Feed.Event>
                                            <Feed.Content style={labelStyle}>
                                                <div style={textStyle}>
                                                    {data.display_name}
                                                </div>
                                                NAME
                                            </Feed.Content>
                                        </Feed.Event>
                                        <Feed.Event>
                                            <Feed.Content style={labelStyle}>
                                                <div style={textStyle}>
                                                    {data.email}
                                                </div>
                                                EMAIL
                                            </Feed.Content>
                                        </Feed.Event>
                                        {data.hasPassword && (
                                            <Feed.Event>
                                                <Feed.Content style={labelStyle}>
                                                    <div style={textStyle}>
                                                        ****************
                                                    </div>
                                                    PASSWORD
                                                </Feed.Content>
                                            </Feed.Event>
                                        )}
                                        <Feed.Event>
                                            <Feed.Content style={labelStyle}>
                                                <div style={textStyle}>
                                                    {data.phone}
                                                </div>
                                                PHONE
                                            </Feed.Content>
                                        </Feed.Event>
                                        <Feed.Event>
                                            <Feed.Content style={labelStyle}>
                                                <div style={textStyle}>
                                                    <p>{data.address}</p>
                                                    {data.address2 && <p>{data.address2}</p>}
                                                    <p>{data.city}, {data.state}</p>
                                                    <p>{data.zip}</p>
                                                </div>
                                                ADDRESS
                                            </Feed.Content>
                                        </Feed.Event>
                                        <Feed.Event>
                                            <Feed.Content style={labelStyle}>
                                                <div style={textStyle}>
                                                    {data.dob}
                                                </div>
                                                BIRTHDAY
                                            </Feed.Content>
                                        </Feed.Event>
                                        <Feed.Event>
                                            <Feed.Content style={labelStyle}>
                                                <div style={textStyle}>
                                                    {data.account_created_on}
                                                </div>
                                                ACCOUNT CREATED ON
                                            </Feed.Content>
                                        </Feed.Event>
                                        <Feed.Event>
                                            <Feed.Content style={labelStyle}>
                                                <div style={textStyle}>
                                                    {data.linked_accounts.map((acc, index) => {
                                                        return (
                                                            <p key={`acc_${index}`}>{acc}</p>
                                                        )
                                                    })}
                                                </div>
                                                LINKED ACCOUNT
                                            </Feed.Content>
                                        </Feed.Event>
                                    </Feed>
                                )}
                                <Feed>
                                    {data.hasPassword && (
                                        <Feed.Event>
                                            <Feed.Content>
                                                <Button
                                                    fluid
                                                    circular
                                                    style={getConfiguration().button2_color}
                                                    onClick={() => this.setState({ passwordMode: true })}
                                                >
                                                    CHANGE PASSWORD
                                                </Button>
                                            </Feed.Content>
                                        </Feed.Event>
                                    )}
                                    <Feed.Event>
                                        <Feed.Content>
                                            <Button
                                                as={Link}
                                                to={'/login'}
                                                fluid
                                                circular
                                                style={getConfiguration().button_color}
                                            >
                                                SIGN OUT
                                            </Button>
                                        </Feed.Content>
                                    </Feed.Event>
                                </Feed>
                            </>
                        )}
                    </Card.Content>
                ) : (
                    <Card.Content>
                        <Placeholder>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder>
                    </Card.Content>
                )}
            </Card>
        );
    };

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <Dimmer active={this.state.loading} inverted>
                                <Loader size='small' inline='centered'>
                                    Loading
                                </Loader>
                            </Dimmer>
                            <Media lessThan='computer'>
                                <Grid padded>
                                    <Grid.Column width={16}>
                                        {this.renderLinks()}
                                    </Grid.Column>
                                    <Grid.Column width={16}>{this.renderContent()}</Grid.Column>
                                </Grid>
                            </Media>
                            <Media greaterThanOrEqual='computer'>
                                <Grid padded>
                                    <Grid.Column width={10}>{this.renderContent()}</Grid.Column>
                                    <Grid.Column width={6}>{this.renderLinks()}</Grid.Column>
                                </Grid>
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, login, locations } = state;
        return { users, login, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
            },
            dispatch
        )
)(withRouter(MyAccount));
