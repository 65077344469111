export function toFormatCurrency(value) {
  if (!value) value = 0
  let valueNum = parseInt(Math.abs(value))
  let formattedValue = Number(valueNum.toFixed(2)).toLocaleString()

  return value >= 0 ? `$${formattedValue}` : `-$${formattedValue}`
}

export function toFormatCurrencyWithCent(value) {
  if (!value) value = 0
  if(isNaN(value) && value.includes("$") !=-1){
    return value
  }
  let valueNum = parseFloat(Math.abs(value))
  let formattedValue = valueNum.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return value >= 0 ? `$${formattedValue}` : `-$${formattedValue}`
}

export function toFormatNumber(value) {
  if (!value) value = 0
  let valueNum = parseInt(value)
  let formattedValue = Number(valueNum.toFixed(2)).toLocaleString()

  return formattedValue
}

export function toFormatPercent(value) {
  if (!value) value = 0
  let valueNum = parseFloat(value)
  let formattedValue = valueNum.toFixed(2) + '%'

  return formattedValue
}
