import React from 'react';
import { Image } from 'semantic-ui-react';
import { getConfiguration } from '../../../../store/api/apiClient';
import leaf from '../../../../assets/leaf.png';

export default (props) => {
    const { vegan, gluten_free, style } = props;
    const { backgroundColor } = getConfiguration().button_color;

    return (
        <div style={style}>
            {vegan && (
                <div data-tooltip='Vegan'>
                    <Image
                        src={leaf}
                        size='mini'
                        style={{ ...Style.vegan, backgroundColor }}
                    />
                </div>
            )}
            {gluten_free && (
                <div
                    style={Style.gluten_free}
                    data-tooltip='Gluten Free'
                >
                    GF
                </div>
            )}
        </div>
    );
};

const Style = {
    vegan: {
        width: 24,
        height: 24,
        padding: 4,
        borderRadius: '50%',
    },
    gluten_free: {
        background: 'white',
        border: '1px solid gray',
        borderRadius: '50%',
        color: 'black',
        fontSize: 12,
        fontWeight: 600,
        height: 24,
        marginLeft: 5,
        padding: 2,
        width: 24,
    },
};
