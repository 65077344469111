import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  Button,
  Card,
  Header,
  Feed,
  Sticky,
  Ref,
} from "semantic-ui-react";

import { Media } from "../../../AppMedia";
import CacheBuster from "../../../CacheBuster";
import { actionCreators } from "../../../store/Users";
import { getConfiguration } from "../../../store/api/apiClient";

class Catering extends Component {
  constructor(props) {
    super(props);

    this.contextRef = React.createRef();
    this.items = [];
  }

  handleMenu = (index) => {
    this.items[index].scrollIntoView();
    // this.contentRef.current.scrollTo(0, this.items[index].offsetTop - 120);
  };

  renderContent = () => {
    const { read_only_non_pos_page } = this.props.locations.configurations;

    return (
      <Card.Group>
        <Card fluid>
          <Card.Content>
            <Header textAlign="center" as="h3">
              {read_only_non_pos_page.menu_information.header}
            </Header>
            <Card.Description
              dangerouslySetInnerHTML={{
                __html: read_only_non_pos_page.menu_information.details,
              }}
            />
          </Card.Content>
        </Card>
        {read_only_non_pos_page.all_menus.map((menu, index) => {
          return (
            <div
              ref={(ref) => (this.items[index] = ref)}
              key={`item_${index}`}
              style={{ width: "100%", padding: "0 8px 15px" }}
            >
              <Card fluid>
                <Card.Content>
                  <Header textAlign="center" as="h3">
                    {menu.header}
                  </Header>
                  <Card.Description
                    dangerouslySetInnerHTML={{
                      __html: menu.details,
                    }}
                  />
                </Card.Content>
              </Card>
            </div>
          );
        })}
      </Card.Group>
    );
  };

  renderLinks = () => {
    const { read_only_non_pos_page } = this.props.locations.configurations;
    return (
      <Card.Group>
        <Card fluid>
          <Card.Content>
            <Header textAlign="center" as="h4">
              {read_only_non_pos_page.menu_links.header}
            </Header>
            <Card.Description />
            <Feed>
              {read_only_non_pos_page.all_menus.map((menu, index) => {
                return (
                  <Feed.Event key={`menu_${index}`}>
                    <Feed.Content>
                      <Button
                        fluid
                        style={{
                          ...getConfiguration().button_color,
                          textAlign: "left",
                        }}
                        onClick={() => this.handleMenu(index)}
                      >
                        {menu.header}
                      </Button>
                    </Feed.Content>
                  </Feed.Event>
                );
              })}
            </Feed>
          </Card.Content>
        </Card>
        <Card fluid>
          <Card.Content>
            <Header textAlign="center" as="h4">
              {read_only_non_pos_page.contact_us.header}
            </Header>
            <Card.Description
              dangerouslySetInnerHTML={{
                __html: read_only_non_pos_page.contact_us.details,
              }}
            />
          </Card.Content>
        </Card>
      </Card.Group>
    );
  };

  renderMobile = () => {
    const { read_only_non_pos_page } = this.props.locations.configurations;

    return (
      <Card.Group>
        <Card fluid>
          <Card.Content>
            <Header textAlign="center" as="h3">
              {read_only_non_pos_page.menu_information.header}
            </Header>
            <Card.Description
              dangerouslySetInnerHTML={{
                __html: read_only_non_pos_page.menu_information.details,
              }}
            />
          </Card.Content>
        </Card>
        <Card fluid>
          <Card.Content>
            <Header textAlign="center" as="h4">
              {read_only_non_pos_page.menu_links.header}
            </Header>
            <Card.Description />
            <Feed>
              {read_only_non_pos_page.all_menus.map((menu, index) => {
                return (
                  <Feed.Event key={`menu_${index}`}>
                    <Feed.Content>
                      <Button
                        fluid
                        style={{
                          ...getConfiguration().button_color,
                          textAlign: "left",
                        }}
                        onClick={() => this.handleMenu(index)}
                      >
                        {menu.header}
                      </Button>
                    </Feed.Content>
                  </Feed.Event>
                );
              })}
            </Feed>
          </Card.Content>
        </Card>
        <Card fluid>
          <Card.Content>
            <Header textAlign="center" as="h4">
              {read_only_non_pos_page.contact_us.header}
            </Header>
            <Card.Description
              dangerouslySetInnerHTML={{
                __html: read_only_non_pos_page.contact_us.details,
              }}
            />
          </Card.Content>
        </Card>
        {read_only_non_pos_page.all_menus.map((menu, index) => {
          return (
            <div
              ref={(ref) => (this.items[index] = ref)}
              key={`item_${index}`}
              style={{ width: "100%", padding: "0 8px 15px", overflowWrap:'break-word' }}
            >
              <Card fluid>
                <Card.Content>
                  <Header textAlign="center" as="h3">
                    {menu.header}
                  </Header>
                  <Card.Description
                    dangerouslySetInnerHTML={{
                      __html: menu.details,
                    }}
                  />
                </Card.Content>
              </Card>
            </div>
          );
        })}
      </Card.Group>
    );
  };

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            console.log("BROWSER IS BEING REFRESHED!");
            refreshCacheAndReload();
          }
          return (
            <>
              <Media at="mobile">{this.renderMobile()}</Media>
              <Media greaterThanOrEqual="tablet">
                <Ref innerRef={this.contextRef}>
                  <Grid padded>
                    <Grid.Column width={11}>{this.renderContent()}</Grid.Column>
                    <Grid.Column width={5}>
                      <Sticky
                        context={this.contextRef}
                        styleElement={{ zIndex: 0 }}
                        offset={120}
                      >
                        {this.renderLinks()}
                      </Sticky>
                    </Grid.Column>
                  </Grid>
                </Ref>
              </Media>
            </>
          );
        }}
      </CacheBuster>
    );
  }
}

export default connect(
  (state) => {
    const { users, locations } = state;
    const { user } = users;
    return { user, locations };
  },
  (dispatch) =>
    bindActionCreators(
      {
        ...actionCreators,
      },
      dispatch
    )
)(withRouter(Catering));
