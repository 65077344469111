import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {Button, Card, Dimmer, Form, Grid, Header, List, Loader, Message, Segment, Table,} from 'semantic-ui-react';

import TicketCheckoutForm from './TicketCheckoutForm';
import {Media} from '../../AppMedia';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as appStore from '../../store/Apps';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import * as cartStore from '../../store/Cart';
import {getConfiguration} from '../../store/api/apiClient';
import Config from '../../config';
import {Logger} from '../../utils/Logger';

class TicketSubmission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripe: null,
            saving: -1,
            error: {},
            screen: sessionStorage.getItem('screen'),
            intervalId: 0,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.scrollStep()
        let error = nextProps.ticket.error ? nextProps.ticket.error : null;
        let successMessage = (this.state.screen === 'promo' || this.state.screen === 'payment') && nextProps.ticket.success_message
            ? nextProps.ticket.success_message
            : null;
        this.setState({
            error,
            successMessage,
            showError: true,
        });
    }

    componentDidMount() {
        this.props.updateLoading(false);
        this.props.updateDimmed(false);
        if (window.Stripe) {
            this.setState({stripe: window.Stripe(Config.api_key)});
        } else if(document){
            const documentStripeJs = document.querySelector('#stripe-js')
            if(documentStripeJs)
                documentStripeJs.addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({
                    stripe: window.Stripe(Config.api_key),
                });
            });
        }
        // this.scrollEnd()
    }

    componentWillMount() {
        this.props.getTicketOrder('/order');
        this.props.locationConfiguration();
        if (this.props.auth.user.email) {
            this.props.getUser();
        }
    }

    handleChange(field, e) {
        let data = this.state.data;
        data[field] = e.target.value;
        this.setState({data});
    }

    scrollStep() {
        setTimeout(()=>{
            if(window.document.getElementById('orderItem')){
                window.document.getElementById('orderItem').scrollTo({top:0, behavior: "smooth"})
                window.document.getElementById('orderItem').scroll({top:0, behavior: "smooth"})
                // window.document.getElementById('orderItem').scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"})
            }
        },1000)
    }
    scrollEnd() {
        setTimeout(()=>{
            if(window.document.getElementById('orderItem')){
                // window.document.getElementById('orderItem').scrollTo({top:0, behavior: "smooth"})
                // window.document.getElementById('orderItem').scroll({top:0, behavior: "smooth"})
                window.document.getElementById('orderItem').scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"})
            }
        },1000)
    }
    async generateEphemeralKey(data) {
        await this.props.generateEphemeralKey(this.props.ticket.ticket_order.recipient_email);
    }

    screenChange(screen) {
        this.scrollStep()
        this.setState({screen});
    }

    async selectReward(reward, e) {
        e.preventDefault();
        try {
            this.scrollStep()
            this.props.updateLoading(true);
            this.setState({showError: false});
            await this.props.addRewardPaymentTicketOrder(reward);
            this.props.updateLoading(false);
            this.setState({reward, showError: true});
        } catch (err) {
            Logger.error('TicketSubmission', 'selectReward', JSON.stringify(err));
        }
    }

    isRewardSelected() {
        let foundReward = this.props.ticket.ticket_order
            ? this.props.ticket.ticket_order.payments.find((payment) => {
                return payment.tender_type === 'reward';
            })
            : false;
        return this.state.reward === this.props.users.user.rewards.reward[0].id || foundReward;
    }

    setLoading(loading) {
        this.props.updateLoading(loading);
    }

    renderDesktop(params) {
        const {user} = this.props.auth;
        const {successMessage, error, showError} = this.state;
        const {screen, currentScreen} = params;

        const {totals, ticket_settings} = this.props.ticket;
        const {order} = this.state;

        const nonTipAmount = totals.due_non_tip ? totals.due_non_tip.replace(/[^0-9.]/g, '') * 1 : 0;
        const amountDue = totals.due.replace(/[^0-9.]/g, '') * 1;
        // const tip = totals.tips.replace(/[^0-9.]/g, '') * 1;
        // const amountDue = due || tipAmount > tip ? due + tipAmount * 1 : 0;
        const payTipsWithGiftCard = ticket_settings.pay_tips_with_gift_card;
        const readyToSubmit =
            amountDue === 0 ||
            (order
                ? order.status === 'PaymentComplete'
                : false);

        return (
            <Grid padded stackable id="orderItem">
                <Grid.Column width={10} style={{paddingLeft: 10}}>
                    <Segment>
                        {successMessage && showError ? (
                            <Message
                                icon
                                success
                                visible={showError}
                                header={successMessage.header.toUpperCase()}
                                content={successMessage.message}
                            />
                        ) : error && showError ? (
                            <Message
                                icon
                                error
                                visible={showError}
                                header={'Error'}
                                content={error.message}
                            />
                        ) : null}
                        <StripeProvider stripe={this.state.stripe}>
                            <Elements>
                                <TicketCheckoutForm
                                    screen={screen ? screen : 'delivery'}
                                    screenChange={this.screenChange.bind(this)}
                                    history={this.props.history}
                                    updateCartAddress={this.props.updateCartAddress}
                                    addPaymentTicketOrder={this.props.addPaymentTicketOrder}
                                    updateCartPersonalMessage={
                                        this.props.updateCartPersonalMessage
                                    }
                                    login={this.props.auth}
                                    setLoading={this.setLoading.bind(this)}
                                    loading={this.props.isLoading}
                                    generateEphemeralKey={this.generateEphemeralKey.bind(this)}
                                />
                            </Elements>
                        </StripeProvider>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={6} style={{paddingLeft: 0, paddingRight: 20}}>
                    {this.props.ticket.ticket_order && (
                        <Segment>
                            <List>
                                <List.Item>
                                    <List.Content
                                        style={{
                                            padding: 10,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Header
                                            as='h4'
                                            style={getConfiguration().label_color}
                                        >
                                            {`Online Order # ${this.props.ticket.ticket_order.name}`}
                                        </Header>
                                    </List.Content>
                                </List.Item>
                                {this.props.ticket.totals.sub_total && this.props.ticket.totals.sub_total !== '$0.00' ? (
                                    <List.Item style={{paddingBottom: 10}}>
                                        <List.Content style={{float: 'right'}}>
                                            {this.props.ticket.totals.sub_total}
                                        </List.Content>
                                        <List.Content>Subtotal</List.Content>
                                    </List.Item>
                                ) : null}
                                {this.props.ticket.totals.tax && this.props.ticket.totals.tax !== '$0.00' ? (
                                    <List.Item style={{paddingBottom: 10}}>
                                        <List.Content style={{float: 'right'}}>
                                            {this.props.ticket.totals.tax}
                                        </List.Content>
                                        <List.Content>Tax</List.Content>
                                    </List.Item>
                                ) : null}
                                {this.props.ticket.totals.discounts && this.props.ticket.totals.discounts !== '$0.00' ? (
                                    <List.Item style={{paddingBottom: 10}}>
                                        <List.Content style={{float: 'right'}}>
                                            -{this.props.ticket.totals.discounts}
                                        </List.Content>
                                        <List.Content>Promo</List.Content>
                                    </List.Item>
                                ) : null}
                                {this.props.ticket.ticket_order.tip ? (
                                    <List.Item style={{paddingBottom: 10}}>
                                        <List.Content style={{float: 'right'}}>
                                            {!readyToSubmit ? this.props.ticket.totals.due_tip : this.props.ticket.totals.tips}
                                        </List.Content>
                                        <List.Content>Tip</List.Content>
                                    </List.Item>
                                ) : null}
                                {currentScreen === 'payment' ? (
                                    <List.Item
                                        style={{
                                            background: '#d3d3d3cc',
                                            padding: '8px 14px',
                                            margin: '0 -14px 10px',
                                            fontSize: 16,
                                            fontWeight: 500,
                                        }}
                                    >
                                        <List.Content style={{float: 'right'}}>
                                            {this.props.ticket.totals.total}
                                        </List.Content>
                                        <List.Content>Total Amount</List.Content>
                                    </List.Item>
                                ) : null}
                                {this.props.ticket.ticket_order.payments.map((payment, index) => {
                                    return (
                                        <List.Item key={`torder-${index}`} style={{paddingBottom: 10}}>
                                            <List.Content style={{float: 'right'}}>
                                                {payment.display_amount}
                                            </List.Content>
                                            <List.Content style={getConfiguration().label_color}>
                                                {`${payment.display_name} ${payment.tip !== '$0.00' ? '(tip included)' : ''}`}
                                            </List.Content>
                                        </List.Item>
                                    )
                                })}
                                <List.Item
                                    style={{
                                        background: '#d3d3d3cc',
                                        padding: '8px 14px',
                                        margin: '0 -14px -14px',
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.due}
                                    </List.Content>
                                    <List.Content>Amount Due</List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                    )}
                    {currentScreen === 'payment' &&
                    this.props.users.user &&
                    this.props.users.user.rewards &&
                    this.props.users.user.rewards.is_rewards_on &&
                    this.props.users.user.rewards.reward.length > 0 ? (
                        <Segment>
                            <Grid
                                verticalAlign='middle'
                                textAlign={'center'}
                                style={{paddingTop: 10}}
                            >
                                <Grid.Row>
                                    <Grid.Column
                                        width={16}
                                        style={{
                                            textAlign: 'left',
                                            paddingRight: 0,
                                            paddingLeft: 0,
                                            marginTop: -14,
                                            marginLeft: -15,
                                            marginBottom: -19,
                                        }}
                                    >
                                        <Table
                                            unstackable
                                            compact
                                            basic='very'
                                            textAlign={'center'}
                                        >
                                            <Table.Header fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell
                                                        style={{
                                                            border: 'none',
                                                            padding: '10px 5px 0px',
                                                            textAlign: 'center',
                                                        }}
                                                        colSpan='2'
                                                    >
                                                        {this.props.ticket.ticket_order ? (
                                                            <Header
                                                                as='h4'
                                                                style={
                                                                    getConfiguration()
                                                                        .label_color
                                                                }
                                                            >
                                                                Available Reward
                                                            </Header>
                                                        ) : null}
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell
                                                        style={{
                                                            border: 'none',
                                                            padding: '10px',
                                                            paddingBottom: '20px',
                                                            textAlign: 'center',
                                                        }}
                                                        colSpan='2'
                                                    >
                                                        {
                                                            this.props.users.user.rewards
                                                                .reward[0].name
                                                        }
                                                        <br/>
                                                        <br/>
                                                        <Button
                                                            key={
                                                                this.props.users.user
                                                                    .rewards.reward[0].id
                                                            }
                                                            size='large'
                                                            circular
                                                            fluid
                                                            disabled={
                                                                this.props.ticket
                                                                    ? !this.props.ticket
                                                                        .reward_can_be_used
                                                                    : true
                                                            }
                                                            onClick={this.selectReward.bind(
                                                                this,
                                                                this.props.users.user
                                                                    .rewards.reward[0].id
                                                            )}
                                                            style={{
                                                                ...getConfiguration()
                                                                    .button_color,
                                                            }}
                                                        >
                                                            {this.isRewardSelected()
                                                                ? 'Reward redeemed for ' +
                                                                this.props.users.user
                                                                    .rewards.reward[0]
                                                                    .amount_pretty
                                                                : 'REDEEM REWARD'}
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    ) : null}
                    {user && !user.authenticated && (
                        <Card fluid>
                            <Card.Content extra>
                                <Card.Description>
                                    Become a member to earn points on this order!
                                </Card.Description>
                                <br/>
                                <Form>
                                    <Form.Field>
                                        <Button
                                            as={Link}
                                            to={'/login'}
                                            name={'/login'}
                                            fluid
                                            circular
                                            style={getConfiguration().button_color}
                                            onClick={() =>
                                                sessionStorage.setItem('previouspath', this.props.history.location.pathname)}
                                        >
                                            SIGN IN
                                        </Button>
                                    </Form.Field>
                                    <Form.Field>
                                        <Button
                                            as={Link}
                                            to={'/signup'}
                                            name={'/signup'}
                                            fluid
                                            circular
                                            style={getConfiguration().button_color}
                                            onClick={() =>
                                                sessionStorage.setItem('previouspath', this.props.history.location.pathname)}
                                        >
                                            REGISTER
                                        </Button>
                                    </Form.Field>
                                </Form>
                            </Card.Content>
                        </Card>
                    )}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(params) {
        const {user} = this.props.auth;
        const {successMessage, error, showError} = this.state;
        const {screen, currentScreen} = params;
        const {totals, ticket_settings} = this.props.ticket;
        const amountDue = totals.due.replace(/[^0-9.]/g, '') * 1;
        const {order} = this.state;

        const readyToSubmit =
            amountDue === 0 ||
            (order
                ? order.status === 'PaymentComplete'
                : false);

        return (
            <Card.Group id="orderItem">
                <Card fluid>
                    <Card.Content>
                        {successMessage && showError ? (
                            <Message
                                icon
                                success
                                visible={showError}
                                header={successMessage.header.toUpperCase()}
                                content={successMessage.message}
                            />
                        ) : error && showError ? (
                            <Message
                                icon
                                error
                                visible={showError}
                                header={'Error'}
                                content={error.message}
                            />
                        ) : null}
                        <StripeProvider stripe={this.state.stripe}>
                            <Elements>
                                <TicketCheckoutForm
                                    screen={screen ? screen : 'delivery'}
                                    screenChange={this.screenChange.bind(this)}
                                    history={this.props.history}
                                    updateCartAddress={this.props.updateCartAddress}
                                    addPaymentTicketOrder={this.props.addPaymentTicketOrder}
                                    updateCartPersonalMessage={this.props.updateCartPersonalMessage}
                                    login={this.props.auth}
                                    setLoading={this.setLoading.bind(this)}
                                    loading={this.props.isLoading}
                                    generateEphemeralKey={this.generateEphemeralKey.bind(this)}
                                />
                            </Elements>
                        </StripeProvider>
                    </Card.Content>
                </Card>
                {this.props.ticket.ticket_order ? (
                    <Card fluid>
                        <Card.Content extra>
                            <Card.Meta
                                style={{
                                    ...getConfiguration().label_color,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {`Online Order # ${this.props.ticket.ticket_order.name}`}
                            </Card.Meta>
                        </Card.Content>
                        <Card.Content extra style={{color: 'black'}}>
                            {this.props.ticket.totals.sub_total &&
                            this.props.ticket.totals.sub_total !== '$0.00' ? (
                                <List.Item>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.sub_total}
                                    </List.Content>
                                    <List.Content>Subtotal</List.Content>
                                </List.Item>
                            ) : null}
                            {this.props.ticket.totals.tax &&
                            this.props.ticket.totals.tax !== '$0.00' ? (
                                <List.Item>
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.tax}
                                    </List.Content>
                                    <List.Content>Tax</List.Content>
                                </List.Item>
                            ) : null}
                            {this.props.ticket.totals.discounts &&
                            this.props.ticket.totals.discounts !== '$0.00' ? (
                                <List.Item>
                                    <List.Content style={{float: 'right'}}>
                                        -{this.props.ticket.totals.discounts}
                                    </List.Content>
                                    <List.Content>Promo</List.Content>
                                </List.Item>
                            ) : null}
                            {this.props.ticket.ticket_order.tip ? (
                                <List.Item>
                                    <List.Content style={{float: 'right'}}>
                                        {!readyToSubmit ? this.props.ticket.totals.due_tip : this.props.ticket.totals.tips}
                                    </List.Content>
                                    <List.Content>Tip</List.Content>
                                </List.Item>
                            ) : null}
                            {currentScreen === 'payment' ? (
                                <List.Item
                                    style={{
                                        background: '#d3d3d3cc',
                                        padding: '5px 14px',
                                        margin: '0 -14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <List.Content style={{float: 'right'}}>
                                        {this.props.ticket.totals.total}
                                    </List.Content>
                                    <List.Content>Total Amount</List.Content>
                                </List.Item>
                            ) : null}
                            {this.props.ticket.ticket_order.payments.map((payment, index) => {
                                return (
                                    <List.Item
                                        style={{
                                            padding: '5px 0',
                                        }}
                                        key={`pay_${index}`}
                                    >
                                        <List.Content style={{float: 'right'}}>
                                            {payment.display_amount}
                                        </List.Content>
                                        <List.Content style={getConfiguration().label_color}>
                                            {`${payment.display_name} ${
                                                payment.tip !== '$0.00' ? '(tip included)' : ''
                                            }`}
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                            <List.Item
                                style={{
                                    background: '#d3d3d3cc',
                                    padding: '5px 14px',
                                    margin: '0 -14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <List.Content style={{float: 'right'}}>
                                    {this.props.ticket.totals.due}
                                </List.Content>
                                <List.Content>Amount Due</List.Content>
                            </List.Item>
                        </Card.Content>
                    </Card>
                ) : null}
                {currentScreen === 'payment' &&
                this.props.users.user &&
                this.props.users.user.rewards &&
                this.props.users.user.rewards.is_rewards_on &&
                this.props.users.user.rewards.reward.length > 0 ? (
                    <Card fluid>
                        <Card.Content extra>
                            <Card.Meta
                                style={{
                                    ...getConfiguration().label_color,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                Available Reward
                            </Card.Meta>
                        </Card.Content>
                        <Card.Content extra>
                            <Card.Meta
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {this.props.users.user.rewards.reward[0].name}
                            </Card.Meta>
                        </Card.Content>
                        <Card.Content>
                            <Button
                                key={this.props.users.user.rewards.reward[0].id}
                                circular
                                fluid
                                disabled={
                                    this.props.ticket ? !this.props.ticket.reward_can_be_used : true
                                }
                                onClick={this.selectReward.bind(
                                    this,
                                    this.props.users.user.rewards.reward[0].id
                                )}
                                style={getConfiguration().button_color}
                            >
                                {this.isRewardSelected()
                                    ? 'Reward redeemed for ' +
                                    this.props.users.user.rewards.reward[0].amount_pretty
                                    : 'REDEEM REWARD'}
                            </Button>
                        </Card.Content>
                    </Card>
                ) : null}
                {user && !user.authenticated && (
                    <Card fluid>
                        <Card.Content extra>
                            <Card.Description>
                                Become a member to earn points on this order!
                            </Card.Description>
                            <br/>
                            <Form>
                                <Form.Field>
                                    <Button
                                        as={Link}
                                        to={'/login'}
                                        name={'/login'}
                                        fluid
                                        circular
                                        style={getConfiguration().button_color}
                                        onClick={() =>
                                            sessionStorage.setItem('previouspath', this.props.history.location.pathname)}
                                    >
                                        SIGN IN
                                    </Button>
                                </Form.Field>
                                <Form.Field>
                                    <Button
                                        as={Link}
                                        to={'/signup'}
                                        name={'/signup'}
                                        fluid
                                        circular
                                        style={getConfiguration().button_color}
                                        onClick={() =>
                                            sessionStorage.setItem('previouspath', this.props.history.location.pathname)}
                                    >
                                        REGISTER
                                    </Button>
                                </Form.Field>
                            </Form>
                        </Card.Content>
                    </Card>
                )}
            </Card.Group>
        );
    }

    render() {
        const {ticket_order} = this.props.ticket;
        let screen = sessionStorage.getItem('screen');
        if (ticket_order) {
            if (
                ticket_order.status === 'PaymentComplete' ||
                ticket_order.status === 'Submitted' ||
                ticket_order.status === 'Queued'
            ) {
                screen = 'payment';
            }
        } else {
            screen = 'delivery';
        }
        let currentScreen = this.state.screen;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <Dimmer active={this.props.isLoading} inverted>
                                <Loader size='small' inline='centered'>
                                    Loading
                                </Loader>
                            </Dimmer>
                            <Media at='mobile'>
                                {this.renderMobile({
                                    screen,
                                    currentScreen,
                                })}
                            </Media>
                            <Media greaterThanOrEqual='tablet'>
                                {this.renderDesktop({
                                    screen,
                                    currentScreen,
                                })}
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {apps, users, login, cart, locations, ticket} = state;
        return {
            users,
            cart,
            auth: {...login},
            locations,
            ticket,
            isLoading: apps.loading,
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...loginStore.actionCreators,
                ...cartStore.actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
                ...appStore.actionCreators,
            },
            dispatch
        )
)(TicketSubmission);
