import {client, getHeaders} from './apiClient'

const user = async () => {
    return await client.request({
        url: '/user',
        method: "GET",
        headers: getHeaders(),
    })
}

const userTransactions = async () => {
    return await client.request({
        url: '/user/transactions',
        method: "GET",
        headers: getHeaders(),
    })
}

const userInfoUpdate = async (data) => {
    return await client.request({
        url: '/v2.0/user',
        method: "PUT",
        data,
        headers: getHeaders(),
    })
}

const userPasswordReset = async (data) => {
    return await client.request({
        url: '/v2.0/user/passwordreset',
        method: "POST",
        data,
        headers: getHeaders(),
    })
}

export default {
    user,
    userTransactions,
    userInfoUpdate,
    userPasswordReset,
}