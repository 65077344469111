import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import NumberFormat from 'react-number-format';
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import {
    Grid,
    Button,
    Form,
    Header,
    Table,
    TextArea,
    Dimmer,
    Loader,
    Dropdown,
    Card,
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { Media } from '../../AppMedia';
import {actionCreators} from '../../store/Users';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import {getConfiguration} from '../../store/api/apiClient';
import states from '../../assets/states.json';
import vehicleMakes from '../../assets/vehicle_makes.json';
import { Logger } from '../../utils/Logger';

class AddInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripe: null,
            saving: -1,
            error_address: {
                recipient_first_name: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_last_name: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_address: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_city: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_state: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_zip: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_email: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_phone: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
            },
            data: {},
            isCurbSide: true,
            loading: true,
        };
    }

    scrollEnd() {
        if(window.document.getElementById('comments')){
            window.document.getElementById('comments')
                .scrollIntoView({block: "center", inline: "nearest", behavior: "smooth"})
        }
    }

    componentDidMount() {
        this.handleInit();
    }

    handleInit = async () => {
        try {
            await this.props.getTicketOrder();
            const data = {...this.props.ticket.ticket_order};
            let error_address = {...this.state.error_address};
    
            if (data.recipient_first_name) {
                delete error_address['recipient_first_name'];
            }
            if (data.recipient_last_name) {
                delete error_address['recipient_last_name'];
            }
    
            if (data.recipient_email) {
                delete error_address['recipient_email'];
            }
            if (data.recipient_phone) {
                delete error_address['recipient_phone'];
            }
    
            if (data.use_curbside || data.recipient_address) {
                delete error_address['recipient_address'];
            }
            if (data.use_curbside || data.recipient_city) {
                delete error_address['recipient_city'];
            }
            if (data.use_curbside || data.recipient_state) {
                delete error_address['recipient_state'];
            }
            if (data.use_curbside || data.recipient_zip) {
                delete error_address['recipient_zip'];
            }
    
            this.setState({
                ...this.state,
                data,
                error_address,
                isCurbSide: data.use_curbside,
                loading: false,
            });
        } catch (err) {
            this.setState({ loading: false });
            Logger.error('AddInformation', 'handleInit', JSON.stringify(err));
        }
    }

    handleStateChange(field, e, target) {
        let data = this.state.data;
        let error_address = this.state.error_address;
        try {
            if (field === 'recipient_state') {
                if (target.value) {
                    delete error_address[field];
                } else {
                    error_address[field] = {
                        content: 'Please enter a valid info',
                        pointing: 'below',
                    };
                }
            }
    
            data[field] = target.value;
            this.setState({data, error_address});
        } catch (err) {
            Logger.error('AddInformation', 'handleStateChange', JSON.stringify(err));
        }
    }

    handleChange(field, value) {
        let data = { ...this.state.data };
        let error_address = { ...this.state.error_address };
        try {
            if (value) {
                if (field === 'recipient_phone' && value) {
                    if (value.length === 10) {
                        delete error_address.recipient_phone;
                    } else {
                        error_address.recipient_phone = {
                            content: 'Please enter a valid phone number',
                            pointing: 'below',
                        };
                    }
                } else if (field === 'recipient_email') {
                    if (this.validateEmail(value)) {
                        delete error_address.recipient_email;
                    } else {
                        error_address.recipient_email = {
                            content: 'Please enter a valid email address',
                            pointing: 'below',
                        };
                    }
                } else {
                    delete error_address[field];
                }
            } else {
                error_address[field] = {
                    content: 'Please enter a valid info',
                    pointing: 'below',
                };
            }
    
            data[field] = value;
            this.setState({data, error_address});
        } catch (err) {
            Logger.error('AddInformation', 'handleChange', JSON.stringify(err));
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handlePlaceSelect = (data) => {
        geocodeByAddress(data.description)
            .then((results) => {
                this.handleGeocode(results[0], data);
            })
            .catch(err => Logger.error('AddInformation', 'handlePlaceSelect', JSON.stringify(err)));
    };

    handleGeocode(addressResult, addr) {
        let data = { ...this.state.data };
        let error_address = { ...this.state.error_address };

        if (addr.types[0] === 'street_address') {
            data.recipient_address = this.getAddressType(addressResult, ['street_number', 'route']);
            data.recipient_city = this.getAddressType(addressResult, ['locality']) || this.getAddressType(addressResult, ['neighborhood']);
        } else {
            data.recipient_address = `${addr.terms[0].value} ${this.getAddressType(addressResult, ['route'])}`;
            data.recipient_city = addr.terms[2].value;
        }
        data.recipient_state = this.getAddressType(addressResult, ['administrative_area_level_1']);
        data.recipient_zip = this.getAddressType(addressResult, ['postal_code']);

        if (data.recipient_address) delete error_address.recipient_address;
        if (data.recipient_city) delete error_address.recipient_city;
        if (data.recipient_state) delete error_address.recipient_state;
        if (data.recipient_zip) delete error_address.recipient_zip;

        this.setState({ data, error_address });
    }

    getAddressType(addressResult, types) {
        let geocodeAddress = [];
        types.forEach((type) => {
            addressResult.address_components.forEach((geocode) => {
                if (geocode.types.indexOf(type) !== -1) {
                    return geocodeAddress.push(geocode.short_name);
                }
            });
        });
        return geocodeAddress.join(' ');
    }

    async updateTicketOrderCurbside(e) {
        e.preventDefault();
        this.setState({loading: true});
        try {
            const data = {
                use_curbside: true,
                phone_number: this.state.data.recipient_phone,
                recipient_phone: this.state.data.recipient_phone,
                recipient_first_name: this.state.data.recipient_first_name,
                recipient_last_name: this.state.data.recipient_last_name,
                recipient_email: this.state.data.recipient_email,
                vehicle_make: this.state.data.vehicle_make,
                vehicle_model: this.state.data.vehicle_model,
                vehicle_color: this.state.data.vehicle_color,
                vehicle_license: this.state.data.vehicle_license,
                recipient_comment: this.state.data.recipient_comment,
            };
            const result = await this.props.updateTicketOrderDelivery(data);
            this.setState({loading: false})
            if (!result.error) {
                this.props.nextStep(this.props.locations.location.settings.promo_codes.enable ? 'promo' : 'tip');
            }
        } catch (err) {
            Logger.error('AddInformation', 'updateTicketOrderCurbside', JSON.stringify(err));
        }
    }

    async updateTicketOrder(e) {
        e.preventDefault();
        this.setState({loading: true});
        try {
            const data = {
                phone_number: this.state.data.recipient_phone,
                recipient_phone: this.state.data.recipient_phone,
                recipient_first_name: this.state.data.recipient_first_name,
                recipient_last_name: this.state.data.recipient_last_name,
                recipient_email: this.state.data.recipient_email,
                recipient_address: this.state.data.recipient_address,
                recipient_address2: this.state.data.recipient_address2,
                recipient_city: this.state.data.recipient_city,
                recipient_state: this.state.data.recipient_state,
                recipient_zip: this.state.data.recipient_zip,
                recipient_comment: this.state.data.recipient_comment,
            };
            const result = await this.props.updateTicketOrderDelivery(data);
            this.setState({loading: false})
            if (!result.error) {
                this.props.nextStep(this.props.locations.location.settings.promo_codes.enable ? 'promo' : 'tip');
            }
        } catch (err) {
            Logger.error('AddInformation', 'updateTicketOrder', JSON.stringify(err));
        }
    }

    handleGoBack() {
        const showOrderAheadOption = this.props.ticket.ticket_settings ? this.props.ticket.ticket_settings.show_order_ahead_option : false;
        this.props.handleGoBack(showOrderAheadOption ? 'time' : 'delivery');
    }

    renderDesktop(params) {
        const {isCurbSide, deliveryCharge, data, error} = params;

        return this.props.ticket.ticket_order ? (
            <Grid textAlign={'center'}  id="orderItem">>
                <Grid.Column width={14}>
                    <Table basic={'very'}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={3}>
                                    <span style={{fontWeight: 600}}>
                                        Please provide the following information for your order
                                    </span>
                                    <br/>
                                    {!isCurbSide ? (
                                        <span>
                                            {'(For delivery, there is a charge of ' +
                                            deliveryCharge +
                                            ' that will be applied to your order)'}
                                        </span>
                                    ) : null}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan={3}>
                                    <Form.Group widths='equal'>
                                        <Form.Field
                                            error={
                                                data.recipient_first_name !== null
                                                    ? error.recipient_first_name
                                                    ? !!data.recipient_first_name
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>* FIRST NAME</label>
                                            <input
                                                autoComplete='new'
                                                placeholder='First Name'
                                                value={data.recipient_first_name || ''}
                                                onChange={(e) => this.handleChange(
                                                    'recipient_first_name',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={
                                                data.recipient_last_name !== null
                                                    ? error.recipient_last_name
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>* LAST NAME</label>
                                            <input
                                                autoComplete='new'
                                                placeholder='Last Name'
                                                value={data.recipient_last_name || ''}
                                                onChange={(e) => this.handleChange(
                                                    'recipient_last_name',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    {!isCurbSide ? (
                                        <>
                                        <Form.Field
                                            error={
                                                data.recipient_address !== null
                                                    ? error.recipient_address
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>* ADDRESS</label>
                                            <GooglePlacesAutocomplete
                                                autoComplete='new'
                                                debounce={600}
                                                onSelect={this.handlePlaceSelect}
                                                initialValue={data.recipient_address || ''}
                                            />
                                        </Form.Field>{' '}
                                        <Form.Field>
                                            <label>ADDRESS 2</label>
                                            <input
                                                autoComplete='new'
                                                placeholder='Address2'
                                                value={data.recipient_address2 || ''}
                                                onChange={(e) => this.handleChange(
                                                    'recipient_address2',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Group>
                                            <Form.Field
                                                width={8}
                                                error={
                                                    data.recipient_city !== null
                                                        ? error.recipient_city
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>* CITY</label>
                                                <input
                                                    autoComplete='new'
                                                    value={data.recipient_city || ''}
                                                    placeholder='City'
                                                    onChange={(e) => this.handleChange(
                                                        'recipient_city',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>
                                            <Form.Field
                                                width={5}
                                                error={
                                                    data.recipient_state !== null
                                                        ? error.recipient_state
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>* STATE</label>
                                                <Dropdown
                                                    autoComplete='new'
                                                    placeholder='State'
                                                    fluid
                                                    onChange={this.handleStateChange.bind(
                                                        this,
                                                        'recipient_state'
                                                    )}
                                                    value={data.recipient_state}
                                                    search
                                                    selection
                                                    options={states.map((state, index) => {
                                                        return {
                                                            key: index,
                                                            value: state.abbreviation,
                                                            text: state.name,
                                                        };
                                                    })}
                                                />
                                            </Form.Field>
                                            <Form.Field
                                                width={3}
                                                error={
                                                    data.recipient_zip !== null
                                                        ? error.recipient_zip
                                                        ? true
                                                        : false
                                                        : null
                                                }
                                            >
                                                <label>* ZIP CODE</label>
                                                <NumberFormat
                                                    value={data.recipient_zip || ''}
                                                    placeholder='Zip code'
                                                    inputMode='decimal'
                                                    pattern='[0-9],*'
                                                    format='#####'
                                                    onInvalid={(e) => e.preventDefault()}
                                                    onValueChange={(values) =>
                                                        this.handleChange('recipient_zip', values.value)
                                                    }
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        </>
                                    ) : null}
                                    <Form.Group widths='equal'>
                                        <Form.Field
                                            error={
                                                data.recipient_email !== null
                                                    ? error.recipient_email
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>* EMAIL</label>
                                            <input
                                                autoComplete='new'
                                                placeholder='Email'
                                                value={data.recipient_email || ''}
                                                onChange={(e) => this.handleChange(
                                                    'recipient_email',
                                                    e.target.value
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={
                                                data.recipient_phone !== null
                                                    ? error.recipient_phone
                                                    ? true
                                                    : false
                                                    : null
                                            }
                                        >
                                            <label>* PHONE NUMBER</label>
                                            <NumberFormat
                                                value={data.recipient_phone || ''}
                                                placeholder='Phone Number'
                                                inputMode='decimal'
                                                pattern='[0-9],*'
                                                format='(###) ###-####'
                                                mask='_'
                                                onInvalid={(e) => e.preventDefault()}
                                                onValueChange={(values) =>
                                                    this.handleChange('recipient_phone', values.value)
                                                }
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    {isCurbSide ? (
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <label>VEHICLE MAKE</label>
                                                <Dropdown
                                                    autoComplete='new'
                                                    placeholder='Make'
                                                    onChange={this.handleStateChange.bind(
                                                        this,
                                                        'vehicle_make'
                                                    )}
                                                    value={data.vehicle_make}
                                                    search
                                                    selection
                                                    options={vehicleMakes.map((make, index) => {
                                                        return {
                                                            key: index,
                                                            value: make,
                                                            text: make,
                                                        };
                                                    })}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>VEHICLE MODEL</label>
                                                <input
                                                    autoComplete='new'
                                                    value={data.vehicle_model || ''}
                                                    placeholder='Model'
                                                    onChange={(e) => this.handleChange(
                                                        'vehicle_model',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    ) : null}
                                    {isCurbSide ? (
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <label>VEHICLE COLOR</label>
                                                <input
                                                    autoComplete='new'
                                                    value={data.vehicle_color || ''}
                                                    placeholder='Color'
                                                    onChange={(e) => this.handleChange(
                                                        'vehicle_color',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>VEHICLE LICENSE</label>
                                                <input
                                                    autoComplete='new'
                                                    value={data.vehicle_license || ''}
                                                    placeholder='License'
                                                    onChange={(e) => this.handleChange(
                                                        'vehicle_license',
                                                        e.target.value
                                                    )}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    ) : null}
                                    <Form.Field>
                                        <label>ADDITIONAL COMMENTS</label>
                                        <TextArea
                                            id={"comments"}
                                            onFocus={()=>{
                                                this.scrollEnd()}
                                            }
                                            onChange={(e) => this.handleChange(
                                                'recipient_comment',
                                                e.target.value
                                            )}
                                            value={data.recipient_comment}
                                            placeholder='Add a note or request'
                                            style={{minHeight: 100}}
                                        />
                                    </Form.Field>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                        <Grid doubling columns={2}>
                            <Grid.Column width={6}>
                                <Button
                                    fluid
                                    circular
                                    size='large'
                                    onClick={this.handleGoBack.bind(this)}
                                >
                                    BACK
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Button
                                    fluid
                                    size='large'
                                    circular
                                    disabled={
                                        !isEmpty(this.state.error_address) ||
                                        this.state.loading
                                    }
                                    onClick={
                                        !isCurbSide
                                            ? this.updateTicketOrder.bind(this)
                                            : this.updateTicketOrderCurbside.bind(this)
                                    }
                                    style={getConfiguration().button_color}
                                >
                                    CONTINUE
                                </Button>
                            </Grid.Column>
                        </Grid>
                    ) : null}
                </Grid.Column>
            </Grid>
        ) : null;
    }

    renderMobile(params) {
        const {isCurbSide, deliveryCharge, data, error} = params;
        return (
            <>
            <Form.Field>
                <Header as='h3' textAlign='center'>
                    Please provide the following information for your order
                </Header>
                {!isCurbSide ? (
                    <Card.Description textAlign='center'>
                        {'(For delivery, there is a charge of ' +
                        deliveryCharge +
                        ' that will be applied to your order)'}
                    </Card.Description>
                ) : null}
            </Form.Field>
            <Form.Field
                error={
                    data.recipient_first_name !== null
                        ? error.recipient_first_name
                        ? !!data.recipient_first_name
                        : false
                        : null
                }
            >
                <label>* FIRST NAME</label>
                <input
                    autoComplete='new'
                    placeholder='First Name'
                    value={data.recipient_first_name || ''}
                    onChange={(e) => this.handleChange(
                        'recipient_first_name',
                        e.target.value
                    )}
                />
            </Form.Field>
            <Form.Field
                error={
                    data.recipient_last_name !== null
                        ? error.recipient_last_name
                        ? true
                        : false
                        : null
                }
            >
                <label>* LAST NAME</label>
                <input
                    autoComplete='new'
                    placeholder='Last Name'
                    value={data.recipient_last_name || ''}
                    onChange={(e) => this.handleChange(
                        'recipient_last_name',
                        e.target.value
                    )}
                />
            </Form.Field>
            {!isCurbSide ? (
                <>
                <Form.Field
                    error={
                        data.recipient_address !== null
                            ? error.recipient_address
                            ? true
                            : false
                            : null
                    }
                >
                    <label>* ADDRESS</label>
                    <GooglePlacesAutocomplete
                        autoComplete='new'
                        debounce={600}
                        onSelect={this.handlePlaceSelect}
                        initialValue={data.recipient_address || ''}
                    />
                </Form.Field>
                <Form.Field>
                    <label>ADDRESS 2</label>
                    <input
                        autoComplete='new'
                        placeholder='Address2'
                        value={data.recipient_address2 || ''}
                        onChange={(e) => this.handleChange(
                            'recipient_address2',
                            e.target.value
                        )}
                    />
                </Form.Field>
                <Form.Field
                    error={
                        data.recipient_city !== null
                            ? error.recipient_city
                            ? true
                            : false
                            : null
                    }
                >
                    <label>* CITY</label>
                    <input
                        autoComplete='new'
                        value={data.recipient_city || ''}
                        placeholder='City'
                        onChange={(e) => this.handleChange(
                            'recipient_city',
                            e.target.value
                        )}
                    />
                </Form.Field>
                <Form.Field
                    error={
                        data.recipient_state !== null
                            ? error.recipient_state
                            ? true
                            : false
                            : null
                    }
                >
                    <label>* STATE</label>
                    <Dropdown
                        autoComplete='new'
                        placeholder='State'
                        onChange={this.handleStateChange.bind(this, 'recipient_state')}
                        value={data.recipient_state}
                        search
                        selection
                        options={states.map((state, index) => {
                            return {
                                key: index,
                                value: state.abbreviation,
                                text: state.name,
                            };
                        })}
                    />
                </Form.Field>
                <Form.Field
                    error={
                        data.recipient_zip !== null
                            ? error.recipient_zip
                            ? true
                            : false
                            : null
                    }
                >
                    <label>* ZIP CODE</label>
                    <NumberFormat
                        value={data.recipient_zip || ''}
                        placeholder='Zip code'
                        inputMode='decimal'
                        pattern='[0-9],*'
                        format='#####'
                        onInvalid={(e) => e.preventDefault()}
                        onValueChange={(values) =>
                            this.handleChange('recipient_zip', values.value)
                        }
                    />
                </Form.Field>
                </>
            ) : null}
            <Form.Field
                error={
                    data.recipient_email !== null
                        ? error.recipient_email
                        ? true
                        : false
                        : null
                }
            >
                <label>* EMAIL</label>
                <input
                    autoComplete='new'
                    placeholder='Email'
                    value={data.recipient_email || ''}
                    onChange={(e) => this.handleChange(
                        'recipient_email',
                        e.target.value
                    )}
                />
            </Form.Field>
            <Form.Field
                error={
                    data.recipient_phone !== null
                        ? error.recipient_phone
                        ? true
                        : false
                        : null
                }
            >
                <label>* PHONE NUMBER</label>
                <NumberFormat
                    value={data.recipient_phone || ''}
                    placeholder='Phone Number'
                    inputMode='decimal'
                    pattern='[0-9],*'
                    format='(###) ###-####'
                    mask='_'
                    onInvalid={(e) => e.preventDefault()}
                    onValueChange={(values) =>
                        this.handleChange('recipient_phone', values.value)
                    }
                />
            </Form.Field>
            {isCurbSide ? (
                <>
                <Form.Field>
                    <label>VEHICLE MAKE</label>
                    <Dropdown
                        autoComplete='new'
                        placeholder='Make'
                        onChange={this.handleStateChange.bind(
                            this,
                            'vehicle_make'
                        )}
                        value={data.vehicle_make}
                        search
                        selection
                        options={vehicleMakes.map((make, index) => {
                            return {
                                key: index,
                                value: make,
                                text: make,
                            };
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>VEHICLE MODEL</label>
                    <input
                        autoComplete='new'
                        value={data.vehicle_model || ''}
                        placeholder='Model'
                        onChange={(e) => this.handleChange(
                            'vehicle_model',
                            e.target.value
                        )}
                    />
                </Form.Field>
                <Form.Field>
                    <label>VEHICLE COLOR</label>
                    <input
                        autoComplete='new'
                        value={data.vehicle_color || ''}
                        placeholder='Color'
                        onChange={(e) => this.handleChange(
                            'vehicle_color',
                            e.target.value
                        )}
                    />
                </Form.Field>
                <Form.Field>
                    <label>VEHICLE LICENSE</label>
                    <input
                        autoComplete='new'
                        value={data.vehicle_license || ''}
                        placeholder='License'
                        onChange={(e) => this.handleChange(
                            'vehicle_license',
                            e.target.value
                        )}
                    />
                </Form.Field>
                </>
            ) : null}
            <Form.Field>
                <label>ADDITIONAL COMMENTS</label>
                <TextArea
                    id={"comments"}
                    onFocus={()=>{
                        this.scrollEnd()}
                    }
                    onChange={(e) => this.handleChange(
                        'recipient_comment',
                        e.target.value
                    )}
                    value={data.recipient_comment}
                    placeholder='Add a note or request'
                    style={{minHeight: 100}}
                />
            </Form.Field>
            {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                <>
                    <Form.Button
                        fluid
                        circular
                        disabled={
                            !isEmpty(this.state.error_address) || this.state.loading
                        }
                        onClick={
                            !isCurbSide
                                ? this.updateTicketOrder.bind(this)
                                : this.updateTicketOrderCurbside.bind(this)
                        }
                        style={getConfiguration().button_color}
                    >
                        CONTINUE
                    </Form.Button>
                    <Form.Button
                        fluid
                        circular
                        onClick={this.handleGoBack.bind(this)}
                    >
                        BACK
                    </Form.Button>
                </>
            ) : null}
            </>
        );
    }

    render() {
        const { ticket } = this.props;
        const { data, error_address: error, loading, isCurbSide } = this.state;
        let deliveryCharge = ticket.ticket_settings
            ? ticket.ticket_settings.delivery_charge
            : '$0.00';

        return ticket.ticket_order ? (
            <>
                <Dimmer active={loading} inverted>
                    <Loader size='small' inline='centered'>
                        Loading
                    </Loader>
                </Dimmer>
                <Media at='mobile'>
                    {this.renderMobile({
                        isCurbSide,
                        deliveryCharge,
                        data,
                        error,
                    })}
                </Media>
                <Media greaterThanOrEqual='tablet'>
                    {this.renderDesktop({
                        isCurbSide,
                        deliveryCharge,
                        data,
                        error,
                    })}
                </Media>
            </>
        ) : null;
    }
}

export default connect(
    (state) => {
        const {users, login, ticket, locations} = state;
        return {users, ticket, auth: {...login}, locations};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...loginStore.actionCreators,
                ...ticketStore.actionCreators,
                ...locationStore.actionCreators,
            },
            dispatch
        )
)(AddInformation);
