import React, { Component } from 'react';
import { Button, Form, Grid, Item } from 'semantic-ui-react';
import { injectStripe } from 'react-stripe-elements';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import { isEmpty } from 'lodash';

import { Media } from '../../../AppMedia';
import { getConfiguration } from '../../../store/api/apiClient';
import Config from '../../../config'
import { Logger } from '../../../utils/Logger';

const ELEMENT_OPTIONS = {
    style: {
        base: {
            letterSpacing: '0.025em',
            margin: 0,
            outline: 0,
            lineHeight: '1.21428571em',
            background: '#fff',
            border: '1px solid rgba(34,36,38,.15)',
            borderRadius: '.28571429rem',
            boxShadow: '0 0 0 0 transparent inset',
            transition: 'color .1s ease,border-color .1s',
            padding: 10,
            color: '#fff',
            fontWeight: 600,
            fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            ':focus': {
                color: '#424770',
            },

            '::placeholder': {
                color: '#9BACC8',
            },

            ':focus::placeholder': {
                color: '#CFD7DF',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

class Payment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            errorCard: {
                card_number: {
                    content: 'Please enter a valid credit number',
                    pointing: 'below',
                },
                card_expiry: {
                    content: 'Please enter a valid credit expiry',
                    pointing: 'below',
                },
                card_cvc: {
                    content: 'Please enter a valid credit cvc',
                    pointing: 'below',
                },
            },
        };
    }

    onError = (field, content, complete) => {
        const { data, errorCard } = this.state;
        try {
            if (content) {
                errorCard[field] = {
                    content,
                    pointing: 'below',
                };
            } else {
                delete errorCard[field];
            }
            data[field] = complete;
            this.setState({ ...this.state, data, errorCard });
        } catch (err) {
            Logger.error('MyCard/Payment', 'onError', JSON.stringify(err));
        }
    };

    onSubmit = async () => {
        const {
            cards: { order },
            login,
            stripe,
            elements,
            setLoading,
            showError,
            generateEphemeralKey,
            handleOrderSubmit,
        } = this.props;
        const { firstName, lastName, email } = this.state;
        setLoading(true);
        try {
            const isLoggedIn = login.user.authenticated;
            const bFirstName = isLoggedIn ? order.buyer_first_name : firstName;
            const bLastName = isLoggedIn ? order.buyer_last_name : lastName;
            const bEmail = isLoggedIn ? order.buyer_email : email;
            const cardElement = elements.getElement('cardNumber');
            let name = bFirstName + ' ' + bLastName;
    
            const {paymentMethod, error: createPaymentMethodError} = await stripe
                .createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                    billing_details: { name },
                })
            console.log('Created PaymentMethod:', paymentMethod, createPaymentMethodError);
            if (createPaymentMethodError) {
                Logger.error('MyCard/Payment', 'onSubmit', `Failed to create PaymentMethod: ${createPaymentMethodError.message}`);
                showError({ message: createPaymentMethodError.message })
                return;
            } else {
                Logger.info('MyCard/Payment', 'onSubmit', `Created PaymentMethod: ${paymentMethod.id}`);
            }

            /*
            The following two methods were commented out as these were NOT implemented correctly besides we are not using PaymentIntent API from Stripe at the moment.
            */
            // const {paymentIntent, error: confirmCardPaymentError} = await stripe.confirmCardPayment(Config.client_id + '_secret_' + Config.secret_key, {
            //     payment_method: {
            //         card: cardElement,
            //     },
            // });
            // console.log('confirmCardPayment:', paymentIntent, confirmCardPaymentError);
            // if (confirmCardPaymentError) {
            //     Logger.error('MyCard/Payment', 'onSubmit', `Charge failed: ${confirmCardPaymentError.message}`);
            // } else {
            //     Logger.info('MyCard/Payment', 'onSubmit', `Charge succeeded! PaymentIntent is in state: ${paymentIntent.status}`);
            // }
            //
            // const {setupIntent, error: confirmCardSetupError} = await stripe.confirmCardSetup(Config.client_id + '_secret_' + Config.secret_key, {
            //     payment_method: {
            //         card: cardElement,
            //     },
            // });
            // console.log('confirmCardSetup:', setupIntent, confirmCardSetupError);
            // if (confirmCardSetupError) {
            //     Logger.error('MyCard/Payment', 'onSubmit', `Setup failed: ${confirmCardSetupError.message}`);
            // } else {
            //     Logger.info('MyCard/Payment', 'onSubmit', `Setup succeeded! SetupIntent is in state: ${setupIntent.status}`);
            // }
    
            const {token} = await stripe.createToken({ type: 'card', name });
            const {source} = await stripe.createSource({
                type: 'card',
                owner: {
                    name,
                },
            });
    
            await generateEphemeralKey(bEmail);
            const params = {
                token: token,
                source: source,
                firstName: bFirstName,
                lastName: bLastName,
                email: bEmail,
            }
            await handleOrderSubmit(params);
        } catch (err) {
            Logger.error('MyCard/Payment', 'onSubmit', JSON.stringify(err));
        }
        setLoading(false);
    };

    render() {
        const { login } = this.props;
        const { firstName, lastName, email} = this.state;
        return (
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                            Please enter your payment information.
                        </Item.Header>
                    </Item.Content>
                </Item>
                {!login.user.authenticated && (
                    <Item>
                        <Item.Content>
                            <Grid centered>
                                <Grid.Column mobile={16} computer={6}>
                                    <Form.Input required width={16}
                                        autoComplete='new'
                                        type={'text'}
                                        label={`Buyer's First Name`}
                                        placeholder='First Name'
                                        value={firstName}
                                        onChange={(e) => this.setState({ firstName: e.target.value})}
                                    />
                                </Grid.Column>
                                <Grid.Column mobile={16} computer={6}>
                                    <Form.Input required width={16}
                                        autoComplete='new'
                                        type={'text'}
                                        label={`Buyer's Last Name`}
                                        placeholder='Last Name'
                                        value={lastName}
                                        onChange={(e) => this.setState({ lastName: e.target.value})}
                                    />
                                </Grid.Column>
                                <Grid.Column mobile={16} computer={12}>
                                    <Form.Input required width={16}
                                        autoComplete='new'
                                        type={'email'}
                                        label={`Buyer's Email`}
                                        placeholder='Email address'
                                        value={email}
                                        onChange={(e) => this.setState({ email: e.target.value})}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Item.Content>
                    </Item>
                )}
                <Item>
                    <Item.Content>
                        <Grid centered>
                            <Grid.Column mobile={16} computer={12}>
                                <Form.Group>
                                    <Form.Field width={10}>
                                        <label>*CARD NUMBER</label>
                                        <CardNumberElement
                                            placeholder='Card Number'
                                            options={ELEMENT_OPTIONS}
                                            style={ELEMENT_OPTIONS}
                                            onChange={(e) => {
                                                this.onError(
                                                    'card_number',
                                                    e.error ? e.error.message : e.complete.length < 16 ? 'Not completed yet' : null,
                                                    e.complete
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field width={3}>
                                        <label>*EXPIRATION</label>
                                        <CardExpiryElement
                                            placeholder='Expiry'
                                            style={ELEMENT_OPTIONS}
                                            onChange={(e) => {
                                                this.onError(
                                                    'card_expiry',
                                                    e.error ? e.error.message : null,
                                                    e.complete
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field width={3}>
                                        <label>*CVC</label>
                                        <CardCvcElement
                                            placeholder='CVC'
                                            options={ELEMENT_OPTIONS}
                                            style={ELEMENT_OPTIONS}
                                            onChange={(e) => {
                                                this.onError(
                                                    'card_cvc',
                                                    e.error ? e.error.message : null,
                                                    e.complete
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Grid.Column>
                        </Grid>
                    </Item.Content>
                </Item>
                <Media at='mobile'>
                    <Form.Button
                        fluid
                        circular
                        disabled={
                            !isEmpty(this.state.errorCard) ||
                            this.state.loading ||
                            (!login.user.authenticated && (!firstName || !lastName || !email))
                        }
                        style={getConfiguration().button_color}
                        onClick={this.onSubmit}
                    >
                        PLACE ORDER
                    </Form.Button>
                    <Form.Button
                        fluid
                        circular
                        onClick={() => this.props.handleGoBack()}
                    >
                        BACK
                    </Form.Button>
                </Media>
                <Media greaterThanOrEqual='tablet'>
                    <Item>
                        <Item.Content>
                            <Grid centered>
                                <Grid.Column width={5}>
                                    <Button
                                        fluid
                                        circular
                                        onClick={() => this.props.handleGoBack()}
                                    >
                                        BACK
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Button
                                        fluid
                                        circular
                                        disabled={
                                            !isEmpty(this.state.errorCard) ||
                                            this.state.loading ||
                                            (!login.user.authenticated && (!firstName || !lastName || !email))
                                        }
                                        style={getConfiguration().button_color}
                                        onClick={this.onSubmit}
                                    >
                                        PLACE ORDER
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </Item.Content>
                    </Item>
                </Media>
            </Item.Group>
        );
    }
}

export default injectStripe(Payment);
