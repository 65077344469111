import {client, getConfiguration, getHeaders} from './apiClient'
import {getCurrentTime} from '../../utils/DateTimeHelper'

const locationConfiguration = async () => {
    return await client.request({
        url: '/location/' + getConfiguration().locationId + '/configurations',
        method: "GET"
    })
}
const locationAnnouncement = async (authSkip) => {
    return await client.request({
        url: `/location/${getConfiguration().locationId}/announcements?time=${getCurrentTime()}`,
        method: "GET",
        headers: authSkip ? {} : getHeaders(),
    })
}

const location = async (authSkip) => {
    return await client.request({
        url: '/location/' + getConfiguration().locationId,
        method: "GET",
        headers: authSkip ? {} : getHeaders(),
    })
}
const contactUs = async (message) => {
    return await client.request({
        url: '/location/' + getConfiguration().locationId + '/contact',
        data: {...message},
        method: "POST",
    })
}
export default {
    locationConfiguration,
    locationAnnouncement,
    location,
    contactUs
}
