import cardApi from './api/card';
import { handleError } from './api/errorHandler';

const CHECK_CARD_BALANCE = 'CHECK_CARD_BALANCE';
const CLEAR_CHECK_CARD_BALANCE = 'CLEAR_CHECK_CARD_BALANCE';
const CARDS_LIST = 'CARDS_LIST';
const CLEAR_CARD = 'CLEAR_CARD';
const FETCH_MY_CARDS_START = 'FETCH_MY_CARDS_START';
const FETCH_MY_CARDS = 'FETCH_MY_CARDS';
const FETCH_MY_CARD_START = 'FETCH_MY_CARD_START';
const FETCH_MY_CARD = 'FETCH_MY_CARD';
const CARD_ORDER = 'CARD_ORDER';
const GENERATE_EPHEMERAL_KEY = 'GENERATE_EPHEMERAL_KEY';
const CARD_ORDER_VIEW = 'CARD_ORDER_VIEW';
const CARD_ORDER_SUBMIT = 'CARD_ORDER_SUBMIT';
const CARD_ORDER_CLEAR = 'CARD_ORDER_CLEAR';
const initialState = {
    program: null,
    step: 0,
};

export const actionCreators = {
    checkCardBalance: (data) => async (dispatch, getState) => {
        let callback = async function () {
            dispatch({
                type: CLEAR_CHECK_CARD_BALANCE,
                payload: null,
            });
            let response = await cardApi.checkCardBalance(data.card_number.split('-').join(''));
            dispatch({
                type: CHECK_CARD_BALANCE,
                payload: response.data,
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback);
        }
    },
    clearCardBalance: (data) => async (dispatch, getState) => {
        let callback = async function () {
            dispatch({
                type: CLEAR_CHECK_CARD_BALANCE,
                payload: null,
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback);
        }
    },
    cardsList: () => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.cardsList();
            dispatch({
                type: CARDS_LIST,
                payload: response.data,
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback);
        }
    },
    clearCard: () => async (dispatch, getState) => {
        dispatch({
            type: CLEAR_CARD,
        });
    },
    fetchMyCards: (data) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.fetchMyCards(data);
            dispatch({
                type: FETCH_MY_CARDS,
                payload: response.data.cards,
            });
        };
        try {
            dispatch({
                type: FETCH_MY_CARDS_START,
            });
            await callback();
        } catch (e) {
            dispatch({
                type: FETCH_MY_CARDS,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    fetchMyCard: (cardId) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.fetchMyCard(cardId);
            dispatch({
                type: FETCH_MY_CARD,
                payload: response.data,
            });
        };
        try {
            dispatch({
                type: FETCH_MY_CARD_START,
            });
            await callback();
        } catch (e) {
            dispatch({
                type: FETCH_MY_CARD,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    importCard: (data) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.importCard(data);
            dispatch({
                type: CARD_ORDER,
                payload: response.data,
            });
            dispatch({
                type: FETCH_MY_CARD,
                payload: response.data,
            });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            dispatch({
                type: CARD_ORDER,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    fetchEGiftCard: (cardId) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.fetchEGiftCard(cardId);
            dispatch({
                type: CARD_ORDER,
                payload: response.data,
            });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            const result = { has_error: true, error: e.response.data };
            dispatch({
                type: CARD_ORDER,
                payload: result,
            });
            return result;
        }
    },
    generateEphemeralKey: (email) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.generateEphemeralKey(email);
            dispatch({
                type: GENERATE_EPHEMERAL_KEY,
                payload: response.data,
            });
        };
        try {
            await callback();
        } catch (e) {
            return handleError(e.response, callback);
        }
    },
    fetchMyCardPrograms: (type) => async (dispatch, getState) => {
        let callback = async function () {
            const response = await cardApi.fetchMyCardPrograms();
            let step = 0;
            if (type === 'SendText' || type === 'SendEmail') {
                step = response.data.programs.length === 1 ? 1 : 0; // step = 1; //
            }
            let payload = {
                ...response.data, // programs: [response.data.programs[0]], //
                type,
                step,
            };
            dispatch({
                type: CARD_ORDER,
                payload,
            });
        };
        try {
            await callback();
        } catch (e) {
            dispatch({
                type: CARD_ORDER,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    startCardOrder: (data, type) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.startCardOrder(data.param);
            let payload = {
                ...response.data,
                program: data.program,
                step: data.step,
                type,
            };
            !response.data.has_error &&
                dispatch({
                    type: CARD_ORDER,
                    payload,
                });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            dispatch({
                type: CARD_ORDER,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    viewCardOrder: (id) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.viewCardOrder(id);
            dispatch({
                type: CARD_ORDER_VIEW,
                payload: response.data,
            });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            dispatch({
                type: CARD_ORDER,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    addCardOrder: (id, data, type) => async (dispatch, getState) => {
        let callback = async function () {
            let items = getState().order ? getState().order.items : [];
            data.param.index = items.length;
            items.push(data.param);
            let response = await cardApi.updateCardOrder(id, items);
            let payload = {
                ...response.data,
                step: data.step,
                type,
            };
            !response.data.has_error &&
                dispatch({
                    type: CARD_ORDER,
                    payload,
                });
        };

        try {
            await callback();
        } catch (e) {
            dispatch({
                type: CARD_ORDER,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    updateCardOrder: (id, data, type) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.updateCardOrder(id, data.param);
            let payload = {
                ...response.data,
                step: data.step,
                type,
            };
            !response.data.has_error &&
                dispatch({
                    type: CARD_ORDER,
                    payload,
                });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            dispatch({
                type: CARD_ORDER,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    submitCardOrder: (id, data, type) => async (dispatch, getState) => {
        let callback = async function () {
            let response = await cardApi.submitCardOrder(id, data.param);
            !response.data.has_error &&
                dispatch({
                    type: CARD_ORDER_SUBMIT,
                    payload: response.data,
                });
            return response.data;
        };
        try {
            return await callback();
        } catch (e) {
            dispatch({
                type: CARD_ORDER,
                payload: { has_error: true, error: e.response.data },
            });
            return handleError(e.response, callback);
        }
    },
    clearCardOrder: (type) => async (dispatch, getState) => {
        let payload = {};
        payload[type] = {};
        dispatch({
            type: CARD_ORDER_CLEAR,
            payload,
        });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CHECK_CARD_BALANCE:
            return { ...state, card: action.payload };
        case CLEAR_CHECK_CARD_BALANCE:
            return { ...state, card: null };
        case CARDS_LIST:
            return { ...state, cards_list: action.payload };
        case CLEAR_CARD:
            return { step: 0 };
        case FETCH_MY_CARDS_START:
            return { ...state, my_cards: null };
        case FETCH_MY_CARDS:
            return { ...state, my_cards: action.payload, my_card: {} };
        // case FETCH_MY_CARD_START:
        //     return { ...state, my_card: {} };
        case FETCH_MY_CARD:
            return { ...state, my_card: {...state.my_card, ...action.payload }};
        case CARD_ORDER:
            return { ...state, ...action.payload };
        case CARD_ORDER_VIEW:
            return { ...state, ...action.payload };
        case CARD_ORDER_SUBMIT:
            return { ...action.payload, step: 0 };
        case CARD_ORDER_CLEAR:
            return { program: null, step: 0 };
        default:
            return state;
    }
};
