import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  Grid,
  Segment,
  Table,
  Button,
  Input,
  Icon,
  Header,
  Form,
  Card,
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { Media } from '../../AppMedia';
import AppMessage from '../common/ui/AppMessage';
import CacheBuster from '../../CacheBuster';
import { actionCreators } from '../../store/Users';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as cartStore from '../../store/Cart';
import { getConfiguration } from '../../store/api/apiClient';
import { Logger } from '../../utils/Logger';

class Cart extends Component {
    state = {
        data: {},
        items: [],
        saving: -1,
        error: {},
    }

    componentWillMount() {
        this.props.locationConfiguration()
        if (!this.props.cart.order) {
            this.props.history.push("/physicalcards")
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
      try {
        let items = nextProps.cart.order ? nextProps.cart.order.items : []
        this.setState({items: JSON.parse(JSON.stringify(items))})
      } catch (err) {
        Logger.error('Cart', 'componentWillReceiveProps', JSON.stringify(err));
      }
    }

    handleChangeQuantity(field, index, e) {
        let error = this.state.error;
        try {
          if (e.target.value === "") {
              if (!error[index]) {
                  error[index] = {}
              }
              error[index][field] = {
                  content: "Please enter a valid " + field,
                  pointing: 'below',
              }
          } else {
              if (!isEmpty(error[index])) {
                  delete error[index][field]
                  if (isEmpty(error[index])) {
                      delete error[index]
                  }
              }
          }
  
          let items = this.state.items
          items = items.map(item => {
              if (item.index === index) {
                  if (e.target.value === "") {
                      item[field] = ""
                  } else if ((!e.target.validity.badInput && Number(e.target.value) > 0)) {
                      item[field] = Number(e.target.value)
                  }
              }
              return item
          })
          this.setState({items, saving: -1, error})
        } catch (err) {
          Logger.error('Cart', 'handleChangeQuantity', JSON.stringify(err));
        }
    }

    handleRemove(index, e) {
        let error = this.state.error;
        try {
          delete error[index]
  
          let items = this.state.items
          items = items.filter(item => {
              return item.index !== index
          })
  
          this.setState({items, saving: -1, error})
        } catch (err) {
          Logger.error('Cart', 'handleRemove', JSON.stringify(err));
        }
    }

    handleChange(field, e) {
        let data = this.state.data
        try {
          data[field] = e.target.value
          this.setState({data, saving: -1})
        } catch (err) {
          Logger.error('Cart', 'handleChange', JSON.stringify(err));
        }
    }

    async handleCoupon() {
        this.setState({saving: 1})
        try {
          const errors = await this.props.applyCoupon(this.state.data)
          if (errors !== undefined) {
              this.setState({saving: 2, errors})
          } else {
              this.setState({saving: 0})
              this.toggleMessage()
          }
        } catch (err) {
          Logger.error('Cart', 'handleCoupon', JSON.stringify(err));
        }
    }

    async handleUpdateCart() {
        this.setState({saving: 1})
        try {
          const errors = await this.props.updateCartItems(this.state.items)
          if (errors !== undefined) {
              const items = this.props.cart.order ? this.props.cart.order.items : []
              this.setState({saving: 2, errors, items: JSON.parse(JSON.stringify(items))})
          } else {
              this.setState({saving: 0})
              this.toggleMessage()
          }
        } catch (err) {
          Logger.error('Cart', 'handleUpdateCart', JSON.stringify(err));
        }
    }

    toggleMessage() {
        setTimeout(() => this.setState({saving: -1}), 3000)
    }

    isSame() {
        let originalItems = this.props.cart.order ? this.props.cart.order.items : []
        return JSON.stringify(originalItems) === JSON.stringify(this.state.items)
    }

    handleCancel() {
      try {
        let items = this.props.cart.order ? this.props.cart.order.items : []
        this.setState({items: JSON.parse(JSON.stringify(items)), error: {}})
      } catch (err) {
        Logger.error('Cart', 'handleCancel', JSON.stringify(err));
      }
    }

    renderDesktop(params) {
        const { items } = params;

        return (
            <Grid textAlign='center' verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 1050}} textAlign='left'>
                    <Segment raised style={{
                        padding: 40,
                        background: '#ffffffed',
                        borderRadius: 5,
                        border: '1px solid darkgray'
                    }}>
                        <Header as='h3' style={getConfiguration().label_color}>
                            Cart
                        </Header>
                        <Table compact>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Product</Table.HeaderCell>
                                    <Table.HeaderCell>Price</Table.HeaderCell>
                                    <Table.HeaderCell>Quantity</Table.HeaderCell>
                                    <Table.HeaderCell>Subtotal</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    items.map(item => {
                                            return (<Table.Row key={item.index}>
                                                <Table.Cell>
                                                    <Button basic icon onClick={this.handleRemove.bind(this, item.index)}>
                                                        <Icon name='times'/>
                                                    </Button>
                                                </Table.Cell>
                                                <Table.Cell>{item.item}</Table.Cell>
                                                <Table.Cell>
                                                    <Form>
                                                        <Form.Field
                                                            error={this.state.error[item.index] ? this.state.error[item.index].price ? true : false : null}>
                                                            <NumberFormat
                                                                placeholder={"Price"}
                                                                value={item.price}
                                                                decimalScale={2}
                                                                onInvalid={(e) => e.preventDefault()}
                                                                onChange={this.handleChangeQuantity.bind(this, 'price', item.index)}
                                                            />
                                                        </Form.Field>
                                                    </Form>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Form>
                                                        <Form.Field
                                                            error={this.state.error[item.index] ? this.state.error[item.index].quantity ? true : false : null}>
                                                            <input type={'text'} step="1" placeholder='Quantity' min="1"
                                                                   inputMode="numeric" pattern="[0-9]*"
                                                                   value={item.quantity}
                                                                   onChange={this.handleChangeQuantity.bind(this, 'quantity', item.index)}/>
                                                        </Form.Field>
                                                    </Form>
                                                </Table.Cell>
                                                <Table.Cell>{"$" + Number(item.price * item.quantity).toFixed(2)}</Table.Cell>
                                            </Table.Row>)
                                        }
                                    )
                                }

                            </Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='2'>
                                        Have a promo code? &nbsp;
                                        <Input type='text' placeholder='Coupon Code' action>
                                            <input onChange={this.handleChange.bind(this, 'promo_code')}/>
                                            <Button type='submit' onClick={this.handleCoupon.bind(this)}>Apply</Button>
                                        </Input>

                                    </Table.HeaderCell>
                                    <Table.HeaderCell colSpan='3' textAlign={'right'}>
                                        <Button disabled={this.isSame() || !isEmpty(this.state.error)}
                                                onClick={this.handleUpdateCart.bind(this)}>
                                            Update Cart
                                        </Button>
                                        {this.isSame() ? null : <Button onClick={this.handleCancel.bind(this)}>
                                            Cancel
                                        </Button>}
                                        <Button as={Link}
                                                to={'/physicalcards'}
                                                name={'/physicalcards'}
                                                icon
                                                style={getConfiguration().button_color}>
                                            <Icon name='plus'/>
                                            &nbsp; Add Another
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                        {this.props.cart.order ?
                            <Grid doubling columns={2}>
                                <Grid.Column floated='right' width={5}>
                                    <Header as='h3' style={getConfiguration().label_color}>
                                        Cart totals
                                    </Header>
                                    <Table singleLine definition>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.HeaderCell>Subtotal</Table.HeaderCell>
                                                <Table.Cell>${this.props.cart.order.totals.subtotal}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Discount</Table.HeaderCell>
                                                <Table.Cell>${this.props.cart.order.totals.discount}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Shipping Handling</Table.HeaderCell>
                                                <Table.Cell>${this.props.cart.order.totals.shipping_handling}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Total</Table.HeaderCell>
                                                <Table.Cell>{this.props.cart.order.totals.total}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <Button as={Link}
                                            to={'/checkout'}
                                            name={'/checkout'}
                                            fluid
                                            floated="right"
                                            icon
                                            disabled={(!isEmpty(this.state.error) || items.length === 0 ) || !this.isSame()}
                                            style={getConfiguration().button_color}>
                                        <Icon name='check'/>
                                        &nbsp; &nbsp; Proceed to Checkout</Button>
                                </Grid.Column>
                            </Grid> : null}
                        <br/>
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }

    renderMobile(params) {
      const { items } = params;
      return (
        <Segment
          style={{
            background: '#ffffffe6',
            borderRadius: 5,
            border: 'none',
            textAlign: 'left',
            maxWidth: 1100,
            margin: '0 auto',
          }}
        >
          <Header as='h3' style={getConfiguration().label_color}>
            Cart
          </Header>
          <Card.Group>
            {items.map((item) => {
              return (
                <Card fluid key={item.index}>
                  <Card.Content>
                    <Button
                      floated='right'
                      basic
                      icon
                      size='small'
                      onClick={this.handleRemove.bind(this, item.index)}
                    >
                      <Icon name='times' />
                    </Button>
                  </Card.Content>
                  <Card.Content>
                    <div style={{ float: 'right' }}>{item.item}</div>
                    <Card.Header>Product</Card.Header>
                  </Card.Content>
                  <Card.Content extra>
                    <Form>
                      <Form.Field
                        error={
                          this.state.error[item.index]
                            ? this.state.error[item.index].price
                              ? true
                              : false
                            : null
                        }
                      >
                        <label>Price</label>
                        <NumberFormat
                          placeholder={'Price'}
                          value={item.price}
                          decimalScale={2}
                          onInvalid={(e) => e.preventDefault()}
                          onChange={this.handleChangeQuantity.bind(
                            this,
                            'price',
                            item.index
                          )}
                        />
                      </Form.Field>
                      <Form.Field
                        error={
                          this.state.error[item.index]
                            ? this.state.error[item.index].quantity
                              ? true
                              : false
                            : null
                        }
                      >
                        <label>Quantity</label>
                        <input
                          type={'text'}
                          step='1'
                          placeholder='Quantity'
                          min='1'
                          inputMode='numeric'
                          pattern='[0-9]*'
                          value={item.quantity}
                          onChange={this.handleChangeQuantity.bind(
                            this,
                            'quantity',
                            item.index
                          )}
                        />
                      </Form.Field>
                    </Form>
                  </Card.Content>
                  <Card.Content extra>
                    <div style={{ float: 'right' }}>
                      {`$${Number(item.price * item.quantity).toFixed(2)}`}
                    </div>
                    <Card.Header>Subtotal</Card.Header>
                  </Card.Content>
                </Card>
              );
            })}
          </Card.Group>
          <Form>
            <Form.Field>
              <div style={{ padding: '10px 0' }}>Have a promo code?</div>
              <Input type='text' placeholder='Coupon Code' action>
                <input onChange={this.handleChange.bind(this, 'promo_code')} />
                <Button type='submit' onClick={this.handleCoupon.bind(this)}>
                  Apply
                </Button>
              </Input>
            </Form.Field>
  
            <Form.Field className='ui two buttons' style={{ marginBottom: 10 }}>
              <Button
                disabled={this.isSame() || !isEmpty(this.state.error)}
                onClick={this.handleUpdateCart.bind(this)}
              >
                Update Cart
              </Button>
              {this.isSame() ? null : (
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={this.handleCancel.bind(this)}
                >
                  Cancel
                </Button>
              )}
            </Form.Field>
            <Form.Field>
              <Button
                fluid
                as={Link}
                to={'/physicalcards'}
                name={'/physicalcards'}
                icon
                style={getConfiguration().button_color}
              >
                <Icon name='plus' />
                &nbsp; Add Another
              </Button>
            </Form.Field>
          </Form>
          <Card fluid>
            <Card.Content>
              <Header as='h3' style={getConfiguration().label_color}>
                Cart totals
              </Header>
            </Card.Content>
            <Card.Content>
              <div style={{ float: 'right' }}>
                {`$${this.props.cart.order.totals.subtotal}`}
              </div>
              <Card.Header>Subtotal</Card.Header>
            </Card.Content>
            <Card.Content>
              <div style={{ float: 'right' }}>
                {`$${this.props.cart.order.totals.discount}`}
              </div>
              <Card.Header>Discount</Card.Header>
            </Card.Content>
            <Card.Content>
              <div style={{ float: 'right' }}>
                {`$${this.props.cart.order.totals.shipping_handling}`}
              </div>
              <Card.Header>Shipping Handling</Card.Header>
            </Card.Content>
            <Card.Content>
              <div style={{ float: 'right' }}>
                {this.props.cart.order.totals.total}
              </div>
              <Card.Header>Total</Card.Header>
            </Card.Content>
            <Card.Content>
              <Button
                as={Link}
                to={'/checkout'}
                name={'/checkout'}
                fluid
                icon
                disabled={
                  !isEmpty(this.state.error) ||
                  items.length === 0 ||
                  !this.isSame()
                }
                style={getConfiguration().button_color}
              >
                <Icon name='check' /> Proceed to Checkout
              </Button>
            </Card.Content>
          </Card>
        </Segment>
      );
    }
  
    render() {
      let items = this.state.items;
  
      return (
        <CacheBuster>
            {({loading, isLatestVersion, refreshCacheAndReload}) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    console.log('BROWSER IS BEING REFRESHED!');
                    refreshCacheAndReload();
                }
                return (
                  <>
                    <AppMessage saving={this.state.saving} errors={this.state.errors} />
                    <Media at='mobile'>
                      {this.renderMobile({ items })}
                    </Media>
                    <Media greaterThanOrEqual='tablet'>
                      {this.renderDesktop({ items })}
                    </Media>
                  </>
                );
            }}
        </CacheBuster>
      );
    }
}
  
export default connect(
  (state) => {
    const { users, login, cart } = state;
    return { users, cart, auth: { ...login } };
  },
  (dispatch) =>
    bindActionCreators(
      {
        ...actionCreators,
        ...loginStore.actionCreators,
        ...cartStore.actionCreators,
        ...locationStore.actionCreators,
      },
      dispatch
    )
)(Cart);
