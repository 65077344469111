import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import {
  Form,
  Header,
  Loader,
  Dimmer,
  Card,
} from 'semantic-ui-react';

import { Media } from '../../AppMedia';
import { getConfiguration } from '../../store/api/apiClient';

const baseStyle = {
    letterSpacing: '0.025em',
    margin: 0,
    outline: 0,
    lineHeight: '1.21428571em',
    background: '#fff',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: '.28571429rem',
    boxShadow: '0 0 0 0 transparent inset',
    transition: 'color .1s ease,border-color .1s',
    padding: 10,
    color: '#fff',
    fontWeight: 600,
    fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    ':focus': {
        color: '#424770',
    },

    '::placeholder': {
        color: '#9BACC8',
    },

    ':focus::placeholder': {
        color: '#CFD7DF',
    },
}

const ELEMENT_OPTIONS = {
    style: {
        base: baseStyle,
        invalid: {
            color: '#9e2146',
        },
    },
};

class CardSection extends React.Component {
    handleChange(field, e) {
        this.props.update(field, e.target.value)
    }

    renderDesktop() {
        return (
            <>
            <br/>
            <Header as='h3' style={getConfiguration().label_color}>
                Payment Information
            </Header>
            <br/>
            <Form autoComplete="new-password">

                {
                    this.props.email ? null :
                        <>
                        <Form.Field
                            error={this.props.data.buyer_email !== undefined ? this.props.error.buyer_email : null}>
                            <label>Buyer's Email: *</label>
                            <input autoComplete="new-password" type={'text'} placeholder='Email'
                                   onChange={this.handleChange.bind(this, 'buyer_email')}/>
                        </Form.Field>
                        <Form.Field
                            error={this.props.data.buyer_first_name !== undefined ? this.props.error.buyer_first_name : null}>
                            <label>Buyer's First Name: *</label>
                            <input autoComplete="new-password" type={'text'} placeholder='First Name'
                                   onChange={this.handleChange.bind(this, 'buyer_first_name')}/>
                        </Form.Field>
                        <Form.Field
                            error={this.props.data.buyer_last_name !== undefined ? this.props.error.buyer_last_name : null}>
                            <label>Buyer's Last Name: *</label>
                            <input autoComplete="new-password" type={'text'} placeholder='Last Name'
                                   onChange={this.handleChange.bind(this, 'buyer_last_name')}/>
                        </Form.Field>
                        </>
                }
                <br/>
                {/*<CardElement style={{base: {fontSize: '18px'}}}*/}
                {/*onChange={(e) => this.props.onError('card', e.error ? e.error.message : null, e.complete)*/}
                {/*}/>*/}
                <Form.Field
                    error={this.props.data.card_number !== undefined ? this.props.error.card_number : null}>
                    <label>Card Number: *</label>
                    <CardNumberElement
                        placeholder="Card Number"
                        options={ELEMENT_OPTIONS}
                        style={ELEMENT_OPTIONS}
                        onChange={(e) => {
                            this.props.onError('card_number', e.error ? e.error.message : null, e.complete)
                        }}
                    />

                </Form.Field>
                <Form.Group widths={'equal'}>
                    <Form.Field
                        error={this.props.data.card_expiry !== undefined ? this.props.error.card_expiry : null}>
                        <label>Expiry: *</label>
                        <CardExpiryElement
                            placeholder="Expiry"
                            style={ELEMENT_OPTIONS}
                            onChange={(e) => {
                                this.props.onError('card_expiry', e.error ? e.error.message : null, e.complete)
                            }}
                        />
                    </Form.Field>
                    <Form.Field
                        error={this.props.data.card_cvc !== undefined ? this.props.error.card_cvc : null}>
                        <label>CVC: *</label>
                        <CardCvcElement
                            placeholder="CVC"
                            options={ELEMENT_OPTIONS}
                            style={ELEMENT_OPTIONS}
                            onChange={(e) => {
                                this.props.onError('card_cvc', e.error ? e.error.message : null, e.complete)
                            }}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>

            <br/>
            <br/>
            </>
        );
    }

    renderMobile() {
      return (
        <Card fluid style={{ marginBottom: 15 }}>
          <Card.Content>
            <Card.Header as='h3' style={getConfiguration().label_color}>
              Payment Information
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <Form autoComplete='new-password'>
              {this.props.email ? null : (
                <>
                  <Form.Field
                    error={
                      this.props.data.buyer_email !== undefined
                        ? this.props.error.buyer_email
                        : null
                    }
                  >
                    <label>Buyer's Email: *</label>
                    <input
                      autoComplete='new-password'
                      type={'text'}
                      placeholder='Email'
                      onChange={this.handleChange.bind(this, 'buyer_email')}
                    />
                  </Form.Field>
                  <Form.Field
                    error={
                      this.props.data.buyer_first_name !== undefined
                        ? this.props.error.buyer_first_name
                        : null
                    }
                  >
                    <label>Buyer's First Name: *</label>
                    <input
                      autoComplete='new-password'
                      type={'text'}
                      placeholder='First Name'
                      onChange={this.handleChange.bind(this, 'buyer_first_name')}
                    />
                  </Form.Field>
                  <Form.Field
                    error={
                      this.props.data.buyer_last_name !== undefined
                        ? this.props.error.buyer_last_name
                        : null
                    }
                  >
                    <label>Buyer's Last Name: *</label>
                    <input
                      autoComplete='new-password'
                      type={'text'}
                      placeholder='Last Name'
                      onChange={this.handleChange.bind(this, 'buyer_last_name')}
                    />
                  </Form.Field>
                </>
              )}
              <Form.Field
                error={
                  this.props.data.card_number !== undefined
                    ? this.props.error.card_number
                    : null
                }
              >
                <label>Card Number: *</label>
                <CardNumberElement
                  placeholder='Card Number'
                  options={ELEMENT_OPTIONS}
                  style={ELEMENT_OPTIONS}
                  onChange={(e) => {
                    this.props.onError(
                      'card_number',
                      e.error ? e.error.message : null,
                      e.complete
                    );
                  }}
                />
              </Form.Field>
              <Form.Field
                error={
                  this.props.data.card_expiry !== undefined
                    ? this.props.error.card_expiry
                    : null
                }
              >
                <label>Expiry: *</label>
                <CardExpiryElement
                  placeholder='Expiry'
                  style={ELEMENT_OPTIONS}
                  onChange={(e) => {
                    this.props.onError(
                      'card_expiry',
                      e.error ? e.error.message : null,
                      e.complete
                    );
                  }}
                />
              </Form.Field>
              <Form.Field
                error={
                  this.props.data.card_cvc !== undefined
                    ? this.props.error.card_cvc
                    : null
                }
              >
                <label>CVC: *</label>
                <CardCvcElement
                  placeholder='CVC'
                  options={ELEMENT_OPTIONS}
                  style={ELEMENT_OPTIONS}
                  onChange={(e) => {
                    this.props.onError(
                      'card_cvc',
                      e.error ? e.error.message : null,
                      e.complete
                    );
                  }}
                />
              </Form.Field>
            </Form>
          </Card.Content>
        </Card>
      );
    }
  
    render() {
      return (
        <>
          <Dimmer active={this.props.loading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Media at='mobile'>
            {this.renderMobile()}
          </Media>
          <Media greaterThanOrEqual='tablet'>
            {this.renderDesktop()}
          </Media>
        </>
      );
    }
}
  
export default CardSection;