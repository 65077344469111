import ConfigDaily from './index.daily';
import ConfigProd from './index.prod';
import ConfigStage from './index.stage';

const Config = {
  daily: ConfigDaily,
  prod: ConfigProd,
  stage: ConfigStage,
}

export default Config[process.env.REACT_APP_ENV || 'prod'];
