import React from "react";
import {Card, Grid, Header, Image} from "semantic-ui-react";

export default class ComponentToPrint extends React.Component {
    render() {
        let catering_page = this.props.catering_page
        let footer = this.props.footer
        let app_icon = this.props.app_icon
        return (
            <Card fluid>
                <Card.Content>
                    <Card.Description
                        dangerouslySetInnerHTML={{
                            __html: this.props.menus.details,
                        }}
                    />
                </Card.Content>
            </Card>
        );
    }
}
