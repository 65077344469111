import {client, getConfiguration, getHeaders} from './apiClient'
import {getCurrentTime} from '../../utils/DateTimeHelper'

const createTicketOrder = async () => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/create?time=${getCurrentTime()}`,
        method: "GET",
        headers: getHeaders(),
    })
}

const updateTicketOrderItems = async (id, data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}/items?time=${getCurrentTime()}`,
        method: "POST",
        headers: getHeaders(),
        data
    })
}
const updateTicketOrderDelivery = async (id, data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}?time=${getCurrentTime()}`,
        method: "PUT",
        headers: getHeaders(),
        data
    })
}
const updateTicketOrderPromo = async (id, data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}/promocode?time=${getCurrentTime()}`,
        method: "POST",
        headers: getHeaders(),
        data
    })
}

const updateTicketOrderTip = async (id, tip) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}/tip?time=${getCurrentTime()}`,
        method: "POST",
        headers: getHeaders(),
        data: {
            tip
        }
    })
}

const getTicketOrder = async (id) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}?time=${getCurrentTime()}`,
        method: "GET",
        headers: getHeaders(),
    })
}

const getCartInformation = async (id) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}?time=${getCurrentTime()}`,
        method: "GET",
        headers: getHeaders(),
    })
}
const applyCoupon = async (id, data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}?time=${getCurrentTime()}`,
        method: "PUT",
        headers: getHeaders(),
        data
    })
}

const updateCart = async (id, data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}?time=${getCurrentTime()}`,
        method: "PUT",
        headers: getHeaders(),
        data
    })
}
const checkout = async (id, data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}?time=${getCurrentTime()}`,
        method: "PUT",
        headers: getHeaders(),
        data
    })
}

const generateEphemeralKey = async (email) => {
    return await client.request({
        url: 'location/' + getConfiguration().locationId + '/payment/ephemeralkey',
        method: "POST",
        headers: getHeaders(),
        data: {
            stripe_version: "2019-05-16",
            email
        }
    })
}

const addRewardPaymentTicketOrder = async (id, reward) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}/reward?time=${getCurrentTime()}`,
        method: "POST",
        headers: getHeaders(),
        timeout: 2 * 60000,
        data: {
            reward_id: reward
        }
    })
}
const addGiftCardPaymentTicketOrder = async (id, giftcard) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}/redeemcard?time=${getCurrentTime()}`,
        method: "POST",
        headers: getHeaders(),
        timeout: 2 * 60000,
        data: giftcard
    })
}

const addGiftCardImportAndRedeemPaymentTicketOrder = async (id, giftcard) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}/importandredeemcard?time=${getCurrentTime()}`,
        method: "POST",
        headers: getHeaders(),
        data: giftcard
    })
}

const addPaymentTicketOrder = async (id, tip, amount, token, payment_source, stripe_customer_id) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/ticketorder/${id}/payment?time=${getCurrentTime()}`,
        method: "POST",
        headers: getHeaders(),
        timeout: 2 * 60000,
        data: {
            tip,
            amount,
            payment_source,
            stripe_customer_id
        }
    })
}
const submitTicketOrder = async (id, phone_number) => {
    return await client.request({
        url: `/v2.0/location/${getConfiguration().locationId}/ticketorder/${id}/submit?time=${getCurrentTime()}`,
        method: "POST",
        headers: getHeaders(),
        data: {
            phone_number
        }
    })
}
const getReceiptInformation = async (type, objectId) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/receipt?type=${type}&object_id=${objectId}`,
        method: "GET",
        headers: getHeaders(),
    })
}

export default {
    getTicketOrder,
    createTicketOrder,
    getCartInformation,
    applyCoupon,
    updateTicketOrderItems,
    updateTicketOrderDelivery,
    updateTicketOrderPromo,
    updateTicketOrderTip,
    checkout,
    generateEphemeralKey,
    addRewardPaymentTicketOrder,
    addPaymentTicketOrder,
    addGiftCardPaymentTicketOrder,
    addGiftCardImportAndRedeemPaymentTicketOrder,
    submitTicketOrder,
    getReceiptInformation
}
