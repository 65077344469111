import {client, getHeaders, getConfiguration} from './apiClient'

const checkCardBalance = async (cardNumber) => {
    return await client.request({
        url: 'location/' + getConfiguration().locationId + '/physicalcard/checkbalance/' + cardNumber,
        method: "GET",
        headers: getHeaders()
    })
}

const cardsList = async () => {
    return await client.request({
        url: '/card/list',
        method: "POST",
        headers: getHeaders()
    })
}

const fetchMyCards = async (data) => {
    const url = `location/${getConfiguration().locationId}/cards?showall=${data.showall ? true : false}`;
    return await client.request({
        url,
        method: "GET",
        headers: getHeaders(),
        data,
    })
}

const fetchMyCard = async (cardId) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/card/${cardId}`,
        method: "GET",
        headers: getHeaders(),
    })
}

const importCard = async (data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/card/import`,
        method: "POST",
        headers: getHeaders(),
        data
    })
}

const fetchEGiftCard = async (cardId) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/card/${cardId}/egift`,
        method: "GET",
        headers: getHeaders(),
    })
}

const generateEphemeralKey = async (email) => {
    return await client.request({
        url: 'location/' + getConfiguration().locationId + '/payment/ephemeralkey',
        method: "POST",
        headers: getHeaders(),
        data: {
            stripe_version: "2019-05-16",
            email
        }
    })
}

const fetchMyCardPrograms = async () => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/programs`,
        method: "GET",
        headers: getHeaders(),
    })
}

const startCardOrder = async (data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/card/order/start`,
        method: "POST",
        headers: getHeaders(),
        data
    })
}

const viewCardOrder = async (id) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/card/order/${id}`,
        method: "GET",
        headers: getHeaders(),
    })
}

const updateCardOrder = async (id, data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/card/order/${id}`,
        method: "PUT",
        headers: getHeaders(),
        data
    })
}

const submitCardOrder = async (id, data) => {
    return await client.request({
        url: `location/${getConfiguration().locationId}/card/order/${id}/submit`,
        method: "POST",
        headers: getHeaders(),
        data
    })
}

export default {
    checkCardBalance,
    cardsList,
    fetchMyCards,
    fetchMyCard,
    importCard,
    fetchEGiftCard,
    generateEphemeralKey,
    fetchMyCardPrograms,
    startCardOrder,
    viewCardOrder,
    updateCardOrder,
    submitCardOrder,
}