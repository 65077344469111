import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Grid, Header,} from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';

class TicketHistory extends Component {

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <Grid padded>
                            <Grid.Column textAlign='center'>
                                <Header as='h3'>Comming Soon!</Header>
                            </Grid.Column>
                        </Grid>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, locations } = state;
        const { user } = users;
        return { user, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
            },
            dispatch
        )
)(withRouter(TicketHistory));
