import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import NumberFormat from 'react-number-format';
import {
    Grid,
    Table,
    Button,
    Form,
    Icon,
    Header,
    Card,
    Message,
    Dropdown,
    Dimmer,
    Loader,
    Placeholder,
    Item,
} from 'semantic-ui-react';
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import {differenceInDays, isYesterday} from 'date-fns';
import querystring from 'query-string';
import {isEmpty, range} from 'lodash';

import {Media} from '../../AppMedia';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import * as cartStore from '../../store/Cart';
import {Logger} from '../../utils/Logger';

import {getConfiguration} from '../../store/api/apiClient';
import {toFormatCurrencyWithCent} from '../../utils/NumberHelper';
import states from '../../assets/states.json';
import vehicleMakes from '../../assets/vehicle_makes.json';

class TicketView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            items: [],
            saving: -1,
            error: {},
            error_address: {
                recipient_address: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_city: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_state: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                recipient_zip: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
                phone_number: {
                    content: 'Please enter a valid data',
                    pointing: 'below',
                },
            },
            ...querystring.parse(props.location.search),
        };
    }

    componentWillMount() {
        this.props.locationConfiguration();
        this.props.getTicketHistory(this.props.match.params.ticketId);
    }

    componentWillReceiveProps(nextProps) {
        let successMessage = nextProps.ticket.history.success_message;
        let showError = false;
        let items = nextProps.ticket.history.ticket_order
            ? nextProps.ticket.history.ticket_order.items
            : [];
        let data = nextProps.ticket.history.ticket_order;
        if (data) {
            let isCurbSide = data ? data.use_curbside : false;
            data.phone_number = data.recipient_phone;
            let error_address = this.state.error_address;

            if (!isCurbSide) {
                if (data.phone_number) {
                    delete error_address['phone_number'];
                }
                if (data.recipient_address) {
                    delete error_address['recipient_address'];
                }
                if (data.recipient_city) {
                    delete error_address['recipient_city'];
                }
                if (data.recipient_state) {
                    delete error_address['recipient_state'];
                }
                if (data.recipient_zip) {
                    delete error_address['recipient_zip'];
                }
            }

            this.setState({
                data,
                error_address,
                isCurbSide,
            });
        }

        this.setState({
            data,
            items: JSON.parse(JSON.stringify(items)),
            successMessage,
            showError,
        });
    }

    isSame() {
        let originalItems = this.props.ticket.history.ticket_order
            ? this.props.ticket.history.ticket_order.items
            : [];
        return JSON.stringify(originalItems) === JSON.stringify(this.state.items);
    }

    handleStateChange(field, e, target) {
        let data = {...this.state.data};
        let error_address = {...this.state.error_address};
        try {
            if (field === 'recipient_state') {
                if (target.value) {
                    delete error_address[field];
                } else {
                    error_address[field] = {
                        content: 'Please enter a valid info',
                        pointing: 'below',
                    };
                }
            }

            data[field] = target.value;
            this.setState({data, error_address});
        } catch (err) {
            Logger.error('TicketView', 'handleStateChange', JSON.stringify(err));
        }
    }

    handleChange(field, e) {
        let data = {...this.state.data};
        let error_address = {...this.state.error_address};
        try {
            if (
                field === 'phone_number' ||
                field === 'recipient_address' ||
                field === 'recipient_city' ||
                field === 'recipient_state' ||
                field === 'recipient_zip'
            ) {
                if (e.target.value) {
                    delete error_address[field];
                } else {
                    error_address[field] = {
                        content: 'Please enter a valid info',
                        pointing: 'below',
                    };
                }
            }

            if (field === 'phone_number' && e.target.value) {
                let phone = e.target.value.replace(/\D/g, '');
                if (phone.length === 10) {
                    delete error_address.recipient_email;
                } else {
                    error_address.phone_number = {
                        content: 'Please enter a valid phone number',
                        pointing: 'below',
                    };
                }
            }

            data[field] = e.target.value;
            this.setState({data, error_address});
        } catch (err) {
            Logger.error('TicketView', 'handleChange', JSON.stringify(err));
        }
    }

    handlePlaceSelect = (data) => {
        geocodeByAddress(data.description)
            .then((results) => {
                this.handleGeocode(results[0], data);
            })
            .catch(err => Logger.error('TicketView', 'handlePlaceSelect', JSON.stringify(err)));
    };

    handleGeocode(addressResult, addr) {
        let data = {...this.state.data};
        let error_address = {...this.state.error_address};

        if (addr.types[0] === 'street_address') {
            data.recipient_address = this.getAddressType(addressResult, ['street_number', 'route']);
            data.recipient_city = this.getAddressType(addressResult, ['locality']) || this.getAddressType(addressResult, ['neighborhood']);
        } else {
            data.recipient_address = `${addr.terms[0].value} ${this.getAddressType(addressResult, ['route'])}`;
            data.recipient_city = addr.terms[2].value;
        }
        data.recipient_state = this.getAddressType(addressResult, ['administrative_area_level_1']);
        data.recipient_zip = this.getAddressType(addressResult, ['postal_code']);

        if (data.recipient_address) delete error_address.recipient_address;
        if (data.recipient_city) delete error_address.recipient_city;
        if (data.recipient_state) delete error_address.recipient_state;
        if (data.recipient_zip) delete error_address.recipient_zip;

        this.setState({data, error_address});
    }

    getAddressType(addressResult, types) {
        let geocodeAddress = [];
        types.forEach((type) => {
            addressResult.address_components.forEach((geocode) => {
                if (geocode.types.indexOf(type) !== -1) {
                    return geocodeAddress.push(geocode.short_name);
                }
            });
        });
        return geocodeAddress.join(' ');
    }

    async updateTicketOrderDelivery(e) {
        e.preventDefault();
        try {
            this.setState({loading: true});
            let data = {
                phone_number: this.state.data.phone_number,
                recipient_phone: this.state.data.phone_number,
                recipient_address: this.state.data.recipient_address,
                recipient_city: this.state.data.recipient_city,
                recipient_state: this.state.data.recipient_state,
                recipient_zip: this.state.data.recipient_zip,
            };
            await this.props.updateTicketHistoryDelivery(data, this.props.match.params.ticketId);
            this.setState({editMode: false, loading: false});
        } catch (err) {
            Logger.error('TicketView', 'updateTicketOrderDelivery', JSON.stringify(err));
        }
    }

    async updateTicketOrderCurbside(e) {
        e.preventDefault();
        try {
            this.setState({loading: true});
            let data = {
                phone_number: this.state.data.phone_number,
                vehicle_make: this.state.data.vehicle_make,
                vehicle_model: this.state.data.vehicle_model,
                vehicle_color: this.state.data.vehicle_color,
                vehicle_license: this.state.data.vehicle_license,
            };
            if (this.state.action === 'checkin') {
                data.recipient_checked_in = true;
            }
            await this.props.updateTicketHistoryDelivery(data, this.props.match.params.ticketId);
            this.setState({editMode: false, loading: false});
        } catch (err) {
            Logger.error('TicketView', 'updateTicketOrderCurbside', JSON.stringify(err));
        }
    }

    editInformation() {
        this.setState({editMode: !this.state.editMode});
    }

    getTicketItemComponent(index, ticketOrderItems, omnivoreItems, backgroundColor, rest) {
        const {history} = this.props.ticket;
        return (
            <Table.Row key={`item_${index}`}>
                <Table.Cell style={{width: 50, verticalAlign: 'top', paddingTop: 23}}>
                    <strong style={getConfiguration().label_color}>QUANTITY</strong>
                    <br/>
                    {
                        <Dropdown
                            placeholder='Quantity'
                            options={range(1, 11).map((quantity, index) => {
                                return {
                                    disabled: ticketOrderItems.menu_external_id ? false : true,
                                    key: index,
                                    value: quantity,
                                    text: quantity,
                                };
                            })}
                            button
                            fluid
                            selection
                            disabled
                            value={ticketOrderItems.quantity}
                        />
                    }
                </Table.Cell>
                <Table.Cell style={{textAlign: 'center', verticalAlign: 'top'}}>
                    {omnivoreItems.imageUrl ? (
                        <div
                            style={{
                                width: 180,
                                height: 150,
                                backgroundImage: 'url("' + omnivoreItems.imageUrl + '")',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center top',
                                borderRadius: 5,
                                margin: '15px auto 10px auto',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    width: 60,
                                    height: 32,
                                    top: 0,
                                    left: 10,
                                    textAlign: 'center',
                                    color: 'white',
                                    borderBottomRightRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    padding: 8,
                                    backgroundColor,
                                    fontSize: 12,
                                }}
                            >
                                <span style={rest}>{omnivoreItems.pretty_price}</span>
                            </div>
                        </div>
                    ) : null}
                </Table.Cell>
                <Table.Cell style={{verticalAlign: 'top'}}>
                    <Table compact basic='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Card.Header
                                        style={{
                                            lineHeight: '30px',
                                            fontWeight: 600,
                                            fontSize: 14,
                                            textShadow: '1px 1px 5px white',
                                        }}
                                    >
                                        {omnivoreItems.name}{' '}
                                        {ticketOrderItems.quantity > 1 && omnivoreItems.price
                                            ? '(' + omnivoreItems.pretty_price + ')'
                                            : null}{' '}
                                    </Card.Header>
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='right'
                                    style={{
                                        padding: 1,
                                        border: 'none',
                                        fontWeight: 600,
                                    }}
                                >
                                    {omnivoreItems.price
                                        ? toFormatCurrencyWithCent(
                                            omnivoreItems.price * ticketOrderItems.quantity
                                        )
                                        : null}
                                </Table.Cell>
                            </Table.Row>
                            {ticketOrderItems.modifiers
                                ? ticketOrderItems.modifiers.map((modifier, index) => {
                                    let findModifier = history.omnivore_ids.modifiers.find(
                                        (omnivoreModifier) =>
                                            omnivoreModifier.modifier === modifier.modifier
                                    );
                                    return findModifier ? (
                                        <Table.Row key={index} style={{padding: 0}}>
                                            <Table.Cell
                                                style={{
                                                    padding: 1,
                                                    border: 'none',
                                                }}
                                                width={14}
                                            >
                                                <Card.Meta style={{color: 'darkgrey'}}>
                                                    {findModifier.name}{' '}
                                                    {findModifier.price
                                                        ? '(' + findModifier.pretty_price + ')'
                                                        : null}{' '}
                                                </Card.Meta>
                                            </Table.Cell>
                                            <Table.Cell
                                                style={{
                                                    padding: 1,
                                                    border: 'none',
                                                }}
                                                width={2}
                                                textAlign='right'
                                            >
                                                {findModifier.price
                                                    ? '$' +
                                                    (
                                                        findModifier.price *
                                                        ticketOrderItems.quantity
                                                    ).toFixed(2)
                                                    : null}
                                            </Table.Cell>
                                        </Table.Row>
                                    ) : null;
                                })
                                : null}
                        </Table.Body>
                    </Table>
                </Table.Cell>
            </Table.Row>
        );
    }

    getTicketItemComponentMobile(index, ticketOrderItems, omnivoreItems, backgroundColor, rest) {
        const {history} = this.props.ticket;
        return (
            <Card.Description style={{display: 'flex', marginBottom: 10}} key={`item_${index}`}>
                <div>
                    <div
                        style={{
                            width: 60,
                            height: 50,
                            backgroundImage: 'url("' + omnivoreItems.imageUrl + '")',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center top',
                            borderRadius: 5,
                            marginRight: 5,
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                width: 45,
                                height: 18,
                                top: 0,
                                left: 3,
                                fontSize: 10,
                                textAlign: 'center',
                                color: 'white',
                                borderBottomRightRadius: 5,
                                borderBottomLeftRadius: 5,
                                backgroundColor,
                                opacity: 0.7,
                            }}
                        >
                            {omnivoreItems.pretty_price}
                        </div>
                    </div>
                    <Dropdown
                        placeholder='Quantity'
                        options={range(1, 11).map(
                            (quantity, index) => {
                                return {
                                    disabled: ticketOrderItems.menu_external_id
                                        ? false
                                        : true,
                                    key: index,
                                    value: quantity,
                                    text: quantity,
                                };
                            }
                        )}
                        className='review-quantity'
                        button
                        disabled
                        selection
                        value={ticketOrderItems.quantity}
                    />
                </div>
                <div style={{flex: 1, fontSize: 12}}>
                    <div>
                        <div
                            style={{float: 'right', fontWeight: 'bold'}}
                        >
                            {omnivoreItems.price
                                ? toFormatCurrencyWithCent(omnivoreItems.price * ticketOrderItems.quantity)
                                : ''}
                        </div>
                        <div style={{fontWeight: 'bold'}}>
                            {`${omnivoreItems.name} ${
                                ticketOrderItems.quantity > 1 &&
                                omnivoreItems.price
                                    ? '(' + omnivoreItems.pretty_price + ')'
                                    : ''
                            }`}
                        </div>
                    </div>
                    {ticketOrderItems.modifiers
                        ? ticketOrderItems.modifiers.map(
                            (modifier, index) => {
                                let findModifier = history.omnivore_ids.modifiers.find(
                                    (omnivoreModifier) =>
                                        omnivoreModifier.modifier ===
                                        modifier.modifier
                                );
                                return findModifier ? (
                                    <div key={index}>
                                        <div style={{float: 'right'}}>
                                            {findModifier.price
                                                ? `$${(
                                                    findModifier.price *
                                                    ticketOrderItems.quantity
                                                ).toFixed(2)}`
                                                : ''}
                                        </div>
                                        <div>
                                            {`${findModifier.name} ${
                                                findModifier.price
                                                    ? '(' +
                                                    findModifier.pretty_price +
                                                    ')'
                                                    : ''
                                            }`}
                                        </div>
                                    </div>
                                ) : null;
                            }
                        )
                        : null}
                </div>
            </Card.Description>
        );
    }

    renderContent() {
        const {online_order_curbside_checking_in_text_arrival} = this.props.locations.configurations
        const {omnivore_ids, ticket_order, ticket_settings} = this.props.ticket.history;
        const {action, data, items, isCurbSide} = this.state;
        let {backgroundColor, ...rest} = getConfiguration().button_color;
        return ticket_order ? (
            <Card fluid>
                <Card.Content>
                    {action === 'checkin' ? (
                        <Grid centered textAlign='center'>
                            <Grid.Column textAlign='center' width={16}>
                                <span style={{fontWeight: 600}}>
                                    {online_order_curbside_checking_in_text_arrival.ready_to_pick_up.header}
                                </span>
                            </Grid.Column>
                            <Grid.Column className={"ready_to_pick_up"} textAlign='left' width={16}>
                                <div dangerouslySetInnerHTML={{__html: online_order_curbside_checking_in_text_arrival.ready_to_pick_up.details}}></div>
                            </Grid.Column>
                            {isCurbSide && (
                                <Grid.Column textAlign='left' width={16}>
                                    <Form>
                                        <Form.Group widths={'equal'}>
                                            <Form.Field>
                                                <label>* MAKE</label>
                                                {data.recipient_checked_in ? (
                                                    <input
                                                        readOnly={data.recipient_checked_in}
                                                        autoComplete='new'
                                                        value={data.vehicle_make || ''}
                                                        placeholder='Model'
                                                        onChange={this.handleChange.bind(
                                                            this,
                                                            'vehicle_make'
                                                        )}
                                                    />
                                                ) : (
                                                    <Dropdown
                                                        readOnly={data.recipient_checked_in}
                                                        autoComplete='new'
                                                        placeholder='Make'
                                                        onChange={this.handleStateChange.bind(
                                                            this,
                                                            'vehicle_make'
                                                        )}
                                                        value={data.vehicle_make || ''}
                                                        selection
                                                        options={vehicleMakes.map((make, index) => {
                                                            return {
                                                                key: index,
                                                                value: make,
                                                                text: make,
                                                            };
                                                        })}
                                                    />
                                                )}
                                            </Form.Field>
                                            <Form.Field>
                                                <label>* MODEL</label>
                                                <input
                                                    readOnly={!!data.recipient_checked_in}
                                                    autoComplete='new'
                                                    value={data.vehicle_model || ''}
                                                    placeholder='Model'
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                        'vehicle_model'
                                                    )}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <label>* COLOR</label>
                                                <input
                                                    readOnly={data.recipient_checked_in}
                                                    autoComplete='new'
                                                    value={data.vehicle_color || ''}
                                                    placeholder='Color'
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                        'vehicle_color'
                                                    )}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>* LICENSE</label>
                                                <input
                                                    readOnly={data.recipient_checked_in}
                                                    autoComplete='new'
                                                    value={data.vehicle_license || ''}
                                                    placeholder='License'
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                        'vehicle_license'
                                                    )}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                </Grid.Column>
                            )}
                            {isCurbSide && !data.recipient_checked_in && (
                                <Grid.Column mobile={16} computer={10}>
                                    <Button
                                        fluid
                                        size='large'
                                        circular
                                        disabled={this.state.loading && data.recipient_checked_in}
                                        onClick={this.updateTicketOrderCurbside.bind(this)}
                                        style={getConfiguration().button_color}
                                    >
                                        I'M HERE
                                    </Button>
                                </Grid.Column>
                            )}
                        </Grid>
                    ) : (
                        <>
                            <Media at='mobile'>
                                {ticket_order.items.length > 0 &&
                                ticket_order.items.map((ticketOrderItems, index) => {
                                        let omnivoreItems = omnivore_ids.items.find(
                                            (omnivoreItem) =>
                                                omnivoreItem.menu_item_external_id === ticketOrderItems.menu_item_external_id ||
                                                omnivoreItem.menu_item === ticketOrderItems.menu_item
                                        );
                                        if (omnivoreItems) {
                                            return this.getTicketItemComponentMobile(
                                                index,
                                                ticketOrderItems,
                                                omnivoreItems,
                                                backgroundColor,
                                                rest
                                            );
                                        }
                                    }
                                )}
                            </Media>
                            <Media greaterThanOrEqual='tablet'>
                                <Table basic='very'>
                                    <Table.Body>
                                        {ticket_order.items.length > 0 &&
                                        ticket_order.items.map((ticketOrderItems, index) => {
                                                let omnivoreItems = omnivore_ids.items.find(
                                                    (omnivoreItem) =>
                                                        omnivoreItem.menu_item_external_id === ticketOrderItems.menu_item_external_id ||
                                                        omnivoreItem.menu_item === ticketOrderItems.menu_item
                                                );
                                                if (omnivoreItems) {
                                                    return this.getTicketItemComponent(
                                                        index,
                                                        ticketOrderItems,
                                                        omnivoreItems,
                                                        backgroundColor,
                                                        rest
                                                    );
                                                }
                                            }
                                        )}
                                    </Table.Body>
                                </Table>
                            </Media>
                            {ticket_settings && (
                                <Grid centered>
                                    <Grid.Column mobile={16} computer={10}>
                                        <Button
                                            as={Link}
                                            to={'/'}
                                            name={'/'}
                                            fluid
                                            circular
                                            disabled={
                                                !isEmpty(this.state.error) ||
                                                items.length === 0 ||
                                                !this.isSame()
                                            }
                                            style={getConfiguration().button_color}
                                        >
                                            RETURN HOME
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            )}
                        </>
                    )}
                </Card.Content>
            </Card>
        ) : (
            <Card fluid>
                <Card.Content>
                    <Placeholder>
                        <Placeholder.Image rectangular/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                    </Placeholder>
                </Card.Content>
            </Card>
        );
    }

    renderOrder() {
        const {ticket_order, totals} = this.props.ticket.history;
        const {action, editMode, error_address, data, isCurbSide} = this.state;
        let {backgroundColor, ...rest} = getConfiguration().button_color;
        let orderTotal = ticket_order ? <Card fluid>
            <Card.Content>
                <Header as='h4' style={getConfiguration().label_color} textAlign='center'>
                    Online Order # {ticket_order.name}
                </Header>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <div style={{float: 'right'}}>{totals.sub_total}</div>
                            <div>Subtotal</div>
                        </Item.Content>
                    </Item>
                    {totals.tax && totals.tax !== '$0.00' && (
                        <Item>
                            <Item.Content>
                                <div style={{float: 'right'}}>{totals.tax}</div>
                                <div>Tax</div>
                            </Item.Content>
                        </Item>
                    )}
                    {totals.discounts && totals.discounts !== '$0.00' && (
                        <Item>
                            <Item.Content>
                                <div style={{float: 'right'}}>{totals.discounts}</div>
                                <div>Promo</div>
                            </Item.Content>
                        </Item>
                    )}
                    <Item>
                        <Item.Content>
                            <div style={{float: 'right'}}>{totals.tips}</div>
                            <div>Tip</div>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content style={Style.amountItem}>
                            <div style={{float: 'right'}}>
                                {totals.total}
                            </div>
                            <div>Total Amount</div>
                        </Item.Content>
                    </Item>
                    {ticket_order.payments.map((payment, index) => {
                        return (
                            <Item key={`payment_${index}`}>
                                <Item.Content>
                                    <div style={{float: 'right'}}>
                                        {payment.display_amount}
                                    </div>
                                    <div style={getConfiguration().label_color}>
                                        {`${payment.display_name}${payment.tip !== '$0.00' ? ' (tip included)' : ''}`}
                                    </div>
                                </Item.Content>
                            </Item>
                        )
                    })}
                    <Item
                        style={{marginBottom: -14}}
                    >
                        <Item.Content style={Style.amountItem}>
                            <div style={{float: 'right'}}>
                                {totals.due}
                            </div>
                            <div>Amount Due</div>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Card.Content>
        </Card> : null;
        let orderInformation = ticket_order ? <Card fluid>
            <Card.Content>
                <Header as='h4' textAlign='center'>
                    Order Information
                </Header>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <div
                                style={{float: 'right'}}>{`${ticket_order.recipient_first_name} ${ticket_order.recipient_last_name}`}</div>
                            <div>Name</div>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <div style={{float: 'right'}}>{ticket_order.name}</div>
                            <div>Order Number</div>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <div style={{float: 'right'}}>
                                {ticket_order.use_delivery ? 'Delivery' : 'Curbside'}
                            </div>
                            <div>Order Type</div>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <div style={{float: 'right'}}>{ticket_order.ready_date_display}</div>
                            <div>Order Date</div>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <div style={{float: 'right'}}>{ticket_order.ready_time_display}</div>
                            <div>{`${ticket_order.use_curbside ? 'Ready' : 'Delivery'} Time`}</div>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Card.Content>
            <Card.Content>
                <Header as='h4' textAlign='center'>
                    Additional Information
                </Header>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <div style={{float: 'left'}}>Comment</div>
                            <div style={{float: 'right'}}>{ticket_order.recipient_comment}</div>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Card.Content>
            <Card.Content>
                {isCurbSide ? (
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                {ticket_order.status !== 'Submitted' && (!isYesterday(new Date(ticket_order.pretty_date)) && differenceInDays(new Date(), new Date(ticket_order.pretty_date)) < 1) && (
                                    <div style={{float: 'right'}}>
                                        <a
                                            onClick={this.editInformation.bind(this)}
                                            style={{color: backgroundColor}}
                                        >
                                            {editMode ? 'CANCEL' : 'EDIT '}
                                            <Icon name={editMode ? 'times' : 'pencil'}/>
                                        </a>
                                    </div>
                                )}
                                <div style={{fontWeight: 600}}>Vehicle Information</div>
                            </Item.Content>
                        </Item>
                        {editMode ? (
                            <Form>
                                <Form.Field>
                                    <label>Make: *</label>
                                    <Dropdown
                                        autoComplete='new'
                                        placeholder='Make'
                                        onChange={this.handleStateChange.bind(
                                            this,
                                            'vehicle_make'
                                        )}
                                        value={data.vehicle_make}
                                        selection
                                        options={vehicleMakes.map(
                                            (make, index) => {
                                                return {
                                                    key: index,
                                                    value: make,
                                                    text: make,
                                                };
                                            }
                                        )}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Model: *</label>
                                    <input
                                        autoComplete='new'
                                        type='text'
                                        value={data.vehicle_model}
                                        placeholder='Model'
                                        onChange={this.handleChange.bind(
                                            this,
                                            'vehicle_model'
                                        )}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Color: *</label>
                                    <input
                                        autoComplete='new'
                                        type='text'
                                        value={data.vehicle_color}
                                        placeholder='Color'
                                        onChange={this.handleChange.bind(
                                            this,
                                            'vehicle_color'
                                        )}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>License: *</label>
                                    <input
                                        autoComplete='new'
                                        type='text'
                                        value={data.vehicle_license}
                                        placeholder='License'
                                        onChange={this.handleChange.bind(
                                            this,
                                            'vehicle_license'
                                        )}
                                    />
                                </Form.Field>
                                <Form.Field
                                    error={
                                        data.phone_number !== null
                                            ? error_address.phone_number
                                                ? true
                                                : false
                                            : null
                                    }
                                    fluid
                                >
                                    <label>*Phone</label>
                                    <NumberFormat
                                        value={data.phone_number}
                                        placeholder='Phone Number'
                                        inputMode='decimal'
                                        pattern='[0-9],*'
                                        format='(###) ###-####'
                                        mask='_'
                                        onInvalid={(e) => e.preventDefault()}
                                        onValueChange={(values) =>
                                            this.setState({
                                                data: {
                                                    phone_number: values.value,
                                                },
                                            })
                                        }
                                    />
                                </Form.Field>
                                <Button
                                    fluid
                                    circular
                                    disabled={this.state.loading}
                                    onClick={this.updateTicketOrderCurbside.bind(this)}
                                    style={getConfiguration().button_color}
                                >
                                    SAVE INFORMATION
                                </Button>
                            </Form>
                        ) : (
                            <>
                                <Item>
                                    <Item.Content>
                                        <div style={{float: 'right'}}>{data.vehicle_make}</div>
                                        <div>Make</div>
                                    </Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>
                                        <div style={{float: 'right'}}>{data.vehicle_model}</div>
                                        <div>Model</div>
                                    </Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>
                                        <div style={{float: 'right'}}>{data.vehicle_color}</div>
                                        <div>Color</div>
                                    </Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>
                                        <div style={{float: 'right'}}>{data.vehicle_license}</div>
                                        <div>License</div>
                                    </Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>
                                        <div style={{float: 'right'}}>{data.phone_number}</div>
                                        <div>Phone</div>
                                    </Item.Content>
                                </Item>
                            </>
                        )}
                    </Item.Group>
                ) : (
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                {ticket_order.status !== 'Submitted' && (!isYesterday(new Date(ticket_order.pretty_date)) && differenceInDays(new Date(), new Date(ticket_order.pretty_date)) < 1) && (
                                    <div style={{float: 'right'}}>
                                        <a
                                            onClick={this.editInformation.bind(this)}
                                            style={{color: backgroundColor}}
                                        >
                                            {editMode ? 'CANCEL' : 'EDIT '}
                                            <Icon name={editMode ? 'times' : 'pencil'}/>
                                        </a>
                                    </div>
                                )}
                                <div style={{fontWeight: 600}}>Delivery Information</div>
                            </Item.Content>
                        </Item>
                        {editMode ? (
                            <Form>
                                <Form.Field
                                    error={data.recipient_address !== null
                                        ? error_address.recipient_address
                                            ? true
                                            : false
                                        : null
                                    }
                                >
                                    <label>Address: *</label>
                                    <GooglePlacesAutocomplete
                                        autoComplete='new'
                                        debounce={600}
                                        onSelect={this.handlePlaceSelect}
                                        initialValue={data.recipient_address}
                                    />
                                </Form.Field>
                                <Form.Field
                                    error={data.recipient_city !== null
                                        ? error_address.recipient_city
                                            ? true
                                            : false
                                        : null
                                    }
                                >
                                    <label>City: *</label>
                                    <input
                                        autoComplete='new'
                                        type='text'
                                        value={data.recipient_city}
                                        placeholder='City'
                                        onChange={this.handleChange.bind(
                                            this,
                                            'recipient_city'
                                        )}
                                    />
                                </Form.Field>
                                <Form.Group widths='equal'>
                                    <Form.Field
                                        error={data.recipient_state !== null
                                            ? error_address.recipient_state
                                                ? true
                                                : false
                                            : null
                                        }
                                    >
                                        <label>State: *</label>
                                        <Dropdown
                                            autoComplete='new'
                                            placeholder='State'
                                            onChange={this.handleStateChange.bind(
                                                this,
                                                'recipient_state'
                                            )}
                                            value={data.recipient_state}
                                            selection
                                            options={states.map((state, index) => {
                                                    return {
                                                        key: index,
                                                        value: state.abbreviation,
                                                        text: state.name,
                                                    };
                                                }
                                            )}
                                        />
                                    </Form.Field>
                                    <Form.Field
                                        error={data.recipient_zip !== null
                                            ? error_address.recipient_zip
                                                ? true
                                                : false
                                            : null
                                        }
                                    >
                                        <label>Zip Code: *</label>
                                        <input
                                            autoComplete='new'
                                            type='text'
                                            value={data.recipient_zip}
                                            placeholder='Zip code'
                                            onChange={this.handleChange.bind(
                                                this,
                                                'recipient_zip'
                                            )}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Button
                                    fluid
                                    circular
                                    disabled={this.state.loading}
                                    onClick={this.updateTicketOrderDelivery.bind(this)}
                                    style={getConfiguration().button_color}
                                >
                                    SAVE INFORMATION
                                </Button>
                            </Form>
                        ) : (
                            <>
                                <Item>
                                    <Item.Content>
                                        <div style={{float: 'right'}}>
                                            {data.recipient_address},<br/>
                                            {data.recipient_city},<br/>
                                            {data.recipient_state},{' '}
                                            {data.recipient_zip}
                                        </div>
                                        <div>Address</div>
                                    </Item.Content>
                                </Item>
                                <Item>
                                    <Item.Content>
                                        <div style={{float: 'right'}}>{data.phone_number}</div>
                                        <div>Phone</div>
                                    </Item.Content>
                                </Item>
                            </>
                        )}
                    </Item.Group>
                )}
            </Card.Content>
        </Card> : null
        return ticket_order ? (
            <>
                <Media lessThan='tablet'>
                    <Card.Group>
                        {!action && orderInformation}
                        {orderTotal}
                    </Card.Group>
                </Media>
                <Media at='tablet'>
                    <Card.Group>
                        {!action && orderInformation}
                        {orderTotal}
                    </Card.Group>
                </Media>
                <Media greaterThanOrEqual='computer'>
                    <Card.Group>
                        {orderTotal}
                        {!action && orderInformation}
                    </Card.Group>
                </Media>
            </>
        ) : (
            <Card>
                <Card.Content>
                    <Placeholder>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                    </Placeholder>
                </Card.Content>
            </Card>
        );
    }

    render() {
        const {history} = this.props.ticket;
        const {items, data, error_address, isCurbSide} = this.state;
        let {color} = getConfiguration().label_color;
        let {backgroundColor, ...rest} = getConfiguration().button_color;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: [
                                        '.ui.positive.message {\n' +
                                        '    background-color: #fcfff5 !important;\n' +
                                        '    color: inherit;\n' +
                                        '    box-shadow: 0 0 0 1px ' +
                                        color +
                                        ' inset, 0 0 0 0 transparent !important;\n' +
                                        '    text-align: center\n' +
                                        '}\n' +
                                        '.ui.positive.message .header {\n' +
                                        '    color: ' +
                                        color +
                                        ' !important;\n' +
                                        '}\n' +
                                        '.checkout.ui.segment {\n' +
                                        '   padding:0;\n' +
                                        '}' +
                                        '.checkout i.icon.close:before {\n' +
                                        '   color: ' +
                                        color +
                                        '\n' +
                                        '}' +
                                        '.ui.selection .dropdown {\n' +
                                        '    background: lightgrey !important;\n' +
                                        '    right: .75em !important;\n' +
                                        '}',
                                    ].join('\n'),
                                }}
                            />
                            {!history.ticket_order || this.state.loading ? (
                                <Dimmer active inverted>
                                    <Loader size='small' inline='centered'>
                                        Loading
                                    </Loader>
                                </Dimmer>
                            ) : null}
                            <Grid reversed='computer'>
                                <Grid.Column mobile={16} computer={5}>
                                    {this.renderOrder()}
                                </Grid.Column>
                                <Grid.Column mobile={16} computer={11}>
                                    {this.state.successMessage && (
                                        <Message icon positive>
                                            <Message.Content>
                                                <Message.Header>
                                                    {this.state.successMessage.header.toUpperCase()}
                                                </Message.Header>
                                                <br/>
                                                {this.state.successMessage.message}
                                            </Message.Content>
                                        </Message>
                                    )}
                                    {this.renderContent()}
                                </Grid.Column>
                            </Grid>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

const Style = {
    amountItem: {
        backgroundColor: '#d3d3d3cc',
        padding: '6px 14px',
        margin: '0 -14px',
        fontWeight: 'bold',
    },
};

export default connect(
    (state) => {
        const {users, login, cart, ticket, locations} = state;
        return {users, cart, auth: {...login}, ticket, locations};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...loginStore.actionCreators,
                ...cartStore.actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
            },
            dispatch
        )
)(TicketView);
