import React from 'react';

import { Item } from 'semantic-ui-react';
import ProgramCard from '../../common/ui/components/ProgramCard';
import { getConfiguration } from '../../../store/api/apiClient';

export default (props) => {
    const { order, success_message } = props.cards;
    const program = {
        image: {
            imageSignedUrl: order.items[0].cards ? order.items[0].cards[0].image : props.locations.configurations.physical_gift_card.products[0].image,
        },
        cardNumber: order.items[0].cards && order.items[0].cards[0].card_number,
        displayName: order.items[0].cards && order.items[0].cards[0].display_name,
        amount: order.items[0].cards && order.items[0].cards[0].amount,
    };

    return (
        <Item.Group>
            <Item>
                <Item.Content style={Style.message}>
                    <Item.Header style={Style.header}>
                        {success_message.header}!
                    </Item.Header>
                    <Item.Description style={Style.description}>
                        {success_message.message}
                    </Item.Description>
                </Item.Content>
            </Item>
            <Item>
                <ProgramCard program={program}
                             canvasName={`program_review_purchase`}
                />
            </Item>
        </Item.Group>
    );
};

const Style = {
    content: {
        marginLeft: 20,
        marginTop: 10,
    },
    description: {
        textAlign: 'center',
    },
    header: {
        color: getConfiguration().label_color.color,
        textAlign: 'center',
        width: '100%',
    },
    message: {
        border: `1px solid ${getConfiguration().label_color.color}`,
        borderRadius: 5,
        padding: 16,
    },
}
