import React, {Component} from 'react';
import {Grid} from 'semantic-ui-react'
import Toggles from './Toggles'
import {Checkbox, Form} from 'semantic-ui-react'

export default class Checklist extends Component {
    constructor(props) {
        super(props)
        let selectedCheck = props.checklist.filter(check => {
            return check.selected
        })
        // if (props.defaultCustomized) {
        //     selectedCheck = selectedCheck.filter(check => {
        //         let found = null
        //         if (check.selected) {
        //             found = props.defaultCustomized.modifiers.find(modifiers => {
        //                 let foundOmnivore = null
        //                 if (check.unselected_omnivore_id.length > 0) {
        //                     foundOmnivore = check.unselected_omnivore_id.find(omnivore => {
        //                         return omnivore.omnivore_id === modifiers.modifier
        //                     })
        //                 }
        //                 return foundOmnivore ? true : false
        //             })
        //         } else {
        //             found = props.defaultCustomized.modifiers.find(modifiers => {
        //                 let foundOmnivore = null
        //                 if (check.selected_omnivore_id.length > 0) {
        //                     foundOmnivore = check.selected_omnivore_id.find(omnivore => {
        //                         return omnivore.omnivore_id === modifiers.modifier
        //                     })
        //                 }
        //                 return foundOmnivore ? true : false
        //             })
        //         }
        //         return found ? true : false
        //     })
        // }
        this.state = {
            selectedCheck: selectedCheck
        }
    }

    componentDidMount(){
        let selectedCheck = this.props.checklist.filter(check => {
            return check.selected
        })
        // if (this.props.defaultCustomized) {
        //     selectedCheck = selectedCheck.filter(check => {
        //         let found = null
        //         if (check.selected) {
        //             found = this.props.defaultCustomized.modifiers.find(modifiers => {
        //                 let foundOmnivore = null
        //                 if (check.unselected_omnivore_id.length > 0) {
        //                     foundOmnivore = check.unselected_omnivore_id.find(omnivore => {
        //                         return omnivore.omnivore_id === modifiers.modifier
        //                     })
        //                 }
        //                 return foundOmnivore ? true : false
        //             })
        //         } else {
        //             found = this.props.defaultCustomized.modifiers.find(modifiers => {
        //                 let foundOmnivore = null
        //                 if (check.selected_omnivore_id.length > 0) {
        //                     foundOmnivore = check.selected_omnivore_id.find(omnivore => {
        //                         return omnivore.omnivore_id === modifiers.modifier
        //                     })
        //                 }
        //                 return foundOmnivore ? true : false
        //             })
        //         }
        //         return found ? true : false
        //     })
        // }

        this.state = {
            selectedCheck: selectedCheck
        }
    }
    componentWillReceiveProps(nextProps) {
        let selectedCheck = nextProps.checklist.filter(check => {
            let found = nextProps.customized.find(customize => customize.id === check.id)
            return found ? !check.selected : check.selected
        })

        this.setState({
            selectedCheck: selectedCheck
        })
    }

    findCheck(option) {
        return this.props.checklist.find(check => {
            return check.id === option.id
        })
    }

    getSelected(check, e, target) {
        let findCheck = this.findCheck(check)
        let selectedCheck = this.state.selectedCheck
        if (target.checked) {
            selectedCheck.push(findCheck)
        } else {
            selectedCheck = selectedCheck.filter(check => {
                return check.id !== findCheck.id
            })
        }
        this.setState({
            selectedCheck
        })

        let customSelected = this.props.checklist.filter(checkItem => {
            let findSelectedCheck = selectedCheck.find(check => {
                return check.id === checkItem.id
            })
            if (checkItem.selected === false && findSelectedCheck) {
                return checkItem
            }
            if (checkItem.selected === true && !findSelectedCheck) {
                return checkItem
            }
        })

        this.props.submit(customSelected)
    }

    isSelected(check) {
        return this.state.selectedCheck.find(selected => {
            return selected.name === check.name && selected.id === check.id
        }) !== undefined
    }

    submitToggle(check) {
        return (toggle) => {
            let selectedCheck = this.state.selectedCheck.map(selected => {
                if (selected.name === check.name && selected.id === check.id) {
                    selected.toggled = toggle
                }
                return selected
            })

            this.setState({
                selectedCheck
            })
            this.props.submit(selectedCheck)
        }
    }

    formatMoney(number, decPlaces, decSep, thouSep) {
        let newDecSep = typeof decSep === "undefined" ? "." : decSep;
        let newDecPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
        let newThouSep = typeof thouSep === "undefined" ? "," : thouSep;
        let sign = number < 0 ? "-" : "";
        let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(newDecPlaces)));
        let j = (j = i.length) > 3 ? j % 3 : 0;

        return "$" + sign +
            (j ? i.substr(0, j) + newThouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + newThouSep) +
            (newDecPlaces ? newDecSep + Math.abs(number - i).toFixed(newDecPlaces).slice(2) : "");
    }

    isChecked(check) {
        let found = this.props.customized.find(customize => customize.id === check.id)
        if (this.props.defaultCustomized) {
            found = this.state.selectedCheck.find(selected => {
                return selected.name === check.name && selected.id === check.id
            })
        }
        return found ? !check.selected : check.selected

    }

    render() {
        return (
            <Grid textAlign='left' style={{margin: 0}}>
                {
                    this.props.checklist.map((check, index) => {
                        return (
                            <Grid.Row key={index} style={{padding: 0}}>
                                <Grid.Column width={check.toggle && check.toggle.length > 0 ? 8 : 16}>
                                    <Checkbox radio checked={this.isChecked(check)}
                                              label={((typeof check.price !== 'undefined') && Number(check.price) > 0 ) ? check.name + ' (' + this.formatMoney(check.price) + ')' : check.name}
                                              onClick={this.getSelected.bind(this, check)}/>
                                </Grid.Column>
                                {check.toggle && check.toggle.length > 0 ? <Grid.Column width={8}>
                                    <Toggles enabled={this.isSelected(check)}
                                             toggles={check.toggle}
                                             customize={this.props.customize}
                                             submit={this.submitToggle(check)}/>
                                </Grid.Column> : null}
                            </Grid.Row>
                        )
                    })
                }
            </Grid>
        );
    }
}

