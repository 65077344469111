import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Button, Form, Grid, Item, Responsive } from 'semantic-ui-react';

import { Media } from '../../../AppMedia';
import { getConfiguration } from '../../../store/api/apiClient';

export default (props) => {
    const { cards: { order }, handlePhoneInfo, handleGoBack } = props;
    const [firstName, setFirstName] = useState(order.recipient_first_name);
    const [lastName, setLastName] = useState(order.recipient_last_name);
    const [phone, setPhone] = useState(order.recipient_phone);

    const validate = () => {
        return !firstName || !lastName || !(phone && phone.length === 10);
    }

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        Please provide the following information for the recipient.
                    </Item.Header>
                </Item.Content>
            </Item>
            <Item>
                <Item.Content>
                    <Grid centered>
                        <Grid.Column mobile={16} computer={7}>
                            <Form.Input required width={16}
                                autoComplete='new'
                                type={'text'}
                                label='Recipient First Name'
                                placeholder='First Name'
                                style={{ margin: 0, width: '100%' }}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={7}>
                            <Form.Input required width={16}
                                autoComplete='new'
                                type={'text'}
                                label='Recipient Last Name'
                                placeholder='Last Name'
                                style={{ margin: 0, width: '100%' }}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={7}>
                            <Form.Field width={16}>
                                <label>Recipient Phone <label style={getConfiguration().label_color}>*</label></label>
                                <NumberFormat
                                    value={phone}
                                    placeholder='Phone Number'
                                    inputMode='decimal'
                                    pattern='[0-9],*'
                                    format='(###) ###-####'
                                    mask='_'
                                    onInvalid={(e) => e.preventDefault()}
                                    onValueChange={(values) =>
                                        setPhone(values.value)
                                    }
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={7}>
                        </Grid.Column>
                    </Grid>
                </Item.Content>
            </Item>
            <Media at='mobile'>
                <Form.Button
                    fluid
                    circular
                    disabled={validate()}
                    style={getConfiguration().button_color}
                    onClick={() => handlePhoneInfo({firstName, lastName, phone})}
                >
                    CONTINUE
                </Form.Button>
                <Form.Button
                    fluid
                    circular
                    onClick={() => handleGoBack()}
                >
                    BACK
                </Form.Button>
            </Media>
            <Media greaterThanOrEqual='tablet'>
                <Item>
                    <Item.Content>
                        <Grid centered>
                            <Grid.Column width={5}>
                                <Button
                                    fluid
                                    circular
                                    onClick={() => handleGoBack()}
                                >
                                    BACK
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Button
                                    fluid
                                    circular
                                    disabled={validate()}
                                    style={getConfiguration().button_color}
                                    onClick={() => handlePhoneInfo({firstName, lastName, phone})}
                                >
                                    CONTINUE
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Item.Content>
                </Item>
            </Media>
        </Item.Group>
    );
};
