import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    Form,
    Grid,
    Header,
    Image,
    Message,
    Segment,
    Popup,
    Container,
    Dimmer,
    Loader,
} from "semantic-ui-react";
import {DateInput} from "semantic-ui-calendar-react";
import NumberFormat from "react-number-format";
import DatePicker from "react-mobile-datepicker";
import MaskedInput from "react-text-mask";
import queryString from "query-string";
import {format, parse} from "date-fns";

import {Media} from "../../AppMedia";
import CacheBuster from "../../CacheBuster";
import {actionCreators} from "../../store/Login";
import * as locationStore from "../../store/Location";
import {getConfiguration} from "../../store/api/apiClient";
import {Logger} from "../../utils/Logger";
import Recaptcha from 'react-recaptcha'
import Config from '../../config'

const monthMap = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
};

const dateConfig = {
    month: {
        format: (value) => monthMap[value.getMonth() + 1],
        caption: "Mon",
        step: 1,
    },
    date: {
        format: "DD",
        caption: "Day",
        step: 1,
    },
    year: {
        format: "YYYY",
        caption: "Year",
        step: 1,
    },
};

class CreateAccount extends Component {
    constructor(props) {
        super(props);
        let query = queryString.parse(props.location.search);
        this.state = {
            email: "",
            email_confirmation: null,
            recaptcha_confirmation: null,
            password: "",
            showDatePicker: false,
            errorMessage: null,
            successMessage: null,
            token: query.token,
            loading: false,
        };
        this.emailInput = React.createRef();
        this.passwordInput = React.createRef();
    }

    componentWillMount() {
        this.props.locationConfiguration();
    }

    async handleSubmit() {
        try {
            if (this.checkValidation()) {
                this.setState({loading: true});
                let data = JSON.parse(JSON.stringify(this.state));
                delete data.errorMessage;
                delete data.successMessage;
                delete data.loading;
                let response = await this.props.createAccount(data);
                this.handleResponse(response);
            }
        } catch (err) {
            Logger.error("CreateAccount", "handleSubmit", JSON.stringify(err));
        }
    }

    checkValidation() {
        if (this.state.email !== this.state.email_confirmation) {
            this.setState({errorMessage: "Email & Email Confirmation must match."});
            this.emailInput.current.select();
            return false;
        }
        if (this.state.password !== this.state.password_confirmation) {
            this.setState({
                errorMessage: "Password & Confirm Password must match.",
            });
            this.passwordInput.current.select();
            return false;
        }
        return true;
    }

    handleResponse(response) {
        console.log("signup:", response);
        let errors = response.data.errors;
        if (!errors) {
            let successMessage = <p>{response.data.status}</p>;
            this.setState({successMessage, errorMessage: null, loading: false});
        } else {
            let errorMessage = Array.isArray(errors) ? (
                errors.map((error, index) => {
                    return <p key={index}>{error.message}</p>;
                })
            ) : (
                <p>{response.data.message}</p>
            );
            this.setState({successMessage: null, errorMessage, loading: false});
        }
    }

    handleChange(field, value) {
        let state = {...this.state};
        try {
            state[field] = value;
            state.errorMessage = null;
            this.setState({...state, showDatePicker: false});
        } catch (err) {
            Logger.error("CreateAccount", "handleChange", JSON.stringify(err));
        }
    }

    render() {
        const {
            loading,
            successMessage,
            errorMessage,
            email,
            email_confirmation,
            recaptcha_confirmation,
            password,
            password_confirmation,
            first_name,
            last_name,
            phone,
            dob,
        } = this.state;

        if (!this.props.locations.location)
            return (
                <Dimmer active inverted>
                    <Loader size="small">Loading</Loader>
                </Dimmer>
            );
        const {
            configurations,
            location: {settings},
        } = this.props.locations;
        const appIcon = configurations ? configurations.app_icon : null;

        const hasEmail = !email || !email_confirmation;
        const hasRecaptcha = !recaptcha_confirmation;
        const hasPassword = !password || !password_confirmation;
        const disabled =
            hasRecaptcha ||
            hasEmail ||
            hasPassword ||
            !first_name ||
            !last_name ||
            !phone ||
            phone.length < 10 ||
            !dob ||
            dob.replace("_", "").length < 10;
        let locationBackgroundUrl = this.props.locations.configurations.location_background_url

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log("BROWSER IS BEING REFRESHED!");
                        refreshCacheAndReload();
                    }
                    return (
                        <Container
                            fluid
                            className="bg-image"
                        >
                            <style
                                type="text/css"
                                dangerouslySetInnerHTML={{
                                    __html: [
                                        '\n',
                                        `body:after {` +
                                        `  background-image: url(${ locationBackgroundUrl ? locationBackgroundUrl : getConfiguration().backgroundImage});` +
                                        `}`,
                                    ].join('\n'),
                                }}
                            ></style>
                            <Grid
                                className="bg-text"
                                textAlign="center"
                                style={{zIndex: 2, margin: 0}}
                                verticalAlign="middle"
                            >
                                <Grid.Column style={{maxWidth: 450}}>
                                    <Media at="mobile">
                                        <Image
                                            src={appIcon}
                                            style={{maxHeight: 150, margin: "auto"}}
                                            onClick={() => this.props.history.push("/")}
                                        />
                                    </Media>
                                    <Media greaterThanOrEqual="tablet">
                                        <Image
                                            src={appIcon}
                                            style={{maxHeight: 300, margin: "auto"}}
                                            onClick={() => this.props.history.push("/")}
                                        />
                                    </Media>
                                    <Segment
                                        raised
                                        style={{
                                            background: "#ffffffe6",
                                            borderRadius: 5,
                                            border: "1px solid darkgray",
                                        }}
                                    >
                                        <style
                                            dangerouslySetInnerHTML={{
                                                __html: [
                                                    ".ui.dimmer>.content {\n" +
                                                    "    top: 45% !important;\n" +
                                                    "}" +
                                                    "#custom-content img {\n" +
                                                    "    width: 100% !important;\n" +
                                                    "}",
                                                ].join("\n"),
                                            }}
                                        ></style>

                                        {loading ? (
                                            <Dimmer active inverted>
                                                <Loader size="small">Loading</Loader>
                                            </Dimmer>
                                        ) : null}
                                        <div
                                            id={"custom-content"}
                                            style={{color: "black", textAlign: "left", overflowX: "auto"}}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                settings.consumer_application_settings
                                                    .register_page_into_text,
                                            }}
                                        />
                                        <Header
                                            as="h3"
                                            style={getConfiguration().label_color}
                                            textAlign="center"
                                        >
                                            SIGN UP
                                        </Header>
                                        {errorMessage ? (
                                            <Message negative>
                                                <Message.Header>
                                                    There were some errors with your submission
                                                </Message.Header>
                                                {errorMessage}
                                            </Message>
                                        ) : successMessage ? (
                                            <Message positive key={1}>
                                                <Message.Header>Success!</Message.Header>
                                                {successMessage}
                                                <a href={"/login"}>Click here </a> to login
                                            </Message>
                                        ) : null}
                                        {successMessage ? null : (
                                            <Form>
                                                <Form.Input
                                                    color="teal"
                                                    label="EMAIL"
                                                    type="email"
                                                    fluid
                                                    placeholder="Email"
                                                    onChange={(e) =>
                                                        this.handleChange("email", e.target.value)
                                                    }
                                                />
                                                <Form.Field>
                                                    <label>EMAIL CONFIRMATION</label>
                                                    <input
                                                        autoComplete="off"
                                                        ref={this.emailInput}
                                                        placeholder="Email Confirmation"
                                                        type="email"
                                                        onChange={(e) =>
                                                            this.handleChange(
                                                                "email_confirmation",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>PASSWORD</label>
                                                    <input
                                                        autoComplete="off"
                                                        ref={this.passwordInput}
                                                        placeholder="Password"
                                                        type="password"
                                                        onChange={(e) =>
                                                            this.handleChange("password", e.target.value)
                                                        }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>CONFIRM PASSWORD</label>
                                                    <input
                                                        autoComplete="off"
                                                        ref={this.passwordInput}
                                                        placeholder="Confirm Password"
                                                        type="password"
                                                        onChange={(e) =>
                                                            this.handleChange(
                                                                "password_confirmation",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Field>
                                                <Form.Input
                                                    color="teal"
                                                    label="FIRST NAME"
                                                    fluid
                                                    placeholder="First Name"
                                                    onChange={(e) =>
                                                        this.handleChange("first_name", e.target.value)
                                                    }
                                                />
                                                <Form.Input
                                                    color="teal"
                                                    label="LAST NAME"
                                                    fluid
                                                    placeholder="Last Name"
                                                    onChange={(e) =>
                                                        this.handleChange("last_name", e.target.value)
                                                    }
                                                />
                                                <Form.Field>
                                                    <label>PHONE</label>
                                                    <NumberFormat
                                                        placeholder="Phone Number"
                                                        inputMode="decimal"
                                                        pattern="[0-9],*"
                                                        format="(###) ###-####"
                                                        mask="_"
                                                        onInvalid={(e) => e.preventDefault()}
                                                        onValueChange={(values) =>
                                                            this.handleChange("phone", values.value)
                                                        }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>DATE OF BIRTH</label>
                                                    <Media lessThan="computer">
                                                        <div
                                                            className="form-field-readonly"
                                                            style={
                                                                dob
                                                                    ? {color: "rgba(0,0,0,.7)"}
                                                                    : {color: "rgba(0,0,0,.25)"}
                                                            }
                                                            onClick={() =>
                                                                this.setState({showDatePicker: true})
                                                            }
                                                        >
                                                            {dob || "Date of Birth"}
                                                        </div>
                                                        <DatePicker
                                                            value={parse(
                                                                dob || "01/01/1970",
                                                                "MM/dd/yyyy",
                                                                new Date()
                                                            )}
                                                            isOpen={this.state.showDatePicker}
                                                            dateConfig={dateConfig}
                                                            min={new Date(1900, 0, 1)}
                                                            headerFormat="MM/DD/YYYY"
                                                            confirmText="Done"
                                                            cancelText="Cancel"
                                                            onSelect={(date) =>
                                                                this.handleChange(
                                                                    "dob",
                                                                    format(date, "MM/dd/yyyy")
                                                                )
                                                            }
                                                            onCancel={() =>
                                                                this.setState({showDatePicker: false})
                                                            }
                                                        />
                                                    </Media>
                                                    <Media greaterThanOrEqual="computer">
                                                        <Popup
                                                            on="click"
                                                            trigger={
                                                                <MaskedInput
                                                                    label="DATE OF BIRTH"
                                                                    style={{fontWeight: "normal"}}
                                                                    mask={[
                                                                        /[0-1]/,
                                                                        /\d/,
                                                                        "/",
                                                                        /[0-3]/,
                                                                        /\d/,
                                                                        "/",
                                                                        /[1-2]/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                    ]}
                                                                    placeholder="Date of Birth"
                                                                    value={dob}
                                                                    onChange={(e) =>
                                                                        this.handleChange("dob", e.target.value)
                                                                    }
                                                                />
                                                            }
                                                        >
                                                            <Popup.Content>
                                                                <DateInput
                                                                    color="teal"
                                                                    fluid
                                                                    name="date"
                                                                    hideMobileKeyboard={true}
                                                                    icon={false}
                                                                    placeholder="Date of Birth"
                                                                    value={dob || ""}
                                                                    dateFormat={"MM/DD/YYYY"}
                                                                    closable={true}
                                                                    autoComplete="off"
                                                                    animation="none"
                                                                    inline
                                                                    pickerStyle={{width: 200, height: 200}}
                                                                    onChange={(e, obj) => {
                                                                        e.persist();
                                                                        this.handleChange("dob", obj.value);
                                                                    }}
                                                                />
                                                            </Popup.Content>
                                                        </Popup>
                                                    </Media>
                                                </Form.Field>
                                                <Recaptcha
                                                    sitekey={Config.recaptcha_siteKey}
                                                    render="explicit"
                                                    onloadCallback={() => {
                                                        console.log('Done!!!!');
                                                    }}
                                                    verifyCallback={(response) => {
                                                        console.log(response);
                                                        this.handleChange("recaptcha_confirmation", response)
                                                    }}
                                                />
                                                <br/>
                                                <Form.Button
                                                    circular
                                                    style={getConfiguration().button_color}
                                                    fluid
                                                    size="large"
                                                    disabled={disabled}
                                                    onClick={this.handleSubmit.bind(this)}
                                                >
                                                    SUBMIT
                                                </Form.Button>
                                                <div style={{position: "relative", color: "grey"}}>
                                                    Already have an account? <a href="/login">LOGIN. </a>
                                                </div>
                                            </Form>
                                        )}
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Container>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {login, locations} = state;
        return {login, locations};
    },
    (dispatch) =>
        bindActionCreators(
            {...actionCreators, ...locationStore.actionCreators},
            dispatch
        )
)(CreateAccount);
