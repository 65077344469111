import React, { useState } from 'react';
import { Button, Form, Grid, Item } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

import { Media } from '../../../AppMedia';
import { getConfiguration } from '../../../store/api/apiClient';

export default (props) => {
    const [cardNumber, setCardNumber] = useState('');
    const [claimNumber, setClaimNumber] = useState('');
    const { isClaim, handleCardImport } = props;

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    {isClaim ? (
                        <Item.Description>
                            <strong>Have a claim code?</strong>
                            {` Redeem your gift card by entering the claim code below.`}
                        </Item.Description>
                    ) : (
                        <Item.Description>
                            <strong>Have a physical gift card?</strong>
                            {` Redeem your gift card by
                            entering the card number found on the back of your card below.`}
                        </Item.Description>
                    )}
                </Item.Content>
            </Item>
            <Item>
                <Item.Content>
                    <Item.Description>
                        <Media at='mobile'>
                            <Form>
                                <Form.Field width={16}>
                                    {isClaim ? (
                                        <input
                                            type='text'
                                            autoComplete='off'
                                            maxLength={32}
                                            placeholder='Enter Claim Number'
                                            value={claimNumber}
                                            onChange={(e) => setClaimNumber(e.target.value.trim())}
                                        />
                                    ) : (
                                        <NumberFormat
                                            value={cardNumber}
                                            placeholder='Enter Gift Card Number'
                                            inputMode='decimal'
                                            pattern='[0-9],*'
                                            format='#### #### #### ####'
                                            mask='_'
                                            onInvalid={(e) => e.preventDefault()}
                                            onValueChange={(values) =>
                                                setCardNumber(values.value)
                                            }
                                        />
                                    )}
                                </Form.Field>
                                <Form.Field width={16}>
                                    {isClaim ? (
                                        <Button
                                            fluid
                                            disabled={!claimNumber}
                                            onClick={() => handleCardImport(claimNumber)}
                                            style={{
                                                ...getConfiguration().button_color,
                                            }}
                                        >
                                            APPLY
                                        </Button>
                                    ) : (
                                        <Button
                                            fluid
                                            disabled={!cardNumber || cardNumber.length < 16}
                                            onClick={() => handleCardImport(cardNumber)}
                                            style={{
                                                ...getConfiguration().button_color,
                                            }}
                                        >
                                            APPLY
                                        </Button>
                                    )}
                                </Form.Field>
                            </Form>
                        </Media>
                        <Media greaterThanOrEqual='tablet'>
                            <Grid centered>
                                <Grid.Column mobile={16} computer={10}>
                                    <Form>
                                        <Form.Group>
                                            <Form.Field width={10}>
                                                {isClaim ? (
                                                    <input
                                                        type='text'
                                                        autoComplete='off'
                                                        maxLength={32}
                                                        placeholder='Enter Claim Number'
                                                        value={claimNumber}
                                                        onChange={(e) => setClaimNumber(e.target.value.trim())}
                                                    />
                                                ) : (
                                                    <NumberFormat
                                                        value={cardNumber}
                                                        placeholder='Enter Gift Card Number'
                                                        inputMode='decimal'
                                                        pattern='[0-9],*'
                                                        format='#### #### #### ####'
                                                        mask='_'
                                                        onInvalid={(e) => e.preventDefault()}
                                                        onValueChange={(values) =>
                                                            setCardNumber(values.value)
                                                        }
                                                    />
                                                )}
                                            </Form.Field>
                                            <Form.Field width={6}>
                                                {isClaim ? (
                                                    <Button
                                                        fluid
                                                        disabled={!claimNumber}
                                                        onClick={() => handleCardImport(claimNumber)}
                                                        style={{
                                                            ...getConfiguration().button_color,
                                                        }}
                                                    >
                                                        APPLY
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        fluid
                                                        disabled={!cardNumber || cardNumber.length < 16}
                                                        onClick={() => handleCardImport(cardNumber)}
                                                        style={{
                                                            ...getConfiguration().button_color,
                                                        }}
                                                    >
                                                        APPLY
                                                    </Button>
                                                )}
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                </Grid.Column>
                            </Grid>
                        </Media>
                    </Item.Description>
                </Item.Content>
            </Item>
        </Item.Group>
    );
};
