import React, { useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import NumberFormat from 'react-number-format';
import { Button, Dropdown, Form, Grid, Item } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { Media } from '../../../AppMedia';
import states from '../../../assets/states.json';
import { getConfiguration } from '../../../store/api/apiClient';
import { Logger } from '../../../utils/Logger';

export default (props) => {
    const { cards: { order }, handleAddressInfo, handleGoBack } = props;
    const [data, setData] = useState({
        recipient_first_name: order.recipient_first_name,
        recipient_last_name: order.recipient_last_name,
        recipient_address: order.recipient_address,
        recipient_address2: order.recipient_address2,
        recipient_city: order.recipient_city,
        recipient_state: order.recipient_state,
        recipient_zip: order.recipient_zip,
        recipient_email: order.recipient_email,
        recipient_phone: order.recipient_phone,
    });
    const [error, setError] = useState({});

    const isEmptyFields = () => {
        return isEmpty(data.recipient_first_name) || isEmpty(data.recipient_last_name) || isEmpty(data.recipient_address)
                || isEmpty(data.recipient_city) || isEmpty(data.recipient_state) || isEmpty(data.recipient_zip);
    }

    const handleChange = (field, value) => {
        try {
            let newData = { ...data };
            let newError = { ...error };
            if (value) {
                delete newError[field];
            } else {
                newError[field] = {
                    content: 'Please enter a valid info',
                    pointing: 'below',
                };
            }
            newData[field] = value;
            setData(newData);
            setError(newError);
        } catch (err) {
            Logger.error('AddressInfo', 'handleChange', JSON.stringify(err));
        }
    };

    const handlePlaceSelect = (data) => {
        geocodeByAddress(data.description)
            .then((results) => {
                handleGeocode(results[0], data);
            })
            .catch(err => Logger.error('AddressInfo', 'handlePlaceSelect', JSON.stringify(err)));
    };

    const handleGeocode = (addressResult, addr) => {
        let newData = { ...data };
        let newError = { ...error };

        if (addr.types[0] === 'street_address') {
            newData.recipient_address = getAddressType(addressResult, ['street_number', 'route']);
            newData.recipient_city = getAddressType(addressResult, ['locality']) || getAddressType(addressResult, ['neighborhood']);
        } else {
            newData.recipient_address = `${addr.terms[0].value} ${getAddressType(addressResult, ['route'])}`;
            newData.recipient_city = addr.terms[2].value;
        }
        newData.recipient_state = getAddressType(addressResult, ['administrative_area_level_1']);
        newData.recipient_zip = getAddressType(addressResult, ['postal_code']);

        if (newData.recipient_address) delete newError.recipient_address;
        if (newData.recipient_city) delete newError.recipient_city;
        if (newData.recipient_state) delete newError.recipient_state;
        if (newData.recipient_zip) delete newError.recipient_zip;

        setData(newData);
        setError(newError);
    };

    const getAddressType = (addressResult, types) => {
        let geocodeAddress = [];
        types.forEach((type) => {
            addressResult.address_components.forEach((geocode) => {
                if (geocode.types.indexOf(type) !== -1) {
                    return geocodeAddress.push(geocode.short_name);
                }
            });
        });
        return geocodeAddress.join(' ');
    };

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        Please provide the following information for the recipient.
                    </Item.Header>
                </Item.Content>
            </Item>
            <Item>
                <Item.Content>
                    <Grid>
                        <Grid.Column mobile={16} computer={8}>
                            <Form.Input
                                required
                                width={16}
                                type={'text'}
                                label='Recipient First Name'
                                placeholder='First Name'
                                value={data.recipient_first_name || ''}
                                error={!data.recipient_first_name ? error.recipient_first_name ? true : false : false}
                                onFocus={(e) => e.target.setAttribute("autocomplete", "nope")}
                                onChange={(e) => handleChange('recipient_first_name', e.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={8}>
                            <Form.Input
                                required
                                width={16}
                                autoComplete='new'
                                type={'text'}
                                label='Recipient Last Name'
                                placeholder='Last Name'
                                value={data.recipient_last_name || ''}
                                error={!data.recipient_last_name ? error.recipient_last_name ? true : false : false}
                                onChange={(e) => handleChange('recipient_last_name', e.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={16}>
                            <Form.Field required width={16}>
                                <label>Recipient Address</label>
                                <GooglePlacesAutocomplete
                                    autoComplete="new"
                                    debounce={600}
                                    onSelect={handlePlaceSelect}
                                    initialValue={data.recipient_address || ''}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={16}>
                            <Form.Input
                                width={16}
                                autoComplete='new'
                                type={'text'}
                                label='Recipient Address 2'
                                placeholder='Address 2'
                                value={data.recipient_address2 || ''}
                                onChange={(e) => setData({ ...data, recipient_address2: e.target.value})}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={6}>
                            <Form.Input
                                required
                                width={16}
                                autoComplete='new'
                                type={'text'}
                                label='Recipient City'
                                placeholder='City'
                                value={data.recipient_city || ''}
                                error={!data.recipient_city ? error.recipient_city ? true : false : false}
                                onChange={(e) => handleChange('recipient_city', e.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={6}>
                            <Form.Field required width={16}>
                                <label style={getConfiguration().label_color}>
                                    Recipient State
                                </label>
                                <Dropdown
                                    autoComplete='new'
                                    placeholder='State'
                                    onFocus={(e) => e.target.setAttribute("autocomplete", "nope")}
                                    onChange={(event, data) => handleChange('recipient_state', data.value)}
                                    value={data.recipient_state || ''}
                                    selection
                                    options={states.map((state, index) => {
                                        return {
                                            key: index,
                                            value: state.abbreviation,
                                            text: state.name,
                                        };
                                    })}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={4}>
                            <Form.Field
                                required
                                error={!data.recipient_zip ? error.recipient_zip ? true : false : false}
                            >
                                <label style={getConfiguration().label_color}>Recipient Zip Code</label>
                                <NumberFormat
                                    value={data.recipient_zip || ''}
                                    placeholder='Zip code'
                                    inputMode='decimal'
                                    pattern='[0-9],*'
                                    format='#####'
                                    onInvalid={(e) => e.preventDefault()}
                                    onValueChange={(values) =>
                                        handleChange('recipient_zip', values.value)
                                    }
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={8}>
                            <Form.Input
                                width={16}
                                autoComplete='new'
                                type={'email'}
                                label='Recipient Email Address'
                                placeholder='Email address'
                                value={data.recipient_email || ''}
                                onChange={(e) => setData({ ...data, recipient_email: e.target.value })}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={8}>
                            <Form.Field width={16}>
                                <label>Recipient Phone</label>
                                <NumberFormat
                                    value={data.recipient_phone || ''}
                                    placeholder='Phone Number'
                                    inputMode='decimal'
                                    pattern='[0-9],*'
                                    format='(###) ###-####'
                                    mask='_'
                                    onInvalid={(e) => e.preventDefault()}
                                    onValueChange={(values) =>
                                        setData({ ...data, recipient_phone: values.value })
                                    }
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>
                </Item.Content>
            </Item>
            <Media at='mobile'>
                <Form.Button
                    fluid
                    circular
                    disabled={!isEmpty(error) || isEmptyFields()}
                    style={getConfiguration().button_color}
                    onClick={() => handleAddressInfo(data)}
                >
                    CONTINUE
                </Form.Button>
                <Form.Button
                    fluid
                    circular
                    onClick={() => handleGoBack()}
                >
                    BACK
                </Form.Button>
            </Media>
            <Media greaterThanOrEqual='tablet'>
                <Item>
                    <Item.Content>
                        <Grid centered>
                            <Grid.Column width={5}>
                                <Button
                                    fluid
                                    circular
                                    onClick={() => handleGoBack()}
                                >
                                    BACK
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Button
                                    fluid
                                    circular
                                    disabled={!isEmpty(error) || isEmptyFields()}
                                    style={getConfiguration().button_color}
                                    onClick={() => handleAddressInfo(data)}
                                >
                                    CONTINUE
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Item.Content>
                </Item>
            </Media>
        </Item.Group>
    );
};
