import {client, getHeadersRefreshToken, getConfiguration} from './apiClient'

const auth = async (email, password, remember) => {
    return await client.request({
        url: '/auth/local',
        method: "POST",
        data: {
            email,
            password,
            organization_id: getConfiguration().organizationId,
            location_id: getConfiguration().locationId,
        }
    })
}

const authFacebook = async (access_token, remember) => {
    return await client.request({
        url: '/auth/facebook/clientside',
        method: "POST",
        data: {
            access_token, organization_id: getConfiguration().organizationId, location_id: getConfiguration().locationId
        }
    })
}

const authGoogle = async (id_token, access_token, remember) => {
    return await client.request({
        url: '/auth/google/clientside',
        method: "POST",
        data: {
            id_token,
            access_token,
            organization_id: getConfiguration().organizationId,
            location_id: getConfiguration().locationId
        }
    })
}

const refresh = async () => {
    return await client.request({
        url: '/v2.0/auth/refreshtoken',
        method: "GET",
        headers: getHeadersRefreshToken()
    })
}

const sendPasswordReset = async (email) => {
    return await client.request({
        url: '/user/sendpasswordreset',
        method: "POST",
        data: {
            email, destination: getConfiguration().adminURL + "/resetpassword",organization_id: getConfiguration().organizationId
        }
    })
}

const resetPassword = async (password, password_confirmation, key) => {
    return await client.request({
        url: '/user/resetpassword',
        method: "POST",
        data: {
            password, password_confirmation, key
        }
    })
}

const createAccount = async (user, token) => {
    return await client.request({
        url: '/auth/register',
        method: "POST",
        data: {
            ...user,
            organization_id: getConfiguration().organizationId, location_id: getConfiguration().locationId
        }
    })
}

const updatePassword = async (currentPassword, newPassword, newPasswordConfirmation) => {
    return await client.request({
        url: '/auth/updatepassword',
        method: "PUT",
        data: {
            currentPassword, newPassword, newPasswordConfirmation
        },
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        }
    })
}

const inviteAdministrator = async (email, firstName, lastName, role) => {
    return await client.request({
        url: '/admin/user/invite',
        method: "POST",
        data: {
            email, firstName, lastName, role, destination: getConfiguration().adminURL
        },
        headers: {
            authorization: "Bearer " + sessionStorage.getItem('token')
        }
    })
}

const unlockAccount = async (token) => {
    return await client.request({
        url: '/auth/unlock',
        method: "GET",
        params: {
            token
        }
    })
}

const resendUnlock = async (email) => {
    return await client.request({
        url: '/auth/resendunlock',
        method: "GET",
        params: {
            email, destination: getConfiguration().adminURL
        }
    })
}

export default {
    auth,
    authFacebook,
    authGoogle,
    refresh,
    sendPasswordReset,
    createAccount,
    resetPassword,
    updatePassword,
    inviteAdministrator,
    unlockAccount,
    resendUnlock
}