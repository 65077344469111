import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import Home from './components/pages/Home';
import NewHome from './components/pages/NewHome';
import CheckCardBalance from './components/pages/CheckCardBalance';
import Menus from './components/pages/Menus';
import Order from './components/pages/Order';
import Gallery from './components/pages/more/Gallery';
import Cards from './components/pages/Cards';
import Cart from './components/pages/Cart';
import OrderCart from './components/pages/OrderCart';
import TicketSubmission from './components/pages/TicketSubmission';
import TicketSubmit from './components/pages/TicketSubmit';
import TicketView from './components/pages/TicketView';
import ReceiptView from './components/pages/ReceiptView';
import Checkout from './components/pages/Checkout';
import ThankYou from './components/pages/ThankYou';
import MenuCategories from './components/pages/MenuCategories';
import OrderCategories from './components/pages/OrderCategories';
import MenuCategoryItems from './components/pages/MenuCategoryItems';
import OrderCategoryItems from './components/pages/OrderCategoryItems';
import MenuItem from './components/pages/MenuItem';
import OrderItem from './components/pages/OrderItem';
import MyCards from './components/pages/mycards/MyCards';
import MyCard from './components/pages/mycards/MyCard';
import CardOrder from './components/pages/mycards/CardOrder';
import CardOrderImport from './components/pages/mycards/CardOrderImport';
import SendCard from './components/pages/SendCard';
import EGiftCardView from './components/pages/mycards/EGiftCardView';
import MyRewards from './components/pages/more/MyRewards';
import About from './components/pages/more/About';
import Privacy from './components/pages/more/Privacy';
import Terms from './components/pages/more/Terms';
import Catering from './components/pages/more/Catering';
import ReadOnlyNonPosMenu from './components/pages/more/ReadOnlyNonPosMenu';
import MyAccount from './components/pages/more/MyAccount';
import TicketHistory from './components/pages/more/TicketHistory';

const PrivateRoute = ({component: Component, authed, ...rest}) => (
    <Route
        {...rest}
        render={(props) =>
            authed === true ? (
                <Component {...props} />
            ) : (
                <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
            )
        }
    />
);
const ConnectedPrivateRoute = connect((state) => ({
    authed: state.login.user.authenticated,
}))(PrivateRoute);

const AppRouter = (props) => (
    <Switch history={props.history}>
        <Route exact path='/' component={NewHome}/>
            <Route exact path='/oldhome' component={Home}/>
            <Route exact path='/home' component={NewHome}/>
        <Route exact path='/cards' component={SendCard}/>
        <Route exact path='/sendcard' component={SendCard}/>
        <Route exact path='/checkcardbalance' component={CheckCardBalance}/>
        <Route exact path='/physicalcards' component={Cards}/>
        <Route exact path='/cart' component={Cart}/>
        <Route exact path='/ordercart' component={OrderCart}/>
        <Route exact path='/checkout' component={Checkout}/>
        <Route exact path='/ordercheckout' component={TicketSubmission}/>
        <Route exact path='/ordersubmit' component={TicketSubmit}/>
        <Route exact path='/receipt' component={ReceiptView}/>
        <Route exact path='/ticket/:ticketId' component={TicketView}/>
        <Route exact path='/thankyou' component={ThankYou}/>
        <Route exact path='/order' component={Order}/>
        <Route exact path='/gallery' component={Gallery}/>
        <Route exact path='/menus' component={Menus}/>
        <Route exact path='/menus/:menuId' component={MenuCategories}/>
        <Route exact path='/order/:menuId' component={OrderCategories}/>
        <Route exact path='/menus/:menuId/category/:categoryId' component={MenuCategoryItems}/>
        <Route exact path='/order/:menuId/category/:categoryId' component={OrderCategoryItems}/>
        <Route exact path='/menus/:menuId/category/:categoryId/item/:itemId' component={MenuItem}/>
        <Route exact path='/order/:menuId/category/:categoryId/item/:itemId' component={OrderItem}/>
        <ConnectedPrivateRoute path='/mycards' exact component={MyCards}/>
        <ConnectedPrivateRoute path='/mycards/:cardId/view' exact component={MyCard}/>
        <ConnectedPrivateRoute path='/mycards/:cardId/import' exact component={MyCard}/>
        <Route path='/mycards/send/:type/:cardId' exact component={CardOrder}/>
        <ConnectedPrivateRoute path='/mycards/:type/:cardId' exact component={CardOrder}/>
        <ConnectedPrivateRoute path='/mycards/import' exact component={CardOrderImport}/>
        <Route path='/mycards/check' exact component={CheckCardBalance}/>
        <Route path='/gift/:cardId' exact component={EGiftCardView}/>
        <Route path='/rewards' exact component={MyRewards}/>
        <Route path='/about' exact component={About}/>
        <Route path='/privacy' exact component={Privacy}/>
        <Route path='/terms' exact component={Terms}/>
        <Route path='/catering' exact component={Catering}/>
        <Route path='/menu/readonly' exact component={ReadOnlyNonPosMenu}/>
        <ConnectedPrivateRoute path='/account' exact component={MyAccount}/>
        <ConnectedPrivateRoute path='/tickethistory' exact component={TicketHistory}/>
        <Route render={() => <Redirect to={{pathname: "/"}}/>}/>
    </Switch>
);

export default AppRouter;
