import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Card, Feed, Grid, Header, Ref, Sticky} from "semantic-ui-react";

import {Media} from "../../../AppMedia";
import CacheBuster from "../../../CacheBuster";
import {actionCreators} from "../../../store/Users";
import {getConfiguration} from "../../../store/api/apiClient";
import PrintComponent from "./PrintComponent";

class Catering extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numPages: 0,
            activePage: 0
        }
        this.contextRef = React.createRef();
        this.items = [];
    }

    handleMenu = (index) => {
        this.items[index].scrollIntoView();
        // this.contentRef.current.scrollTo(0, this.items[index].offsetTop - 120);
    };

    renderContent = (catering_page) => {

        return (
            <Card.Group>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign="center" as="h3">
                            {catering_page.catering_information.header}
                        </Header>
                        <Card.Description
                            dangerouslySetInnerHTML={{
                                __html: catering_page.catering_information.details,
                            }}
                        />
                    </Card.Content>
                </Card>
                {catering_page.all_menus.map((menu, index) => {
                    return (
                        <div
                            ref={(ref) => (this.items[index] = ref)}
                            key={`item_${index}`}
                            style={{width: "100%", padding: "0 8px 15px"}}
                        >
                            <Card fluid>
                                <Card.Content>
                                    <Header textAlign="center" as="h3">
                                        {menu.header}
                                    </Header>
                                    <Card.Description
                                        dangerouslySetInnerHTML={{
                                            __html: menu.details,
                                        }}
                                    />
                                </Card.Content>
                            </Card>
                        </div>
                    );
                })}
            </Card.Group>
        );
    };

    renderLinks = () => {
        const {catering_page} = this.props.locations.configurations;
        return (
            <Card.Group>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign="center" as="h4">
                            {catering_page.catering_links.header}
                        </Header>
                        <Card.Description/>
                        <Feed>
                            {catering_page.all_menus.map((menu, index) => {
                                return (
                                    <Feed.Event key={`menu_${index}`}>
                                        <Feed.Content>
                                            <Button
                                                fluid
                                                style={{
                                                    ...getConfiguration().button_color,
                                                    textAlign: "left",
                                                }}
                                                onClick={() => this.handleMenu(index)}
                                            >
                                                {menu.header}
                                            </Button>
                                        </Feed.Content>
                                    </Feed.Event>
                                );
                            })}
                        </Feed>
                    </Card.Content>
                </Card>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign="center" as="h4">
                            {catering_page.contact_us.header}
                        </Header>
                        <Card.Description
                            dangerouslySetInnerHTML={{
                                __html: catering_page.contact_us.details,
                            }}
                        />
                    </Card.Content>
                </Card>
            </Card.Group>
        );
    };

    renderMobile = () => {
        const {catering_page} = this.props.locations.configurations;

        return (
            <Card.Group>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign="center" as="h3">
                            {catering_page.catering_information.header}
                        </Header>
                        <Card.Description
                            dangerouslySetInnerHTML={{
                                __html: catering_page.catering_information.details,
                            }}
                        />
                    </Card.Content>
                </Card>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign="center" as="h4">
                            {catering_page.catering_links.header}
                        </Header>
                        <Card.Description/>
                        <Feed>
                            {catering_page.all_menus.map((menu, index) => {
                                return (
                                    <Feed.Event key={`menu_${index}`}>
                                        <Feed.Content>
                                            <Button
                                                fluid
                                                style={{
                                                    ...getConfiguration().button_color,
                                                    textAlign: "left",
                                                }}
                                                onClick={() => this.handleMenu(index)}
                                            >
                                                {menu.header}
                                            </Button>
                                        </Feed.Content>
                                    </Feed.Event>
                                );
                            })}
                        </Feed>
                    </Card.Content>
                </Card>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign="center" as="h4">
                            {catering_page.contact_us.header}
                        </Header>
                        <Card.Description
                            dangerouslySetInnerHTML={{
                                __html: catering_page.contact_us.details,
                            }}
                        />
                    </Card.Content>
                </Card>
                {catering_page.all_menus.map((menu, index) => {
                    return (
                        <div
                            ref={(ref) => (this.items[index] = ref)}
                            key={`item_${index}`}
                            style={{width: "100%", padding: "0 8px 15px"}}
                        >
                            <Card fluid>
                                <Card.Content>
                                    <Header textAlign="center" as="h3">
                                        {menu.header}
                                    </Header>
                                    <Card.Description
                                        dangerouslySetInnerHTML={{
                                            __html: menu.details,
                                        }}
                                    />
                                </Card.Content>
                            </Card>
                        </div>
                    );
                })}
            </Card.Group>
        );
    };

    render() {

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log("BROWSER IS BEING REFRESHED!");
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <style dangerouslySetInnerHTML={{
                                __html: [
                                    '.card .description img:not(.footer-image-mobile) {' +
                                    '   width: 100%; \n' +
                                    '   height: auto !important; \n' +
                                    '}'
                                ].join('\n'),
                            }}
                            />
                            <Media at="mobile">{this.renderMobile()}</Media>
                            <Media greaterThanOrEqual="tablet">
                                <Ref innerRef={this.contextRef}>
                                    <Grid padded>
                                        <Grid.Row>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={11}>
                                                <PrintComponent
                                                    color={{...getConfiguration().button_color}}
                                                    catering_page={this.props.locations.configurations.catering_page}
                                                    footer={this.props.locations.location.settings.consumer_application_settings.consumer_portal_footer}
                                                    app_icon={this.props.locations.configurations.app_icon}
                                                    content={this.renderContent(this.props.locations.configurations.catering_page)}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={5}>
                                                <Sticky
                                                    context={this.contextRef}
                                                    styleElement={{zIndex: 0}}
                                                    offset={120}
                                                >
                                                    {this.renderLinks()}
                                                </Sticky>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Ref>
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {users, locations} = state;
        const {user} = users;
        return {user, locations};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
            },
            dispatch
        )
)(withRouter(Catering));
