import {client, getHeaders, getConfiguration} from './apiClient'

const addToCartStart = async (items) => {
  return await client.request({
    url: 'location/' + getConfiguration().locationId + '/physicalcard/start',
    method: "POST",
    headers: getHeaders(),
    data: {
      items
    }
  })
}

const getCartInformation = async (id) => {
  return await client.request({
    url: 'location/' + getConfiguration().locationId + '/physicalcard/' + id,
    method: "GET",
    headers: getHeaders(),
  })
}
const applyCoupon = async (id, data) => {
  return await client.request({
    url: 'location/' + getConfiguration().locationId + '/physicalcard/' + id,
    method: "PUT",
    headers: getHeaders(),
    data
  })
}
const updateCart = async (id, data) => {
  return await client.request({
    url: 'location/' + getConfiguration().locationId + '/physicalcard/' + id,
    method: "PUT",
    headers: getHeaders(),
    data
  })
}
const checkout = async (id, data) => {
  return await client.request({
    url: 'location/' + getConfiguration().locationId + '/physicalcard/' + id,
    method: "PUT",
    headers: getHeaders(),
    data
  })
}

const generateEphemeralKey = async (email) => {
  return await client.request({
    url: 'location/' + getConfiguration().locationId + '/payment/ephemeralkey',
    method: "POST",
    headers: getHeaders(),
    data: {
      stripe_version: "2019-05-16",
      email
    }
  })
}

export default {
  addToCartStart,
  getCartInformation,
  applyCoupon,
  updateCart,
  checkout,
  generateEphemeralKey
}