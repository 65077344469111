import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Card, Grid, Header, Icon, Image, Modal, Placeholder} from "semantic-ui-react";

import CacheBuster from "../../../CacheBuster";
import {actionCreators} from "../../../store/Users";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';

class Catering extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    goToItems(item, index) {
        this.setState({open: true, item, index})

    }

    setOpen(open) {
        this.setState({open})
    }

    render() {
        const {gallery_page} = this.props.locations.configurations;
        const {width} = this.state;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log("BROWSER IS BEING REFRESHED!");
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <Modal
                                dimmer={'blurring'}
                                basic
                                onClose={() => this.setOpen(false)}
                                open={this.state.open}
                                size='large'
                            >
                                <Header icon>
                                    {gallery_page && gallery_page.active_images.length > 0 ? gallery_page.active_images[this.state.index || 0].title !=='undefined'? gallery_page.active_images[this.state.index || 0].title : null : null}
                                </Header>
                                <Modal.Content>
                                    <Carousel showArrows={true}
                                              showThumbs={false}
                                              showStatus={false}
                                              infiniteLoop={true}
                                              selectedItem={this.state.index}
                                              onChange={(index) => this.setState({index})}
                                              dynamicHeight={true}>
                                        {gallery_page && gallery_page.active_images.length > 0 ?
                                            gallery_page.active_images.map((item, index) => {
                                                    return <div style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}>
                                                        <Image src={item.image.url} key={index}
                                                               style={{
                                                                   maxHeight: "calc( 100vh - 150px)",
                                                                   width: "auto",
                                                                   objectFit: 'cover'}}/>
                                                    </div>
                                                }
                                            )
                                            : null}
                                    </Carousel>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Icon style={{
                                        position: 'absolute',
                                        top: 20,
                                        right: 15,
                                    }} name='remove' size='large' onClick={() => this.setOpen(false)}/>
                                </Modal.Actions>
                            </Modal>
                            <Grid padded textAlign='center' verticalAlign='middle'>
                                <Grid.Column style={{maxWidth: 1080, marginTop: 20, marginBottom: 20}}>
                                    <Card.Group stackable doubling itemsPerRow={3}>
                                        {gallery_page ? (gallery_page.active_images.length > 0 ? gallery_page.active_images.map((item, index) =>
                                                <Card raised key={item.image.image_id}
                                                      onClick={this.goToItems.bind(this, item, index)}
                                                      style={{
                                                          minHeight:
                                                              width >= 768 ? 250 : 180,
                                                          backgroundImage: 'url("' + item.image.url + '")',
                                                          backgroundSize: 'cover',
                                                          backgroundPosition: 'center top'
                                                      }}>
                                                    <Card.Content style={{
                                                        position: 'absolute',
                                                        width: '100%',
                                                        bottom: 0,
                                                        textAlign: 'center',
                                                        border: 'none',
                                                        background: 'linear-gradient(to right top, rgb(0 0 0 / 29%), rgba(255, 255, 255, 0))',
                                                    }}>
                                                        <Card.Header style={{color:"white", textShadow: "0 0 5px rgb(0 0 0), 0 0 5px rgb(0 0 0), 0 0 5px rgb(0 0 0)"}}>{item.title !=='undefined'? item.title: ""}</Card.Header>
                                                    </Card.Content>
                                                </Card>)
                                            : null) : [0, 1, 2].map(menu =>
                                            <Card raised key={menu}
                                                  style={{
                                                      height:
                                                          width >= 768 ? 250 : 180,
                                                  }}>
                                                <Placeholder>
                                                    <Placeholder.Image rectangular/>
                                                </Placeholder>
                                                <Card.Content className="item-content" style={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    bottom: 0,
                                                    textAlign: 'center',
                                                    background: 'linear-gradient(to left bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 1))',
                                                    borderTop: 'none',
                                                }}>
                                                    <Grid textAlign='left'>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}
                                                                         style={{paddingRight: 0}}>
                                                                <Placeholder>
                                                                    <Placeholder.Header>
                                                                        <Placeholder.Line/>
                                                                    </Placeholder.Header>
                                                                </Placeholder>
                                                            </Grid.Column>

                                                        </Grid.Row>
                                                        <Grid.Row style={{
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            maxHeight: 65,
                                                            minHeight: 65
                                                        }}>
                                                            <Grid.Column>
                                                                <Placeholder>
                                                                    <Placeholder.Paragraph>
                                                                        <Placeholder.Line/>
                                                                        <Placeholder.Line/>
                                                                        <Placeholder.Line/>
                                                                    </Placeholder.Paragraph>
                                                                </Placeholder>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Card.Content>
                                            </Card>)}
                                    </Card.Group>
                                </Grid.Column>
                            </Grid>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {users, locations} = state;
        const {user} = users;
        return {user, locations};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
            },
            dispatch
        )
)(withRouter(Catering));
