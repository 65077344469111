import React, {Component} from 'react'
import {
    Image,
    Menu,
    Item,
    Segment,
    Grid,
    Container
} from 'semantic-ui-react'
import * as userStore from '../../store/Users';
import * as ticketStore from '../../store/Ticket';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';

class Footer extends Component {

    render() {
        let footerSettings = this.props.locations.location.settings.consumer_application_settings.consumer_portal_footer
        return (
            <Menu className="footer" style={{background: '#0000009e', color: 'white'}}>
                <Segment style={{padding: '0em 0em', width: "80%", margin: "auto"}}
                         vertical>
                    <div>
                        <Grid stackable columns={2}>
                            <Grid.Row>
                                <Grid.Column width={11}
                                             style={{paddingBottom: 0, color: 'white', verticalAlign: "middle"}}>
                                    {footerSettings.show_footer_logo ?
                                        <Image src={this.props.locations.configurations.app_icon} size='small'
                                               className={"footer-image-mobile"}
                                               style={{
                                                   height: 80,
                                                   width: "auto"
                                               }}
                                               floated='left'
                                        /> : null}
                                    {footerSettings.show_contact_info ?
                                        <div style={{textAlign: "left", margin: "auto", padding: 10}}
                                             className={"footer-contact-mobile"}
                                             dangerouslySetInnerHTML={{__html: footerSettings.contact_info_text}}>
                                        </div> : null}

                                </Grid.Column>
                                <Grid.Column width={5} className="social-links">
                                    {footerSettings.show_facebook ?
                                        <a href={footerSettings.facebook_url} target="_blank"
                                           className="social social-facebook"></a> : null}
                                    {footerSettings.show_instagram ?
                                        <a href={footerSettings.instagram_url} target="_blank"
                                           className="social social-instagram"></a> : null}
                                    {footerSettings.show_twitter ?
                                        <a href={footerSettings.twitter_url} target="_blank"
                                           className="social social-twitter"></a> : null}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Segment>
            </Menu>
        );
    }
}

export default connect(
    state => {
        const {login: auth, users, locations} = state;
        return {auth, users, locations};
    },
    dispatch => {
        return {
            actions: bindActionCreators(Object.assign({}, userStore.actionCreators, ticketStore.actionCreators), dispatch)
        }
    }
)(Footer);


