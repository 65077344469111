import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Form,
  Button,
  Table,
  Dimmer,
  Loader,
  Card,
} from 'semantic-ui-react';

import { Media } from '../../AppMedia';
import { actionCreators } from '../../store/Users';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import { getConfiguration } from '../../store/api/apiClient';
import { Logger } from '../../utils/Logger';

class AddPromo extends Component {
    state = {
        data: {},
        error: null
    }

    componentWillReceiveProps(nextProps) {
        try {
          let data = nextProps.ticket.ticket_order
  
          this.setState({
              data,
              settings: nextProps.ticket.ticket_settings,
          })
        } catch (err) {
            Logger.error('AddPromo', 'componentWillReceiveProps', JSON.stringify(err));
        }
    }

    componentWillMount() {
        this.props.getTicketOrder()
    }

    handleChange(field, e) {
        let data = this.state.data
        data[field] = e.target.value
        this.setState({data})
    }

    async applyPromoCode(e) {
        e.preventDefault()
        try {
          this.setState({loading: true})
          await this.props.updateTicketOrderPromo(this.state.data)
          this.setState({loading: false})
        } catch (err) {
          Logger.error('AddPromo', 'applyPromoCode', JSON.stringify(err));
        }
    }

    async updateTicketOrder() {
        this.setState({loading: true})
        this.props.nextStep('tip')
    }

    renderDesktop() {
        return (
            <Grid textAlign={'center'}>
                <Grid.Column width={10}>
                    <Table basic={'very'}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={3}>
                                    <span style={{fontWeight: 600}}>
                                        Have a promo code?
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={3} style={{paddingBottom: 0}}>
                                    <br/>
                                    <Form.Group>
                                        <Form.Field width={10}>
                                            <input autoComplete="new" type={'text'}
                                                   placeholder='Enter Code'
                                                   value={this.state.data.promo_code || ''}
                                                   onChange={this.handleChange.bind(this, 'promo_code')}/>
                                        </Form.Field>
                                        <Form.Field width={6}>
                                            <Button size='large'
                                                    fluid
                                                    disabled={!this.state.data.promo_code || this.state.loading}
                                                    onClick={this.applyPromoCode.bind(this)}
                                                    style={{
                                                        ...getConfiguration().button_color,
                                                    }}>
                                                APPLY
                                            </Button>
                                        </Form.Field>
                                    </Form.Group>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ?
                        <Grid doubling columns={2}>
                          <Grid.Column width={6}>
                              <Button fluid
                                      size='large'
                                      circular
                                      onClick={() => this.props.handleGoBack('information')}>
                                  BACK</Button>
                          </Grid.Column>
                            <Grid.Column width={10}>
                                <Button fluid
                                        size='large'
                                        circular
                                        onClick={this.updateTicketOrder.bind(this)}
                                        style={getConfiguration().button_color}>
                                    CONTINUE</Button>
                            </Grid.Column>
                        </Grid> : null}
                </Grid.Column>
            </Grid>
        )
    }

    renderMobile() {
      return (
        <>
          <Form.Field>
            <Card.Header as='h3' textAlign='center'>
              Have a promo code?
            </Card.Header>
          </Form.Field>
          <Form.Input
            autoComplete='new'
            type={'text'}
            placeholder='Enter Code'
            value={this.state.data.promo_code || ''}
            onChange={this.handleChange.bind(this, 'promo_code')}
          />
          <Form.Button
            fluid
            disabled={!this.state.data.promo_code || this.state.loading}
            onClick={this.applyPromoCode.bind(this)}
            style={{
              ...getConfiguration().button_color,
            }}
          >
            APPLY
          </Form.Button>
          {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
            <>
              <Form.Button
                fluid
                circular
                onClick={this.updateTicketOrder.bind(this)}
                style={getConfiguration().button_color}
              >
                CONTINUE
              </Form.Button>
              <Form.Button
                fluid
                circular
                onClick={() => this.props.handleGoBack('information')}
              >
                BACK
              </Form.Button>
            </>
          ) : null}
        </>
      );
    }
  
    render() {
      return (
        <>
          <Dimmer active={this.state.loading} inverted>
            <Loader size='small' inline='centered'>
              Loading
            </Loader>
          </Dimmer>
          <Media at='mobile'>
            {this.renderMobile()}
          </Media>
          <Media greaterThanOrEqual='tablet'>
            {this.renderDesktop()}
          </Media>
        </>
      );
    }
}
  
export default connect(
  (state) => {
    const { users, apps, locations, ticket } = state;
    return { users, locations, ticket, isLoading: apps.loading };
  },
  (dispatch) =>
    bindActionCreators(
      {
        ...actionCreators,
        ...locationStore.actionCreators,
        ...ticketStore.actionCreators,
      },
      dispatch
    )
)(AddPromo);
