import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import NumberFormat from 'react-number-format';
import {Button, Dimmer, Form, Grid, Header, Icon, Item, Loader, Message, Modal, Segment} from 'semantic-ui-react';

import {Media} from '../../AppMedia';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as appStore from '../../store/Apps';
import * as locationStore from '../../store/Location';
import * as cardStore from '../../store/Card';
import {getConfiguration} from '../../store/api/apiClient';
import {Logger} from '../../utils/Logger';
import {BarcodeScanner} from 'dynamsoft-javascript-barcode';

let pScanner = null
let scanner = null

class CheckCardBalance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            cardNumber: null,
            formattedCardNumber: null,
            loading: false,
            intervalId: setInterval(this.scrollStep.bind(this), 16),
            saving: -1,
            error: {},
        };
    }

    async componentDidMount() {
        BarcodeScanner.license = "100729711"
        BarcodeScanner.organizationID = "100701188"
        BarcodeScanner.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@8.8.7/dist/";
    }

    componentWillUnmount() {
        if(scanner) scanner.stop()
        if(scanner) scanner.hide()
    }
    componentWillMount() {
        this.props.clearCardBalance();
    }

    scrollStep() {
        if (window.pageYOffset <= 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    async handleCardCheck() {
        const data = {
            card_number: this.state.cardNumber,
        };
        try {
            let intervalId = setInterval(this.scrollStep.bind(this), 16);
            this.setState({ loading: true, intervalId, saving: 1 });
            let errors = await this.props.checkCardBalance(data);
            if (errors !== undefined) {
                this.setState({
                    saving: 2,
                    loading: false,
                    showMessage: true,
                    errors,
                    cardNumber: null,
                    formattedCardNumber: null,
                });
            } else {
                this.setState({
                    errors : null,
                    saving: 0,
                    loading: false,
                    cardNumber: null,
                    formattedCardNumber: null
                });
            }
        } catch (err) {
            Logger.error('CheckCardBalance', 'handleCardCheck', JSON.stringify(err));
        }
    }

    async setOpen(){
        this.setState({open: true}, async ()=> {
            try {
                scanner = await (pScanner = pScanner || BarcodeScanner.createInstance());
                await scanner.setUIElement(document.getElementById('div-video-container'));
                scanner.onFrameRead = results => {
                    if ((results.length > 0)) {
                        this.setState({formattedCardNumber: results[0].barcodeText, cardNumber: results[0].barcodeText, result: results[0], open: false})
                    }
                };
                scanner.onUnduplicatedRead = (txt, result) => {
                    scanner.stop()
                    scanner.hide()
                };
                await scanner.show();
            } catch (e) {
                console.log(e)
            }
        })
    }
    renderContentDesktop = () => {
        const { cardNumber, formattedCardNumber } = this.state;
        return (
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Item.Description>
                            {`Enter the card number found on the back of your card below to check the available balance.`}
                        </Item.Description>
                    </Item.Content>
                </Item>
                <Item>
                    <Item.Content>
                        <Item.Description>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <Grid centered >
                                <Grid.Column mobile={16} computer={16} >
                                    <Form>
                                        <Form.Group>
                                            <Form.Field>
                                                <NumberFormat
                                                    value={formattedCardNumber}
                                                    placeholder='Enter Gift Card Number'
                                                    inputMode='decimal'
                                                    pattern='[0-9],*'
                                                    format='#### #### #### ####'
                                                    mask='_'
                                                    onInvalid={(e) => e.preventDefault()}
                                                    onValueChange={(values) =>
                                                        this.setState({
                                                            formattedCardNumber: values.formattedValue,
                                                            cardNumber: values.value,
                                                        })
                                                    }
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <div>
                                                    <Button
                                                        onClick={this.setOpen.bind(this)}
                                                        style={{
                                                            ...getConfiguration().button_color,
                                                        }}
                                                    >
                                                        SCAN GIFT CARD
                                                    </Button>
                                                    <Button
                                                        disabled={!cardNumber || cardNumber.length < 16}
                                                        onClick={() => this.handleCardCheck()}
                                                        style={{
                                                            ...getConfiguration().button_color,
                                                        }}
                                                    >
                                                        APPLY
                                                    </Button>
                                                </div>
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                </Grid.Column>
                            </Grid>
                            </div>
                        </Item.Description>
                    </Item.Content>
                </Item>
            </Item.Group>
        );
    };

    renderContentMobile = () => {
        const { cardNumber } = this.state;
        return (
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Item.Description>
                            {`Enter the card number found on the back of your card below to check the available balance.`}
                        </Item.Description>
                    </Item.Content>
                </Item>
                <Item>
                    <Item.Content>
                        <Item.Description>
                            <Form>
                                <Form.Field width={16}>
                                    <NumberFormat
                                        value={cardNumber}
                                        placeholder='Enter Gift Card Number'
                                        inputMode='decimal'
                                        pattern='[0-9],*'
                                        format='#### #### #### ####'
                                        mask='_'
                                        onInvalid={(e) => e.preventDefault()}
                                        onValueChange={(values) =>
                                            this.setState({
                                                cardNumber: values.value,
                                            })
                                        }
                                    />
                                </Form.Field>
                                <Form.Field width={16}>
                                    <Button
                                        fluid
                                        onClick={this.setOpen.bind(this)}
                                        style={{
                                            ...getConfiguration().button_color,
                                        }}
                                    >
                                        SCAN GIFT CARD
                                    </Button>
                                    <br/>
                                    <Button
                                        fluid
                                        disabled={!cardNumber || cardNumber.length < 16}
                                        onClick={() => this.handleCardCheck()}
                                        style={{
                                            ...getConfiguration().button_color,
                                        }}
                                    >
                                        APPLY
                                    </Button>
                                </Form.Field>
                            </Form>
                        </Item.Description>
                    </Item.Content>
                </Item>
            </Item.Group>
        );
    };

    renderLinks = () => {
        const { settings } = this.props.locations.location;
        return (
            <Segment>
                <Item.Group>
                    <Item className='align-center'>
                        <Item.Header>Manage or Purchase a Gift Card.</Item.Header>
                    </Item>
                    {settings.cards.digital.card_management.buy_card && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/Purchase/0'}
                                name={'/mycards/Purchase/0'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                PURCHASE A NEW CARD
                            </Button>
                        </Item>
                    )}
                    {settings.cards.digital.card_management.import_card && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/import'}
                                name={'/mycards/import'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                ADD AN EXISTING CARD
                            </Button>
                        </Item>
                    )}
                    {settings.cards.physical.check_card_balance && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/check'}
                                name={'/mycards/check'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                CHECK A CARD BALANCE
                            </Button>
                        </Item>
                    )}
                </Item.Group>
            </Segment>
        );
    };

    render() {
        const { errors, showMessage, successMessage } = this.state;
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <Grid padded>
                            {this.state.open? <Modal
                                dimmer={'blurring'}
                                basic
                                onClose={() => this.setState({open: false})}
                                open={this.state.open}
                                size='large'
                            >
                                <Header icon>
                                    Scan Card
                                </Header>
                                <Modal.Content>
                                    <div style={{
                                        width: 500,
                                        height: 650,
                                        padding: 20
                                    }}>
                                        <div id="div-video-container">
                                            <video className="dbrScanner-video" playsInline={true} style={{
                                                width: "100%",
                                                height: "100%",
                                                position: "absolute",
                                                left: 0,
                                                top: 0,
                                            }}></video>
                                        </div>
                                    </div>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Icon style={{
                                        position: 'absolute',
                                        top: 20,
                                        right: 15,
                                    }} name='remove' size='large' onClick={() => this.setState({open: false})}/>
                                </Modal.Actions>
                            </Modal>: null }


                            <Grid.Column mobile={16} computer={11}>
                                {this.props.cards.card ? (
                                    <Segment>
                                        <Header as='h3' style={{ color: '#0b7e57' }}>
                                            RESULTS
                                        </Header>
                                        <Form autoComplete='off'>
                                            <Form.Field inline style={{ marginBottom: 3 }}>
                                                <label style={{ width: 100 }}>Card Number:</label>
                                                <input
                                                    autoComplete='new'
                                                    type={'text'}
                                                    style={{ border: 'none' }}
                                                    placeholder='Card Number'
                                                    readOnly
                                                    value={this.props.cards.card.card_number}
                                                />
                                            </Form.Field>
                                            <Form.Field inline style={{ marginBottom: 3 }}>
                                                <label style={{ width: 100 }}>Balance:</label>
                                                <input
                                                    autoComplete='new'
                                                    type={'text'}
                                                    style={{ border: 'none' }}
                                                    placeholder='Card Number'
                                                    readOnly
                                                    value={this.props.cards.card.balance}
                                                />
                                            </Form.Field>
                                            <Form.Field inline style={{ marginBottom: 3 }}>
                                                <label style={{ width: 100 }}>Status:</label>
                                                <input
                                                    autoComplete='new'
                                                    type={'text'}
                                                    style={{ border: 'none' }}
                                                    placeholder='Card Number'
                                                    readOnly
                                                    value={this.props.cards.card.status}
                                                />
                                            </Form.Field>
                                        </Form>
                                    </Segment>
                                ) : null}
                                <Segment>
                                    <Dimmer active={this.state.loading} inverted>
                                        <Loader size='small' inline='centered'>
                                            Loading
                                        </Loader>
                                    </Dimmer>
                                    {successMessage && showMessage ? (
                                        <Message
                                            icon
                                            success
                                            visible={showMessage}
                                            header={successMessage.header.toUpperCase()}
                                            content={successMessage.message}
                                        />
                                    ) : errors && showMessage ? (
                                        <Message
                                            icon
                                            error
                                            visible={showMessage}
                                            header={'Error'}
                                            content={errors.message}
                                        />
                                    ) : null}
                                    <Header textAlign='center' style={{ margin: '5px 0 16px 0' }}>
                                        Check A Card Balance
                                    </Header>
                                    <Media at='mobile'>
                                        {this.renderContentMobile()}
                                    </Media>
                                    <Media greaterThanOrEqual='tablet'>
                                        {this.renderContentDesktop()}
                                    </Media>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={5}>
                                {this.renderLinks()}
                            </Grid.Column>
                        </Grid>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, apps, locations, cards } = state;
        return { users, locations, cards, isDimmed: apps.dimmed };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...appStore.actionCreators,
                ...locationStore.actionCreators,
                ...cardStore.actionCreators,
            },
            dispatch
        )
)(CheckCardBalance);
