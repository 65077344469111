import React from "react";
import {Card, Grid, Header, Image} from "semantic-ui-react";

export default class ComponentToPrint extends React.Component {
    render() {
        let catering_page = this.props.catering_page
        let footer = this.props.footer
        let app_icon = this.props.app_icon
        return (
            <div style={{margin:20}}>
            <Card.Group>
                <Card fluid className={'james'}>
                    <Card.Content>
                        <Card.Description>
                            <Grid stackable columns={2}>
                                <Grid.Row>
                                    <Grid.Column width={11}
                                                 style={{paddingBottom: 0, color: 'white', verticalAlign: "middle"}}>
                                        {footer.show_footer_logo ?
                                            <Image src={app_icon} size='small'
                                                   className={"footer-image-mobile"}
                                                   style={{
                                                       height: 80,
                                                       width: "auto"
                                                   }}
                                                   floated='left'
                                            /> : null}

                                    </Grid.Column>
                                    <Grid.Column width={5}
                                                 style={{paddingBottom: 0, color: 'white', verticalAlign: "middle"}}>
                                        {footer.show_contact_info ?
                                            <div style={{textAlign: "left", margin: "auto", padding: 10}}
                                                 className={"footer-contact-mobile"}
                                                 dangerouslySetInnerHTML={{__html: footer.contact_info_text}}>
                                            </div> : null}
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card fluid className={'james'}>
                    <Card.Content>
                        <Header textAlign="center" as="h3">
                            {catering_page.catering_information.header}
                        </Header>
                        <Card.Description
                            dangerouslySetInnerHTML={{
                                __html: catering_page.catering_information.details,
                            }}
                        />
                    </Card.Content>
                </Card>
                {catering_page.all_menus.map((menu, index) => {
                    return (
                        <div
                            key={`item_${index}`}
                            style={{width: "100%", padding: "0 8px 15px"}}
                        >
                            <Card fluid className={'james'}>
                                <Card.Content>
                                    <Header textAlign="center" as="h3">
                                        {menu.header}
                                    </Header>
                                    <Card.Description
                                        dangerouslySetInnerHTML={{
                                            __html: menu.details,
                                        }}
                                    />
                                </Card.Content>
                            </Card>
                        </div>
                    );
                })}
            </Card.Group>
            </div>
        );
    }
}
