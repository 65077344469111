import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    Grid,
    Form,
    Button,
    Header,
    Segment,
    Image,
    Card,
    Icon,
    Divider,
    Feed, Table,
} from 'semantic-ui-react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from 'react-stripe-elements';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import {isEmpty} from 'lodash';

import {Media} from '../../AppMedia';
import {actionCreators} from '../../store/Users';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import * as cardStore from '../../store/Card';
import {getConfiguration} from '../../store/api/apiClient';
import {Logger} from '../../utils/Logger';
import giftitpng from '../../assets/giftit.png';
import giftitsvg from '../../assets/giftit.svg';
import {toFormatCurrencyWithCent} from '../../utils/NumberHelper';

const baseStyle = {
    letterSpacing: '0.025em',
    margin: 0,
    outline: 0,
    lineHeight: '1.21428571em',
    background: '#fff',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: '.28571429rem',
    boxShadow: '0 0 0 0 transparent inset',
    transition: 'color .1s ease,border-color .1s',
    padding: 10,
    color: '#fff',
    fontWeight: 600,
    fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    ':focus': {
        color: '#424770',
    },

    '::placeholder': {
        color: '#9BACC8',
    },

    ':focus::placeholder': {
        color: '#CFD7DF',
    },
};

const ELEMENT_OPTIONS = {
    style: {
        base: baseStyle,
        invalid: {
            color: '#9e2146',
        },
    },
};

class AddPayment extends Component {
    state = {
        data: {},
        card: {},
        error_card: {
            card_number: {
                content: 'Please enter a valid credit number',
                pointing: 'below',
            },
            card_expiry: {
                content: 'Please enter a valid credit expiry',
                pointing: 'below',
            },
            card_cvc: {
                content: 'Please enter a valid credit cvc',
                pointing: 'below',
            },
        },
        error_gift: {
            card_number: {
                content: 'Please enter a valid card number',
                pointing: 'below',
            },
        },
        loading: false,
        intervalId: 0,
    };

    componentWillReceiveProps(nextProps) {
        try {
            this.setState({
                settings: nextProps.ticket.ticket_settings,
                order: nextProps.ticket.ticket_order,
                loading: nextProps.loading,
            });
        } catch (err) {
            Logger.error('AddPayment', 'componentWillReceiveProps', JSON.stringify(err));
        }
    }

    componentWillMount() {
        this.props.getTicketOrder();
        if (this.props.login.user.email) {
            this.props.getUser();
            this.props.fetchMyCards({showall: false});
        }
    }

    handleChange(field, value) {
        let data = {...this.state.data};
        try {
            data[field] = value;
            let error_gift = this.state.error_gift;
            if (value.length > 0 && value.length < 16) {
                error_gift[field] = {
                    content: 'Please enter a valid card number',
                    pointing: 'below',
                };
            } else {
                delete error_gift[field];
            }
            this.setState({data, error_gift});
        } catch (err) {
            Logger.error('AddPayment', 'handleChange', JSON.stringify(err));
        }
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    selectPayment(payment, e) {
        e.preventDefault();
        let currentPayment = this.state.payment;
        payment = payment !== currentPayment ? payment : '';
        this.setState({payment, data: {}});
    }

    async selectGiftCards(card, e) {
        e.preventDefault();
        this.props.setLoading(true);
        try {
            let intervalId = setInterval(this.scrollStep.bind(this), 16);
            this.setState({intervalId});
            let due = this.props.ticket.totals.due_non_tip.replace(/[^0-9.]/g, '') * 1;
            await this.props.addGiftCardPaymentTicketOrder({
                card_id: card.id,
                amount: due < card.balance ? due : card.balance,
            });
            this.props.setLoading(false);
            this.setState({card});

            if (this.props.login.user.email) {
                await this.props.fetchMyCards({showall: false});
            }
        } catch (err) {
            Logger.error('AddPayment', 'selectGiftCards', JSON.stringify(err));
        }
    }

    async addGiftCardImportAndRedeemPaymentTicketOrder(e) {
        e.preventDefault();
        this.props.setLoading(true);
        try {
            let intervalId = setInterval(this.scrollStep.bind(this), 16);
            this.setState({intervalId});
            let due = this.props.ticket.totals.due_non_tip.replace(/[^0-9.]/g, '') * 1;
            await this.props.addGiftCardImportAndRedeemPaymentTicketOrder({
                card_number: this.state.data.card_number,
                amount: due,
            });

            if (this.props.login.user.email) {
                await this.props.fetchMyCards({showall: false});
            }
        } catch (err) {
            Logger.error('AddPayment', 'addGiftCardImportAndRedeemPaymentTicketOrder', JSON.stringify(err));
        }
        this.props.setLoading(false);
    }

    async addPayment(e) {
        this.props.setLoading(true);
        try {
            let intervalId = setInterval(this.scrollStep.bind(this), 16);
            this.setState({intervalId});
            await this.props.handleSubmit(e);
        } catch (err) {
            Logger.error('AddPayment', 'addPayment', JSON.stringify(err));
        }
        this.props.setLoading(false);
    }

    async submitTicket(e) {
        e.preventDefault();
        try {
            this.props.setLoading(true);
            this.setState({loading: true});
            await this.props.submitTicketOrder(() => {
                this.props.setLoading(false);
                this.props.history.push('ordersubmit')
            });
        } catch (err) {
            Logger.error('AddPayment', 'submitTicket', JSON.stringify(err));
        }
    }

    onError(field, content, complete) {
        let data = this.state.data;
        let error_card = this.state.error_card;
        try {
            if (content) {
                error_card[field] = {
                    content,
                    pointing: 'below',
                };
            } else {
                delete error_card[field];
            }
            data[field] = complete;
        } catch (err) {
            Logger.error('AddPayment', 'onError', JSON.stringify(err));
        }
        this.setState({data, error_card});
    }

    isRewardSelected() {
        let foundReward = this.props.ticket.ticket_order
            ? this.props.ticket.ticket_order.payments.find((payment) => {
                return payment.tender_type === 'reward';
            })
            : false;
        return (
            this.state.reward === this.props.users.user.rewards.reward[0].id ||
            foundReward
        );
    }

    isGiftCardSelected(card) {
        let foundCard = this.props.ticket.ticket_order
            ? this.props.ticket.ticket_order.payments.find((payment) => {
                return payment.tender_type === 'card';
            })
            : false;
        return this.state.card.id === card.id || foundCard;
    }

    renderDesktop(params) {
        const {readyToSubmit, amountDue, nonTipAmount, payTipsWithGiftCard} = params;

        return (
            <Grid textAlign={'center'}>
                <Grid.Column width={14}>
                    <Table basic={'very'}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={3}>
                                    <span style={{fontWeight: 600}}>
                                         {readyToSubmit
                                             ? 'Order is ready to be submitted'
                                             : 'Please select a payment method'}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan={3}>
                                    {!readyToSubmit && (
                                        <>
                                            <Form.Group widths={'equal'} >
                                                <Button
                                                    size='large'
                                                    disabled={readyToSubmit}
                                                    onClick={this.selectPayment.bind(this, 'credit_card')}
                                                    style={
                                                        this.state.payment === 'credit_card'
                                                            ? {width: "50%", ...getConfiguration().button_color}
                                                            : {width: "50%"}
                                                    }
                                                >
                                                    <Grid verticalAlign={'middle'} textAlign={'left'}>
                                                        <Grid.Column width={4}>
                                                            <Icon name='credit card outline' size={'large'}/>
                                                        </Grid.Column>
                                                        <Grid.Column width={10}>CREDIT CARD</Grid.Column>
                                                    </Grid>
                                                </Button>
                                                <Button
                                                    size='large'
                                                    disabled={readyToSubmit}
                                                    onClick={this.selectPayment.bind(this, 'gift_card')}
                                                    style={
                                                        this.state.payment === 'gift_card'
                                                            ? {width: "50%", ...getConfiguration().button_color}
                                                            : {width: "50%"}
                                                    }
                                                >
                                                    <Grid verticalAlign={'middle'} textAlign={'left'}>
                                                        <Grid.Column width={4}>
                                                            <Image
                                                                style={{width: 35}}
                                                                size={'mini'}
                                                                className={'brightness'}
                                                                src={this.state.payment === 'gift_card'
                                                                    ? giftitsvg
                                                                    : giftitpng
                                                                }
                                                                name={'/cart'}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={10}>GIFT CARD</Grid.Column>
                                                    </Grid>
                                                </Button>
                                            </Form.Group>
                                            {this.state.payment === 'credit_card' ? (
                                                <Segment textAlign={'left'} >
                                                    <Header textAlign={'center'} as='h5'>
                                                        ENTER YOUR CREDIT CARD INFORMATION
                                                    </Header>
                                                    <Form.Group>
                                                        <Form.Field
                                                            width={6}
                                                            error={
                                                                this.state.card_number !== undefined
                                                                    ? this.state.error_card.card_number
                                                                    : null
                                                            }
                                                        >
                                                            <label>*CARD NUMBER</label>
                                                            <CardNumberElement
                                                                disabled={readyToSubmit}
                                                                placeholder='Card Number'
                                                                options={ELEMENT_OPTIONS}
                                                                style={ELEMENT_OPTIONS}
                                                                onChange={(e) => {
                                                                    this.onError(
                                                                        'card_number',
                                                                        e.error ? e.error.message : null,
                                                                        e.complete
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field
                                                            width={3}
                                                            error={
                                                                this.state.card_expiry !== undefined
                                                                    ? this.state.error_card.card_expiry
                                                                    : null
                                                            }
                                                        >
                                                            <label>*EXPIRATION</label>
                                                            <CardExpiryElement
                                                                disabled={readyToSubmit}
                                                                placeholder='Expiry'
                                                                style={ELEMENT_OPTIONS}
                                                                onChange={(e) => {
                                                                    this.onError(
                                                                        'card_expiry',
                                                                        e.error ? e.error.message : null,
                                                                        e.complete
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field
                                                            width={3}
                                                            error={
                                                                this.state.card_cvc !== undefined
                                                                    ? this.state.error_card.card_cvc
                                                                    : null
                                                            }
                                                        >
                                                            <label>*CVC</label>
                                                            <CardCvcElement
                                                                disabled={readyToSubmit}
                                                                placeholder='CVC'
                                                                options={ELEMENT_OPTIONS}
                                                                style={ELEMENT_OPTIONS}
                                                                onChange={(e) => {
                                                                    this.onError(
                                                                        'card_cvc',
                                                                        e.error ? e.error.message : null,
                                                                        e.complete
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field width={4}>
                                                            {this.state.order &&
                                                            (this.state.order.status !== 'PaymentComplete' ||
                                                                amountDue !== 0) ? (
                                                                <Button
                                                                    fluid
                                                                    size='large'
                                                                    onClick={this.addPayment.bind(this)}
                                                                    disabled={
                                                                        !isEmpty(this.state.error_card) ||
                                                                        this.state.loading
                                                                    }
                                                                    style={{
                                                                        ...getConfiguration().button_color,
                                                                        marginTop: 25,
                                                                    }}
                                                                >
                                                                    PAY
                                                                </Button>
                                                            ) : null}
                                                        </Form.Field>
                                                    </Form.Group>
                                                </Segment>
                                            ) : nonTipAmount > 0 && this.state.payment === 'gift_card' ? (
                                                <Segment textAlign={'center'} >
                                                    {payTipsWithGiftCard === false &&
                                                        nonTipAmount > 0 &&
                                                        this.props.cards.my_cards &&
                                                        this.props.cards.my_cards.length > 0 && (
                                                            <>
                                                                <Card.Header textAlign={'center'} as='h5'>
                                                                    SELECT A SAVED GIFT CARD
                                                                </Card.Header>
                                                                <Card.Group stackable doubling itemsPerRow={2}>
                                                                    {this.props.cards.my_cards.map((card, index) =>
                                                                            !card.card_is_expired && <Card
                                                                                raised
                                                                                key={index}
                                                                                disabled={readyToSubmit}
                                                                                onClick={this.selectGiftCards.bind(this, card)}
                                                                                style={this.isGiftCardSelected(card)
                                                                                    ? {...getConfiguration().button_color, padding: 0}
                                                                                    : {padding: 0}
                                                                                }
                                                                            >
                                                                                <Card.Content
                                                                                    style={{
                                                                                        background: '#d3d3d36b',
                                                                                        color: 'black',
                                                                                        padding: 0,
                                                                                    }}
                                                                                >
                                                                                    <Feed>
                                                                                        <Feed.Event>
                                                                                            <Feed.Label
                                                                                                style={{paddingLeft: 10, paddingTop: 10, width: 48}}>
                                                                                                <Image
                                                                                                    style={{
                                                                                                        width: 40,
                                                                                                        borderRadius: 0
                                                                                                    }}
                                                                                                    className={'brightness'}
                                                                                                    src={giftitpng}
                                                                                                    name={'/cart'}
                                                                                                />
                                                                                            </Feed.Label>
                                                                                            <Feed.Content>
                                                                                                <p>{card.number_display}</p>
                                                                                                <p>BALANCE: {toFormatCurrencyWithCent(card.balance)} </p>
                                                                                                <p>{`PURCHASED: ${moment(card.created_at).format('MM/DD/YYYY')}`}</p>
                                                                                            </Feed.Content>
                                                                                        </Feed.Event>
                                                                                    </Feed>
                                                                                </Card.Content>
                                                                            </Card>
                                                                    )}
                                                                </Card.Group>
                                                            </>
                                                        )}
                                                    <Card.Header textAlign={'center'} as='h5'>
                                                        ADD A GIFT CARD
                                                    </Card.Header>
                                                    <Form.Group style={{display: 'inline-flex'}}>
                                                        <Form.Field
                                                            error={!isEmpty(this.state.error_gift) && this.state.data.card_number && this.state.data.card_number.length > 0}
                                                            style={{width: 320}}
                                                        >
                                                            <NumberFormat
                                                                value={this.state.data.card_number || ''}
                                                                placeholder='Enter Gift Card Number'
                                                                inputMode='decimal'
                                                                pattern='[0-9],*'
                                                                format='#### #### #### ####'
                                                                mask='_'
                                                                onInvalid={(e) => e.preventDefault()}
                                                                onValueChange={(values) =>
                                                                    this.handleChange('card_number', values.value)
                                                                }
                                                            />
                                                        </Form.Field>
                                                        {this.state.order &&
                                                        (this.state.order.status !== 'PaymentComplete' ||
                                                            amountDue !== 0) ? (
                                                            <Button
                                                                size='large'
                                                                onClick={this.addGiftCardImportAndRedeemPaymentTicketOrder.bind(
                                                                    this
                                                                )}
                                                                disabled={
                                                                    !isEmpty(this.state.error_gift) ||
                                                                    !this.state.data.card_number ||
                                                                    this.state.loading
                                                                }
                                                                style={{
                                                                    width: 140,
                                                                    ...getConfiguration().button_color,
                                                                }}
                                                            >
                                                                APPLY
                                                            </Button>
                                                        ) : null}
                                                    </Form.Group>
                                                </Segment>
                                            ) : null}
                                        </>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {this.props.ticket.ticket_order &&
                    this.props.ticket.ticket_settings ? (
                        <Grid doubling columns={2} style={{margin: '15px 40px 0'}}>
                            {!readyToSubmit && (
                                <Grid.Column width={6}>
                                    <Button
                                        fluid
                                        size='large'
                                        circular
                                        onClick={() => this.props.handleGoBack('tip')}
                                    >
                                        BACK
                                    </Button>
                                </Grid.Column>
                            )}
                            <Grid.Column width={readyToSubmit ? 16 : 10}>
                                <Button
                                    fluid
                                    size='large'
                                    circular
                                    onClick={this.submitTicket.bind(this)}
                                    disabled={!readyToSubmit || this.state.loading}
                                    style={getConfiguration().button_color}
                                >
                                    PLACE YOUR ORDER
                                </Button>
                            </Grid.Column>
                        </Grid>
                    ) : null}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(params) {
        const {readyToSubmit, amountDue, nonTipAmount, payTipsWithGiftCard} = params;

        return (
            <>
                <Form.Field>
                    <Header as='h3' textAlign='center'>
                        {readyToSubmit
                            ? 'Order is ready to be submitted'
                            : 'Please select a payment method'}
                    </Header>
                </Form.Field>
                {readyToSubmit ? null : (
                    <>
                        <Form.Button
                            fluid
                            onClick={this.selectPayment.bind(this, 'credit_card')}
                            style={
                                this.state.payment === 'credit_card'
                                    ? {...getConfiguration().button_color}
                                    : {}
                            }
                        >
                            <Icon name='credit card outline' size={'large'}/> CREDIT CARD
                        </Form.Button>
                        <Form.Button
                            fluid
                            onClick={this.selectPayment.bind(this, 'gift_card')}
                            style={
                                this.state.payment === 'gift_card'
                                    ? {...getConfiguration().button_color}
                                    : {}
                            }
                        >
                            <Icon name='gift' size={'large'}/> GIFT CARD
                        </Form.Button>
                        {this.state.payment === 'credit_card' ? (
                            <>
                                <Card.Header textAlign={'center'} as='h5'>
                                    ENTER YOUR CREDIT CARD INFORMATION
                                </Card.Header>
                                <Form.Field
                                    error={
                                        this.state.card_number !== undefined
                                            ? this.state.error_card.card_number
                                            : null
                                    }
                                >
                                    <label>*CARD NUMBER</label>
                                    <CardNumberElement
                                        disabled={readyToSubmit}
                                        placeholder='Card Number'
                                        options={ELEMENT_OPTIONS}
                                        style={ELEMENT_OPTIONS}
                                        onChange={(e) => {
                                            this.onError(
                                                'card_number',
                                                e.error ? e.error.message : null,
                                                e.complete
                                            );
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field
                                    error={
                                        this.state.card_expiry !== undefined
                                            ? this.state.error_card.card_expiry
                                            : null
                                    }
                                >
                                    <label>*EXPIRATION</label>
                                    <CardExpiryElement
                                        disabled={readyToSubmit}
                                        placeholder='Expiry'
                                        style={ELEMENT_OPTIONS}
                                        onChange={(e) => {
                                            this.onError(
                                                'card_expiry',
                                                e.error ? e.error.message : null,
                                                e.complete
                                            );
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field
                                    error={
                                        this.state.card_cvc !== undefined
                                            ? this.state.error_card.card_cvc
                                            : null
                                    }
                                >
                                    <label>*CVC</label>
                                    <CardCvcElement
                                        disabled={readyToSubmit}
                                        placeholder='CVC'
                                        options={ELEMENT_OPTIONS}
                                        style={ELEMENT_OPTIONS}
                                        onChange={(e) => {
                                            this.onError(
                                                'card_cvc',
                                                e.error ? e.error.message : null,
                                                e.complete
                                            );
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    {this.state.order &&
                                    (this.state.order.status !== 'PaymentComplete' ||
                                        amountDue !== 0) ? (
                                        <Button
                                            fluid
                                            size='large'
                                            onClick={this.addPayment.bind(this)}
                                            disabled={
                                                !isEmpty(this.state.error_card) ||
                                                this.state.loading
                                            }
                                            style={{
                                                ...getConfiguration().button_color,
                                                marginTop: 25,
                                            }}
                                        >
                                            PAY
                                        </Button>
                                    ) : null}
                                </Form.Field>
                            </>
                        ) : null}
                        {nonTipAmount > 0 && this.state.payment ===
                        'gift_card' ? (
                            <>
                                {payTipsWithGiftCard === false &&
                                nonTipAmount > 0 && this.props.cards.my_cards &&
                                this.props.cards.my_cards.length > 0 ? (
                                    <>
                                        <Card.Header as='h5' textAlign='center'>
                                            SELECT A SAVED GIFT CARD
                                        </Card.Header>
                                        {this.props.cards.my_cards.map((card, index) =>
                                                !card.card_is_expired && <Button
                                                    key={index}
                                                    basic
                                                    fluid
                                                    disabled={readyToSubmit}
                                                    onClick={this.selectGiftCards.bind(this, card)}
                                                    style={{
                                                        ...(this.isGiftCardSelected(card)
                                                            ? {
                                                                ...getConfiguration().button_color,
                                                            }
                                                            : {}),
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        marginTop: 5,
                                                    }}
                                                >
                                                    <Image
                                                        style={{
                                                            width: 40,
                                                            marginRight: 15,
                                                        }}
                                                        size={'mini'}
                                                        className={'brightness'}
                                                        src={giftitpng}
                                                        name={'/cart'}
                                                    />
                                                    <div style={{textAlign: 'left'}}>
                                                        <div style={{
                                                            fontWeight: 500
                                                        }}>{card.number_display}</div>
                                                        <div style={{
                                                            fontWeight: 500,
                                                            margin: '5px 0'
                                                        }}>BALANCE: ${card.balance.toFixed(2)}</div>
                                                        <div>{`PURCHASED: ${moment(card.created_at).format(
                                                            'MM/DD/YYYY'
                                                        )}`}</div>
                                                    </div>
                                                </Button>
                                        )}
                                    </>
                                ) : null}
                                <Card.Header textAlign={'center'} as='h5'>
                                    ADD A GIFT CARD
                                </Card.Header>
                                <Form.Field
                                    error={!isEmpty(this.state.error_gift) && this.state.data.card_number && this.state.data.card_number.length > 0}
                                    style={{width: '100%'}}
                                >
                                    <NumberFormat
                                        value={this.state.data.card_number}
                                        placeholder='Enter Gift Card Number'
                                        inputMode='decimal'
                                        pattern='[0-9],*'
                                        format='#### #### #### ####'
                                        mask='_'
                                        onInvalid={(e) => e.preventDefault()}
                                        onValueChange={(values) =>
                                            this.handleChange('card_number', values.value)
                                        }
                                    />
                                </Form.Field>
                                {this.state.order &&
                                (this.state.order.status !== 'PaymentComplete' ||
                                    amountDue !== 0) ? (
                                    <Form.Button
                                        fluid
                                        onClick={this.addGiftCardImportAndRedeemPaymentTicketOrder.bind(
                                            this
                                        )}
                                        disabled={
                                            !isEmpty(this.state.error_gift) ||
                                            !this.state.data.card_number ||
                                            this.state.loading
                                        }
                                        style={getConfiguration().button_color}
                                    >
                                        APPLY
                                    </Form.Button>
                                ) : null}
                            </>
                        ) : null}
                    </>
                )}
                {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                    <>
                        <Form.Button
                            fluid
                            circular
                            onClick={this.submitTicket.bind(this)}
                            disabled={!readyToSubmit || this.state.loading}
                            style={getConfiguration().button_color}
                        >
                            PLACE YOUR ORDER
                        </Form.Button>
                        {!readyToSubmit && (
                            <Form.Button
                                fluid
                                circular
                                onClick={() => this.props.handleGoBack('tip')}
                            >
                                BACK
                            </Form.Button>
                        )}
                    </>
                ) : null}
            </>
        );
    }

    render() {
        const {totals, ticket_settings} = this.props.ticket;
        const {order} = this.state;

        const nonTipAmount = totals.due_non_tip ? totals.due_non_tip.replace(/[^0-9.]/g, '') * 1 : 0;
        const amountDue = totals.due.replace(/[^0-9.]/g, '') * 1;
        // const tip = totals.tips.replace(/[^0-9.]/g, '') * 1;
        // const amountDue = due || tipAmount > tip ? due + tipAmount * 1 : 0;
        const payTipsWithGiftCard = ticket_settings.pay_tips_with_gift_card;
        const readyToSubmit =
            amountDue === 0 ||
            (order
                ? order.status === 'PaymentComplete'
                : false);

        return (
            <>
                <Media at='mobile'>
                    {this.renderMobile({
                        readyToSubmit,
                        amountDue,
                        nonTipAmount,
                        payTipsWithGiftCard,
                    })}
                </Media>
                <Media greaterThanOrEqual='tablet'>
                    {this.renderDesktop({
                        readyToSubmit,
                        amountDue,
                        nonTipAmount,
                        payTipsWithGiftCard,
                    })}
                </Media>
            </>
        );
    }
}

export default connect(
    (state) => {
        const {login, users, locations, ticket, cards} = state;
        return {login, users, locations, ticket, cards};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
                ...cardStore.actionCreators,
            },
            dispatch
        )
)(AddPayment);
