import React from 'react';
import {Form, Header, Card, Dropdown} from 'semantic-ui-react';
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import MaskedInput from 'react-text-mask';

import { Media } from '../../AppMedia';
import {getConfiguration} from '../../store/api/apiClient';
import states from '../../assets/states.json';

class AddressSection extends React.Component {

    handleChange(field, e) {
        this.props.update(field, e.target.value)
    }

    handleStateChange(field, e, target) {
        this.props.update(field, target.value)
    }

    handlePlaceSelect = (data) => {
        geocodeByAddress(data.description)
            .then(results => {
                this.props.handleGeocode(results[0], data)
            })
            .catch(error => console.error(error));
    };

    renderDesktop() {
        return (
            <>
            <br/>
            <Header as='h3' style={getConfiguration().label_color}>
                Shipping Information
            </Header>
            <Form autoComplete="off">
                <Form.Group widths='equal'>
                    <Form.Field
                        error={this.props.data.recipient_first_name !== undefined ? this.props.error.recipient_first_name ? true : false : null}>
                        <label>Recipient First Name: *</label>
                        <input autoComplete="new" type={'text'} placeholder='First Name'
                               value={this.props.data.recipient_first_name}
                               onChange={this.handleChange.bind(this, 'recipient_first_name')}/>
                    </Form.Field>
                    <Form.Field
                        error={this.props.data.recipient_last_name !== undefined ? this.props.error.recipient_last_name ? true : false : null}>
                        <label>Recipient Last Name: *</label>
                        <input autoComplete="new" type={'text'} placeholder='Last Name'
                               value={this.props.data.recipient_last_name}
                               onChange={this.handleChange.bind(this, 'recipient_last_name')}/>
                    </Form.Field>
                </Form.Group>
                <Form.Field
                    error={this.props.data.recipient_address !== undefined ? this.props.error.recipient_address ? true : false : null}>
                    <label>Recipient Address: *</label>
                    <GooglePlacesAutocomplete
                        autoComplete="new"
                        debounce={600}
                        onSelect={this.handlePlaceSelect}
                        initialValue={this.props.data.recipient_address}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Recipient Address 2</label>
                    <input autoComplete="new" type={'text'} placeholder='Address2'
                           value={this.props.data.recipient_address2}
                           onChange={this.handleChange.bind(this, 'recipient_address2')}/>
                </Form.Field>
                <Form.Group>
                    <Form.Field
                        width={8}
                        error={this.props.data.recipient_city !== undefined ? this.props.error.recipient_city ? true : false : null}>
                        <label>Recipient City: *</label>
                        <input autoComplete="new" type={'text'}
                               value={this.props.data.recipient_city}
                               placeholder='City'
                               onChange={this.handleChange.bind(this, 'recipient_city')}/>
                    </Form.Field>
                    <Form.Field
                        width={5}
                        error={this.props.data.recipient_state !== undefined ? this.props.error.recipient_state ? true : false : null}>
                        <label>Recipient State: *</label>

                        <Dropdown autoComplete="new"
                                  placeholder='State'
                                  onChange={this.handleStateChange.bind(this, 'recipient_state')}
                                  value={this.props.data.recipient_state}
                                  search selection
                                  options={states.map((state, index) => {
                                      return {
                                          key: index,
                                          value: state.abbreviation,
                                          text: state.name
                                      }
                                  })}/>
                    </Form.Field>
                    <Form.Field
                        width={3}
                        error={this.props.data.recipient_zip !== undefined ? this.props.error.recipient_zip ? true : false : null}>
                        <label>Recipient Zip Code: *</label>
                        <input autoComplete="new" type={'text'}
                               value={this.props.data.recipient_zip}
                               placeholder='Zip code'
                               onChange={this.handleChange.bind(this, 'recipient_zip')}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Recipient Email</label>
                        <input autoComplete="new" type={'text'} placeholder='Email'
                               value={this.props.data.recipient_email}
                               onChange={this.handleChange.bind(this, 'recipient_email')}/>
                    </Form.Field>
                    <Form.Input
                        color="teal"
                        label="Recipient Phone"
                        fluid
                        placeholder='Phone'
                        autoComplete="new"
                        value={this.props.data.recipient_phone}
                        children={
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Phone"
                                onChange={this.handleChange.bind(this, 'recipient_phone')}
                            />
                        }
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        onChange={this.handleChange.bind(this, 'recipient_phone')}
                    />
                </Form.Group>
            </Form>
            </>
        );
    }

    renderMobile() {
        return (
            <Card fluid style={{marginBottom: 15}}>
                <Card.Content>
                    <Card.Header as='h3' style={getConfiguration().label_color}>
                        Shipping Information
                    </Card.Header>
                </Card.Content>
                <Card.Content>
                    <Form autoComplete='off'>
                        <Form.Field
                            error={
                                this.props.data.recipient_first_name !== undefined
                                    ? this.props.error.recipient_first_name
                                    ? true
                                    : false
                                    : null
                            }
                        >
                            <label>Recipient First Name: *</label>
                            <input
                                autoComplete='new'
                                type={'text'}
                                placeholder='First Name'
                                value={this.props.data.recipient_first_name}
                                onChange={this.handleChange.bind(this, 'recipient_first_name')}
                            />
                        </Form.Field>
                        <Form.Field
                            error={
                                this.props.data.recipient_last_name !== undefined
                                    ? this.props.error.recipient_last_name
                                    ? true
                                    : false
                                    : null
                            }
                        >
                            <label>Recipient Last Name: *</label>
                            <input
                                autoComplete='new'
                                type={'text'}
                                placeholder='Last Name'
                                value={this.props.data.recipient_last_name}
                                onChange={this.handleChange.bind(this, 'recipient_last_name')}
                            />
                        </Form.Field>

                        <Form.Field
                            error={
                                this.props.data.recipient_address !== undefined
                                    ? this.props.error.recipient_address
                                    ? true
                                    : false
                                    : null
                            }
                        >
                            <label>Recipient Address: *</label>
                            <GooglePlacesAutocomplete
                                autoComplete='new'
                                debounce={600}
                                onSelect={this.handlePlaceSelect}
                                initialValue={this.props.data.recipient_address}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Recipient Address 2</label>
                            <input
                                autoComplete='new'
                                type={'text'}
                                placeholder='Address2'
                                value={this.props.data.recipient_address2}
                                onChange={this.handleChange.bind(this, 'recipient_address2')}
                            />
                        </Form.Field>
                        <Form.Field
                            error={
                                this.props.data.recipient_city !== undefined
                                    ? this.props.error.recipient_city
                                    ? true
                                    : false
                                    : null
                            }
                        >
                            <label>Recipient City: *</label>
                            <input
                                autoComplete='new'
                                type={'text'}
                                value={this.props.data.recipient_city}
                                placeholder='City'
                                onChange={this.handleChange.bind(this, 'recipient_city')}
                            />
                        </Form.Field>
                        <Form.Field
                            error={
                                this.props.data.recipient_state !== undefined
                                    ? this.props.error.recipient_state
                                    ? true
                                    : false
                                    : null
                            }
                        >
                            <label>Recipient State: *</label>

                            <Dropdown
                                autoComplete='new'
                                placeholder='State'
                                onChange={this.handleStateChange.bind(this, 'recipient_state')}
                                value={this.props.data.recipient_state}
                                selection
                                options={states.map((state, index) => {
                                    return {
                                        key: index,
                                        value: state.abbreviation,
                                        text: state.name,
                                    };
                                })}
                            />
                        </Form.Field>
                        <Form.Field
                            error={
                                this.props.data.recipient_zip !== undefined
                                    ? this.props.error.recipient_zip
                                    ? true
                                    : false
                                    : null
                            }
                        >
                            <label>Recipient Zip Code: *</label>
                            <input
                                autoComplete='new'
                                type={'text'}
                                value={this.props.data.recipient_zip}
                                placeholder='Zip code'
                                onChange={this.handleChange.bind(this, 'recipient_zip')}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Recipient Email</label>
                            <input
                                autoComplete='new'
                                type={'text'}
                                placeholder='Email'
                                value={this.props.data.recipient_email}
                                onChange={this.handleChange.bind(this, 'recipient_email')}
                            />
                        </Form.Field>
                        <Form.Input
                            type='number'
                            pattern='[0-9]*'
                            color='teal'
                            label='Recipient Phone'
                            fluid
                            placeholder='Phone'
                            autoComplete='new'
                            value={this.props.data.recipient_phone || ''}
                            onInput={(e) =>
                                this.props.update('recipient_phone', e.target.validity.valid
                                ? e.target.value
                                : this.props.data.recipient_phone)
                            }
                            maxLength='10'
                        />
                    </Form>
                </Card.Content>
            </Card>
        );
    }

    render() {
        return (
            <>
                <Media at='mobile'>
                    {this.renderMobile()}
                </Media>
                <Media greaterThanOrEqual='tablet'>
                    {this.renderDesktop()}
                </Media>
            </>
        );
    }
}

export default AddressSection;