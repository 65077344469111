import React from 'react';
import {Route, Router, Switch} from 'react-router';
import App from './components/layout/App';
import NewApp from './components/layout/NewApp';

import Login from './components/pages/Login';
import ResetPassword from './components/pages/ResetPassword';
import ForgotPassword from './components/pages/ForgotPassword';
import UnlockAccount from './components/pages/UnlockAccount';
import ResendUnlock from './components/pages/ResendUnlock';
import CreateAccount from './components/pages/CreateAccount';

const MainRouter = (props) => (
    <Router history={props.history}>
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/signup' exact component={CreateAccount} />
            <Route path='/forgotpassword' exact component={ForgotPassword} />
            <Route path='/unlockaccount' exact component={UnlockAccount} />
            <Route path='/resendunlock' exact component={ResendUnlock} />
            <Route path='/resetpassword' exact component={ResetPassword} />
            <Route path='/newhome' component={NewApp} />
            <Route path='/' component={App} />
        </Switch>
    </Router>
);

export default MainRouter;
