import React, {Component} from 'react';
import {Button, Badge} from 'react-bootstrap';
import {Grid} from 'semantic-ui-react'


export default class Toggles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedToggle: {}
        }
    }


    getSelected(selectedToggle) {
        let selected = this.findToggle(selectedToggle)
        this.setState({
            selectedToggle: selected ? {} : selectedToggle
        })
        this.props.submit(selectedToggle)
    }


    findToggle(toggle) {
        return this.state.selectedToggle.name === toggle.name
    }

    render() {
        return (
            <Grid.Row>
                {
                    this.props.toggles.map((toggle, index) => {
                        let selected = this.findToggle(toggle)
                        return <Grid.Column width={12}  key={index} style={{
                                textAlign: "center"
                            }}>
                            <Button
                                disabled={!this.props.enabled}
                                className={selected ? "btn btn-primary" : "btn btn-default"}
                                type="button"
                                onClick={this.getSelected.bind(this, toggle)}>{toggle.name.toUpperCase()}
                                </Button>
                            <br />
                            {toggle.price > 0 ? <Badge variant={selected ? "success" : "light"}>+${toggle.price}</Badge> : null}
                        </Grid.Column>
                    })
                }
            </Grid.Row>
        );
    }
}

