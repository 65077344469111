import React, {Component, createRef} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    Button,
    Card,
    Dimmer,
    Divider,
    Feed,
    Grid,
    Header,
    Item,
    Loader,
    Progress,
    Ref,
    Segment,
    Sticky
} from 'semantic-ui-react';

import {Media} from '../../../AppMedia';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';
import {getConfiguration} from '../../../store/api/apiClient';
import rewards from '../../../assets/rewards.png';

class MyRewards extends Component {
    constructor(props) {
        super(props);

        this.contextRef = createRef();
    }

    componentDidMount() {
        this.handleInit();
    }

    handleInit = async () => {
        const { auth } = this.props;
        auth.authenticated && await this.props.getUser();
    }

    renderContent = () => {
        const { auth, user, locations } = this.props;
        const { reward_page } = locations.configurations;
        const { backgroundImage } = getConfiguration().progress_bar_gradient;
        return (
            <Segment>
                <style
                    dangerouslySetInnerHTML={{
                        __html: [
                            '.ui.progress.custom-color-progress .bar {\n' +
                                '    background-image: ' +
                                backgroundImage +
                                ';\n' +
                                '    min-width: 0px' +
                                '}' +
                                '.ui.progress[data-percent="0"] .bar {\n' +
                                '  background:transparent;\n' +
                                '}\n' +
                                '.ui.progress[data-percent="0"] .bar .progress {\n' +
                                '  color: rgba(0, 0, 0, 0.87);\n' +
                                '}\n' +
                                '.ui.inverted.progress[data-percent="0"] .bar .progress {\n' +
                                '  color: rgba(255, 255, 255, 0.9);\n' +
                                '}\n' +
                                '.ui.inverted.indicating.progress .label {\n' +
                                '  color: rgba(255, 255, 255, 0.9);\n' +
                                '}' +
                              `.how_it_works ul {
                                counter-reset:item;
                                padding-left: 42px;
                              }` +
                              `.how_it_works ul>li {
                                counter-increment:item;
                                list-style:none;
                                margin: 10px 0;
                              }` +
                              `.how_it_works ul>li::before {
                                content: counter(item);
                                color: ${getConfiguration().label_color.color};
                                display: inline-block;
                                margin-left: -32px;
                                margin-right: 10px;
                                text-align: center;
                                border: 1px solid;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                border-radius: 50%;
                                width: 22px;
                                float: left;
                              }`,
                        ].join('\n'),
                    }}
                />
                {auth.authenticated && user.rewards && user.rewards.is_rewards_on && (
                    <>
                        <Grid>
                            <Grid.Column width={16}>
                                <Header textAlign='center' as='h3'>
                                    {reward_page.your_rewards_progress.header}
                                </Header>
                                <Header as='h2'>
                                    <span
                                        style={{
                                            ...getConfiguration().label_color,
                                            fontWeight: 'bolder',
                                            fontSize: 35,
                                        }}
                                    >
                                        {`${user.rewards.summary.current_points} /`}
                                    </span>
                                    <span style={getConfiguration().label_color}>
                                        {` ${user.rewards.summary.points_to_next_reward}`}
                                    </span>
                                </Header>
                                <Progress
                                    percent={
                                        (user.rewards.summary.current_points /
                                            user.rewards.summary.points_to_next_reward) *
                                        100
                                    }
                                    style={{ margin: '0 0 10px' }}
                                    className='custom-color-progress'
                                />
                                <Card.Description
                                    dangerouslySetInnerHTML={{
                                        __html: reward_page.your_rewards_progress.details.replace(
                                            '{points_left}',
                                            user.rewards.summary.points_needed
                                        ),
                                    }}
                                />
                            </Grid.Column>
                        </Grid>
                        <Divider />
                    </>
                )}
                <Grid>
                    <Grid.Column width={16}>
                        <Header textAlign='center' as='h3'>
                            {reward_page.how_it_works_information.header}
                        </Header>
                        <Card.Description
                            textAlign='center'
                            className='how_it_works'
                            dangerouslySetInnerHTML={{
                                __html: reward_page.how_it_works_information.details,
                            }}
                        />
                    </Grid.Column>
                </Grid>
                {!auth.authenticated && (
                    <Grid centered>
                        <Grid.Column mobile={16} computer={10}>
                            <Button
                                as={Link}
                                to='/signup'
                                name='/signup'
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                SIGN UP!
                            </Button>
                        </Grid.Column>
                    </Grid>
                )}
            </Segment>
        );
    };

    renderLinks = () => {
        const { auth, user, locations } = this.props;
        const { reward_page } = locations.configurations;
        return (
            <Card.Group>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign='center' as='h4'>
                            {reward_page.your_rewards_information.header}
                        </Header>
                        <Card.Description
                            textAlign='center'
                            dangerouslySetInnerHTML={{
                                __html: reward_page.your_rewards_information.details,
                            }}
                        />
                        {auth.authenticated && user.rewards && user.rewards.reward ? (
                            <Feed>
                                {user.rewards.reward.map((reward, index) => {
                                    return (
                                        <Feed.Event
                                            as={Link}
                                            to='#'
                                            name='#'
                                            style={{
                                                ...getConfiguration().button_color,
                                                borderRadius: 5,
                                                marginTop: 10,
                                            }}
                                            key={`reward_${index}`}
                                        >
                                            <Feed.Label
                                                style={{ marginTop: 5 }}
                                            >
                                              <img src={rewards} alt='Reward' style={{ width: 32 }} />
                                            </Feed.Label>
                                            <Feed.Content style={{ paddingLeft: 10 }}>
                                                <Feed.Summary style={{ color: 'white' }}>
                                                    {reward.name}
                                                </Feed.Summary>
                                                <Feed.Meta style={{ color: 'white' }}>
                                                    Expires on {reward.end}
                                                </Feed.Meta>
                                            </Feed.Content>
                                        </Feed.Event>
                                    );
                                })}
                            </Feed>
                        ) : (
                            <Item>
                                <Item.Header as='h5' className='text-center'>
                                    No current rewards or offers.
                                </Item.Header>
                            </Item>
                        )}
                    </Card.Content>
                </Card>
                <Card fluid>
                    <Card.Content>
                        <Header textAlign='center' as='h4'>
                            {reward_page.your_points_information.header}
                        </Header>
                        {auth.authenticated && user.rewards ? (
                            <Item>
                                <Item.Content>
                                    <Item.Description>Expiring Soon</Item.Description>
                                </Item.Content>
                                <Feed>
                                {user.rewards.point_expiration.map((reward, index) => {
                                    return (
                                        <Feed.Event key={`pointexp_${index}`}>
                                            <Feed.Content>
                                                <Feed.Meta style={{ width: '100%' }}>
                                                    <Link
                                                        to={`#`}
                                                        style={{
                                                            ...getConfiguration().label_color,
                                                            float: 'right',
                                                        }}
                                                    >
                                                        {`${reward.points_expiring} POINTS`}
                                                    </Link>
                                                    <div>{reward.date}</div>
                                                </Feed.Meta>
                                            </Feed.Content>
                                        </Feed.Event>
                                    );
                                })}
                                </Feed>
                            </Item>
                        ) : (
                            <Card.Description textAlign='center'>
                                Information unavailable.
                            </Card.Description>
                        )}
                    </Card.Content>
                </Card>
            </Card.Group>
        );
    };

    render() {
        const { auth, user } = this.props;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <Dimmer active={auth.authenticated && (!user || !user.rewards)} inverted>
                                <Loader size='small' inline='centered'>
                                    Loading
                                </Loader>
                            </Dimmer>
                            <Media lessThan='computer'>
                                <Grid>
                                    <Grid.Column width={16}>
                                        {this.renderContent()}
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        {this.renderLinks()}
                                    </Grid.Column>
                                </Grid>
                            </Media>
                            <Media greaterThanOrEqual='computer'>
                                <Ref innerRef={this.contextRef}>
                                    <Grid padded>
                                        <Grid.Column width={11}>
                                            <Sticky
                                                context={this.contextRef}
                                                styleElement={{zIndex: 0}}
                                                offset={120}
                                                bottomOffset={20}
                                            >
                                                {this.renderContent()}
                                            </Sticky>
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            {this.renderLinks()}
                                        </Grid.Column>
                                    </Grid>
                                </Ref>
                            </Media>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users: { user }, login, locations } = state;
        const { user: auth } = login;
        return { user, auth, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
            },
            dispatch
        )
)(withRouter(MyRewards));
