import Config from '../config'

const logger = require ('./LogEntries.js')(Config.logentriesToken, {trace: true});  //start up the logentries code with your token and options
const ERROR = 'error';
const WARN = 'warn';
const INFO = 'info';
const LOG = 'log';

const error = (component, action, message) => {
  //call the approriate type's method (error, warn, log, info)
  logger[ERROR](`[${component}] [${action}] ${message}`);
}

const warn = (component, action, message) => {
  //call the approriate type's method (error, warn, log, info)
  logger[WARN](`[${component}] [${action}] ${message}`);
}

const info = (component, action, message) => {
  //call the approriate type's method (error, warn, log, info)
  logger[INFO](`[${component}] [${action}] ${message}`);
}

const log = (component, action, message) => {
  //call the approriate type's method (error, warn, log, info)
  logger[LOG](`[${component}] [${action}] ${message}`);
}

export const Logger = {
  error,
  warn,
  info,
  log,
}