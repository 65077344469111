import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Card, Grid, Header, Segment,} from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';

class Terms extends Component {
    renderContent = () => {
        const { terms_page } = this.props.locations.configurations;

        return (
            <Segment>
                <Grid>
                    <Grid.Column width={16}>
                        <Header textAlign='center' as='h3'>
                            {terms_page.terms.header}
                        </Header>
                        <div dangerouslySetInnerHTML={{ __html: terms_page.terms.details }} />
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    };

    renderLinks = () => {
        const { app_icon } = this.props.locations.configurations;
        const { address1, address2, city, fax, phone, state, zip } = this.props.locations.location;
        return (
            <Card fluid>
                <Card.Content>
                    <Card.Description style={{ textAlign: 'center' }}>
                        <p><img src={app_icon} height='180' /></p>
                        <p>{address2 ? `${address1}, ${address2}` : address1}</p>
                        <p>{`${city}, ${state} ${zip}`}</p>
                        <p>{`Phone: ${phone}`}</p>
                        <p>{`Fax: ${fax}`}</p>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    };

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <Grid padded>
                            <Grid.Column mobile={16} tablet={10} computer={11}>{this.renderContent()}</Grid.Column>
                            <Grid.Column mobile={16} tablet={6} computer={5}>{this.renderLinks()}</Grid.Column>
                        </Grid>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, locations } = state;
        const { user } = users;
        return { user, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
            },
            dispatch
        )
)(withRouter(Terms));
