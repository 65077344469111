import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Dimmer, Loader, Button, Header, Table, Form } from 'semantic-ui-react';

import { Media } from '../../AppMedia';
import { actionCreators } from '../../store/Users';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import { getConfiguration } from '../../store/api/apiClient';
import { Logger } from '../../utils/Logger';

class DeliveryOption extends Component {
    constructor(props) {
        super(props);

        this.state = {
            receive:
                props.ticket.ticket_order && props.ticket.ticket_order.use_delivery
                    ? 'delivery'
                    : props.ticket.ticket_order.use_curbside
                    ? 'curbside'
                    : '',
            loading: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        try {
            const receive = nextProps.ticket.ticket_order && nextProps.ticket.ticket_order.use_delivery
                ? 'delivery'
                : nextProps.ticket.ticket_order.use_curbside
                ? 'curbside'
                : '';
            this.setState({ receive });
        } catch (err) {
            this.setState({ loading: false });
            Logger.error('DeliveryOption', 'componentWillReceiveProps', JSON.stringify(err));
        }
    }

    selectOption(option) {
        return (e) => {
            e.preventDefault();
            this.setState({ receive: option });
        };
    }

    async updateTicketOrderDelivery(e) {
        e.preventDefault();
        this.setState({loading: true})
        try {
            const showOrderAheadOption = this.props.ticket.ticket_settings
                ? this.props.ticket.ticket_settings.show_order_ahead_option
                : false;
            const receive = this.state.receive;
            let data = {};
            if (receive === 'delivery') {
                data = {
                    use_delivery: true,
                    use_curbside: false,
                };
            } else if (receive === 'curbside') {
                data = {
                    use_delivery: false,
                    use_curbside: true,
                };
            }
            const result = await this.props.updateTicketOrderDelivery(data);
            this.setState({loading: false})
            if (!result.error) {
                this.props.nextStep(showOrderAheadOption ? 'time' : 'information');
            }
        } catch (err) {
            Logger.error('DeliveryOption', 'updateTicketOrderDelivery', JSON.stringify(err));
        }
    }

    renderDesktop(params) {
        const { showDeliveryOption, showCurbsideOption, deliveryCharge } = params;

        return (
            <Grid textAlign={'center'}>
                <Grid.Column width={12}>
                    <Table basic={'very'}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={3}>
                                    <span style={{ fontWeight: 600 }}>
                                        Select how would you like to receive your order?
                                    </span>
                                    <br />
                                    {showDeliveryOption ? (
                                        <span>
                                            *A fee of {deliveryCharge} will be applied to your order
                                            if you choose delivery
                                        </span>
                                    ) : null}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={3}>
                                    {showDeliveryOption ? (
                                        <>
                                            <br />
                                            <Button
                                                size='large'
                                                fluid
                                                onClick={this.selectOption('delivery').bind(this)}
                                                style={
                                                    this.state.receive === 'delivery'
                                                        ? {
                                                              ...getConfiguration().button_color,
                                                          }
                                                        : {}
                                                }
                                            >
                                                DELIVERY
                                            </Button>
                                        </>
                                    ) : null}
                                    {showCurbsideOption ? (
                                        <>
                                            <br />
                                            <Button
                                                size='large'
                                                onClick={this.selectOption('curbside').bind(this)}
                                                fluid
                                                style={
                                                    this.state.receive === 'curbside'
                                                        ? {
                                                              ...getConfiguration().button_color,
                                                          }
                                                        : {}
                                                }
                                            >
                                                CURBSIBE PICK UP
                                            </Button>
                                        </>
                                    ) : null}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                        <Grid doubling columns={2}>
                            <Grid.Column width={6}>
                                <Button
                                    fluid
                                    circular
                                    size='large'
                                    onClick={() => this.props.handleGoBack()}
                                >
                                    BACK
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <Button
                                    fluid
                                    size='large'
                                    circular
                                    disabled={!this.state.receive || this.state.loading}
                                    onClick={this.updateTicketOrderDelivery.bind(this)}
                                    style={getConfiguration().button_color}
                                >
                                    CONTINUE
                                </Button>
                            </Grid.Column>
                        </Grid>
                    ) : null}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile(params) {
        const { showDeliveryOption, showCurbsideOption, deliveryCharge } = params;
        return (
            <>
                <Form.Field>
                    <Header as='h4' textAlign='center'>
                        Select how would you like to receive your order?
                    </Header>
                    {showDeliveryOption ? (
                        <div style={{ textAlign: 'center' }}>
                            A fee of {deliveryCharge} will be applied to your order if you choose
                            delivery
                        </div>
                    ) : null}
                </Form.Field>
                {showDeliveryOption ? (
                    <Form.Button
                        fluid
                        onClick={this.selectOption('delivery').bind(this)}
                        style={
                            this.state.receive === 'delivery'
                                ? {
                                      ...getConfiguration().button_color,
                                  }
                                : {}
                        }
                    >
                        DELIVERY
                    </Form.Button>
                ) : null}
                {showCurbsideOption ? (
                    <Form.Button
                        fluid
                        onClick={this.selectOption('curbside').bind(this)}
                        style={
                            this.state.receive === 'curbside'
                                ? {
                                      ...getConfiguration().button_color,
                                  }
                                : {}
                        }
                    >
                        CURBSIBE PICK UP
                    </Form.Button>
                ) : null}
                {this.props.ticket.ticket_order && this.props.ticket.ticket_settings ? (
                    <>
                        <Form.Button
                            fluid
                            circular
                            disabled={!this.state.receive || this.state.loading}
                            onClick={this.updateTicketOrderDelivery.bind(this)}
                            style={getConfiguration().button_color}
                        >
                            CONTINUE
                        </Form.Button>
                        <Form.Button fluid circular onClick={() => this.props.handleGoBack()}>
                            BACK
                        </Form.Button>
                    </>
                ) : null}
            </>
        );
    }

    render() {
        const { ticket_settings } = this.props.ticket;
        const showDeliveryOption = ticket_settings ? ticket_settings.show_delivery_option : false;
        const showCurbsideOption = ticket_settings ? ticket_settings.show_curbside_option : false;
        const deliveryCharge = ticket_settings ? ticket_settings.delivery_charge : '$0.00';

        return (
            <>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='small' inline='centered'>
                        Loading
                    </Loader>
                </Dimmer>
                <Media at='mobile'>
                    {this.renderMobile({
                        showDeliveryOption,
                        showCurbsideOption,
                        deliveryCharge,
                    })}
                </Media>
                <Media greaterThanOrEqual='tablet'>
                    {this.renderDesktop({
                        showDeliveryOption,
                        showCurbsideOption,
                        deliveryCharge,
                    })}
                </Media>
            </>
        );
    }
}

export default connect(
    (state) => {
        const { users, locations, ticket } = state;
        return { users, locations, ticket };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
            },
            dispatch
        )
)(DeliveryOption);
