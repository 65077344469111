import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Button, Form, Grid, Input, Item } from 'semantic-ui-react';

import { Media } from '../../../AppMedia';
import ProgramCard from '../../common/ui/components/ProgramCard';
import { getConfiguration } from '../../../store/api/apiClient';

export default (props) => {
    const { cards: { my_card, program, programs }, handleAmountSelect, handleGoBack, type } = props;
    const [amount, setAmount] = useState({
        price: (sessionStorage.getItem('custom_amount_value') && sessionStorage.getItem('custom_amount_value') * 1) || 0,
        isCustom: sessionStorage.getItem('custom_amount') === 'true' || false,
    });
    const defaultProgram = {
        description: 'xxxx-xxxx-xxxx-1234',
        image: {
            imageSignedUrl: null,
        },
    };

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        Select Amount
                    </Item.Header>
                </Item.Content>
            </Item>
            <Item>
                <Item.Content>
                    <Item.Description>
                        <Grid centered>
                            <Grid.Column mobile={16} computer={7}
                                         style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                {type === "Reload" ?
                                    <ProgramCard canvasName={'mycanvas'} card={my_card && my_card.card ? my_card.card : null} program={my_card && my_card.card.program ? my_card.card.program : program ? program : defaultProgram} />
                                    :<ProgramCard canvasName={'mycanvas'}  program={my_card && my_card.card && my_card.card.program ? my_card.card.program : program ? program : defaultProgram} />
                                }
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={8}>
                                <Grid columns={3}>
                                    {[25, 50, 75, 100, 125, 150].map((price, index) => {
                                        return (
                                            <Grid.Column key={`price_${index}`}>
                                                <Button
                                                    className={'tip_button'}
                                                    fluid
                                                    style={
                                                        amount.isCustom || amount.price !== price
                                                            ? { display: 'block' }
                                                            : {
                                                                  display: 'block',
                                                                  ...getConfiguration()
                                                                      .button_color,
                                                              }
                                                    }
                                                    onClick={() =>
                                                        setAmount({ price, isCustom: false })
                                                    }
                                                >
                                                    ${price}
                                                </Button>
                                            </Grid.Column>
                                        );
                                    })}
                                    <Grid.Column mobile={16} computer={amount.isCustom ? 8 : 16}>
                                        <Button
                                            className={'tip_button'}
                                            fluid
                                            onClick={() => setAmount({ price: 0, isCustom: true })}
                                        >
                                            Other Amount
                                        </Button>
                                    </Grid.Column>
                                    {amount.isCustom && (
                                        <Grid.Column mobile={16} computer={amount.isCustom ? 8 : 0}>
                                            <Form.Field width={16}>
                                                <NumberFormat
                                                    value={amount.price || ''}
                                                    placeholder='Enter Amount'
                                                    inputMode='decimal'
                                                    isAllowed={(values) => {
                                                        const { formattedValue, floatValue } = values;
                                                        return formattedValue === '' || floatValue <= 10000;
                                                    }}
                                                    isNumericString
                                                    decimalScale={2}
                                                    pattern='[0-9],*'
                                                    customInput={Input}
                                                    style={{ width: '100%' }}
                                                    onInvalid={(e) => e.preventDefault()}
                                                    onValueChange={(values) =>
                                                        setAmount({price: Math.abs(values.floatValue), isCustom: true})
                                                    }
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    )}
                                </Grid>
                            </Grid.Column>
                        </Grid>
                    </Item.Description>
                </Item.Content>
            </Item>
            <Media at='mobile'>
                <Form.Button
                    fluid
                    circular
                    style={getConfiguration().button_color}
                    disabled={!amount || amount.price === 0}
                    onClick={() => {
                        sessionStorage.setItem('custom_amount', amount.isCustom);
                        sessionStorage.setItem('custom_amount_value', amount.price);
                        handleAmountSelect(amount.price);
                    }}
                >
                    CONTINUE
                </Form.Button>
                {programs && programs.length > 1 && (
                    <Form.Button
                        fluid
                        circular
                        onClick={() => handleGoBack()}
                    >
                        BACK
                    </Form.Button>
                )}
            </Media>
            <Media greaterThanOrEqual='tablet'>
                <Grid centered doubling columns={2}>
                    {programs && programs.length > 1 && (
                        <Grid.Column width={5}>
                            <Button
                                fluid
                                circular
                                onClick={() => handleGoBack()}
                            >
                                BACK
                            </Button>
                        </Grid.Column>
                    )}
                    <Grid.Column width={10}>
                        <Button
                            fluid
                            circular
                            style={getConfiguration().button_color}
                            disabled={!amount || amount.price === 0}
                            onClick={() => {
                                sessionStorage.setItem('custom_amount', amount.isCustom);
                                sessionStorage.setItem('custom_amount_value', amount.price);
                                handleAmountSelect(amount.price);
                            }}
                        >
                            CONTINUE
                        </Button>
                    </Grid.Column>
                </Grid>
            </Media>
        </Item.Group>
    );
};
