import {history} from '../../index'
import loginApi from './login'
import { Logger } from '../../utils/Logger';

export const handleError = async (response, callback, authSkip = false) => {
    Logger.error('API', 'handleError', `status=${response.status} ${response.data ? response.data.message : JSON.stringify(response)}`);
    if (response.status === 404 || response.status === 500) {
        return response.data
    } else if (authSkip) {
        callback(true);
    } else if (response.status === 401) {
        try {
            let response = await loginApi.refresh()
            let auth = response.data
            sessionStorage.setItem('token', auth.token);
            sessionStorage.setItem('refresh_token', auth.refresh_token);
            sessionStorage.setItem('expires', auth.expires);
            callback()
        } catch (e) {
            console.log('Refresh token error(401): ', e);
            Logger.error('API', 'handleError', `401 - ${JSON.stringify(e)}`);
            sessionStorage.setItem('previouspath', history.location.pathname)
            history.push("/login")
        }
    } else if (response.data.code === "unauthorized") {
        try {
            let response = await loginApi.refresh()
            let auth = response.data
            sessionStorage.setItem('token', auth.token);
            sessionStorage.setItem('refresh_token', auth.refresh_token);
            sessionStorage.setItem('expires', auth.expires);
            callback()
        } catch (e) {
            console.log('Refresh token error(unauthorized): ', e);
            Logger.error('API', 'handleError', `unauthorized - ${JSON.stringify(e)}`);
            sessionStorage.setItem('previouspath', history.location.pathname)
            history.push("/login")
        }
    } else {
        return response.data
    }
}

export const handleTicketError = async (response, callback) => {
    Logger.error('API', 'handleTicketError', `status=${response.status} ${response.data ? response.data.message : JSON.stringify(response)}`);
    if (response.status === 404) {
        if(response.data.code === 'not_found' && !response.goBack) {
            localStorage.removeItem('ticket')
        }
        if (response.goBack) {
            history.goBack();
        } else {
            history.replace(response.route);
        }
    } else if (response.status === 401) {
        try {
            let response = await loginApi.refresh()
            let auth = response.data
            sessionStorage.setItem('token', auth.token);
            sessionStorage.setItem('refresh_token', auth.refresh_token);
            sessionStorage.setItem('expires', auth.expires);
            callback()
        } catch (e) {
            console.log('Refresh token error(401): ', e);
            Logger.error('API', 'handleTicketError', `401 - ${JSON.stringify(e)}`);
            sessionStorage.setItem('previouspath', history.location.pathname)
            history.push("/login")
        }
    } else if (response.data.code === "unauthorized") {
        try {
            let response = await loginApi.refresh()
            let auth = response.data
            sessionStorage.setItem('token', auth.token);
            sessionStorage.setItem('refresh_token', auth.refresh_token);
            sessionStorage.setItem('expires', auth.expires);
            callback()
        } catch (e) {
            console.log('Refresh token error(unauthorized): ', e);
            Logger.error('API', 'handleTicketError', `unauthorized - ${JSON.stringify(e)}`);
            sessionStorage.setItem('previouspath', history.location.pathname)
            history.push("/login")
        }
    } else {
        return response.data
    }
}