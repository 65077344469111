import React, {Component, createRef} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Dimmer, Form, Grid, Item, Loader, Message, Placeholder, Ref, Segment, Sticky,} from 'semantic-ui-react';
import {Elements, StripeProvider} from 'react-stripe-elements';

import ProgramList from './ProgramList';
import Amount from './Amount';
import PhysicalCard from './PhysicalCard';
import PhysicalPreview from './PhysicalPreview';
import PromoCode from './PromoCode';
import EmailInfo from './EmailInfo';
import PhoneInfo from './PhoneInfo';
import AddressInfo from './AddressInfo';
import PersonalMessage from './PersonalMessage';
import Payment from './Payment';
import Review from './Review';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';
import * as locationStore from '../../../store/Location';
import * as cardStore from '../../../store/Card';
import {getConfiguration} from '../../../store/api/apiClient';
import {toFormatCurrencyWithCent} from '../../../utils/NumberHelper';
import {Logger} from '../../../utils/Logger';
import Config from '../../../config'

const ORDER_STEP_LIST = {
    Purchase: ['program', 'amount', 'promo', 'payment', 'review'],
    Reload: ['amount', 'promo', 'payment', 'review'],
    SendText: ['program', 'amount', 'promo', 'phone', 'message', 'payment', 'review'],
    SendEmail: ['program', 'amount', 'promo', 'email', 'message', 'payment', 'review'],
    SendPhysical: ['physical', 'preview', 'promo', 'address', 'message', 'payment', 'review'],
};

class CardOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showMessage: false,
            successMessage: null,
            errors: null,
            isStick: false,
            stripe: null,
            type: props.match.params.type,
            step: props.cards.type === props.match.params.type ? props.cards.step : 0,
            orderStep: ORDER_STEP_LIST[props.match.params.type],
        };

        this.contextRef = createRef();

        this.ORDER_STEP = {
            PROGRAM: 'program',
            PHYSICAL: 'physical',
            PREVIEW: 'preview',
            AMOUNT: 'amount',
            PROMO: 'promo',
            EMAIL: 'email',
            PHONE: 'phone',
            ADDRESS: 'address',
            MESSAGE: 'message',
            PAYMENT: 'payment',
            REVIEW: 'review',
        };
    }

    componentDidMount() {
        this.handleInit();
        this.scrollStep()
    }

    showError(err) {
        this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
    }

    scrollStep() {
        if(window.document.getElementById('orderItem')){
            console.log("TEST")
            window.document.getElementById('orderItem').scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
        }
    }

    handleStick = (event, data) => {
        if (this.state.isStick) return;
        this.setState({ isStick: true })
    }

    handleUnstick = (event, data) => {
        if (!this.state.isStick) return;
        this.setState({ isStick: false })
    }

    handleNextStep = () => {
        this.scrollStep()
        const { step } = this.state;
        this.setState({ ...this.state, loading: false, showMessage: false, errors: null, successMessage: null, step: step + 1 });
    }

    handleGoBack = () => {
        this.scrollStep()
        const { step } = this.state;
        if (step > 0) {
            this.setState({ ...this.state, loading: false, showMessage: false, errors: null, successMessage: null, step: step - 1 });
        } else {
            this.props.history.goBack();
        }
    }


    handleInit = async () => {
        const { type, step } = this.state;
        try {
            sessionStorage.removeItem('custom_amount');
            sessionStorage.removeItem('custom_amount_value');
            if (type === 'Reload' && step === 0) {
                const {
                    my_card: { card },
                } = this.props.cards;
                const data = {
                    param: {
                        type,
                    },
                    program: card.program,
                    step: 0,
                };
                await this.props.startCardOrder(data, type);
            } else if ((type === 'SendText' || type === 'SendEmail')) {
                if (step === 1) {
                    await this.handleProgramSelect(this.props.cards.programs[0]);
                }
            } else if (type === 'SendPhysical' && step === 0) {
            } else if (step === 0) {
                this.setState({ loading: true });
                await this.props.fetchMyCardPrograms(type);
                if (this.props.cards.programs.length === 1) {
                    await this.handleProgramSelect(this.props.cards.programs[0]);
                }
                this.setState({ loading: false });
            }
            if (window.Stripe) {
                this.setState({ stripe: window.Stripe(Config.api_key) });
            } else if(document){
                const documentStripeJs = document.querySelector('#stripe-js')
                if(documentStripeJs)
                    documentStripeJs.addEventListener('load', () => {
                        // Create Stripe instance once Stripe.js loads
                        this.setState({
                            stripe: window.Stripe(Config.api_key),
                        });
                    });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handleInit', JSON.stringify(err));
        }
    }

    handleProgramSelect = async (program) => {
        const { type, step } = this.state;
        const data = {
            param: {
                type,
            },
            program,
            step: step > 0 ? step : step + 1,
        };
        try {
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.startCardOrder(data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else {
                this.setState({ ...this.state, loading: false, step: step > 0 ? step : step + 1 });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handleProgramSelect', JSON.stringify(err));
        }
    };

    handleAmountSelect = async (price) => {
        const { my_card, order, program } = this.props.cards;
        const { type, step } = this.state;
        const data = {
            param: {
                items: type === 'Reload' ? [
                    {
                        price,
                        quantity: 1,
                        card_id: my_card.card.id,
                        item: 'giftcard',
                    },
                ] : [
                    {
                        price,
                        quantity: 1,
                        program_id: program.external_id,
                        item: 'giftcard',
                    },
                ],
            },
            step: step + 1,
        };
        try {
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.updateCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false, step: step + 1 });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handleAmountSelect', JSON.stringify(err));
        }
    };

    handlePhysicalCard = async (quantity, price) => {
        const { cards: { order }, locations: { configurations } } = this.props;
        const { type, step, errors } = this.state;
        try {
            if (errors) {
                order.items.pop();
            }
            const items = order.items || [];
            items.push({
                    price,
                    quantity,
                    program_id: configurations.physical_gift_card.products[0].program_id,
                    item: 'giftcard',
            });
            const data = {
                param: {
                    items,
                },
                step: step + 1,
            };
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.updateCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false, step: step + 1 });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handlePhysicalCard', JSON.stringify(err));
        }
    };

    updatePhysicalCard = async (items) => {
        const { cards: { order } } = this.props;
        const { type, step } = this.state;
        const data = {
            param: {
                items
            },
            step,
        };
        try {
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.updateCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false });
            }
        } catch (err) {
            Logger.error('CardOrder', 'updatePhysicalCard', JSON.stringify(err));
        }
    };

    addPhysicalCard = async () => {
        const { step } = this.state;
        try {
            this.scrollStep()
            this.setState({ ...this.state, loading: false, step: step - 1 });
        } catch (err) {
            Logger.error('CardOrder', 'addPhysicalCard', JSON.stringify(err));
        }

    };

    handlePhysicalPreview = async () => {
        const { step } = this.state;
        try {
            this.scrollStep()
            this.setState({ ...this.state, loading: false, step: step + 1 });
        } catch (err) {
            Logger.error('CardOrder', 'handlePhysicalPreview', JSON.stringify(err));
        }
    };

    handlePromoSelect = async (promo_code) => {
        const { order } = this.props.cards;
        const { type, step } = this.state;
        const data = {
            param: {
                promo_code,
            },
            step: step + 1,
        };
        try {
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = promo_code && await this.props.updateCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                const success = {
                    header: 'success',
                    message: err.success_message
                }
                this.setState({ ...this.state, loading: false, showMessage: true, successMessage: success });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handlePromoSelect', JSON.stringify(err));
        }
    };

    handleEmailInfo = async (info) => {
        const { order } = this.props.cards;
        const { type, step } = this.state;
        const data = {
            param: {
                recipient_email: info.email,
                recipient_first_name: info.firstName,
                recipient_last_name: info.lastName,
            },
            step: step + 1,
        };
        try {
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.updateCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false, step: step + 1 });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handleEmailInfo', JSON.stringify(err));
        }
    };

    handlePhoneInfo = async (info) => {
        const { order } = this.props.cards;
        const { type, step } = this.state;
        const data = {
            param: {
                recipient_phone: info.phone,
                recipient_first_name: info.firstName,
                recipient_last_name: info.lastName,
            },
            step: step + 1,
        };
        try {
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.updateCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false, step: step + 1 });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handlePhoneInfo', JSON.stringify(err));
        }
    };

    handleAddressInfo = async (param) => {
        const { order } = this.props.cards;
        const { type, step } = this.state;
        const data = {
            param,
            step: step + 1,
        };
        try {
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.updateCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false, step: step + 1 });
            }
            this.scrollStep()
        } catch (err) {
            Logger.error('CardOrder', 'handleAddressInfo', JSON.stringify(err));
        }
    };

    handlePersonalMessage = async (message) => {
        const { order } = this.props.cards;
        const { type, step } = this.state;
        const data = {
            param: {
                personal_message: message,
            },
            step: step + 1,
        };
        try {
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.updateCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false, step: step + 1 });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handlePersonalMessage', JSON.stringify(err));
        }
    };

    handleOrderSubmit = async (params) => {
        const {
            users: { user },
            login,
            cards: { order },
        } = this.props;
        const { type, step } = this.state;
        try {
            const data = {
                param: login.user.authenticated
                    ? {
                          payment_source: params.source.id,
                          stripe_customer_id: user.stripe_customer_id,
                      }
                    : {
                          payment_token: params.token.id,
                          buyer_first_name: params.firstName,
                          buyer_last_name: params.lastName,
                          buyer_email: params.email,
                      },
                step: step + 1,
            };
            this.scrollStep()
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.submitCardOrder(order.id, data, type);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false, step: step + 1 });
            }
        } catch (err) {
            Logger.error('CardOrder', 'handleOrderSubmit', JSON.stringify(err));
        }
    };

    renderContentPurchase = (type) => {
        const { step, orderStep, stripe } = this.state;

        return (
            <StripeProvider stripe={stripe}>
                <Elements>
                    <Form>
                        {orderStep[step] === this.ORDER_STEP.PROGRAM ? (
                            <ProgramList
                                title='Purchase a new gift card'
                                description='Please select which gift card you would like to purchase'
                                programs={this.props.cards.programs}
                                handleGoBack={() => this.handleGoBack()}
                                handleProgramSelect={(program) => this.handleProgramSelect(program)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.AMOUNT ? (
                            <Amount
                                type={type}
                                {...this.props}
                                hint={this.hint}
                                handleGoBack={() => this.handleGoBack()}
                                handleAmountSelect={(price) => this.handleAmountSelect(price)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.PROMO ? (
                            <PromoCode
                                {...this.props}
                                handleNextStep={this.handleNextStep}
                                handleGoBack={() => this.handleGoBack()}
                                handlePromoSelect={(promo) => this.handlePromoSelect(promo)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.PAYMENT ? (
                            <Payment
                                {...this.props}
                                setLoading={(loading) => this.setState({ loading })}
                                showError={(err) => this.showError(err)}
                                handleGoBack={() => this.handleGoBack()}
                                handleOrderSubmit={(data) =>
                                    this.handleOrderSubmit(data)
                                }
                            />
                        ) : orderStep[step] === this.ORDER_STEP.REVIEW ? (
                            <Review {...this.props} />
                        ) : null}
                    </Form>
                </Elements>
            </StripeProvider>
        );
    };

    renderContentSend = (type) => {
        const { step, orderStep, stripe } = this.state;
        return (
            <StripeProvider stripe={stripe}>
                <Elements>
                    <Form>
                        {orderStep[step] === this.ORDER_STEP.PROGRAM ? (
                            <ProgramList
                                title='Send a Gift Card'
                                description='Please select which gift card you would like to send'
                                programs={this.props.cards.programs}
                                handleGoBack={() => this.handleGoBack()}
                                handleProgramSelect={(program) => this.handleProgramSelect(program)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.AMOUNT ? (
                            <Amount
                                type={type}
                                {...this.props}
                                handleGoBack={() => this.handleGoBack()}
                                handleAmountSelect={(price) => this.handleAmountSelect(price)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.PHYSICAL ? (
                            <PhysicalCard
                                {...this.props}
                                physicalGiftCard={this.props.locations.configurations.physical_gift_card}
                                handleGoBack={() => this.handleGoBack()}
                                handlePhysicalCard={(quantity, price) => this.handlePhysicalCard(quantity, price)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.PREVIEW ? (
                            <PhysicalPreview
                                {...this.props}
                                updatePhysicalCard={(items) => this.updatePhysicalCard(items)}
                                addPhysicalCard={() => this.addPhysicalCard()}
                                handleGoBack={() => this.handleGoBack()}
                                handlePhysicalPreview={() => this.handlePhysicalPreview()}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.PROMO ? (
                            <PromoCode
                                {...this.props}
                                handleNextStep={this.handleNextStep}
                                handleGoBack={() => this.handleGoBack()}
                                handlePromoSelect={(promo) => this.handlePromoSelect(promo)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.EMAIL ? (
                            <EmailInfo
                                {...this.props}
                                handleGoBack={() => this.handleGoBack()}
                                handleEmailInfo={(info) => this.handleEmailInfo(info)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.PHONE ? (
                            <PhoneInfo
                                {...this.props}
                                handleGoBack={() => this.handleGoBack()}
                                handlePhoneInfo={(info) => this.handlePhoneInfo(info)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.ADDRESS ? (
                            <AddressInfo
                                {...this.props}
                                handleGoBack={() => this.handleGoBack()}
                                handleAddressInfo={(info) => this.handleAddressInfo(info)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.MESSAGE ? (
                            <PersonalMessage
                                {...this.props}
                                handleGoBack={() => this.handleGoBack()}
                                handlePersonalMessage={(message) => this.handlePersonalMessage(message)}
                            />
                        ) : orderStep[step] === this.ORDER_STEP.PAYMENT ? (
                            <Payment
                                {...this.props}
                                setLoading={(loading) => this.setState({ loading })}
                                showError={(err) => this.showError(err)}
                                handleGoBack={() => this.handleGoBack()}
                                handleOrderSubmit={(data) =>
                                    this.handleOrderSubmit(data)
                                }
                            />
                        ) : orderStep[step] === this.ORDER_STEP.REVIEW ? (
                            <Review {...this.props} />
                        ) : null}
                    </Form>
                </Elements>
            </StripeProvider>
        );
    };

    renderProgramLinks = () => {
        const { settings } = this.props.locations.location;
        return (
            <Sticky
                context={this.contextRef}
                styleElement={{ zIndex: 0 }}
                onStick={this.handleStick}
                onUnstick={this.handleUnstick}
                offset={this.state.isStick ? 120 : 0}
            >
                <Segment>
                    <Item.Group>
                        <Item className='align-center'>
                            <Item.Header>Manage or Purchase a Gift Card.</Item.Header>
                        </Item>
                        {settings.cards.digital.card_management.buy_card && (
                            <Item>
                                <Button
                                    as={Link}
                                    to={'/mycards/Purchase/0'}
                                    name={'/mycards/Purchase/0'}
                                    fluid
                                    circular
                                    style={getConfiguration().button_color}
                                >
                                    PURCHASE A NEW CARD
                                </Button>
                            </Item>
                        )}
                        {settings.cards.digital.card_management.import_card && (
                            <Item>
                                <Button
                                    as={Link}
                                    to={'/mycards/import'}
                                    name={'/mycards/import'}
                                    fluid
                                    circular
                                    style={getConfiguration().button_color}
                                >
                                    ADD AN EXISTING CARD
                                </Button>
                            </Item>
                        )}
                        {settings.cards.physical.check_card_balance && (
                            <Item>
                                <Button
                                    as={Link}
                                    to={'/mycards/check'}
                                    name={'/mycards/check'}
                                    fluid
                                    circular
                                    style={getConfiguration().button_color}
                                >
                                    CHECK A CARD BALANCE
                                </Button>
                            </Item>
                        )}
                    </Item.Group>
                </Segment>
            </Sticky>
        );
    };

    renderOrderLinks = () => {
        const { cards: { order }, login, locations: { configurations } } = this.props;
        const { type, step, orderStep, isStick } = this.state;

        return (
            <Sticky
                context={this.contextRef}
                styleElement={{ zIndex: 0 }}
                onStick={this.handleStick}
                onUnstick={this.handleUnstick}
                offset={isStick ? 120 : 0}
            >
                <Segment>
                    {order ? (
                        <Item.Group>
                            <Item className='align-center'>
                                {((type === 'Purchase' || type === 'Reload') && orderStep[step] === this.ORDER_STEP.REVIEW) ? (
                                    <Item.Header style={getConfiguration().label_color}>
                                        {order.items[0].cards[0].display_name}
                                    </Item.Header>
                                ) : (
                                    <Item.Header />
                                )}
                            </Item>
                            <Item>
                                <Item.Content>
                                    <div style={{ float: 'right' }}>${order.totals.subtotal}</div>
                                    <div>Subtotal</div>
                                </Item.Content>
                            </Item>
                            {(orderStep[step] !== this.ORDER_STEP.AMOUNT && orderStep[step] !== this.ORDER_STEP.PHYSICAL) && (
                                <Item>
                                    <Item.Content>
                                        <div style={{ float: 'right' }}>
                                            -${order.totals.discount}
                                        </div>
                                        <div>Discount</div>
                                    </Item.Content>
                                </Item>
                            )}
                            {type === 'SendPhysical' && (
                                <Item>
                                    <Item.Content>
                                        <div style={{ float: 'right' }}>
                                            ${configurations.physical_gift_card.shipping_handling}
                                        </div>
                                        <div>Shipping & Handling</div>
                                    </Item.Content>
                                </Item>
                            )}
                            {orderStep[step] === this.ORDER_STEP.REVIEW && (
                                <>
                                    <Item>
                                        <Item.Content style={Style.amountItem}>
                                            <div style={{ float: 'right' }}>
                                                {order.totals.total}
                                            </div>
                                            <div>Total Amount</div>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <div style={{ float: 'right' }}>
                                                {toFormatCurrencyWithCent(order.payments[0].payment_amount)}
                                            </div>
                                            <div style={getConfiguration().label_color}>
                                                {order.payments[0].display_name}
                                            </div>
                                        </Item.Content>
                                    </Item>
                                </>
                            )}
                            <Item
                                style={
                                    orderStep[step] === this.ORDER_STEP.REVIEW
                                        ? {}
                                        : { marginBottom: -14 }
                                }
                            >
                                <Item.Content style={Style.amountItem}>
                                    <div style={{ float: 'right' }}>
                                        {order.totals.due}
                                    </div>
                                    <div>Amount Due</div>
                                </Item.Content>
                            </Item>
                            {orderStep[step] === this.ORDER_STEP.REVIEW && (
                                <Item>
                                    {login.user.authenticated ?
                                        <Button
                                            as={Link}
                                            to={'/mycards'}
                                            name={'/mycards'}
                                            fluid
                                            circular
                                            onClick={() => this.props.clearCardOrder()}
                                            style={getConfiguration().button_color}
                                        >
                                            VIEW MY GIFT CARDS
                                        </Button>
                                    :
                                        <Button
                                            as={Link}
                                            to={'/'}
                                            name={'/'}
                                            fluid
                                            circular
                                            onClick={() => this.props.clearCardOrder()}
                                            style={getConfiguration().button_color}
                                        >
                                            GO TO HOME
                                        </Button>
                                    }
                                </Item>
                            )}
                        </Item.Group>
                    ) : (
                        <Item.Group>
                            <Item>
                                <Placeholder>
                                    <Placeholder.Line />
                                </Placeholder>
                            </Item>
                            <Item>
                                <Placeholder>
                                    <Placeholder.Line />
                                </Placeholder>
                            </Item>
                            <Item>
                                <Placeholder>
                                    <Placeholder.Line />
                                </Placeholder>
                            </Item>
                        </Item.Group>
                    )}
                </Segment>
            </Sticky>
        );
    };

    render() {
        const { type, step, errors, showMessage, successMessage } = this.state;
        const { color } = getConfiguration().label_color;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <Ref innerRef={this.contextRef}>
                            <Grid padded>
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: [`.ui.form .field>label { color: ${color};}`].join('\n'),
                                    }}
                                />
                                <Grid.Column mobile={16} computer={11}>
                                    <Segment>
                                        {successMessage && showMessage ? (
                                            <Message
                                                icon
                                                success
                                                visible={showMessage}
                                                header={successMessage.header.toUpperCase()}
                                                content={successMessage.message}
                                            />
                                        ) : errors && showMessage ? (
                                            <Message
                                                icon
                                                error
                                                visible={showMessage}
                                                header={'Error'}
                                                content={errors.message}
                                            />
                                        ) : null}
                                        <Dimmer active={this.state.loading} inverted>
                                            <Loader size='small' inline='centered'>
                                                Loading
                                            </Loader>
                                        </Dimmer>
                                        {(type === 'Purchase' || type === 'Reload') && this.renderContentPurchase(type)}
                                        {(type === 'SendEmail' || type === 'SendText' || type === 'SendPhysical') && this.renderContentSend(type)}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column mobile={16} computer={5}>
                                    {(type !== 'SendPhysical' && step === 0) ? this.renderProgramLinks() : this.renderOrderLinks()}
                                </Grid.Column>
                            </Grid>
                        </Ref>
                    );
                }}
            </CacheBuster>
        );
    }
}

const Style = {
    amountItem: {
        backgroundColor: '#d3d3d3cc',
        padding: '6px 14px',
        margin: '0 -14px',
        fontWeight: 'bold',
    },
};

export default connect(
    (state) => {
        const { users, login, cards, locations } = state;
        return { users, login, cards, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...cardStore.actionCreators,
                ...locationStore.actionCreators,
            },
            dispatch
        )
)(withRouter(CardOrder));
