import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Card, Dimmer, Grid, Loader, Placeholder,} from 'semantic-ui-react';
import querystring from 'query-string';

import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as loginStore from '../../store/Login';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import * as cartStore from '../../store/Cart';

import {getConfiguration} from '../../store/api/apiClient';

class ReceiptView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            ...querystring.parse(props.location.search),
        };
    }

    componentWillMount() {
        this.props.locationConfiguration();
        this.props.getReceiptInformation(this.state.type, this.state.object_id);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: false})
    }


    renderContent() {
        const receipt = this.props.ticket.receipt
        let {backgroundColor, ...rest} = getConfiguration().button_color;
        return receipt && <>
            <Card fluid>
                <Card.Content>
                    <Grid centered textAlign='center'>
                        <Grid.Column textAlign='center'>
                            <span style={{fontWeight: 600}}>
                                {receipt.success_message.message}
                            </span>
                        </Grid.Column>
                    </Grid>

                </Card.Content>
            </Card>
            <Card fluid>
                <Card.Content style={{padding: 50}}>
                    {this.state.loading ?
                        <Placeholder>
                            <Placeholder.Image rectangular/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder> :
                        <Grid.Column textAlign='left' width={16}>
                            <table style={{width: "100%"}}>
                                <tr style={{textAlign: "center"}}>
                                    <td colSpan={2} style={{textAlign: "center"}}>
                                        <img src={receipt.receipt.location_image_url} width={100}/>
                                        <p>
                                            {receipt.receipt.location_name}
                                        </p>
                                        <p>{receipt.receipt.location_address}&nbsp;{receipt.receipt.location_address2}</p>
                                        <p>{receipt.receipt.location_city}, {receipt.receipt.location_state}&nbsp; {receipt.receipt.location_zip}</p>
                                        <p>{receipt.receipt.location_phone}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <br/>
                                        <br/>
                                    </td>
                                </tr>
                                <tr style={{textAlign: "center"}}>
                                    <td colSpan={2} style={{textAlign: "center"}}>
                                        <p style={{fontWeight: "bold"}}>{receipt.receipt.message}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <br/>
                                        <br/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        Server: {receipt.receipt.server_name}
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        {receipt.receipt.created_date}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        Check: {receipt.receipt.check_number}
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        {receipt.receipt.check_name}
                                    </td>
                                </tr>
                                {receipt.receipt.check_link &&
                                    <tr>
                                        <td style={{textAlign: "left"}}>
                                            <a href={receipt.receipt.check_link}>View Order</a>
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td colSpan={2}>
                                        <hr style={{borderTop: "1px solid black"}}/>
                                    </td>
                                </tr>
                                {
                                    receipt.receipt.items.map((item, index) => {
                                        const itemDesciption = item.type === 'item' ? item.name + " " + item.quantity : item.name + " " + item.price
                                        const itemPrice = item.type === 'item' ? item.price : " ";
                                        return <tr key={index}>
                                            <td style={{textAlign: "left"}}>
                                                {itemDesciption}
                                            </td>
                                            <td style={{textAlign: "right"}}>
                                                {itemPrice}
                                            </td>
                                        </tr>
                                    })
                                }

                                <tr>
                                    <td colSpan={2}>
                                        <br/>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        Subtotal
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        {receipt.receipt.sub_total}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        Discounts
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        {receipt.receipt.discounts}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        Shipping & Handling
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        {receipt.receipt.shipping_handling}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        Tax
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        {receipt.receipt.tax}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        Tips
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        {receipt.receipt.tips}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        Total
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        {receipt.receipt.total}
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={2}>
                                        <br/>
                                    </td>
                                </tr>
                                {
                                    receipt.receipt.payments.map((payment, index) => {
                                        return <tr key={index}>
                                            <td style={{textAlign: "left"}}>
                                                {payment.payment_type}
                                            </td>
                                            <td style={{textAlign: "right"}}>
                                                {payment.payment_amount}
                                            </td>
                                        </tr>
                                    })
                                }

                                <tr>
                                    <td colSpan={2}>
                                        <br/>
                                    </td>
                                </tr>
                                <tr style={{textAlign: "center"}}>
                                    <td colSpan={2} style={{textAlign: "center"}}>
                                        {receipt.receipt.full_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <hr style={{borderTop: "1px solid black"}}/>
                                    </td>
                                </tr>

                                <tr style={{textAlign: "center"}}>
                                    <td colSpan={2} style={{textAlign: "center", height:50}}>
                                        {receipt.receipt.support_footer}
                                    </td>
                                </tr>
                                <tr style={{textAlign: "center"}}>
                                    <td colSpan={2} style={{textAlign: "center", height:50}}>
                                        {receipt.receipt.location_promotion_text}
                                    </td>
                                </tr>
                                <tr style={{textAlign: "center"}}>
                                    <td colSpan={2} style={{textAlign: "center", height:50}}>
                                        {receipt.receipt.location_url}
                                    </td>
                                </tr>
                                <tr style={{textAlign: "center"}}>
                                    <td colSpan={2} style={{textAlign: "center"}}>
                                        <img  height={150} src={'https://assets.crave.it/images/crave_it_powered_by_small_300_v2.png'} />
                                    </td>
                                </tr>
                            </table>
                        </Grid.Column>
                    }
                </Card.Content>
            </Card>
        </>
    }

    render() {
        let {color} = getConfiguration().label_color;
        let {backgroundColor} = getConfiguration().button_color;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: [
                                        '.ui.positive.message {\n' +
                                        '    background-color: #fcfff5 !important;\n' +
                                        '    color: inherit;\n' +
                                        '    box-shadow: 0 0 0 1px ' +
                                        color +
                                        ' inset, 0 0 0 0 transparent !important;\n' +
                                        '    text-align: center\n' +
                                        '}\n' +
                                        '.ui.positive.message .header {\n' +
                                        '    color: ' +
                                        color +
                                        ' !important;\n' +
                                        '}\n' +
                                        '.checkout.ui.segment {\n' +
                                        '   padding:0;\n' +
                                        '}' +
                                        '.checkout i.icon.close:before {\n' +
                                        '   color: ' +
                                        color +
                                        '\n' +
                                        '}' +
                                        '.ui.selection .dropdown {\n' +
                                        '    background: lightgrey !important;\n' +
                                        '    right: .75em !important;\n' +
                                        '}',
                                    ].join('\n'),
                                }}
                            />
                            {this.state.loading ? (
                                <Dimmer active inverted>
                                    <Loader size='small' inline='centered'>
                                        Loading
                                    </Loader>
                                </Dimmer>
                            ) : null}
                            <Grid reversed='computer'>
                                <Grid.Column mobile={16} computer={16}>
                                    {this.renderContent()}
                                </Grid.Column>
                            </Grid>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

const Style = {
    amountItem: {
        backgroundColor: '#d3d3d3cc',
        padding: '6px 14px',
        margin: '0 -14px',
        fontWeight: 'bold',
    },
};

export default connect(
    (state) => {
        const {users, login, cart, ticket, locations} = state;
        return {users, cart, auth: {...login}, ticket, locations};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...loginStore.actionCreators,
                ...cartStore.actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators,
            },
            dispatch
        )
)(ReceiptView);
