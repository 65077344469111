import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Button, Form, Grid, Input, Item } from 'semantic-ui-react';

import { Media } from '../../../AppMedia';
import ProgramCard from '../../common/ui/components/ProgramCard';
import { getConfiguration } from '../../../store/api/apiClient';

export default (props) => {
    const { physicalGiftCard, handlePhysicalCard, handleGoBack } = props;
    const [quantity, setQuantity] = useState(1);
    const [amount, setAmount] = useState({ price: 0, isCustom: false });
    const program = {
        image: {
            imageSignedUrl: physicalGiftCard.products[0].image,
        },
    };

    return (
        <Item.Group divided>
            <Item>
                <Item.Content>
                    <Item.Header style={{ width: '100%', textAlign: 'center' }}>
                        Select Quantity & Amount
                    </Item.Header>
                    <Item.Description>
                        <Grid centered>
                            <Grid.Column mobile={16} computer={7}
                                         style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <ProgramCard program={program} canvasName={"mycanvas"}/>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={8}>
                                <Grid columns={3}>
                                    <Grid.Column mobile={16} computer={16}>
                                        <Form.Field width={16}>
                                            <NumberFormat
                                                value={quantity || ''}
                                                placeholder='Enter Quantity'
                                                inputMode='decimal'
                                                isAllowed={(values) => {
                                                    const { formattedValue, floatValue } = values;
                                                    return formattedValue === '' || floatValue <= 10000;
                                                }}
                                                pattern='[0-9]*'
                                                customInput={Input}
                                                style={{ width: '100%' }}
                                                onInvalid={(e) => e.preventDefault()}
                                                onValueChange={(values) =>
                                                    setQuantity(values.floatValue)
                                                }
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    {[25, 50, 75, 100, 125, 150].map((price, index) => {
                                        return (
                                            <Grid.Column key={`price_${index}`}>
                                                <Button
                                                    className={'tip_button'}
                                                    fluid
                                                    style={
                                                        amount.isCustom || amount.price !== price
                                                            ? { display: 'block' }
                                                            : {
                                                                display: 'block',
                                                                ...getConfiguration()
                                                                    .button_color,
                                                            }
                                                    }
                                                    onClick={() =>
                                                        setAmount({ price, isCustom: false })
                                                    }
                                                >
                                                    ${price}
                                                </Button>
                                            </Grid.Column>
                                        );
                                    })}
                                    <Grid.Column mobile={16} computer={amount.isCustom ? 8 : 16}>
                                        <Button
                                            className={'tip_button'}
                                            fluid
                                            onClick={() => setAmount({ price: 0, isCustom: true })}
                                        >
                                            Other Amount
                                        </Button>
                                    </Grid.Column>
                                    {amount.isCustom && (
                                        <Grid.Column mobile={16} computer={amount.isCustom ? 8 : 0}>
                                            <Form.Field width={16}>
                                                <NumberFormat
                                                    value={amount.price || ''}
                                                    placeholder='Enter Amount'
                                                    inputMode='decimal'
                                                    isAllowed={(values) => {
                                                        const { formattedValue, floatValue } = values;
                                                        return formattedValue === '' || floatValue <= 10000;
                                                    }}
                                                    pattern='[0-9],*'
                                                    customInput={Input}
                                                    style={{ width: '100%' }}
                                                    onInvalid={(e) => e.preventDefault()}
                                                    onValueChange={(values) =>
                                                        setAmount({price: Math.abs(values.floatValue).toFixed(2), isCustom: true})
                                                    }
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    )}
                                </Grid>
                            </Grid.Column>
                        </Grid>
                    </Item.Description>
                    <br/>
                    <Item.Extra>
                        <Media at='mobile'>
                            <Form.Button
                                fluid
                                circular
                                style={getConfiguration().button_color}
                                disabled={!quantity || quantity === 0 || !amount.price || amount.price === 0}
                                onClick={() => handlePhysicalCard(quantity, amount.price)}
                            >
                                CONTINUE
                            </Form.Button>
                            <Form.Button
                                fluid
                                circular
                                onClick={() => handleGoBack()}
                            >
                                BACK
                            </Form.Button>
                        </Media>
                        <Media greaterThanOrEqual='tablet'>
                            <Grid centered>
                                <Grid.Column width={5}>
                                    <Button
                                        fluid
                                        circular
                                        onClick={() => handleGoBack()}
                                    >
                                        BACK
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Button
                                        fluid
                                        circular
                                        style={getConfiguration().button_color}
                                        disabled={!quantity || quantity === 0 || !amount.price || amount.price === 0}
                                        onClick={() => handlePhysicalCard(quantity, amount.price)}
                                    >
                                        CONTINUE
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </Media>
                    </Item.Extra>
                </Item.Content>
            </Item>
            <Item>
                <Item.Content>
                    <Item.Description>
                        <div dangerouslySetInnerHTML={{__html: physicalGiftCard.products[0].description}}></div>
                    </Item.Description>
                </Item.Content>
            </Item>
        </Item.Group>
    );
};
