import React, {useRef} from "react";
import {Button, Icon} from "semantic-ui-react";
import ReactToPrint from "react-to-print";
import NonPOSMenuComponentToPrint from './NonPOSMenuComponentToPrint'
import 'semantic-ui-css/semantic.min.css';

export default function PrintComponent(props) {
    let componentRef = useRef();

    return (
        <div>
            <ReactToPrint
                copyStyles={true}
                trigger={() => <Button style={{...props.color, position: 'absolute', right: 20, top: 20, zIndex: 1}}
                                       icon>
                    <Icon name='print'/> PRINT
                </Button>}
                content={() => componentRef}
            />
            {props.content}
            <div style={{visibility: 'hidden', top: -10000, position: "absolute"}}>
                <style dangerouslySetInnerHTML={{
                    __html: [
                        '\n' +
                        '.james.ui.cards > .card,\n' +
                        '.james.ui.card {\n' +
                        '  width: 100%;\n' +
                        '  max-width: 100%;\n' +
                        '  position: relative;\n' +
                        '  display: -webkit-box;\n' +
                        '  display: -ms-flexbox;\n' +
                        '  display: flex;\n' +
                        '  -webkit-box-orient: vertical;\n' +
                        '  -webkit-box-direction: normal;\n' +
                        '  -ms-flex-direction: column;\n' +
                        '  flex-direction: column;\n' +
                        '  min-height: 0px;\n' +
                        '  padding: 0em !important;\n' +
                        '  border: none !important;\n' +
                        '  border-radius: 0.28571429rem !important;\n' +
                        '  -webkit-box-shadow: 0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5 !important;\n' +
                        '  box-shadow: 0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5 !important;\n' +
                        '  -webkit-transition: -webkit-box-shadow 0.1s ease, -webkit-transform 0.1s ease;\n' +
                        '  transition: -webkit-box-shadow 0.1s ease, -webkit-transform 0.1s ease;\n' +
                        '  transition: box-shadow 0.1s ease, transform 0.1s ease;\n' +
                        '  transition: box-shadow 0.1s ease, transform 0.1s ease, -webkit-box-shadow 0.1s ease, -webkit-transform 0.1s ease;\n' +
                        '  z-index: \'\';\n' +
                        '}'
                    ].join('\n'),
                }}
                />
                <NonPOSMenuComponentToPrint ref={(el) => (componentRef = el)}
                                      menus={props.menus}
                                      footer={props.footer} app_icon={props.app_icon}/>
            </div>
        </div>
    );
}
