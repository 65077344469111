import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import {
    Container,
    Button,
    Form,
    Grid,
    Message,
    Divider,
    Image,
    Segment,
    Header,
    Dimmer,
    Loader
} from 'semantic-ui-react';

import { Media } from '../../AppMedia';
import CacheBuster from '../../CacheBuster'
import {actionCreators} from '../../store/Login';
import * as userStore from '../../store/Users';
import * as locationStore from '../../store/Location';
import * as cardStore from '../../store/Card';
import {getConfiguration} from '../../store/api/apiClient';
import { Logger } from '../../utils/Logger';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: null,
            locked: false,
            loading: false
        };
    }

    componentWillMount() {
        try {
            this.props.clearLogin();
            this.props.clearUser();
            this.props.clearCard();
        } catch (err) {
            Logger.error('Login', 'componentWillMount', JSON.stringify(err));
        }
        let pathname = window.location.pathname
        let page = this.props.locations.seo.find((item) => {
            return pathname.indexOf(item.path) != -1
        });
        if (!page) {
            page = this.props.locations.seo.find((item) => item.path === "/home");
        }
        document.title = page.title
        document.description = page.description
    }

    componentDidMount() {
        let { from } = this.props.location.state || { from: null };
        if (from) {
            sessionStorage.setItem('previouspath', from.pathname);
        }

    }

    async handleSubmit() {
        try {
            this.setState({errorMessage: null, loading: true});
            let response = await this.props.login(this.state);
            let previousPath = sessionStorage.getItem('previouspath');
            if (!response) {
                await this.props.getUser(this.state);
                let { from } = this.props.location.state || { from: null };
                sessionStorage.setItem('previouspath', '/')
                this.props.history.replace( from ||
                    (previousPath && previousPath !== '/login' ? previousPath : '/')
                );
            } else {
                this.handleErrors(response);
            }
        } catch (err) {
            Logger.error('Login', 'handleSubmit', JSON.stringify(err));
        }
    }

    handleErrors(response) {
        let errors = response.data.errors;
        let errorMessage = Array.isArray(errors) ? (
            errors.map((error, index) => {
                return <p key={index}>{error.message}</p>;
            })
        ) : (
            <p>{response.data.message}</p>
        );
        this.setState({
            errorMessage,
            loading: false,
            locked: response.data.message.indexOf('locked') > -1,
        });
    }

    handleChange(field, e) {
        let state = this.state;
        state[field] = e.target.value;
        state.errorMessage = null;
        this.setState(state);
    }

    handleUnlock() {
        this.props.history.push('/resendunlock');
    }

    responseFacebook = async (responseFacebook) => {
        try {
            if (responseFacebook.accessToken) {
                let response = await this.props.loginFacebook(responseFacebook);
                this.setState({errorMessage: null, loading: true});
                let previousPath = sessionStorage.getItem('previouspath');
                if (!response) {
                    let { from } = this.props.location.state || { from: null };
                    sessionStorage.setItem('previouspath', '/')
                    this.props.history.replace( from ||
                        (previousPath && previousPath !== '/login' ? previousPath : '/')
                    );
                } else {
                    this.handleErrors(response);
                }
            }
        } catch (err) {
            Logger.error('Login', 'responseFacebook', JSON.stringify(err));
        }
    };

    responseGoogle = async (responseGoogle) => {
        try {
            if (responseGoogle.accessToken) {
                let response = await this.props.loginGoogle(responseGoogle);
                this.setState({errorMessage: null, loading: true});
                let previousPath = sessionStorage.getItem('previouspath');
                if (!response) {
                    let { from } = this.props.location.state || { from: null };
                    sessionStorage.setItem('previouspath', '/')
                    this.props.history.replace( from ||
                        (previousPath && previousPath !== '/login' ? previousPath : '/')
                    );
                } else {
                    this.handleErrors(response);
                }
            }
        } catch (err) {
            Logger.error('Login', 'responseGoogle', JSON.stringify(err));
        }
    };

    render() {
        const googleId = this.props.locations.configurations
            ? this.props.locations.configurations.google_id.web
            : null;
        const facebookId = this.props.locations.configurations
            ? this.props.locations.configurations.facebook_id
            : null;
        const appIcon = this.props.locations.configurations
            ? this.props.locations.configurations.app_icon
            : null;
            let locationBackgroundUrl = this.props.locations.configurations.location_background_url
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <Container
                            className='bg-image'
                            fluid
                        >
                            <style
                                type="text/css"
                                dangerouslySetInnerHTML={{
                                    __html: [
                                        '\n',
                                        `body:after {` +
                                        `  background-image: url(${ locationBackgroundUrl ? locationBackgroundUrl : getConfiguration().backgroundImage});` +
                                        `}`,
                                    ].join('\n'),
                                }}
                            ></style>
                            <Grid
                                className='bg-text'
                                textAlign='center'
                                style={{zIndex: 2, margin: 0}}
                                verticalAlign='middle'
                            >
                                <Grid.Column style={{maxWidth: 450}}>
                                    <Media at='mobile'>
                                        <Image src={appIcon} style={{maxHeight: 150, margin: 'auto'}} onClick={() => this.props.history.push('/')}/>
                                    </Media>
                                    <Media greaterThanOrEqual='tablet'>
                                        <Image src={appIcon} style={{maxHeight: 300, margin: 'auto'}} onClick={() => this.props.history.push('/')}/>
                                    </Media>
                                    <Segment
                                        raised
                                        style={{
                                            background: '#ffffffe6',
                                            border: '1px solid darkgray',
                                        }}
                                    >
                                        <style dangerouslySetInnerHTML={{
                                            __html: [
                                                '.ui.dimmer>.content {\n' +
                                                '    top: 45% !important;\n' +
                                                '}'
                                            ].join('\n')
                                        }}>
                                        </style>


                                        {this.state.loading ? <Dimmer active inverted>
                                            <Loader size='small'>Loading</Loader>
                                        </Dimmer> : null}
                                        <Header
                                            as='h3'
                                            style={getConfiguration().label_color}
                                            textAlign='center'
                                        >
                                            LOGIN
                                        </Header>
                                        {this.state.errorMessage ? (
                                            <Message negative>
                                                <Message.Header>
                                                    There were some errors with your submission
                                                </Message.Header>
                                                {this.state.errorMessage}
                                                {this.state.locked ? (
                                                    <p>
                                                        <a href='/resendunlock'>Click here </a> to resend unlock
                                                        code
                                                    </p>
                                                ) : null}
                                            </Message>
                                        ) : null}
                                        {facebookId ? (
                                            <FacebookLogin
                                                appId={facebookId}
                                                autoLoad={false}
                                                fields='name,email,picture'
                                                callback={this.responseFacebook}
                                                disableMobileRedirect={true}
                                                isMobile={false}
                                                render={(renderProps) => (
                                                    <Button
                                                        onClick={renderProps.onClick}
                                                        circular
                                                        size='huge'
                                                        color='facebook'
                                                        icon='facebook'
                                                    />
                                                )}
                                            />
                                        ) : null}
                                        &nbsp;&nbsp;
                                        {googleId ? (
                                            <GoogleLogin
                                                clientId={googleId || ''}
                                                render={(renderProps) => (
                                                    <Button
                                                        onClick={renderProps.onClick}
                                                        circular
                                                        size='huge'
                                                        color='google plus'
                                                        icon='google plus'
                                                    />
                                                )}
                                                buttonText='Login'
                                                onSuccess={this.responseGoogle}
                                                onFailure={this.responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        ) : null}

                                        <br/>
                                        <br/>
                                        <Form inverted>
                                            <Form.Field>LOGIN WITH SOCIAL</Form.Field>
                                            <Form.Group style={{justifyContent: 'center'}}>
                                            </Form.Group>
                                            <Divider horizontal>Or</Divider>
                                            <Form.Input
                                                label='EMAIL'
                                                fluid
                                                icon='user'
                                                iconPosition='left'
                                                placeholder='E-mail address'
                                                autoFocus
                                                onChange={this.handleChange.bind(this, 'email')}
                                            />
                                            <Form.Input
                                                label='PASSWORD'
                                                fluid
                                                icon='lock'
                                                iconPosition='left'
                                                placeholder='Password'
                                                type='password'
                                                onChange={this.handleChange.bind(this, 'password')}
                                            />
                                            <Form.Field>
                                                <a href='/forgotpassword'>FORGOT PASSWORD?</a>
                                            </Form.Field>
                                            <Form.Button
                                                circular
                                                style={getConfiguration().button_color}
                                                fluid
                                                size='large'
                                                type='submit'
                                                onClick={this.handleSubmit.bind(this)}
                                            >
                                                LOGIN
                                            </Form.Button>
                                            <Media at='mobile'>
                                                <Form.Field>
                                                    <a href='/signup'>CREATE AN ACCOUNT</a>
                                                </Form.Field>
                                                <Form.Field>
                                                    <a href='/'>SKIP</a>
                                                </Form.Field>
                                            </Media>
                                            <Media greaterThanOrEqual='tablet'>
                                                <Form.Field style={{textAlign: 'left', padding: '0 10px'}}>
                                                    <a href='/' style={{float: 'right'}}>
                                                        SKIP
                                                    </a>
                                                    <a href='/signup'>CREATE AN ACCOUNT</a>
                                                </Form.Field>
                                            </Media>
                                        </Form>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Container>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {locations} = state;
        return {locations};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...locationStore.actionCreators,
                ...userStore.actionCreators,
                ...cardStore.actionCreators,
            },
            dispatch
        )
)(withRouter(Login));
