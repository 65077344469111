import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Card, Grid, Header, Icon, Progress,} from 'semantic-ui-react';

import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Users';
import * as ticketStore from '../../store/Ticket';
import {getConfiguration} from '../../store/api/apiClient';
import OpenTable from 'react-opentable'
import 'react-opentable/dist/index.css'
import {Media} from '../../AppMedia';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

class Home extends Component {
    componentDidMount() {
        this.handleInit();
    }

    handleInit = async () => {
        const {auth} = this.props;
        if (auth.user.email && auth.user.authenticated) {
            await this.props.getUser();
        }
        await this.props.getTicketOrder();
    }

    render() {
        const showRewardsHome = this.props.locations.location
            ? this.props.locations.location.settings.home.show_rewards
            : false;
        const showAnnoucementHome = this.props.locations.location
            ? this.props.locations.location.settings.home.show_announcements
            : false;
        // const isMyReward = this.props.locations.location
        //     ? this.props.locations.location.settings.rewards.enable
        //     : false;

        const isTiles = this.props.locations.location.settings.consumer_application_settings.home_page_announcement_layout === 'tiles'
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }

                    let cards = (tiles) => {
                        let cards = []

                        if (this.props.users.user.rewards &&
                            this.props.users.user.rewards.summary &&
                            this.props.users.user.rewards.is_rewards_on &&
                            showRewardsHome)
                            cards.push(
                                <Card fluid
                                      style={{
                                          margin: tiles ? '0px 0px 0px 0px' : '20px 0px 0px 0px',
                                          background: 'rgba(255, 255, 255, 0.9)'
                                      }}>
                                    <Card.Content>
                                        <Header as='h3' textAlign='center'
                                                style={getConfiguration().label_color}>
                                            MY REWARDS
                                        </Header>
                                        <Header as='h2' textAlign='left'>
                                            <span
                                                style={{
                                                    ...getConfiguration().label_color,
                                                    fontWeight: 'bolder',
                                                    fontSize: 35,
                                                }}
                                            >
                                            {this.props.users.user.rewards.summary.current_points}{' '}
                                                /
                                            </span>
                                            <span style={getConfiguration().label_color}>
                                                {' '}
                                                &nbsp;
                                                {
                                                    this.props.users.user.rewards.summary
                                                        .points_to_next_reward
                                                }
                                            </span>
                                        </Header>
                                        <Progress
                                            percent={
                                                (this.props.users.user.rewards.summary
                                                        .current_points /
                                                    this.props.users.user.rewards.summary
                                                        .points_to_next_reward) *
                                                100
                                            }
                                            className='custom-color-progress'
                                        />
                                        <Header textAlign='left' as='h6' color='black'>
                                            {this.props.users.user.rewards.summary.reward_message !== undefined ? this.props.users.user.rewards.summary.reward_message : ""}
                                        </Header>
                                        <Link to={'/rewards'} name={'/rewards'}>
                                            <Header as='h5' color='black'>
                                                Manage My Rewards <Icon name='share'/>
                                            </Header>
                                        </Link>
                                    </Card.Content>
                                </Card>
                            )

                        if (this.props.locations.announcements && showAnnoucementHome){
                            const announcements = this.props.locations.announcements.filter(announcement => announcement.data.opentable_id == null && announcement.type !== "advertisement")
                            announcements.map(
                                (announcement, index) => {
                                    let isOpenTable = announcement.data.opentable_id != null
                                    cards.push(
                                        <Card fluid
                                              style={{
                                                  margin: tiles ? '0px 0px 0px 0px' : '20px 0px 0px 0px',
                                                  background: 'rgba(255, 255, 255, 0.9)'
                                              }}
                                              key={`ann-${index}`}>
                                            <Card.Content>
                                                <Header
                                                    as='h3'
                                                    textAlign='center'
                                                    style={getConfiguration().label_color}
                                                >
                                                    {announcement.title.toUpperCase()}
                                                </Header>
                                                <Grid textAlign='left'>
                                                    <Grid.Column className='announcement'>
                                                        {isOpenTable ?
                                                            <OpenTable
                                                                rid={announcement.data.opentable_id}
                                                                customClassName={"open_table"}/> :
                                                            announcement.action_is_external ?
                                                                <a target="_blank" href={announcement.action}>
                                                                    <div
                                                                        style={{color: 'black'}}
                                                                        className="announcement"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: announcement.data.body,
                                                                        }}
                                                                    >
                                                                    </div>
                                                                </a> :
                                                                announcement.action ? (
                                                                    <Link to={announcement.action}>
                                                                        <div
                                                                            style={{color: 'black'}}
                                                                            className="announcement"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: announcement.data.body,
                                                                            }}
                                                                        >
                                                                        </div>
                                                                    </Link>
                                                                ) : (
                                                                    <div
                                                                        style={{color: 'black'}}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: announcement.data.body,
                                                                        }}
                                                                    ></div>
                                                                )}
                                                    </Grid.Column>
                                                </Grid>
                                            </Card.Content>
                                        </Card>
                                    );
                                }
                            )
                        }

                        return cards
                    }


                    let cardGroup = (tiles) => {
                        return tiles ?
                            <div style={{maxWidth: "80%", margin: '10px auto'}}>
                                <ResponsiveMasonry
                                    columnsCountBreakPoints={{350: 1, 1000: 2, 1500: 3}}
                                >
                                    <Masonry gutter={"20px"}>
                                        {cards(tiles)}
                                    </Masonry>
                                </ResponsiveMasonry>
                            </div>
                            :
                            <Card.Group itemsPerRow={null}
                                        style={{maxWidth: 700, margin: '50px auto'}}>
                                {cards(tiles)}
                            </Card.Group>
                    }

                    return <>
                    <style dangerouslySetInnerHTML={{
                        __html: [
                            '.ui.progress.custom-color-progress .bar {\n' +
                            '    background-image: ' +
                            getConfiguration().progress_bar_gradient
                                .backgroundImage +
                            ';\n' +
                            '    min-width: 0px' +
                            '}' +
                            '.ui.progress[data-percent="0"] .bar {\n' +
                            '  background:transparent;\n' +
                            '}\n' +
                            '.ui.progress[data-percent="0"] .bar .progress {\n' +
                            '  color: rgba(0, 0, 0, 0.87);\n' +
                            '}\n' +
                            '.ui.inverted.progress[data-percent="0"] .bar .progress {\n' +
                            '  color: rgba(255, 255, 255, 0.9);\n' +
                            '}\n' +
                            '/* simplified to one setting here, the PR respects possible different colors for each data-percent value */\n' +
                            '.ui.inverted.indicating.progress .label {\n' +
                            '  color: rgba(255, 255, 255, 0.9);\n' +
                            '}',
                            'div.announcement img {\n' +
                            '    width:100% !important;' +
                            '   height: auto !important ' +
                            '}' +
                            '.open_table {' +
                            '   position:absolute;\n' +
                            '   margin:5px auto;\n' +
                            '}'
                        ].join('\n'),
                    }}
                    />
                    <Media at='mobile'>
                        {cardGroup(false)}
                    </Media>
                    <Media greaterThanOrEqual='tablet'>
                        {cardGroup(isTiles)}
                    </Media>
                    </>
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {login: auth, users, locations} = state;
        return {auth, users, locations};
    },
    (dispatch) => bindActionCreators({
        ...actionCreators,
        ...ticketStore.actionCreators
    }, dispatch)
)(Home);
