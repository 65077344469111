import React, {Component, createRef} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Dimmer, Divider, Grid, Header, Item, Loader, Message, Segment,} from 'semantic-ui-react';

import CardImport from './CardImport';
import CacheBuster from '../../../CacheBuster';
import {actionCreators} from '../../../store/Users';
import * as locationStore from '../../../store/Location';
import * as cardStore from '../../../store/Card';
import {getConfiguration} from '../../../store/api/apiClient';
import {Logger} from '../../../utils/Logger';

class CardOrderImport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showMessage: false,
            successMessage: null,
            intervalId: setInterval(this.scrollStep.bind(this), 16),
            errors: null,
        };

        this.contextRef = createRef();
    }

    showError(err) {
        this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
    }

    scrollStep() {
        if (window.pageYOffset <= 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    handleCardImport = async (cardNumber, isClaim) => {
        const data = isClaim ? {
            claim_code: cardNumber,
        } : {
            card_number: cardNumber,
        };
        try {
            this.setState({ ...this.state, errors: null, successMessage: null, loading: true });
            const err = await this.props.importCard(data);
            if (err && err.errors !== undefined) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.message } });
            } else if (err.has_error) {
                this.setState({ loading: false, showMessage: true, errors: { message: err.error } });
            } else {
                this.setState({ ...this.state, loading: false, showMessage: true, successMessage: err.success_message });
                this.props.history.push('/mycards/'+err.card.id+'/import')
            }

        } catch (err) {
            Logger.error('CardOrderImport', 'handleCardImport', JSON.stringify(err));
        }
    };

    renderContent = () => {
        return (
            <>
                <Header textAlign='center' style={{ margin: '5px 0 16px 0' }}>
                    Add An Existing Gift Card
                </Header>
                <CardImport {...this.props}
                    isClaim={false} handleCardImport={(cardNumber) => this.handleCardImport(cardNumber, false)} />
                <Divider />
                <CardImport {...this.props}
                    isClaim={true} handleCardImport={(claimNumber) => this.handleCardImport(claimNumber, true)} />
            </>
        );
    };

    renderLinks = () => {
        const { settings } = this.props.locations.location;
        return (
            <Segment>
                <Item.Group>
                    <Item className='align-center'>
                        <Item.Header>Manage or Purchase a Gift Card.</Item.Header>
                    </Item>
                    {settings.cards.digital.card_management.buy_card && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/Purchase/0'}
                                name={'/mycards/Purchase/0'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                PURCHASE A NEW CARD
                            </Button>
                        </Item>
                    )}
                    {settings.cards.digital.card_management.import_card && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/import'}
                                name={'/mycards/import'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                ADD AN EXISTING CARD
                            </Button>
                        </Item>
                    )}
                    {settings.cards.physical.check_card_balance && (
                        <Item>
                            <Button
                                as={Link}
                                to={'/mycards/check'}
                                name={'/mycards/check'}
                                fluid
                                circular
                                style={getConfiguration().button_color}
                            >
                                CHECK A CARD BALANCE
                            </Button>
                        </Item>
                    )}
                </Item.Group>
            </Segment>
        );
    };

    render() {
        const { errors, showMessage, successMessage } = this.state;
        const { color } = getConfiguration().label_color;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <Grid padded>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: [`.ui.form .field>label { color: ${color};}`].join('\n'),
                                }}
                            />
                            <Grid.Column mobile={16} computer={11}>
                                <Segment>
                                    {successMessage && showMessage ? (
                                        <Message
                                            icon
                                            success
                                            visible={showMessage}
                                            header={successMessage.header.toUpperCase()}
                                            content={successMessage.message}
                                        />
                                    ) : errors && showMessage ? (
                                        <Message
                                            icon
                                            error
                                            visible={showMessage}
                                            header={'Error'}
                                            content={errors.message}
                                        />
                                    ) : null}
                                    <Dimmer active={this.state.loading} inverted>
                                        <Loader size='small' inline='centered'>
                                            Loading
                                        </Loader>
                                    </Dimmer>
                                    {this.renderContent()}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={5}>
                                {this.renderLinks()}
                            </Grid.Column>
                        </Grid>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const { users, cards, locations } = state;
        return { users, cards, locations };
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...cardStore.actionCreators,
                ...locationStore.actionCreators,
            },
            dispatch
        )
)(withRouter(CardOrderImport));
