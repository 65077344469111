import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {range} from 'lodash';
import {Card, Dimmer, Dropdown, Grid, Header, Loader, Menu, Placeholder,} from 'semantic-ui-react';

import {Media} from '../../AppMedia';
import CacheBuster from '../../CacheBuster';
import {actionCreators} from '../../store/Menus';
import * as locationStore from '../../store/Location';
import * as ticketStore from '../../store/Ticket';
import {getConfiguration} from '../../store/api/apiClient';

class OrderCategories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuId: props.match.params.menuId,
            width: window.innerWidth,
            loading: true,
        }
    }

    componentDidMount() {
        this.handleInit();
        this.scrollStep()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.scrollStep()
    }

    handleInit = async () => {
        this.setState({ loading: true });
        await this.props.locationAnnouncement();
        await this.props.getLocation();
        await this.props.getTicketOrder();
        await this.props.getMenus(false);
        await this.props.getMenuCategories(false, this.props.match.params.menuId);
        this.setState({ loading: false });
    }

    goToCategory(menu) {
        this.scrollStep()
        this.setState({
            menuId: menu.external_id
        })
        this.props.getMenuCategories(false, menu.external_id)
    }

    goToItems(menu) {
        this.scrollStep()
        this.props.pushMenuTitle(menu.name.toUpperCase());
        this.props.history.push('/order/' + menu.external_menu_id + "/category/" + menu.external_id)
    }

    scrollStep() {
        if(window.document.getElementById('orderItem')){
            window.document.getElementById('orderItem').scrollTo({top:0, behavior: "smooth"})
            window.document.getElementById('orderItem').scroll({top:0, behavior: "smooth"})
        }
    }

    render() {
        const {title} = this.props.menus;
        const {backgroundColor, ...rest} = getConfiguration().button_color;
        const { menuId, width } = this.state;

        let cutIndex = 9
        let cutMenuIndex = 9
        let found = false
        let foundMenu = false
        const concatCategories = this.props.menus.categories ? this.props.menus.categories.categories.reduce((accumulator, currentValue, index) => {
            let currentLength = accumulator.concat(currentValue.name).join(" ").length
            if (!found && currentLength > 115) {
                found = true
                cutIndex = index
            }
            return accumulator.concat(currentValue.name)
        }, []).join(" ").length : null

        const concatMenus = this.props.menus.data.menus ? this.props.menus.data.menus.reduce((accumulator, currentValue, index) => {
            let currentLength = accumulator.concat(currentValue.name).join(" ").length
            if (!foundMenu && currentLength > 115) {
                foundMenu = true
                cutMenuIndex = index
            }
            return accumulator.concat(currentValue.name)
        }, []).join(" ").length : null

        const currentMenu = this.props.menus.data.menus ? this.props.menus.data.menus.find(menu => menu.external_id === menuId) : {}

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        console.log('BROWSER IS BEING REFRESHED!');
                        refreshCacheAndReload();
                    }
                    return (
                        <>
                            <Dimmer active={this.state.loading} inverted>
                                <Loader size='small' inline='centered'>
                                    Loading
                                </Loader>
                            </Dimmer>
                            <Media
                                lessThan='computer'
                                style={{
                                    background: 'white',
                                    margin: -14,
                                    padding: '0px 5px',
                                    borderTopLeftRadius: '5px',
                                    borderTopRightRadius: '5px',
                                }}
                                onUpdate={this.handleOnUpdate}
                            >
                                <Menu secondary>
                                    <Menu.Item
                                        style={{
                                            marginTop: 10,
                                            padding: 10,
                                        }}
                                    >
                                        <Header
                                            style={{
                                                textDecoration: 'none',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                width: 'calc(100vw * 4 / 5)',
                                                color: backgroundColor
                                            }}
                                        >
                                            {title[title.length - 1]}
                                        </Header>
                                    </Menu.Item>
                                </Menu>
                            </Media>
                            <Media
                                greaterThanOrEqual='computer'
                                style={{
                                    background: 'white',
                                    margin: -14,
                                    padding: '0px 5px',
                                    borderTopLeftRadius: '5px',
                                    borderTopRightRadius: '5px',
                                }}
                                onUpdate={this.handleOnUpdate}
                            >
                                <Menu
                                    pointing
                                    secondary
                                    style={{borderBottom: '2px solid rgba(34, 36, 38, 0.01)'}}
                                >
                                    {this.props.menus.data.menus
                                        ? this.props.menus.data.menus.map((menu, index) => {
                                            let isActive = menuId === menu.external_id;
                                            return index <= cutMenuIndex ? (
                                                <Menu.Item key={menu.external_id}
                                                        active={isActive}
                                                        style={{
                                                            marginTop: 10,
                                                            padding: 10,
                                                            color: isActive ? backgroundColor : 'inherit',
                                                            borderColor: isActive ? backgroundColor : '#ffffff00',
                                                        }}
                                                        onClick={this.goToCategory.bind(this, menu)}
                                                        name={menu.name}
                                                        content={menu.name.toUpperCase()}
                                                />
                                            ): null
                                        })
                                        : null}
                                    {this.props.menus.data.menus ? (
                                        this.props.menus.data.menus.length > cutMenuIndex + 1 ? (
                                            <Dropdown
                                                item
                                                text='MORE'
                                                style={{
                                                    padding: 10,
                                                }}
                                            >
                                                <Dropdown.Menu>
                                                    {this.props.menus.data.menus.map(
                                                        (menu, index) => {
                                                            let isActive = menuId === menu.external_id;
                                                            return index > cutMenuIndex ? (
                                                                <Dropdown.Item
                                                                    key={menu.external_id}
                                                                    active={isActive}
                                                                    style={{
                                                                        marginTop: 10,
                                                                        padding: 10,
                                                                        color: isActive ? backgroundColor : 'inherit',
                                                                        borderColor: isActive
                                                                            ? backgroundColor
                                                                            : '#ffffff00',
                                                                    }}
                                                                    onClick={this.goToCategory.bind(this, menu)}
                                                                    text={menu.name.toUpperCase()}
                                                                />
                                                            ) : null;
                                                        }
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ) : null
                                    ) : null}
                                </Menu>
                                <Menu stackable pointing secondary
                                    style={{borderBottom: '2px solid rgba(34, 36, 38, 0.01)'}}>
                                    {this.props.menus.categories ?
                                        this.props.menus.categories.categories.length > 0 ?
                                            this.props.menus.categories.categories.map((menu, index) => {
                                                    return index <= cutIndex ? <Menu.Item
                                                        key={menu.external_id}
                                                        style={{
                                                            padding: 10,
                                                        }}
                                                        as={Link}
                                                        to={'/order/' + menu.external_menu_id + "/category/" + menu.external_id}
                                                        name={menu.name}
                                                        content={menu.name.toUpperCase()}
                                                    /> : null
                                                }
                                            ) : null : null
                                    }
                                    {this.props.menus.categories ?
                                        this.props.menus.categories.categories.length > cutIndex + 1 ?
                                            <Dropdown item text='MORE'
                                                    style={{
                                                        padding: 10
                                                    }}>
                                                <Dropdown.Menu>
                                                    {this.props.menus.categories.categories.map((menu, index) => {
                                                            return index > cutIndex ? <Dropdown.Item
                                                                key={menu.external_id}
                                                                style={{
                                                                    marginTop: 10,
                                                                    padding: 10,
                                                                }}
                                                                as={Link}
                                                                to={'/order/' + menu.external_menu_id + "/category/" + menu.external_id}
                                                                text={menu.name.toUpperCase()}
                                                            /> : null
                                                        }
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown> : null : null
                                    }
                                </Menu>
                            </Media>
                            <Grid textAlign='center' verticalAlign='middle'>
                                <Grid.Column style={{maxWidth: 1280, marginTop: 20}}>
                                    {currentMenu.description &&
                                        <Card fluid raised style={{ marginBottom: 0 }}>
                                            <Card.Content textAlign='left'>
                                                {currentMenu.description}
                                            </Card.Content>
                                        </Card>
                                    }
                                    <Card.Group stackable doubling itemsPerRow={3} style={currentMenu.description ? { marginTop: '1em' } : {}}>
                                        {this.props.menus.categories ?
                                            this.props.menus.categories.categories.length > 0 ?
                                                this.props.menus.categories.categories.map(menu =>
                                                    <Card raised key={menu.id}
                                                        onClick={this.goToItems.bind(this, menu)}
                                                        style={{
                                                            minHeight:
                                                                width >= 768 ? 250 : 180,
                                                            backgroundImage: 'url("' + menu.image.imageSignedUrl + '")',
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center top'
                                                        }}>
                                                        <Card.Content style={{
                                                            position: 'absolute',
                                                            width: '100%',
                                                            bottom: 0,
                                                            textAlign: 'center',
                                                            border: 'none',
                                                            background: 'linear-gradient(to left bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 1))',
                                                            background: '-webkit-linear-gradient(left bottom, rgb(253, 253, 253, 0.9) 65%, rgba(0, 0, 0, 0.1) 100%)',
                                                        }}>
                                                            <Card.Header>{menu.name}</Card.Header>
                                                        </Card.Content>
                                                    </Card>) :
                                                <span style={{fontSize: 18, margin: '30px auto 10px auto'}}
                                                    color='black'>
                                                    No categories on this menu
                                                </span>
                                            : range(9).map(menu =>
                                                <Card raised key={menu}
                                                    style={{
                                                        height: 250
                                                    }}>
                                                    <Placeholder>
                                                        <Placeholder.Image rectangular/>
                                                    </Placeholder>
                                                    <Card.Content className="item-content" style={{
                                                        position: 'absolute',
                                                        width: '100%',
                                                        bottom: 0,
                                                        textAlign: 'center',
                                                        background: 'linear-gradient(to left bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 1))',
                                                        background: '-webkit-linear-gradient(left bottom, rgb(253, 253, 253, 0.9) 65%, rgba(0, 0, 0, 0.1) 100%)',
                                                        borderTop: 'none',
                                                    }}>
                                                        <Grid textAlign='left'>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}
                                                                            style={{paddingRight: 0}}>
                                                                    <Placeholder>
                                                                        <Placeholder.Header>
                                                                            <Placeholder.Line/>
                                                                        </Placeholder.Header>
                                                                    </Placeholder>
                                                                </Grid.Column>

                                                            </Grid.Row>
                                                            <Grid.Row style={{
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                maxHeight: 65,
                                                                minHeight: 65
                                                            }}>
                                                                <Grid.Column>
                                                                    <Placeholder>
                                                                        <Placeholder.Paragraph>
                                                                            <Placeholder.Line/>
                                                                            <Placeholder.Line/>
                                                                            <Placeholder.Line/>
                                                                        </Placeholder.Paragraph>
                                                                    </Placeholder>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>

                                                    </Card.Content>
                                                </Card>)}
                                    </Card.Group>
                                </Grid.Column>
                            </Grid>
                        </>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default connect(
    (state) => {
        const {menus} = state;
        return {menus};
    },
    (dispatch) =>
        bindActionCreators(
            {
                ...actionCreators,
                ...locationStore.actionCreators,
                ...ticketStore.actionCreators
            },
            dispatch
        )
)(OrderCategories);
